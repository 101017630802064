import { css, styled } from '@mui/material';

import { PALETTE } from '../../../theme/palette';

const cardBackground = (product) =>
  ({
    dental: {
      backgroundColor: PALETTE.DA_DIREKT_LIGHT_ORANGE,
    },
    pet: {
      backgroundColor: PALETTE.DA_DIREKT_LIGHT_BLUE,
    },
    health: {
      backgroundColor: PALETTE.DA_DIREKT_LIGHT_GREEN,
    },
  })[`${product}`];

export const StyledDaDirektCrossSellingCard = styled('div')(
  ({ theme, product }) => css`
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: 1.125rem;
    width: 100%;
    height: 17.75rem;

    ${cardBackground(product)};

    ${theme.breakpoints.up('desktop')} {
      padding-left: 1.25rem;
      height: 300px;
    }
  `
);

export const StyledDaDirektCrossSellingCardContent = styled('div')(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    margin-top: 1.5rem;
    max-width: initial;
    z-index: 1;

    ${theme.breakpoints.up('desktop')} {
      margin-top: 2.5rem;
      max-width: 400px;
    }
  `
);

export const StyledDaDirektCrossSellingCardLogoAndImage = styled('div')(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0;
    align-items: flex-end;
    justify-content: space-between;
    width: 142px;

    ${theme.breakpoints.up('desktop')} {
      row-gap: 1.5rem;
      width: 230px;
    }
  `
);

export const StyledDaDirektCrossSellingCardTitle = styled('h2')(
  ({ theme }) => css`
    max-width: 185px;
    font-family: ${theme.daDirekt.fontFamily};
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 18px;

    & + & {
      margin-top: 0.8rem;
    }

    ${theme.breakpoints.up('desktop')} {
      max-width: 100%;
      font-size: 1.25rem;
      line-height: 26px;

      & + & {
        margin-top: 0;
      }
    }
  `
);

export const StyledDaDirektCrossSellingCardText = styled('p')(
  ({ theme }) => css`
    font-family: ${theme.daDirekt.fontFamily};
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 18px;

    ${theme.breakpoints.up('desktop')} {
      margin-top: 2.75rem;
      font-size: 0.875rem;
      max-width: 350px;
    }
  `
);

export const StyledDaDirektCrossSellingCardUl = styled('ul')(
  ({ theme, bulletimage }) => css`
    margin: 0;
    padding-left: 1.125rem; // same as the container's padding-left
    list-style-image: url(${bulletimage});
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 22px;

    > li {
      font-family: ${theme.daDirekt.fontFamily};
    }
  `
);

export const StyledDaDirektCrossSellingCardDaDirektLogo = styled('img')(
  ({ theme }) => css`
    margin-right: 0.875rem;
    margin-top: initial;
    margin-bottom: 1.5rem;
    width: 67px;
    height: auto;

    ${theme.breakpoints.up('desktop')} {
      margin-right: 1.5rem;
      margin-top: 1.5rem;
      margin-bottom: initial;
      width: 92px;
    }
  `
);

export const StyledDaDirektCrossSellingCardButton = styled('button')(
  ({ theme }) => css`
    display: block;
    all: unset;
    margin-top: 0.5rem;
    padding: 0.688rem 0;
    width: 186px;
    font-family: ${theme.daDirekt.fontFamily};
    font-size: 0.875rem;
    font-weight: 700;
    text-align: center;
    color: ${PALETTE.WHITE};
    background-color: ${theme.palette.main.professional_blue};
    border-radius: 4px;
    cursor: pointer;

    ${theme.breakpoints.up('desktop')} {
      margin-top: 1.125rem;
      padding: 0.75rem;
    }
  `
);
