import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '../../../components/common';

import {
  StyledPromotionCarouselControls,
  StyledPromotionCarouselControlsDot,
  StyledPromotionCarouselControlsDots,
} from './PromotionCarousel.styled';

const PromotionCarouselControls = (props) => {
  const { slides, slideCount, current, onPrevious, onNext, onGoto } = props;

  if (slideCount <= 1) return null;

  return (
    <StyledPromotionCarouselControls>
      <IconButton icon="icon.back.arrow" size="sm" onClick={onPrevious} />

      <StyledPromotionCarouselControlsDots>
        {slides.map((slide, idx) => (
          <StyledPromotionCarouselControlsDot
            key={slide?.id}
            active={current === idx}
            onClick={() => onGoto(idx)}
          />
        ))}
      </StyledPromotionCarouselControlsDots>

      <IconButton icon="icon.caret.right" size="sm" onClick={onNext} />
    </StyledPromotionCarouselControls>
  );
};

export default PromotionCarouselControls;

PromotionCarouselControls.propTypes = {
  slideCount: PropTypes.number,
  current: PropTypes.number,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onGoto: PropTypes.func,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
};
