// eslint-disable-next-line import/prefer-default-export
export const muiIconButtonComponent = {
  defaultProps: {
    disableRipple: true,
    size: 'medium',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing('NONE'),
      height: theme.spacing('2XL'),
      width: theme.spacing('2XL'),

      [`&:focus`]: {
        boxShadow: theme.shadows[4],
      },

      [`& > svg`]: {
        height: theme.spacing('MEDIUM'),
        width: theme.spacing('MEDIUM'),
      },

      [`&.Mui-disabled`]: {
        opacity: '0.6',
      },
    }),
    sizeLarge: ({ theme }) => ({
      [`& > svg`]: {
        height: theme.spacing('1XL'),
        width: theme.spacing('1XL'),
      },
    }),
  },
  variants: [
    {
      props: { variant: 'round' },
      style: ({ theme }) => ({
        background: theme.palette.background.button,
        height: theme.spacing('4XL'),
        width: theme.spacing('4XL'),

        [`& > svg`]: {
          height: theme.spacing('1XL'),
          width: theme.spacing('1XL'),
        },

        [`&:hover`]: {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), ${theme.palette.background.button}`,
        },

        [`&.Mui-disabled`]: {
          background: theme.palette.background.button,
        },
      }),
    },
  ],
};
