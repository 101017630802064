import { css, Paper, styled } from '@mui/material';

import { promotionCarouselSliderHeight } from '../PromotionCarousel/PromotionCarousel.styled';

export const StyledPromotionCarouselCard = styled(Paper)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: ${promotionCarouselSliderHeight.mobile};
    justify-content: space-between;

    ${theme.breakpoints.up('desktop')} {
      height: ${promotionCarouselSliderHeight.desktop};
      justify-content: end;
      padding: ${theme.spacing('2XL')};
    }
  `
);

export const StyledPromotionCarouselCardContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    ${theme.breakpoints.up('desktop')} {
      justify-content: end;
      row-gap: ${theme.spacing('LARGE')};
    }
  `
);

export const StyledPromotionCarouselCardContentImageWrapper = styled('div')(
  ({ theme }) => css`
    margin: 0 auto;
    width: auto;
    height: 3rem;

    > img {
      width: 100%;
      height: 100%;
    }

    ${theme.breakpoints.up('desktop')} {
      height: 4.875rem;
    }
  `
);

export const StyledPromotionCarouselCardContentTitle = styled('div')(
  ({ theme }) => css`
    text-align: center;

    ${theme.breakpoints.up('desktop')} {
    }
  `
);

export const StyledPromotionCarouselButtonContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing('LARGE')};

    ${theme.breakpoints.up('desktop')} {
      margin-top: ${theme.spacing('1XL')};
    }
  `
);
