import React, { useState } from 'react';
import Slider from 'react-slick';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Typography } from '@mui/material';

import { imgLinks } from '../../../shared';

import {
  StyledOnlineVetSlider,
  StyledOnlineVetSliderCounter,
  StyledOnlineVetSliderCustomDots,
  StyledOnlineVetSliderTitle,
} from '../OnlineVets.styled';

const slidesConfig = [
  {
    title: <FormattedMessage id="first.vet.slider.create.account" />,
    img: 'icon.online.vet.slider.one',
    alt: 'slide-one',
    bodyText: <FormattedMessage id="first.vet.slider.create.account.description" />,
  },
  {
    title: <FormattedMessage id="first.vet.slider.appointment" />,
    img: 'icon.online.vet.slider.two',
    alt: 'slide-two',
    bodyText: <FormattedMessage id="first.vet.slider.appointment.description" />,
  },
  {
    title: <FormattedMessage id="first.vet.slider.secure" />,
    img: 'icon.online.vet.slider.three',
    alt: 'slide-three',
    bodyText: <FormattedMessage id="first.vet.slider.secure.description" />,
  },
];

const Slide = ({ title, bodyText }) => {
  return (
    <>
      <StyledOnlineVetSliderTitle>
        <Typography variant="h3">{title}</Typography>
      </StyledOnlineVetSliderTitle>
      <Typography variant="p">{bodyText}</Typography>
    </>
  );
};

const renderCustomDots = (dots) => (
  <StyledOnlineVetSliderCustomDots>{dots}</StyledOnlineVetSliderCustomDots>
);

const FirstVetPromoSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(null);
  const [nextSlide, setNextSlide] = useState(0);

  const sliderSettings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    appendDots: (dots) => renderCustomDots(dots), // custom dots
    beforeChange: () => {
      setNextSlide(nextSlide);
      setCurrentSlide(null);
    },
    afterChange: (idx) => {
      setCurrentSlide(slidesConfig[`${idx}`]);
    },
    onInit: () => {
      setCurrentSlide(slidesConfig[0]);
    },
  };

  return (
    <StyledOnlineVetSlider>
      <CSSTransition in={!!currentSlide} timeout={400} classNames="fade" unmountOnExit>
        <StyledOnlineVetSliderCounter
          src={imgLinks[currentSlide?.img]}
          alt={currentSlide?.alt || ''}
        />
      </CSSTransition>

      <Slider {...sliderSettings}>
        {slidesConfig.map((card) => (
          <Slide key={card.alt} {...card} style={{ display: 'flex' }} />
        ))}
      </Slider>
    </StyledOnlineVetSlider>
  );
};

export default FirstVetPromoSlider;
