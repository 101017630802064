import de from './de';
import en from './en';
import fr from './fr';

export const ACCOUNT_AREA_LOCALE = {
  DE: 'de',
  EN: 'en',
  FR: 'fr',
};

export const messages = {
  [ACCOUNT_AREA_LOCALE.DE]: { ...de },
  [ACCOUNT_AREA_LOCALE.EN]: { ...en },
  [ACCOUNT_AREA_LOCALE.FR]: { ...fr },
};
