import { css, styled } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const StyledDialogHeaderContainer = styled('div')(
  ({ theme, hasTitle }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${hasTitle ? theme.spacing('1XL') : theme.spacing('SMALL')};
    padding: ${theme.spacing('LARGE')};
    padding-bottom: ${theme.spacing('NONE')};
  `
);
