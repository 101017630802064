import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { Link, Typography } from '@mui/material';

import { StyledAccordionToggle } from './SignDocs.styled';

const Accordion = (props) => {
  const { children, toggleTitle, showText, hideText, onExpand, onCollapse } = props;
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    if (open) {
      if (onCollapse) onCollapse();
    } else if (onExpand) {
      onExpand();
    }

    setOpen(!open);
  };

  return (
    <>
      <StyledAccordionToggle>
        <Typography variant="p">{toggleTitle}</Typography>

        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link component="button" variant="p" onClick={handleToggle}>
          {open ? hideText : showText}
        </Link>
      </StyledAccordionToggle>

      <CSSTransition in={open} timeout={450} classNames="slide-down" unmountOnExit>
        <div>{children}</div>
      </CSSTransition>
    </>
  );
};

Accordion.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  toggleTitle: PropTypes.string,
  showText: PropTypes.string,
  hideText: PropTypes.string,
  onExpand: PropTypes.func,
  onCollapse: PropTypes.func,
};

export default Accordion;
