import React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { GET_FAVORITE_DENTISTS, GET_DENTISTS, GET_CUSTOMER } from '../../../shared';

import FavoriteDentistBlock from './FavoriteDentistBlock';

import {
  StyledFavoriteDentistMessage,
  StyledNoFavoriteDentistsMessage,
} from './FavoriteDentist.styled';

const FavoriteDentist = () => {
  const getDentistList = useQuery(GET_DENTISTS);
  const getCustomer = useQuery(GET_CUSTOMER);
  const { data: favoriteDentistsData } = useQuery(GET_FAVORITE_DENTISTS);

  const filterFavouriteDentists = (dentists, favourites) => {
    const favouritesKeys = favourites?.map((f) => f?.dentistKey);
    return dentists && favourites
      ? dentists?.filter((dentist) => favouritesKeys?.includes(dentist?.referenceKey))
      : [];
  };

  const favouriteDentists = filterFavouriteDentists(
    getDentistList?.data?.dentists,
    favoriteDentistsData?.customer?.favoriteDentists
  );

  if (favouriteDentists?.length === 0) {
    return (
      <StyledNoFavoriteDentistsMessage>
        <Typography variant="p">
          <FormattedMessage id="map.dentists.tabs.dentist.favourite.message.no_dentist" />
        </Typography>
      </StyledNoFavoriteDentistsMessage>
    );
  }

  return (
    <>
      <StyledFavoriteDentistMessage>
        <Typography variant="p">
          <FormattedMessage id="map.dentists.tabs.dentist.favourite.message" />
        </Typography>
      </StyledFavoriteDentistMessage>

      <FavoriteDentistBlock
        customer={getCustomer?.data?.customer}
        favouriteDentists={favouriteDentists}
      />
    </>
  );
};

export default FavoriteDentist;
