import { useIntl } from 'react-intl';

import { ACCOUNT_AREA_LOCALE } from '../locale';
import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO, INSURANCE_TYPE_VITOLO } from '../shared';
import useInsuranceInfo from './useInsuranceInfo';

export const useCompanyLogo = () => {
  const { type: insuranceType } = useInsuranceInfo();
  const intl = useIntl();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'company_logo.dentolo';
    case INSURANCE_TYPE_PETOLO:
      return intl.locale === ACCOUNT_AREA_LOCALE.FR ? 'company_logo.patolo' : 'company_logo.petolo';
    case INSURANCE_TYPE_VITOLO:
      return 'company_logo.vitolo';
    default:
      return 'company_logo.dentolo';
  }
};

export const useDownloadIcon = () => {
  const { type: insuranceType } = useInsuranceInfo();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.download.dental';
    case INSURANCE_TYPE_PETOLO:
      return 'icon.download.pet';
    case INSURANCE_TYPE_VITOLO:
      return 'icon.download.vitolo';
    default:
      return 'icon.download.dental';
  }
};

export const useDeleteIcon = () => {
  const { type: insuranceType } = useInsuranceInfo();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.delete.circle.dental';
    case INSURANCE_TYPE_PETOLO:
      return 'icon.delete.circle.pet';
    case INSURANCE_TYPE_VITOLO:
      return 'icon.delete.circle.violet';
    default:
      return 'icon.delete.circle.dental';
  }
};

export const useFileUploadIcon = () => {
  const { type: insuranceType } = useInsuranceInfo();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.plus.teal';
    case INSURANCE_TYPE_PETOLO:
      return 'icon.plus.orange';
    case INSURANCE_TYPE_VITOLO:
      return 'icon.plus.violet';
    default:
      return 'icon.plus.teal';
  }
};

export const useCheckIcon = () => {
  const { type: insuranceType } = useInsuranceInfo();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'icon.success';
    case INSURANCE_TYPE_PETOLO:
      return 'icon-checked-stroke-orange';
    case INSURANCE_TYPE_VITOLO:
      return 'icon.check.vitolo.stroke';
    default:
      return 'icon.success';
  }
};

export const useEditIcon = () => {
  const { type: insuranceType } = useInsuranceInfo();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return 'edit_icon_dentolo';
    case INSURANCE_TYPE_PETOLO:
      return 'edit_icon_petolo';
    case INSURANCE_TYPE_VITOLO:
      return 'edit_icon_vitolo';
    default:
      return 'edit_icon_dentolo';
  }
};
