import { css, styled } from '@mui/material';

export const StyledVitoloReferralStatusEmptyContainer = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing('1XL')};
  `
);

export const StyledVitoloReferralStatusEmptyImageContainer = styled('div')(
  ({ theme }) => css`
    margin: ${theme.spacing('LARGE')} auto;
    padding: 0;
    width: 18.75rem;
    height: auto;
    overflow: hidden;

    ${theme.breakpoints.up('desktop')} {
      margin-bottom: 0;
      width: 25rem;
    }
  `
);

export const StyledVitoloReferralStatusEmptyImg = styled('img')`
  max-width: 100%;
  width: 100%;
  height: auto;
`;
