import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Tab, Tabs, Typography } from '@mui/material';

import DentoloReferralInformation from './DentoloReferralInformation';
import DentoloReferralStatus from './DentoloReferralStatus';
import { PageTitle } from '../../../../components/common';

function DentoloReferral() {
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);

  const handleSelectTab = (event, index) => {
    event.persist();
    setSelectedTabIdx(index);
  };

  return (
    <Container>
      <PageTitle data-testid="pagename_referral_dentolo">
        <Typography variant="h1">
          <FormattedMessage
            id="referral.page.dentolo.title"
            values={{ amount: 50, couponbrand: 'Amazon Gutschein' }}
          />
        </Typography>
      </PageTitle>

      <Tabs value={selectedTabIdx} aria-label="referral details" onChange={handleSelectTab}>
        <Tab
          label={
            <Typography variant={selectedTabIdx === 0 ? 'h3' : 'p'}>
              <FormattedMessage id="referral.page.tab.label.one" />
            </Typography>
          }
          id="referral_information_tab"
          aria-controls="referral_information_tabpanel"
        />

        <Tab
          label={
            <Typography variant={selectedTabIdx === 1 ? 'h3' : 'p'}>
              <FormattedMessage id="referral.page.tab.label.two" />
            </Typography>
          }
          id="referral_status_tab"
          aria-controls="referral_status_tabpanel"
        />
      </Tabs>

      <div
        role="tabpanel"
        hidden={selectedTabIdx !== 0}
        id="referral_information_tabpanel"
        aria-labelledby="referral_information_tab"
      >
        <DentoloReferralInformation />
      </div>

      <div
        role="tabpanel"
        hidden={selectedTabIdx !== 1}
        id="referral_status_tabpanel"
        aria-labelledby="referral_status_tab"
      >
        <DentoloReferralStatus />
      </div>
    </Container>
  );
}

export default DentoloReferral;
