import { ACCOUNT_AREA_LOCALE, messages } from './messages';
import { DOMAIN_PATOLO } from '../shared';

export function getLocale() {
  if (window.location.host.includes(DOMAIN_PATOLO)) {
    return ACCOUNT_AREA_LOCALE.FR;
  }

  return ACCOUNT_AREA_LOCALE.DE;
}

export { ACCOUNT_AREA_LOCALE, messages };
