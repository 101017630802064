import { styled, css, Typography } from '@mui/material';

import { PALETTE } from '../../../theme/palette';
import { imgLinks } from '../../../shared';
import { promotionCarouselSliderHeight } from '../PromotionCarousel/PromotionCarousel.styled';

export const StyledReferralLinkCardVitolo = styled('div')(
  ({ theme, imgMobile, imgDesktop }) => css`
    position: relative;
    padding: 1rem;
    padding-top: 1.75rem;
    width: 100%;
    height: ${promotionCarouselSliderHeight.mobile};
    background-color: ${PALETTE.VIBRANT_LIGHT_VIOLET};
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: url(${imgMobile}) no-repeat;
      background-size: contain;
      background-position: center right;
    }

    ${theme.breakpoints.up('desktop')} {
      padding: 2.625rem ${theme.spacing('2XL')};
      height: ${promotionCarouselSliderHeight.desktop};

      &::before {
        background: url(${imgDesktop}) no-repeat;
        background-size: contain;
        background-position: center right;
      }
    }
  `
);

export const StyledReferralLinkCardVitoloContent = styled('div')(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: ${theme.spacing('2XL')};
    width: 100%;
    height: auto;

    ${theme.breakpoints.up('desktop')} {
      justify-content: flex-start;
    }
  `
);

export const StyledReferralLinkCardVitoloText = styled(Typography)(
  ({ theme }) => css`
    width: 50%;
    max-width: 13.75rem;

    > span {
      position: relative;
      display: inline-block;
      padding: 0 0.25rem;
      margin-left: -0.25rem;
      margin-right: -0.25rem;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1875rem;
        background: url(${imgLinks['image-vitolo-underline']}) no-repeat center;
        background-size: 100% 100%;
      }
    }

    ${theme.breakpoints.up('desktop')} {
      > span {
        &::after {
          bottom: -0.1875rem;
          height: 0.375rem;
        }
      }
    }
  `
);
