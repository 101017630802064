import { css, styled } from '@mui/material';

export const StyledForm = styled('form')(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
  `
);

export const StyledVerticalContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledSeparator = styled('hr')(
  ({ theme }) => css`
    background-color: ${theme.palette.main.product_blue};
    border: none;
    height: 1px;
    margin: 0;
    width: 100%;
  `
);

export const StyledRadioInputAndLabel = styled('div')`
  display: flex;
`;

export const StyledFormLegend = styled('legend')(
  ({ theme }) => css`
    padding: 0;
    margin-bottom: ${theme.spacing('SMALL')};
  `
);

export const StyledInputGroup = styled('div')(
  ({ theme }) => css`
    display: flex;
    column-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledBigInputContainer = styled('div')`
  flex: 4 1 0;
`;

export const StyledSmallInputContainer = styled('div')`
  flex: 1 1 0;
`;

export const StyledDigitalPaymentContainerTitle = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing('3XL')};
  `
);

export const StyledDigitalPaymentContainer = styled('div')(
  ({ theme, hasApplePay }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${hasApplePay ? '49rem' : '45rem'};
    border-radius: 5px;

    ${theme.breakpoints.up('desktop')} {
      height: ${hasApplePay ? '48rem ' : '44rem'};
    }
  `
);
