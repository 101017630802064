// eslint-disable-next-line import/prefer-default-export
export const muiTabsComponent = {
  defaultProps: {
    selectionFollowsFocus: true,
    variant: 'fullWidth',
  },
  styleOverrides: {
    indicator: ({ theme }) => ({
      backgroundColor: theme.palette.main.product_blue,
      height: '2px',
    }),
  },
};
