// eslint-disable-next-line import/prefer-default-export
export const muiLinkComponent = {
  defaultProps: {
    component: 'a',
    underline: 'none',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.main.professional_blue,
      cursor: 'pointer',
      textAlign: 'left',
      textDecoration: 'underline',

      [`&:hover`]: {
        textDecoration: 'none',
      },

      [`&:active`]: {
        color: theme.palette.main.product_blue,
      },

      [`&:focus, &.Mui-focusVisible`]: {
        border: `2px solid ${theme.palette.main.accent_blue}`,
        borderRadius: '2px',
        outline: 'none',
      },

      [`&[disabled]`]: {
        opacity: '0.4',
        pointerEvents: 'none',
      },
    }),
  },
};
