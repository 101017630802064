import { css, styled } from '@mui/material';

import ButtonContainer from '../../../components/ButtonContainer';

export const StyledImageContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-bottom: ${theme.spacing('1XL')};
  `
);

export const StyledCancellationReasonsTitle = styled('div')(
  ({ theme }) => css`
    text-align: center;
    margin-bottom: ${theme.spacing('1XL')};
  `
);

export const StyledCancellationReasonsDescription = styled('div')(
  ({ theme }) => css`
    text-align: center;
    margin-top: ${theme.spacing('1XL')};
    margin-bottom: ${theme.spacing('LARGE')};
  `
);

export const StyledButtonContainer = styled(ButtonContainer)(
  ({ theme }) => css`
    flex-direction: column-reverse;

    ${theme.breakpoints.up('desktop')} {
      flex-direction: row-reverse;
    }
  `
);

export const StyledReason = styled('div')(
  ({ theme }) => css`
    column-gap: ${theme.spacing('LARGE')};
    display: flex;
    padding: ${theme.spacing('LARGE')};
  `
);
