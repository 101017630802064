import React from 'react';
import { useTheme } from '@mui/material';
import CreatableSelect from 'react-select/creatable';

export const customSelectStyles = (theme) => ({
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? theme.palette.background.info : theme.palette.common.white,
    color: isFocused ? theme.palette.main.professional_blue : theme.palette.text.subtext,
    padding: '.75em',
  }),
  container: (styles) => ({ ...styles }),
  control: (styles, { isSelected, isFocused }) => ({
    ...styles,
    'paddingRight': '0.5em',
    'height': '3.5em',
    'borderColor':
      isSelected || isFocused ? theme.palette.main.professional_blue : theme.palette.text.subtext,
    'boxShadow': isSelected || isFocused ? theme.shadows[3] : 'none',
    ':hover': {
      borderColor:
        isSelected || isFocused ? theme.palette.main.professional_blue : theme.palette.text.subtext,
      boxShadow: isSelected || isFocused ? theme.shadows[3] : 'none',
    },
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: '.25em',
    fontFamily: '"Circular TT", "Arial", sans-serif',
    zIndex: 3,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: theme.palette.main.professional_blue,
    fontFamily: '"Circular TT", "Arial", sans-serif',
    fontSize: '1em',
  }),
  input: (styles) => ({
    ...styles,
    'color': theme.palette.main.professional_blue,
    'fontFamily': '"Circular TT", "Arial", sans-serif',
    'fontSize': '1em',
    'input:focus': {
      boxShadow: 'none !important',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: theme.palette.text.subtext,
    fontSize: '1em',
    fontFamily: '"Circular TT", "Arial", sans-serif',
  }),
  clearIndicator: (styles) => ({
    ...styles,
    'cursor': 'pointer',
    'color': theme.palette.gray.light_gray,
    ':hover': {
      color: theme.palette.main.professional_light_blue,
    },
  }),
});

const Select = (props) => {
  const theme = useTheme();
  const customStylesForSelect = customSelectStyles(theme);

  return <CreatableSelect styles={customStylesForSelect} {...props} />;
};

export { Select };
