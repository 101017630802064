import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { referralSteps } from './vitoloReferral.helper';

import {
  StyledReferralAdditionalInformation,
  StyledReferralAdditionalInformationContainer,
  StyledReferralStepsContainer,
  StyledReferralStepTitle,
  StyledVerticalContent,
} from '../ReferralPage.styled';

function VitoloReferralSteps() {
  return (
    <StyledVerticalContent>
      <Typography variant="h2">
        <FormattedMessage id="referral.page.information.header" />
      </Typography>

      <StyledReferralStepsContainer>
        {referralSteps?.map((step) => {
          const { titleId, descriptionId, descriptionValues, additionalInfo, descriptionTestId } =
            step || {};

          return (
            <div key={titleId}>
              <StyledReferralStepTitle variant="h3">
                <FormattedMessage id={titleId} />
              </StyledReferralStepTitle>

              <Typography variant="p" data-testid={descriptionTestId || ''}>
                <FormattedMessage id={descriptionId} values={descriptionValues} />
              </Typography>

              {additionalInfo && (
                <StyledReferralAdditionalInformationContainer>
                  {additionalInfo?.map(({ infoId, testId }) => (
                    <StyledReferralAdditionalInformation
                      variant="p"
                      key={infoId}
                      data-testid={testId || ''}
                    >
                      <FormattedMessage id={infoId} />
                    </StyledReferralAdditionalInformation>
                  ))}
                </StyledReferralAdditionalInformationContainer>
              )}
            </div>
          );
        })}
      </StyledReferralStepsContainer>
    </StyledVerticalContent>
  );
}

export default VitoloReferralSteps;
