import { css, styled } from '@mui/material';

export const StyledOnlineVetFlexContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    > button {
      margin-bottom: ${theme.spacing('1XL')};
    }
  `
);

export const StyledOnlineVetCoverImage = styled('img')(
  ({ theme }) => css`
    width: 100%;
    max-width: 100%;
    height: auto;
    margin-bottom: ${theme.spacing('1XL')};
  `
);

export const StyledOnlineVetSliderTitle = styled('div')(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing('LARGE')};
  `
);

export const StyledOnlineVetSliderCounter = styled('img')(
  ({ theme }) => css`
    position: absolute;
    top: -${theme.spacing('4XL')};
    left: 50%;
    transform: translateX(-50%);
    width: ${theme.spacing('6XL')};
  `
);

export const StyledOnlineVetSlider = styled('div')(
  ({ theme }) => css`
    position: relative;
    margin-top: ${theme.spacing('4XL')};
    margin-bottom: ${theme.spacing('5XL')};
    text-align: center;
    background-color: ${theme.palette.common.white};
    border-radius: 32px;

    .slick-list {
      .slick-slide {
        padding: ${theme.spacing('3XL')} ${theme.spacing('1XL')} ${theme.spacing('2XL')};
      }
    }

    .slick-arrow {
      position: absolute;
      bottom: -${theme.spacing('5XL')};
      width: 2.25rem;
      height: 2.25rem;
      padding: ${theme.spacing('SMALL')};
      font-size: 0;
      border: none;
      background-color: transparent;
      box-shadow: none;
      cursor: pointer;
      z-index: 2;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: transparent;
      }
    }
    .slick-prev {
      left: 0;

      &::before {
        background-image: url('/images/user-area-icons/general/icon.caret.left.svg');
      }
    }

    .slick-next {
      right: 0;

      &::before {
        background-image: url('/images/user-area-icons/general/icon.caret.right.svg');
      }
    }

    .slick-disabled {
      opacity: 0;
    }
  `
);

export const StyledOnlineVetSliderCustomDots = styled('ul')(
  ({ theme }) => css`
    list-style: none;
    position: absolute;
    bottom: -${theme.spacing('4XL')};
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: ${theme.spacing('SMALL')};
    padding: 0;
    margin: 0;

    li {
      button {
        margin: 0;
        padding: 0;
        font-size: 0;
        width: ${theme.spacing('SMALL')};
        height: ${theme.spacing('SMALL')};
        border: 1px solid ${theme.palette.main.professional_blue};
        border-radius: 50%;
        background-color: transparent;
        cursor: pointer;
        transition: background-color 0.23s ease-in-out;
      }

      &.slick-active {
        button {
          background-color: ${theme.palette.main.professional_blue};
        }
      }
    }
  `
);
