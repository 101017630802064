import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, DialogActions, DialogContent, TextField, Typography } from '@mui/material';

import useForm from '../../../shared/useForm';
import useInsuranceInfo from '../../../hooks/useInsuranceInfo';
import { UPDATE_CUSTOMER_DETAILS, GET_CUSTOMER } from '../../../shared';
import { isValidEmail } from '../../../utils/emailValidationUtils';

import { Radio, RadioLabel } from '../../../components/common';
import { ErrorMessage } from '../../../components/common/FormElements';

import {
  StyledForm,
  StyledFormLegend,
  StyledRadioInputAndLabel,
  StyledInputGroup,
  StyledBigInputContainer,
  StyledSmallInputContainer,
  StyledVerticalContent,
} from './CustomerDetailsEditor.styled';

function validateForm(values) {
  const errors = {};

  if (!values?.email || !isValidEmail(values?.email)) {
    errors.email = 'personal.details.form.email.error.message';
  }

  if (
    !values?.phoneNumber ||
    !(values?.phoneNumber?.length >= 6 && values?.phoneNumber?.length <= 14)
  ) {
    errors.phoneNumber = 'personal.details.form.phone.error.message';
  }

  if (!values?.streetName) {
    errors.streetName = 'personal.details.form.address.error.message';
  }

  if (!values?.houseNumber) {
    errors.houseNumber = 'personal.details.form.address.error.message';
  }

  if (!values?.postcode || !(values?.postcode.length === 5)) {
    errors.postcode = 'personal.details.form.address.error.message';
  }

  if (!values?.city) {
    errors.city = 'personal.details.form.address.error.message';
  }

  return errors;
}

function CustomerDetailsEditor({ data, onSuccess, onError }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const intl = useIntl();

  const { type: insuranceType } = useInsuranceInfo();

  const {
    values,
    errors: formErrors,
    isDirty,
    handleChange,
    handleSubmit,
  } = useForm(data, handleFormSubmit, validateForm);

  const { firstName, lastName, ...otherValues } = values;

  const [updateCustomerDetails] = useMutation(UPDATE_CUSTOMER_DETAILS, {
    variables: {
      ...otherValues,
    },
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  async function handleFormSubmit(ev) {
    if (ev) ev.preventDefault();

    try {
      setIsProcessing(true);

      const res = await updateCustomerDetails();
      const { customer } = res?.data || {};

      if (customer?.status !== 'success') throw new Error(customer?.errors);

      onSuccess();
    } catch (err) {
      onError();
    } finally {
      setIsProcessing(false);
    }
  }

  const isBtnDisabled = !isDirty || Object.keys(formErrors).length > 0 || isProcessing;

  return (
    <>
      <DialogContent>
        <StyledForm onSubmit={handleSubmit} id="customer_details_editor_form">
          <StyledVerticalContent>
            <fieldset>
              <StyledFormLegend>
                <Typography variant="h3">
                  <FormattedMessage id="personal.details.form.gender.label" />
                </Typography>
              </StyledFormLegend>

              <StyledInputGroup>
                <StyledRadioInputAndLabel>
                  <Radio
                    id="radio_gender_male"
                    name="gender"
                    onChange={(e) => {
                      e.stopPropagation();
                      handleChange(e);
                    }}
                    value="male"
                    checked={values?.gender === 'male'}
                  />

                  <RadioLabel htmlFor="radio_gender_male">
                    <Typography variant="p">
                      <FormattedMessage id="personal.details.form.gender.male.label" />
                    </Typography>
                  </RadioLabel>
                </StyledRadioInputAndLabel>

                <StyledRadioInputAndLabel>
                  <Radio
                    id="radio_gender_female"
                    name="gender"
                    onChange={(e) => {
                      e.stopPropagation();
                      handleChange(e);
                    }}
                    value="female"
                    checked={values?.gender === 'female'}
                  />

                  <RadioLabel htmlFor="radio_gender_female">
                    <Typography variant="p">
                      <FormattedMessage id="personal.details.form.gender.female.label" />
                    </Typography>
                  </RadioLabel>
                </StyledRadioInputAndLabel>
              </StyledInputGroup>
            </fieldset>

            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              placeholder="Alex"
              label={
                <Typography variant="h3" component="span">
                  <FormattedMessage id="common.first.name" />
                </Typography>
              }
              value={values?.firstName || ''}
              disabled
            />

            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              placeholder="Müller"
              label={
                <Typography variant="h3" component="span">
                  <FormattedMessage id="common.last.name" />
                </Typography>
              }
              value={values?.lastName || ''}
              disabled
            />

            <TextField
              fullWidth
              type="email"
              id="email"
              name="email"
              placeholder={intl.formatMessage({ id: 'placeholder.contact.email' })}
              label={
                <Typography variant="h3" component="span">
                  <FormattedMessage id="personal.details.form.email.label" />
                </Typography>
              }
              value={values?.email || ''}
              onChange={handleChange}
              error={Boolean(formErrors?.email)}
              helperText={
                formErrors?.email && (
                  <Typography variant="p" color="error" component="span">
                    <FormattedMessage id={formErrors?.email} />
                  </Typography>
                )
              }
            />

            <TextField
              fullWidth
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              placeholder={intl.formatMessage({ id: 'placeholder.contact.phone' })}
              label={
                <Typography variant="h3" component="span">
                  <FormattedMessage id="personal.details.form.phone.label" />
                </Typography>
              }
              value={values?.phoneNumber || ''}
              onChange={handleChange}
              error={Boolean(formErrors?.phoneNumber)}
              helperText={
                formErrors?.phoneNumber && (
                  <Typography variant="p" color="error" component="span">
                    <FormattedMessage id={formErrors?.phoneNumber} />
                  </Typography>
                )
              }
            />

            <fieldset>
              <StyledFormLegend>
                <Typography variant="h3">
                  <FormattedMessage id="personal.details.form.street.house.number.label" />
                </Typography>
              </StyledFormLegend>

              <StyledInputGroup>
                <StyledBigInputContainer>
                  <TextField
                    fullWidth
                    id="streetName"
                    name="streetName"
                    placeholder={intl.formatMessage({ id: 'placeholder.contact.street' })}
                    value={values?.streetName || ''}
                    onChange={handleChange}
                    error={Boolean(formErrors?.streetName)}
                  />
                </StyledBigInputContainer>

                <StyledSmallInputContainer>
                  <TextField
                    fullWidth
                    id="houseNumber"
                    name="houseNumber"
                    placeholder={intl.formatMessage({ id: 'placeholder.contact.house.number' })}
                    value={values?.houseNumber || ''}
                    onChange={handleChange}
                    error={Boolean(formErrors?.houseNumber)}
                  />
                </StyledSmallInputContainer>
              </StyledInputGroup>
            </fieldset>

            <fieldset>
              <StyledFormLegend>
                <Typography variant="h3">
                  <FormattedMessage id="personal.details.form.postcode.city.label" />
                </Typography>
              </StyledFormLegend>

              <StyledInputGroup>
                <StyledSmallInputContainer>
                  <TextField
                    fullWidth
                    id="postcode"
                    name="postcode"
                    placeholder="10234"
                    value={values?.postcode || ''}
                    onChange={handleChange}
                    error={Boolean(formErrors?.postcode)}
                  />
                </StyledSmallInputContainer>

                <StyledBigInputContainer>
                  <TextField
                    fullWidth
                    id="city"
                    name="city"
                    placeholder={intl.formatMessage({ id: 'placeholder.account.city' })}
                    value={values?.city || ''}
                    onChange={handleChange}
                    error={Boolean(formErrors?.city)}
                  />
                </StyledBigInputContainer>
              </StyledInputGroup>

              {(formErrors?.streetName ||
                formErrors?.houseNumber ||
                formErrors?.postcode ||
                formErrors?.city) && (
                <ErrorMessage>
                  <Typography variant="p">
                    <FormattedMessage id="personal.details.form.address.error.message" />
                  </Typography>
                </ErrorMessage>
              )}
            </fieldset>

            <TextField
              fullWidth
              id="addressDetails"
              name="addressDetails"
              placeholder={intl.formatMessage({ id: 'placeholder.contact.address' })}
              label={
                <Typography variant="h3" component="span">
                  <FormattedMessage id="personal.details.form.address.details.label" />
                </Typography>
              }
              value={values?.addressDetails || ''}
              onChange={handleChange}
            />

            <Typography variant="p">
              <FormattedMessage id={`personal.details.form.additional.details.${insuranceType}`} />
            </Typography>
          </StyledVerticalContent>
        </StyledForm>
      </DialogContent>

      <DialogActions>
        <Button type="submit" form="customer_details_editor_form" disabled={isBtnDisabled}>
          <Typography variant="buttons">
            <FormattedMessage id="personal.details.form.submit.button" />
          </Typography>
        </Button>
      </DialogActions>
    </>
  );
}

export default CustomerDetailsEditor;
