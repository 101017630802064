import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { StyledOnlineVetSliderTitle } from '../OnlineVets.styled';

export const slidesConfig = [
  {
    title: <FormattedMessage id="fressnapf.slide1.title" />,
    img: 'icon.online.vet.slider.one',
    alt: 'slide-one',
    bodyText: <FormattedMessage id="fressnapf.slide1.description" values={{ nbsp: <>&nbsp;</> }} />,
  },
  {
    title: <FormattedMessage id="fressnapf.slide2.title" />,
    img: 'icon.online.vet.slider.two',
    alt: 'slide-two',
    bodyText: <FormattedMessage id="fressnapf.slide2.description" />,
  },
  {
    title: <FormattedMessage id="fressnapf.slide3.title" />,
    img: 'icon.online.vet.slider.three',
    alt: 'slide-three',
    bodyText: <FormattedMessage id="fressnapf.slide3.description" />,
  },
];

const FressnapfSlide = ({ title, bodyText }) => {
  return (
    <>
      <StyledOnlineVetSliderTitle>
        <Typography variant="h3">{title}</Typography>
      </StyledOnlineVetSliderTitle>
      <Typography variant="p">{bodyText}</Typography>
    </>
  );
};

export default FressnapfSlide;
