import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import IBAN from 'iban';
import { Button, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';

import { getBillingDays, UPDATE_BANK_DETAILS, GET_CUSTOMER } from '../../../shared';
import useForm from '../../../shared/useForm';
import { sepaCheck } from '../../../utils/iban';

import { ErrorMessage } from '../../../components/common/FormElements';
import { DropdownSearch } from '../../../components/ui';
import { FormLabel } from '../../../components/common';

import { StyledForm, StyledVerticalContent } from './CustomerDetailsEditor.styled';

function validateForm(values, dirty) {
  const errors = {};

  if (!values?.firstName) {
    errors.firstName = 'bank.details.form.error.firstname';
  }

  if (!values?.lastName) {
    errors.lastName = 'bank.details.form.error.lastname';
  }

  if (dirty?.iban) {
    if (values?.iban === '') {
      errors.iban = 'bank.details.form.error.iban_empty';
    } else if (!IBAN.isValid(values?.iban)) {
      errors.iban = 'bank.details.form.error.iban_invalid';
    } else if (!sepaCheck(values.iban)) {
      errors.iban = 'bank.details.form.error.iban_not_sepa';
    }
  }

  if (!values?.billingDay) {
    errors.billingDay = 'bank.details.form.error.billingDay';
  }

  return errors;
}

function BankDetailsEditor({
  data,
  hasActiveDigitalPaymentMethod,
  missingIban,
  onSuccess,
  onError,
}) {
  const intl = useIntl();

  const [isProcessing, setIsProcessing] = useState(false);

  const {
    values,
    errors: formErrors,
    dirty,
    isDirty,
    handleChange,
    handleCustomChange,
    handleSubmit,
    updateValue,
  } = useForm(data, handleFormSubmit, validateForm);

  const handleIbanFocus = () => {
    if (data?.iban !== values?.iban) return;

    updateValue('iban', '');
  };

  const handleIbanBlur = () => {
    if (!dirty?.iban && values?.iban === '') {
      updateValue('iban', data?.iban);
    }
  };

  const [updateBankDetails] = useMutation(UPDATE_BANK_DETAILS, {
    variables: {
      ...values,
      iban: !dirty?.iban ? null : values?.iban,
    },
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  async function handleFormSubmit(ev) {
    if (ev) ev.preventDefault();

    try {
      setIsProcessing(true);

      const res = await updateBankDetails();
      const { data: respData } = res || {};

      if (respData?.updateBankDetails.status !== 'success')
        throw new Error(respData?.updateBankDetails.errors);

      onSuccess();
    } catch (err) {
      onError();
    } finally {
      setIsProcessing(false);
    }
  }

  const billingDays = getBillingDays(intl);
  const isBtnDisabled = !isDirty || Object.keys(formErrors).length > 0 || isProcessing;

  return (
    <>
      <DialogContent>
        <StyledForm onSubmit={handleSubmit} id="bank_details_editor_form">
          <StyledVerticalContent>
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              placeholder={intl.formatMessage({ id: 'placeholder.account.first_name' })}
              label={
                <Typography variant="h3" component="span">
                  <FormattedMessage id="common.first.name" />
                </Typography>
              }
              value={values?.firstName || ''}
              onChange={handleChange}
              error={Boolean(formErrors?.firstName)}
              helperText={
                formErrors?.firstName && (
                  <Typography variant="p" color="error" component="span">
                    <FormattedMessage id={formErrors?.firstName} />
                  </Typography>
                )
              }
            />

            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              placeholder={intl.formatMessage({ id: 'placeholder.account.last_name' })}
              label={
                <Typography variant="h3" component="span">
                  <FormattedMessage id="common.last.name" />
                </Typography>
              }
              value={values?.lastName || ''}
              onChange={handleChange}
              error={Boolean(formErrors?.lastName)}
              helperText={
                formErrors?.lastName && (
                  <Typography variant="p" color="error" component="span">
                    <FormattedMessage id={formErrors?.lastName} />
                  </Typography>
                )
              }
            />

            <TextField
              fullWidth
              id="iban"
              name="iban"
              placeholder={intl.formatMessage({ id: 'placeholder.account.iban' })}
              label={
                <Typography variant="h3" component="span">
                  <FormattedMessage id="policy.details.billing.info.iban" />
                </Typography>
              }
              value={values?.iban}
              onChange={handleChange}
              onFocus={handleIbanFocus}
              onBlur={handleIbanBlur}
              error={Boolean(formErrors?.iban)}
              helperText={
                formErrors?.iban && (
                  <Typography variant="p" color="error" component="span">
                    <FormattedMessage id={formErrors?.iban} />
                  </Typography>
                )
              }
            />

            {!hasActiveDigitalPaymentMethod ? (
              <>
                <div>
                  <FormLabel htmlFor="contribution_type">
                    <Typography variant="p">
                      <FormattedMessage id="policy.details.billing.info.billing_day" />
                    </Typography>
                  </FormLabel>

                  <DropdownSearch
                    id="contribution_type"
                    options={billingDays}
                    onSelect={(val) => handleCustomChange('billingDay', val.value)}
                    defaultValue={billingDays.find((val) => val.value === values?.billingDay)}
                  />

                  {formErrors?.billingDay && (
                    <ErrorMessage>
                      <Typography variant="p">
                        <FormattedMessage id={formErrors?.billingDay} />
                      </Typography>
                    </ErrorMessage>
                  )}
                </div>

                <Typography variant="p">
                  <FormattedMessage id="bank.details.form.note_1" />
                </Typography>

                <Typography variant="p">
                  <FormattedMessage id="bank.details.form.note_2" />
                </Typography>
              </>
            ) : (
              <Typography variant="p">
                {missingIban ? (
                  <FormattedMessage id="bank.details.add.missed.iban.modal.desc" />
                ) : (
                  <FormattedMessage id="bank.details.edit_refund_account.modal.desc" />
                )}
              </Typography>
            )}
          </StyledVerticalContent>
        </StyledForm>
      </DialogContent>

      <DialogActions>
        <Button type="submit" form="bank_details_editor_form" disabled={isBtnDisabled}>
          <Typography variant="buttons">
            <FormattedMessage id="bank.details.form.submit_button" />
          </Typography>
        </Button>
      </DialogActions>
    </>
  );
}

export default BankDetailsEditor;
