import { css, styled } from '@mui/material';

export const StyledFooterContainer = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.background.footer,
  flexShrink: 0,
  padding: '1.5rem 1rem',
}));

export const StyledFooterWrapper = styled('div')(() => ({
  margin: '0.5rem auto 0',
  maxWidth: '57.0625rem',
}));

export const StyledFooterLinksWrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  margin: '0 -0.75rem',
  rowGap: '0.5rem',

  [`& > a, button`]: {
    border: `2px solid ${theme.palette.background.footer}`,
    color: theme.palette.text.footerLink,
    margin: '0 0.25rem',
    padding: '0.375rem 0.625rem',
    textDecoration: 'none',

    [`&:hover`]: {
      textDecoration: 'underline',
    },
  },

  [`& > a:first-of-type`]: {
    marginLeft: 0,
  },

  [`& > a:last-of-type`]: {
    marginRight: 0,
  },
}));

export const StyledFooterLinkSeparator = styled('div')(({ theme }) => ({
  height: theme.spacing('LARGE'),
  borderLeft: `2px solid ${theme.palette.text.footerLink}`,
}));

export const StyledFooterCopyright = styled('div')(({ theme }) => ({
  color: theme.palette.text.copyright,
  margin: '2rem 0 1rem',

  [theme.breakpoints.up('desktop')]: {
    margin: '1rem 0',
  },
}));

export const StyledBranchName = styled('div')(
  ({ theme }) => css`
    margin-top: 1em;
    color: ${theme.palette.gray.gray};
    text-align: right;
  `
);
