import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  parseFloatToCents,
  PETOLO_DATA_PROTECTION_CHECKBOX_LINK,
  PATOLO_DATA_PROTECT_LINK,
} from '../../../../shared';
import { useEditIcon } from '../../../../hooks/useIcons';
import usePetoloClaims from '../../usePetoloClaims';
import useSelectableVideoConsultationsList from '../SelectVideoConsultation/useSelectableVideoConsultationsList';
import {
  PETOLO_DOCUMENT_DATE,
  PETOLO_DOCUMENT_TYPE,
  PETOLO_TREATMENT_AMOUNT,
  PETOLO_TREATMENT_DATE,
  PETOLO_CUSTOMER_NOTE,
  PETOLO_VIDEO_CONSULTATION_ID,
  PETOLO_NO_SUITABLE_VIDEO_CONSULTATION,
  useNewPetoloFormDataValue,
  PET_HEALTH_TREATMENT_CATEGORIES,
  PET_HEALTH_RESAON_FOR_TREATMENT,
} from '../../claimFormData/newClaimFormData';
import {
  treatmentCategoriesAccordingToReasonForTreatment,
  useEditDetailsLinkPetolo,
  useEditDocumentsLinkPetolo,
  useSubmitClaimFormPetoloBackButtonLink,
} from './submitClaimFormPetoloHooks';

import SubmitClaimForm from './SubmitClaimForm';
import useInsuranceInfo from '../../../../hooks/useInsuranceInfo';

const SubmitClaimFormPetolo = (props) => {
  const { selectedDocuments, setSelectedDocuments, setClaimSubmitted } = props;
  const { isPatolo } = useInsuranceInfo();

  const { claimId } = useParams();

  const intl = useIntl();

  const [submitPetoloClaim] = usePetoloClaims(claimId);

  const editIcon = useEditIcon();

  const treatmentCategoryTextMappingId =
    'petolo.new_claim.submit_claim_form.treatment_category.other';

  const [treatmentCategories] = useNewPetoloFormDataValue(PET_HEALTH_TREATMENT_CATEGORIES);

  const [reasonForTreatment] = useNewPetoloFormDataValue(PET_HEALTH_RESAON_FOR_TREATMENT);

  const [documentType] = useNewPetoloFormDataValue(PETOLO_DOCUMENT_TYPE);
  const documentTypeMappingId = documentType
    ? `petolo.new_claim.select_document_type.${documentType}`
    : '';

  const [documentDate] = useNewPetoloFormDataValue(PETOLO_DOCUMENT_DATE);
  const formattedDocumentDate = documentDate
    ? intl.formatDate(documentDate, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : '';

  const [treatmentDate] = useNewPetoloFormDataValue(PETOLO_TREATMENT_DATE);
  const formattedTreatmentDate = treatmentDate
    ? intl.formatDate(treatmentDate, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : '';

  const [treatmentAmount] = useNewPetoloFormDataValue(PETOLO_TREATMENT_AMOUNT);
  const formattedTreatmentAmount = treatmentAmount
    ? intl.formatNumber(treatmentAmount, { style: 'currency', currency: 'EUR' })
    : '';

  const [customerNote] = useNewPetoloFormDataValue(PETOLO_CUSTOMER_NOTE);
  const formattedCustomerNote = customerNote || '';

  const [selectedVideoConsultationId] = useNewPetoloFormDataValue(PETOLO_VIDEO_CONSULTATION_ID);
  const videoConsultationId =
    selectedVideoConsultationId === PETOLO_NO_SUITABLE_VIDEO_CONSULTATION
      ? ''
      : selectedVideoConsultationId;

  const selectableVideoConsultationsList = useSelectableVideoConsultationsList();
  const selectedVideoConsultationOption = Array.isArray(selectableVideoConsultationsList)
    ? selectableVideoConsultationsList.find(
        (consultationOption) => consultationOption?.id === selectedVideoConsultationId
      )
    : null;
  const videoConsultationDisplayText = selectedVideoConsultationOption?.label || '';

  const cardContent = [
    {
      label: 'petolo.new_claim.submit_claim_form.card_label.document_type',
      value: documentTypeMappingId ? intl.formatMessage({ id: documentTypeMappingId }) : '',
    },
    {
      label: 'petolo.new_claim.submit_claim_form.card_label.document_date',
      value: formattedDocumentDate,
    },
    {
      label: 'petolo.new_claim.submit_claim_form.card_label.treatment_date',
      value: formattedTreatmentDate,
    },
    {
      label: 'petolo.new_claim.submit_claim_form.card_label.amount',
      value: formattedTreatmentAmount,
    },
    {
      label: 'petolo.new_claim.submit_claim_form.card_label.treatment_necessary_answer',
      value: formattedCustomerNote,
    },
    {
      label: 'petolo.new_claim.submit_claim_form.card_label.videoConsultation',
      value: videoConsultationDisplayText,
    },
  ];

  const uploadClaimForm = () => {
    const validDocuments = Array.isArray(selectedDocuments)
      ? selectedDocuments.filter((d) => d?.valid)
      : [];

    const payload = {
      files: validDocuments.map((item) => item.file),
      amountCents: parseFloatToCents(treatmentAmount),
      documentType,
      lossDate: documentDate,
      treatmentStartDate: treatmentDate || null,
      treatmentInfo: customerNote,
      videoConsultationId: videoConsultationId || null,
      dentistKey: null,
      dentistName: null,
      treatmentCategories: treatmentCategoriesAccordingToReasonForTreatment(
        treatmentCategories,
        reasonForTreatment
      ),
      fillingsCount: null,
      claimId: claimId || null,
    };

    submitPetoloClaim({
      payload,
      setSelectedDocuments,
      setClaimSubmitted,
    });
  };

  const backButtonLink = useSubmitClaimFormPetoloBackButtonLink(
    claimId,
    videoConsultationId,
    treatmentCategories
  );
  const editDetailsButtonLink = useEditDetailsLinkPetolo(claimId);
  const editUploadedFilesLink = useEditDocumentsLinkPetolo(claimId);

  return (
    <SubmitClaimForm
      selectedDocuments={selectedDocuments}
      backButtonLink={backButtonLink}
      editDetailsButtonLink={editDetailsButtonLink}
      editUploadedFilesLink={editUploadedFilesLink}
      pageTitleId="petolo.new_claim.submit_claim_form.page_title"
      editIcon={editIcon}
      formDataCardHeaderId={treatmentCategoryTextMappingId}
      cardContent={cardContent}
      uploadedFilesCardHeaderId="petolo.new_claim.submit_claim_form.uploaded_files.title"
      uploadClaimForm={uploadClaimForm}
      submitButtonId="petolo.new_claim.submit_claim_form.send_button"
      dataProtectionLink={
        isPatolo ? PATOLO_DATA_PROTECT_LINK : PETOLO_DATA_PROTECTION_CHECKBOX_LINK
      }
    />
  );
};

export default SubmitClaimFormPetolo;
