import {
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
  INSURANCE_CATEGORY_HEALTH,
} from '../shared';

export const getInsuredPersonName = (customer) => {
  if (!customer?.insuredPersonIsCustomer) {
    return `${customer?.insuredPerson?.firstName} ${customer?.insuredPerson?.lastName}`;
  }

  return `${customer?.firstName} ${customer?.lastName}`;
};

export const getNameAccordingToInsuranceCategory = (customer) => {
  const { firstName, lastName, insuranceCategory } = customer || {};
  const customername = `${firstName || ''} ${lastName || ''}`;

  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_DENTAL:
    case INSURANCE_CATEGORY_HEALTH:
      return getInsuredPersonName(customer);
    case INSURANCE_CATEGORY_PET_HEALTH:
      return customer?.insuredPet?.name || customername;
    case INSURANCE_CATEGORY_PET_LIABILITY:
      return customer?.insuredPet?.name || customername;

    default:
      return getInsuredPersonName(customer);
  }
};
