import { css, styled } from '@mui/material';

export const StyledVerticalContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledBoxSpaceBetween = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledBoxAlignStart = styled('div')`
  display: flex;
  align-items: flex-start;
`;

export const StyledBoxAlignCenter = styled('div')`
  display: flex;
  align-items: center;
`;

export const StyledSeparator = styled('hr')(
  ({ theme }) => css`
    background-color: ${theme.palette.main.product_blue};
    border: none;
    height: 1px;
    margin: 0;
    width: 100%;
  `
);

export const StyledPolicyDetailsNumericalValue = styled('div')`
  flex-shrink: 0;
`;

export const StyledContractCancelLink = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing('LARGE')};
  `
);
