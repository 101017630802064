import { css } from '@mui/material';
import styled from '@emotion/styled/macro';

import { Tag } from '../../components/common';

export const StyledNotificationsContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};

    & > div:last-of-type {
      margin-bottom: ${theme.spacing('2XL')};
    }

    ${theme.breakpoints.up('desktop')} {
      & > div:last-of-type {
        margin-bottom: ${theme.spacing('6XL')};
      }
    }
  `
);

export const StyledDashbaordTitleContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    column-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledTag = styled(Tag)(
  ({ theme, status }) => css`
    padding: 0.5rem;
    background-color: ${theme.palette.background.tag};

    ${!status &&
    css`
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      background: transparent;
      border: 1px solid ${theme.palette.main.professional_blue};
      color: ${theme.palette.main.professional_blue};
    `}
  `
);

export const StyledPolicyCardText = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.background.radio};
    column-gap: ${theme.spacing('LARGE')};
    display: flex;
  `
);

export const StyledPolicyCardItem = styled('div')(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing('LARGE')};

    &:last-of-type {
      margin-bottom: ${theme.spacing('NONE')};
    }
  `
);

export const StyledContractCancelLink = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing('LARGE')};
  `
);

export const StyledMissingIbanBannerContainer = styled('div')(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing('LARGE')};
  `
);
