import { alpha, hexToRgb } from '@mui/material';
import { PALETTE } from '../palette';

// eslint-disable-next-line import/prefer-default-export
export const muiButtonComponent = {
  defaultProps: {
    disableRipple: true,
    variant: 'contained',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      background: theme.palette.background.button,
      border: `2px solid ${theme.palette.background.button}`,
      borderRadius: '8px',
      boxShadow: 'none',
      color: theme.palette.text.button,
      display: 'flex',
      padding: '0.875rem',
      textTransform: 'none',
      transition: 'none',
      width: '100%',

      [`${theme.breakpoints.up('desktop')}`]: {
        minWidth: '21.375rem',
        width: 'fit-content',
      },

      [`&:hover`]: {
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), ${theme.palette.background.button}`,
        boxShadow: 'none',
      },

      [`&:focus`]: {
        boxShadow: theme.shadows[4],
      },

      [`&:active`]: {
        background: theme.palette.background.button,
        color: theme.palette.text.button,
      },

      [`&.Mui-disabled`]: {
        background: theme.palette.background.button,
        color: theme.palette.text.button,
        opacity: '0.6',
      },
    }),
    outlined: ({ theme }) => ({
      background: 'transparent',
      border: `2px solid ${theme.palette.main.professional_blue}`,
      color: theme.palette.main.professional_blue,

      [`&:hover`]: {
        background: `${alpha(hexToRgb(theme.palette.main.professional_blue), 0.15)}`,
        border: `2px solid ${theme.palette.main.professional_blue}`,
      },

      [`&:focus`]: {
        boxShadow: theme.shadows[5],
      },

      [`&:active`]: {
        background: 'transparent',
        color: theme.palette.main.professional_blue,
      },

      [`&.Mui-disabled`]: {
        background: 'transparent',
        border: `2px solid ${theme.palette.main.professional_blue}`,
        color: theme.palette.main.professional_blue,
      },
    }),
  },
  variants: [
    {
      props: { variant: 'cross_sell_vitolo' },
      style: ({ theme }) => ({
        background: theme.palette.main.professional_blue,
        border: `2px solid ${theme.palette.main.professional_blue}`,
        color: theme.palette.common.white,

        [`&:hover`]: {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), ${theme.palette.main.professional_blue}`,
          boxShadow: 'none',
        },

        [`&:focus`]: {
          boxShadow: `0 0 4px 1px ${PALETTE.LAVENDER_INDIGO}`,
        },

        [`&:active`]: {
          background: theme.palette.main.professional_blue,
          color: theme.palette.common.white,
        },

        [`&.Mui-disabled`]: {
          background: theme.palette.main.professional_blue,
          border: `2px solid ${theme.palette.main.professional_blue}`,
          color: theme.palette.common.white,
        },
      }),
    },
  ],
};
