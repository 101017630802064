import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Container, Paper, Typography } from '@mui/material';

import { useTrackDtEvent } from '../../DtlTracker';
import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { DECLINED, PENDING } from '../../shared/contractStatus';
import {
  SIGN_CONTRACT,
  GET_DOCUMENTS,
  GET_CUSTOMER,
  GET_PAYMENT,
  useReady,
  documentsOrder,
  SIGN_OUT,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
} from '../../shared';

import { Checkbox, PageTitle } from '../common';
import { DownloadLink, Accordion } from '../ui';
import ButtonContainer from '../ButtonContainer';

import { StyledSeparator, StyledVerticalContent } from './SignDocs.styled';

const getNextURL = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return '/intro-dentolo/1';
    case INSURANCE_TYPE_PETOLO:
      return '/intro-petolo/1';
    case INSURANCE_TYPE_VITOLO:
      return '/dashboard';
    default:
      return '/intro-dentolo/1';
  }
};

const trackDtEvent =
  (eventType = '', uuid, sendUserData) =>
  () => {
    const trackData = {
      userData: {
        eventType,
        time: new Date().toISOString(),
        url: window?.location?.href,
        uuid,
      },
    };
    sendUserData(trackData);
  };

const mainDocsSection = [
  'contract',
  'protocol',
  'Tarifblatt Akutschutz Modul',
  'Sondervereinbarung Probemonat',
];

// function to sort and arrange documents
const sortAndArrangeDocuments = (documentsData) => {
  if (!Array.isArray(documentsData?.documents) || documentsData?.documents?.length < 1) {
    return { documents: [], extraDocuments: [] };
  }

  const maxSortNum = documentsOrder.length;

  const [documents, extraDocuments] = documentsData.documents
    // ordering documents by `documentsOrder`
    .map((doc, index) => {
      const docOrder = documentsOrder.find((order) => order.name === doc.name);
      return { ...doc, num: docOrder ? docOrder.numContractDoc : maxSortNum + index };
    })
    // sorting the ordered documents
    .sort((a, b) => a.num - b.num)
    // separating main documents and extra documents
    .reduce(
      (acc, item) => {
        const [docs, extraDocs] = acc;

        if (mainDocsSection.includes(item.name)) {
          docs.push(item);
        } else {
          extraDocs.push(item);
        }

        return acc;
      },
      [[], []]
    );

  return { documents, extraDocuments };
};

export default function SignDocs() {
  const history = useHistory();
  const intl = useIntl();

  const ready = useReady();
  const [isLoading, setIsLoading] = useState(false);
  const [sendUserData] = useTrackDtEvent();
  const { type: insuranceType } = useInsuranceInfo();

  const { loading: customerLoading, data: customerData } = useQuery(GET_CUSTOMER);
  const uuid = customerData?.customer?.uuid;
  const contractStatus = customerData?.customer?.contract?.status;

  const [signOut] = useMutation(SIGN_OUT, {
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  useEffect(() => {
    if (!customerLoading) {
      if (contractStatus === PENDING) {
        history.push('/pending-approval');
      } else if (contractStatus === DECLINED) {
        signOut().then(() => history.push('/login?invalid=true'));
      }
    }
  }, [history, signOut, contractStatus, customerLoading]);

  const [signContract] = useMutation(SIGN_CONTRACT, {
    refetchQueries: [{ query: GET_CUSTOMER }, { query: GET_PAYMENT }],
  });

  const signContractFunc = async () => {
    const res = await signContract();
    if (res.data && res.data.signContract.errors.length < 1) {
      setIsLoading(false);
      const nextURL = getNextURL(insuranceType);
      history.push(nextURL);
    }
  };

  const [signDocs, setSignDocs] = useState(false);
  const { data: documentsData } = useQuery(GET_DOCUMENTS);

  const { documents, extraDocuments } = sortAndArrangeDocuments(documentsData);

  const handleDocumentLinkClick = (doc) => {
    trackDtEvent(
      `click 'open in new tab' link: ${intl.formatMessage({
        id: `document.${doc?.name}`,
      })}`,
      uuid,
      sendUserData
    )();
  };

  const handleDocumentButtonClick = (doc) => {
    trackDtEvent(
      `click 'download icon': ${intl.formatMessage({
        id: `document.${doc?.name}`,
      })}`,
      uuid,
      sendUserData
    )();
  };

  const handleCheckboxChange = () => {
    trackDtEvent(`check 'confirm': ${!signDocs}`, uuid, sendUserData)();
    setSignDocs(!signDocs);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    setIsLoading(true);
    signContractFunc();

    trackDtEvent(`click 'Zum Kundenkonto'`, uuid, sendUserData)();
  };

  return (
    <CSSTransition in={ready && !!uuid} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <PageTitle>
          <Typography variant="h1">
            <FormattedMessage id="sign.docs.title" />
          </Typography>
        </PageTitle>

        <StyledVerticalContent>
          <Typography variant="p">
            <FormattedMessage id="sign.docs.description" />
          </Typography>

          <Paper>
            <StyledVerticalContent>
              {documents && (
                <>
                  <div>
                    {documents.map((doc) => {
                      return (
                        doc &&
                        doc.url &&
                        doc.name && (
                          <DownloadLink
                            link={doc?.url}
                            name={doc?.name}
                            key={doc?.name}
                            target="_blank"
                            onLinkClick={() => handleDocumentLinkClick(doc)}
                            onButtonClick={() => handleDocumentButtonClick(doc)}
                          >
                            <Typography variant="h3">
                              <FormattedMessage id={`document.${doc?.name}`} />
                            </Typography>
                          </DownloadLink>
                        )
                      );
                    })}
                  </div>

                  <StyledSeparator />
                </>
              )}

              <Accordion
                onExpand={trackDtEvent(`click 'anzeigen'`, uuid, sendUserData)}
                toggleTitle={intl.formatMessage({ id: 'sign.docs.accordion.toggle.text' })}
                showText={intl.formatMessage({ id: 'sign.docs.accordion.show.text' })}
                hideText={intl.formatMessage({ id: 'sign.docs.accordion.hide.text' })}
              >
                {extraDocuments.map((doc) => {
                  return (
                    doc &&
                    doc.url &&
                    doc.name && (
                      <DownloadLink
                        link={doc?.url}
                        name={doc?.name}
                        key={doc?.name}
                        target="_blank"
                        onLinkClick={() => handleDocumentLinkClick(doc)}
                        onButtonClick={() => handleDocumentButtonClick(doc)}
                      >
                        <Typography variant="h3">
                          <FormattedMessage id={`document.${doc?.name}`} />
                        </Typography>
                      </DownloadLink>
                    )
                  );
                })}
              </Accordion>

              <StyledSeparator />

              <Checkbox
                id="sign-docs-checkbox"
                label={intl.formatMessage({ id: 'sign.docs.input.label.text' })}
                checked={signDocs}
                onChange={handleCheckboxChange}
                required
              />
            </StyledVerticalContent>

            <ButtonContainer>
              <Button type="button" disabled={!signDocs || isLoading} onClick={handleSubmit}>
                <Typography variant="buttons">
                  <FormattedMessage id="sign.docs.go.to.account" />
                </Typography>
              </Button>
            </ButtonContainer>
          </Paper>
        </StyledVerticalContent>
      </Container>
    </CSSTransition>
  );
}
