import React from 'react';
import { styled, css, Typography, hexToRgb, alpha } from '@mui/material';
import PropTypes from 'prop-types';

import { imgLinks } from '../../shared';

export const StyledCheckbox = styled('input')(
  ({ theme }) => css`
    position: relative;
    margin: 0;
    padding: 0;
    min-width: 1.55rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: transparent;
    border: 1px solid ${theme.palette.main.professional_light_blue};
    border-radius: 2px;
    appearance: none;
    cursor: pointer;
    transition: border 0.3s ease-in-out;

    &:hover {
      background-color: ${alpha(hexToRgb(theme.palette.main.professional_blue), 0.15)};
    }

    &:focus {
      box-shadow: ${theme.shadows[5]};
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      background-image: url(${imgLinks['icon.menu.blue.mark']});
      background-position: center;
      background-repeat: no-repeat;
      transform: scale(0);
      transition: transform 0.2s ease-in-out;
    }

    &:checked:after {
      transform: scale(1);
    }
  `
);

export const StyledLabel = styled('label')(
  ({ theme }) => css`
    position: relative;
    display: flex;
    color: ${theme.palette.main.professional_blue};
    cursor: pointer;
    column-gap: ${theme.spacing('SMALL')};
  `
);

export default function Checkbox(props) {
  const { children, label, checked, onChange, required, id, ...rest } = props;

  return (
    <StyledLabel htmlFor={id} {...rest}>
      <StyledCheckbox
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        required={required}
      />

      <Typography variant="p">
        {label}
        {required && <span>&nbsp;*</span>}
      </Typography>
    </StyledLabel>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  alignItems: PropTypes.string,
};
