import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { formatPolicyName } from '../../shared';

const PolicyAndAddonName = ({ customer, addons, shouldFormatPolicyName }) => {
  const addOnTitle = addons?.title || '';
  const { policyName } = customer?.contract || {};

  if (shouldFormatPolicyName) {
    if (addOnTitle) {
      return (
        <Typography variant="p">
          <FormattedMessage id={`policies.${formatPolicyName(policyName)}`} />
          {` + ${addOnTitle}`}
        </Typography>
      );
    }

    return (
      <Typography variant="p">
        <FormattedMessage id={`policies.${formatPolicyName(policyName)}`} />
      </Typography>
    );
  }

  return <Typography variant="p">{policyName}</Typography>;
};

export default PolicyAndAddonName;
