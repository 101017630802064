import React from 'react';

import useInsuranceInfo from '../../../hooks/useInsuranceInfo';
import {
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
} from '../../../shared';

import DentoloReferral from './DentoloReferral';
import PetoloReferral from './PetoloReferral';
import VitoloReferral from './VitoloReferral';

const ReferralPage = () => {
  const { type: insuranceType } = useInsuranceInfo();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return <DentoloReferral />;

    case INSURANCE_TYPE_PETOLO:
      return <PetoloReferral />;

    case INSURANCE_TYPE_VITOLO:
      return <VitoloReferral />;

    default:
      return <DentoloReferral />;
  }
};

export default ReferralPage;
