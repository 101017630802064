import { useEffect } from 'react';
import { MD5, enc } from 'crypto-js';
import { datadogLogs } from '@datadog/browser-logs';

const identifyHotjarUser = (userId) => {
  const scriptChecker = setInterval(function identifyHotjar() {
    const hotjar = window.hj;
    if (typeof hotjar === 'function') {
      try {
        hotjar('identify', userId, {});
      } catch (error) {
        datadogLogs.logger.error('Could not identify hotjar user', {}, error);
      }
      clearInterval(scriptChecker);
    }
  }, 1000);
};

export default function useIdentifyHotjarUser(uuid) {
  const hotjarUniqueId = MD5(uuid).toString(enc.Hex);

  useEffect(() => {
    identifyHotjarUser(hotjarUniqueId);
  }, [hotjarUniqueId]);
}
