import { css, Paper, styled } from '@mui/material';

export const StyledFressnapfFriendsPaper = styled(Paper)(
  ({ theme }) => css`
    display: flex;
    padding: ${theme.spacing('NONE')};
  `
);

export const StyledFressnapfFriendsCardImageWrapper = styled('div')(
  ({ theme }) => css`
    background: transparent;
    flex-basis: 8rem;
    flex-shrink: 0;
    overflow: hidden;

    ${theme.breakpoints.up('desktop')} {
      flex-basis: 16rem;
    }
  `
);

export const StyledFressnapfFriendsCardImage = styled('img')`
  width: 100%;
  height: auto;
`;

// The values are design approved. No other way to fit this content in mobile
export const StyledFressnapfFriendsCardContent = styled('div')(
  ({ theme }) => css`
    align-self: center;
    margin: 0 auto;
    padding: ${theme.spacing('LARGE')};
    padding-left: 0.3125rem;

    ${theme.breakpoints.up('desktop')} {
      padding: ${theme.spacing('NONE')};
      width: 19.0625rem;
    }
  `
);

// The values are design approved. No other way to fit this content in mobile
export const StyledFressnapfFriendsCardButtonContainer = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing('LARGE')};

    button {
      min-width: fit-content !important;
    }

    ${theme.breakpoints.down('desktop')} {
      button {
        padding: ${theme.spacing('MEDIUM')} ${theme.spacing('SMALL')} !important;
      }
    }

    ${theme.breakpoints.up('desktop')} {
      margin-top: ${theme.spacing('1XL')};

      button {
        min-width: 19.0625rem !important;
      }
    }
  `
);
