import { styled, css } from '@mui/material';

export const StyledIllustrationContainerDesktop = styled('div')(
  ({ theme }) => css`
    width: 100%;
    display: none;

    ${theme.breakpoints.up('desktop')} {
      display: block;
    }

    & > svg {
      width: 100%;
      height: auto;
    }
  `
);

export const StyledIllustrationContainerMobile = styled(StyledIllustrationContainerDesktop)(
  ({ theme }) => css`
    display: block;

    ${theme.breakpoints.up('desktop')} {
      display: none;
    }
  `
);

export const StyledContractPendingApprovalContent = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.main.professional_blue};
    margin-bottom: ${theme.spacing('LARGE')};
    text-align: center;
  `
);
