import React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { Typography, Button, Container, Paper } from '@mui/material';
import { CSSTransition } from 'react-transition-group';

import { GET_DOCUMENTS, useReady, documentsOrder } from '../../shared';

import { BackButton, PageTitle } from '../../components/common';
import { DownloadLink } from '../../components/ui';

import { StyledButtonContainer, StyledVerticalContent } from './ContractDocuments.styled';

const sortDocuments = (data) => {
  if (!Array.isArray(data?.documents) || data?.documents?.length < 1) return [];

  const maxSortNum = documentsOrder.length;

  const documentWithNums = data.documents.map((doc, index) => {
    const docOrder = documentsOrder.find((order) => order.name === doc.name);

    return { ...doc, num: docOrder ? docOrder.numContractDoc : maxSortNum + index };
  });

  return documentWithNums.sort((a, b) => a.num - b.num);
};

export default function ContractDocuments() {
  const showZip = false;
  const ready = useReady();
  const { data } = useQuery(GET_DOCUMENTS);

  const documents = sortDocuments(data);

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <BackButton to="/" />

        <PageTitle>
          <Typography variant="h1">
            <FormattedMessage id="contract.docs.title" />
          </Typography>
        </PageTitle>

        <StyledVerticalContent>
          <Typography variant="p">
            <FormattedMessage id="contract.docs.description" />
          </Typography>

          <Paper>
            {documents &&
              documents.map(
                (doc) =>
                  doc &&
                  doc.url &&
                  doc.name && (
                    <DownloadLink link={doc.url} name={doc.name} key={doc.name} target="_blank">
                      <FormattedMessage id={`document.${doc.name}`} />
                    </DownloadLink>
                  )
              )}

            {showZip && (
              <>
                <StyledButtonContainer>
                  <Button variant="outlined">
                    <Typography variant="buttons">
                      <FormattedMessage id="contract.docs.download.all" />
                    </Typography>
                  </Button>
                </StyledButtonContainer>

                <Typography variant="p" textAlign="center">
                  <FormattedMessage id="contract.docs.download.all.subtext" />
                </Typography>
              </>
            )}
          </Paper>
        </StyledVerticalContent>
      </Container>
    </CSSTransition>
  );
}
