import { hexToRgb, alpha, typographyClasses } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const muiTabComponent = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.main.professional_light_blue,
      padding: theme.spacing('LARGE'),
      textTransform: 'none',

      [`&:hover`]: {
        backgroundColor: alpha(hexToRgb(theme.palette.main.professional_blue), 0.15),
      },

      [`&.Mui-focusVisible`]: {
        [`.${typographyClasses.root}`]: {
          border: `2px solid ${theme.palette.main.accent_blue}`,
          borderRadius: '2px',
        },
      },

      [`&.Mui-selected`]: {
        color: theme.palette.main.professional_blue,
      },

      [`&.Mui-disabled`]: {
        color: theme.palette.gray.gray,
      },
    }),
  },
};
