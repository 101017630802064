import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/client';

import useInsuranceInfo from '../../../hooks/useInsuranceInfo';
import { carouselSettings as settings, filterValidSlides } from './carouselHelper';
import {
  INSURANCE_TYPE_PETOLO,
  GET_CUSTOMER,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_VITOLO,
} from '../../../shared';
import {
  promotionSlidesDentolo,
  promotionSlidesPetolo,
  promotionSlidesVitolo,
} from './carouselSlides';

import PromotionCarouselSlides from './PromotionCarouselSlides';
import PromotionCarouselControls from './PromotionCarouselControls';
import { StyledPromotionCarouselPaper } from './PromotionCarousel.styled';

const slidesMap = {
  [INSURANCE_TYPE_DENTOLO]: promotionSlidesDentolo,
  [INSURANCE_TYPE_PETOLO]: promotionSlidesPetolo,
  [INSURANCE_TYPE_VITOLO]: promotionSlidesVitolo,
};

const PromotionCarousel = () => {
  const { type: insuranceType } = useInsuranceInfo();
  const { data: customerData } = useQuery(GET_CUSTOMER);

  const riskCarrier = customerData?.customer?.contract?.riskCarrier;

  const [currSlide, setCurrSlide] = useState(0);
  const sliderRef = useRef(null);

  const currentPolicyCategory = customerData?.customer?.contract?.policyCategory;
  const slidesToUse = slidesMap[`${insuranceType}`] || [];

  const promotionSlides = filterValidSlides(slidesToUse, currentPolicyCategory, riskCarrier);
  const promotionSlideCount = promotionSlides?.length || 0;

  const handleNavigate = (to) => {
    if (!sliderRef.current) return;

    if (to === 'prev') {
      sliderRef.current.slickPrev();
    } else if (to === 'next') {
      sliderRef.current.slickNext();
    } else {
      sliderRef.current.slickGoTo(to);
    }
  };

  const handleBeforeChange = (_, idx) => {
    setCurrSlide(idx);
  };

  if (!Array.isArray(promotionSlides) || promotionSlideCount < 1) return null;

  const productDataTestId = insuranceType ? `${insuranceType}-home-carousel-card` : '';

  return (
    <StyledPromotionCarouselPaper
      data-testid="home-carousel-card"
      data-product-test-id={productDataTestId}
    >
      <PromotionCarouselSlides
        ref={sliderRef}
        settings={{ ...settings, infinite: promotionSlideCount > 1 }}
        slides={promotionSlides}
        slideCount={promotionSlideCount}
        beforeChange={handleBeforeChange}
        autoplay
      />

      <PromotionCarouselControls
        slides={promotionSlides}
        slideCount={promotionSlideCount}
        current={currSlide}
        onPrevious={() => handleNavigate('prev')}
        onNext={() => handleNavigate('next')}
        onGoto={handleNavigate}
      />
    </StyledPromotionCarouselPaper>
  );
};

export default PromotionCarousel;
