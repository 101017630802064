import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Container, Paper, Typography } from '@mui/material';

import { SUBJECTS_ENUMS } from '../../shared';
import { BackButton, PageTitle } from '../../components/common';
import InfoWindow from '../../components/InfoWindow';

const daDirektSuccessTextIdMap = {
  [SUBJECTS_ENUMS.SUBJECT_OPTION_REFERRAL]: 'contact.page.form.success.text.referral.dadirekt',
};

const ContactStatus = () => {
  const { status } = useParams();
  const intl = useIntl();

  const location = useLocation();
  const { state: locationState } = location || {};
  const { isDaDirektCustomer, selectedSubject } = locationState || {};

  const messageSent = intl.formatMessage({ id: 'contact.page.main.sent' });
  const registerViaEmail = intl.formatMessage({ id: 'contact.page.main.register.email' });
  const backToStartPage = intl.formatMessage({ id: 'contact.page.main.back' });
  const failedToSend = intl.formatMessage({ id: 'contact.page.main.fail' });
  const cancelSending = intl.formatMessage({ id: 'contact.page.main.cancel' });
  const tryAgain = intl.formatMessage({ id: 'contact.page.main.try.again' });

  const successButtonsConfig = {
    text: backToStartPage,
    href: '/',
  };

  const failureButtonsConfig = [
    {
      text: cancelSending,
      href: '/',
      style: 'outlined',
    },
    {
      text: tryAgain,
      href: '/contact',
      style: 'contained',
    },
  ];

  const messageIdForDaDirketCustomer = isDaDirektCustomer
    ? daDirektSuccessTextIdMap[`${selectedSubject}`]
    : null;

  const successTextDaDirekt = messageIdForDaDirketCustomer
    ? intl.formatMessage({ id: messageIdForDaDirketCustomer })
    : null;

  const successSentTextToUse = successTextDaDirekt || registerViaEmail;

  return (
    <Container>
      <BackButton to="/contact" />

      <PageTitle>
        <Typography variant="h1">
          <FormattedMessage id="contact.page.main.form.message" />
        </Typography>
      </PageTitle>

      <Paper>
        {status === 'success' ? (
          <InfoWindow
            mode="success"
            titleHtml={messageSent}
            textHtml={successSentTextToUse}
            button={successButtonsConfig}
          />
        ) : (
          <InfoWindow mode="failure" titleHtml={failedToSend} buttons={failureButtonsConfig} />
        )}
      </Paper>
    </Container>
  );
};

export default ContactStatus;
