import { css, styled } from '@mui/material';

import { StyledOnlineVetSlider } from '../OnlineVets.styled';

export const StyledDesktopSlidesContainer = styled('div')(
  ({ theme }) => css`
    display: none;
    margin-top: ${theme.spacing('7XL')};

    ${theme.breakpoints.up('desktop')} {
      display: flex;
      justify-content: center;
      align-items: stretch;
      column-gap: ${theme.spacing('2XL')};
    }
  `
);

export const StyledDesktopSlideContainer = styled('div')(
  ({ theme }) => css`
    position: relative;
    text-align: center;
    background-color: ${theme.palette.common.white};
    border-radius: 32px;

    padding: ${theme.spacing('2XL')} ${theme.spacing('LARGE')} ${theme.spacing('1XL')};
  `
);

export const StyledMobileButtonContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacing('1XL')};
    margin-bottom: ${theme.spacing('1XL')};

    ${theme.breakpoints.up('desktop')} {
      display: none;
    }
  `
);

export const StyledDesktopButtonContainer = styled('div')(
  ({ theme }) => css`
    display: none;

    ${theme.breakpoints.up('desktop')} {
      display: flex;
      justify-content: center;
      margin: ${theme.spacing('4XL')} 0;
    }
  `
);

export const StyledMobileSlidesContainer = styled(StyledOnlineVetSlider)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing('7XL')};

    ${theme.breakpoints.up('desktop')} {
      display: none;
    }
  `
);
