import { css, styled } from '@mui/material';

export const StyledVerticalContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledSeparator = styled('hr')(
  ({ theme }) => css`
    background-color: ${theme.palette.main.product_blue};
    border: none;
    height: 1px;
    margin: 0;
    width: 100%;
  `
);

export const StyledAccordionToggle = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
