import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Container } from '@mui/material';

import { PETOLO_DASHBOARD_ONBOARD_KEY } from '../../../shared';
import { Onboard } from '../../ui';

import HomeOnboardCard1 from './HomeOnboardCard1';
import HomeOnboardCard2 from './HomeOnboardCard2';
import HomeOnboardCard3 from './HomeOnboardCard3';

const homeOnboardConfig = (intl) => [
  {
    id: 'HomeOnboardCard1',
    name: HomeOnboardCard1,
    titleHtml: intl.formatMessage({ id: 'pet_onboard.card_1.title' }),
    bodyText: intl.formatMessage({ id: 'pet_onboard.card_1.body' }),
    btnText: intl.formatMessage({ id: 'pet_onboard.card_1.button' }),
  },
  {
    id: 'HomeOnboardCard2',
    name: HomeOnboardCard2,
    titleHtml: intl.formatMessage({ id: 'pet_onboard.card_2.title' }),
    bodyText: intl.formatMessage({ id: 'pet_onboard.card_2.body' }),
    btnText: intl.formatMessage({ id: 'pet_onboard.card_2.button' }),
  },
  {
    id: 'HomeOnboardCard3',
    name: HomeOnboardCard3,
    titleHtml: intl.formatMessage({ id: 'pet_onboard.card_3.title' }),
    bodyText: intl.formatMessage({ id: 'pet_onboard.card_3.body' }),
    btnText: intl.formatMessage({ id: 'pet_onboard.card_3.button' }),
    btnType: 'primary',
  },
];

const HomeOnboardPetolo = () => {
  const { id } = useParams();
  const intl = useIntl();

  return (
    <Container>
      <Onboard
        initialIndex={id}
        name={PETOLO_DASHBOARD_ONBOARD_KEY}
        onboardingUrl="/intro-petolo"
        mainPageUrl="/dashboard"
        schema={homeOnboardConfig(intl)}
      />
    </Container>
  );
};

export default HomeOnboardPetolo;
