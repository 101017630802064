import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import QueryString from 'query-string';
import { Button, Container, Typography } from '@mui/material';

import ButtonContainer from '../../components/ButtonContainer';

import { StyledAkutschutzCancellationCompleteContainer } from './AkutschutzCancellation.styled';

export default function AkutschutzCancellationAbort() {
  const history = useHistory();

  const { search } = useLocation();
  const params = QueryString.parse(search);

  const isWithdrawal = params?.withdraw === 'true';

  return (
    <Container>
      <StyledAkutschutzCancellationCompleteContainer>
        {isWithdrawal && (
          <Typography variant="p">
            <FormattedMessage id="akutschutz_cancellation.abort.withdraw.content" />
          </Typography>
        )}

        {!isWithdrawal && (
          <Typography variant="p">
            <FormattedMessage id="akutschutz_cancellation.abort.cancel.content" />
          </Typography>
        )}
      </StyledAkutschutzCancellationCompleteContainer>

      <ButtonContainer>
        <Button variant="outlined" type="button" onClick={() => history.push('/user-info')}>
          <Typography variant="buttons">
            <FormattedMessage id="akutschutz_cancellation.abort.back_to_page" />
          </Typography>
        </Button>
      </ButtonContainer>
    </Container>
  );
}
