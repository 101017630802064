import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { useMediaQuery, useTheme, Button, Typography } from '@mui/material';
import ReactTooltip from 'react-tooltip';
import { useQuery } from '@apollo/client';

import { imgLinks, useReady, GET_CUSTOMER } from '../../shared';
import useConsole from '../../hooks/useConsole';

import {
  StyledFirstVetFranceHeader,
  StyledFranceFirstVetFlexContainer,
  StyledOnlineVetCoverImage,
  StyledHeaderText,
  StyledContentContainer,
  StyledItemImg,
  StyledItemContainer,
  StyledNumberImg,
  StyledContentTextHeader,
  StyledInsuranceNumberContainer,
  StyledInsuranceNumberCopyContainer,
  StyledIconButton,
  StyledArrowImg,
  StyledContainer,
  StyledPaper,
} from './FirstVetFrance.styled';

const FirstVetFrance = () => {
  const ready = useReady();
  const firstvetAccount = 'https://livvet.vet/';
  const textRef = useRef(null);
  const buttonRef = useRef(null);
  const { consoleError } = useConsole();
  const getCustomer = useQuery(GET_CUSTOMER);
  const { customer } = getCustomer.data;

  const {
    contract: { key: contractKey },
  } = customer;
  const theme = useTheme();

  const isSmScreen = useMediaQuery(theme.breakpoints.down('tablet'));
  const isMdScreen = useMediaQuery(theme.breakpoints.between('tablet', 'desktop'));
  const isLgScreen = useMediaQuery(theme.breakpoints.between('desktop', 'largeDesktop'));
  const isXlScreen = useMediaQuery(theme.breakpoints.up('largeDesktop'));

  const getFirstVetFranceHeaderImage = () => {
    switch (true) {
      case isXlScreen:
        return imgLinks['first-vet-france-xl'];
      case isLgScreen:
        return imgLinks['first-vet-france-lg'];
      case isMdScreen:
        return imgLinks['first-vet-france-md'];
      case isSmScreen:
        return imgLinks['first-vet-france-sm'];
      default:
        return imgLinks['first-vet-france-lg'];
    }
  };
  const getFirstVetFranceFormIcon = () => {
    switch (true) {
      case isXlScreen:
        return imgLinks['france.first.vet.insurance.form.lg'];
      case isLgScreen:
        return imgLinks['france.first.vet.insurance.form.lg'];
      case isMdScreen:
        return imgLinks['france.first.vet.insurance.form.sm'];
      case isSmScreen:
        return imgLinks['france.first.vet.insurance.form.sm'];
      default:
        return imgLinks['france.first.vet.insurance.form.lg'];
    }
  };

  const handleCopy = () => {
    if (!window?.navigator?.clipboard) return;

    const textToCopy = textRef.current?.innerText || '';
    if (!textToCopy) return;
    window.navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        // Use buttonRef.current directly:
        ReactTooltip.show(buttonRef.current);
        setTimeout(() => {
          ReactTooltip.hide(buttonRef.current);
        }, 1000);
      })
      .catch((err) => consoleError('Copy failed', err));
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleCopy();
    }
  };
  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <>
        <StyledFirstVetFranceHeader>
          <StyledOnlineVetCoverImage src={getFirstVetFranceHeaderImage()} alt="FirstVetFrance" />
          <StyledContainer>
            <StyledHeaderText>
              <Typography variant="h1" color="white">
                <FormattedMessage id="first.vet.france.header.title" />
              </Typography>
              <Typography variant="p" color="white">
                <FormattedMessage id="first.vet.france.header.description" />
              </Typography>
            </StyledHeaderText>
          </StyledContainer>
        </StyledFirstVetFranceHeader>
        <StyledContainer>
          <StyledContentContainer>
            <StyledContentTextHeader>
              <Typography variant="h2">
                <FormattedMessage id="first.vet.france.content.header.title" />
              </Typography>
              <Typography variant="p">
                <FormattedMessage id="first.vet.france.content.header.description" />
              </Typography>
            </StyledContentTextHeader>
            <StyledPaper>
              <StyledItemContainer>
                <StyledNumberImg
                  src={imgLinks['icon.online.france.first.vet.one']}
                  alt="item-one"
                />
                <Typography variant="h3">
                  <FormattedMessage id="first.vet.france.content.section1.title" />
                </Typography>
              </StyledItemContainer>
              <Typography variant="p">
                <FormattedMessage id="first.vet.france.content.section1.description" />
              </Typography>
              <StyledFranceFirstVetFlexContainer>
                <Button fullWidth onClick={() => window.open(firstvetAccount, '_blank')}>
                  <Typography variant="buttons">
                    <FormattedMessage id="first.vet.france.content.section1.link.button" />
                  </Typography>
                </Button>
              </StyledFranceFirstVetFlexContainer>
            </StyledPaper>
            <StyledArrowImg src={imgLinks['icon.arrow.right']} alt="arrow-right" />

            <StyledPaper>
              <StyledItemContainer>
                <StyledNumberImg
                  src={imgLinks['icon.online.france.first.vet.two']}
                  alt="item-two"
                />
                <Typography variant="h3">
                  <FormattedMessage id="first.vet.france.content.section2.title" />
                </Typography>
              </StyledItemContainer>
              <Typography variant="p">
                <FormattedMessage id="first.vet.france.content.section2.description" />
              </Typography>
              <StyledItemImg
                src={imgLinks['france.first.vet.insurance.input']}
                alt="input-example"
              />
            </StyledPaper>
            <StyledArrowImg src={imgLinks['icon.arrow.left']} alt="arrow-left" />

            <StyledPaper>
              <StyledItemContainer>
                <StyledNumberImg
                  src={imgLinks['icon.online.france.first.vet.three']}
                  alt="item-three"
                />
                <Typography variant="h3">
                  <FormattedMessage id="first.vet.france.content.section3.title" />
                </Typography>
              </StyledItemContainer>
              <Typography variant="p">
                <FormattedMessage id="first.vet.france.content.section3.description" />
              </Typography>
              <StyledInsuranceNumberContainer>
                <Typography variant="p">
                  <FormattedMessage id="first.vet.france.content.section3.insurance_number" />
                </Typography>
                <StyledInsuranceNumberCopyContainer
                  role="button"
                  tabIndex={0}
                  onKeyDown={handleKeyDown}
                >
                  <Typography variant="h3" ref={textRef}>
                    {contractKey}
                  </Typography>
                  <ReactTooltip
                    id="copyTooltip"
                    place="top"
                    effect="solid"
                    className="u3-referral-tooltip"
                  >
                    <Typography variant="p">
                      <FormattedMessage id="first.vet.france.link.copied" />
                    </Typography>
                  </ReactTooltip>
                  <StyledIconButton
                    icon="copy.insurance.number"
                    data-tip
                    data-for="copyTooltip"
                    data-event="none"
                    ref={buttonRef}
                    onClick={handleCopy}
                  />
                </StyledInsuranceNumberCopyContainer>
              </StyledInsuranceNumberContainer>
              <StyledItemImg src={getFirstVetFranceFormIcon()} alt="input-example" />
            </StyledPaper>
            <StyledArrowImg src={imgLinks['icon.arrow.right']} alt="arrow-right" />

            <StyledPaper>
              <StyledItemContainer>
                <StyledNumberImg
                  src={imgLinks['icon.online.france.first.vet.four']}
                  alt="item-four"
                />
                <Typography variant="h3">
                  <FormattedMessage id="first.vet.france.content.section4.title" />
                </Typography>
              </StyledItemContainer>
              <Typography variant="p">
                <FormattedMessage
                  id="first.vet.france.content.section4.description"
                  values={{
                    dummyPrice: (
                      <Typography variant="h3" component="span">
                        <FormattedMessage id="first.vet.france.content.section4.dumy.price" />
                      </Typography>
                    ),
                  }}
                />
              </Typography>
              <StyledItemImg src={imgLinks['france.first.vet.price.example']} alt="input-example" />
            </StyledPaper>
          </StyledContentContainer>
        </StyledContainer>
      </>
    </CSSTransition>
  );
};

export default FirstVetFrance;
