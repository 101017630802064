import { styled, css } from '@mui/material';

import { PALETTE } from '../../../theme/palette';
import { promotionCarouselSliderHeight } from '../PromotionCarousel/PromotionCarousel.styled';

export const StyledReferralLinkCardDentolo = styled('div')(
  ({ theme, backgroundImgMobile, backgroundImgDesktop, imgMobile, imgDesktop }) => css`
    background: url(${backgroundImgMobile}) no-repeat;
    background-color: ${theme.palette.background.viewport};
    background-position: center;
    display: flex;
    flex-direction: column;
    height: ${promotionCarouselSliderHeight.mobile};
    padding: ${theme.spacing('LARGE')};
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 9.8125rem;
      height: 8.375rem;
      background: url(${imgMobile}) no-repeat;
      background-size: 9.8125rem 8.375rem;
    }

    ${theme.breakpoints.up('desktop')} {
      background: url(${backgroundImgDesktop}) no-repeat;
      background-color: ${theme.palette.background.viewport};
      background-position: center;
      height: ${promotionCarouselSliderHeight.desktop};
      justify-content: center;
      padding: ${theme.spacing('1XL')};
      row-gap: ${theme.spacing('1XL')};

      &::before {
        top: 1rem;
        right: 1.5rem;
        width: 17.875rem;
        height: 16.375rem;
        background: url(${imgDesktop}) no-repeat;
        background-size: 17.875rem 16.375rem;
      }
    }
  `
);

export const StyledReferralLinkCardDentoloText = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    width: 9.5625rem;

    span {
      display: inline;
      padding-left: 0.125rem;
      padding-right: 0.125rem;
      background: linear-gradient(to bottom, transparent 62%, ${PALETTE.VITALIZED_GREEN} 38%);
      background-repeat: no-repeat;
    }

    ${theme.breakpoints.up('desktop')} {
      flex-grow: 0;
      width: 16.875rem;

      span {
        padding-left: 0.1875rem;
        padding-right: 0.1875rem;
      }
    }
  `
);
