import { css, styled } from '@mui/material';
import { PALETTE } from '../../theme/palette';

export const StyledVerticalContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledContactFormCustomerName = styled('div')(
  ({ theme }) => css`
    display: flex;
    column-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledContactFormField = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('SMALL')};

    > label,
    > select,
    > textarea {
      width: 100%;
      padding: ${theme.spacing('SMALL')};
      border: 2px solid ${theme.palette.main.product_blue};
      border-radius: 4px;
      color: ${theme.palette.main.professional_blue};
    }

    > select {
      appearance: none;
      padding-right: ${theme.spacing('2XL')};
      background: url(/images/user-area-icons/general/icon.caret-down-24x24.svg) no-repeat #fff;
      background-position: center right 0.375rem;
    }
  `
);

export const StyledContactUsByPhoneContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: fit-content;
    padding-left: ${theme.spacing('4XL')};
    row-gap: ${theme.spacing('SMALL')};

    ${theme.breakpoints.up('desktop')} {
      flex-direction: row;
      padding-left: ${theme.spacing('5XL')};
      column-gap: ${theme.spacing('1XL')};
    }
  `
);

export const StyledTipContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.background.info};
    column-gap: ${theme.spacing('SMALL')};
    display: flex;
    padding: ${theme.spacing('LARGE')};
  `
);

export const StyledTipIconContainer = styled('div')`
  flex-shrink: 0;
`;

export const StyledBacklogBannerContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    background-color: ${PALETTE.BLUE_STATUS};
    border-radius: 4px;
    padding: ${theme.spacing('LARGE')};
    column-gap: ${theme.spacing('LARGE')};
    margin-bottom: ${theme.spacing('LARGE')};
  `
);

export const StyledBannerIcon = styled('img')`
  width: auto;
`;
