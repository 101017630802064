import { css, styled } from '@mui/material';
import { MAX_WIDTH_MD, MIN_WIDTH_LG } from '../../theme/breakpoints';

const StyledContainer = styled('div')(
  ({ theme }) => css`
    margin: 1.5rem auto 0;
    padding: 0 1rem;
    width: 100%;

    @media (min-width: ${MIN_WIDTH_LG}) {
      max-width: ${theme.shape.container};
      padding: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-top: 4rem;
    }

    @media (min-width: ${MAX_WIDTH_MD}) {
      padding: 0;
      margin-top: 4rem;
    }
  `
);

export default StyledContainer;
