import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useIntl, FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import { Container, Paper, Typography } from '@mui/material';

import { GET_CUSTOMER, formatPolicyName, showMoney, useReady } from '../../shared';
import fetchPolicyCoverageInfo from '../../actions/PolicyActions';

import { Premium, Conditions, MainDetails, AddOns } from './PolicyDetailsItems';
import ErrorPlaceholder from '../errorPlaceholder';
import { BackButton, PageTitle } from '../../components/common';

import {
  StyledBoxSpaceBetween,
  StyledPolicyDetailsNumericalValue,
  StyledSeparator,
  StyledVerticalContent,
} from './PolicyInfo.styled';

const PriceBreakup = ({ addons, monthlyPremium, policyName }) => {
  const addOnTitle = addons?.title || '';
  const addOnPrice = addons?.price || 0;
  const premiumPrice = monthlyPremium - addOnPrice;

  return (
    <>
      <StyledBoxSpaceBetween>
        <Typography variant="p" color="subtext">
          <FormattedMessage id={`policies.${formatPolicyName(policyName)}`} />
        </Typography>

        <StyledPolicyDetailsNumericalValue>
          <Typography variant="h3">{showMoney(premiumPrice)}</Typography>
        </StyledPolicyDetailsNumericalValue>
      </StyledBoxSpaceBetween>

      <StyledBoxSpaceBetween>
        <Typography variant="p" color="subtext">
          {addOnTitle}
        </Typography>

        <StyledPolicyDetailsNumericalValue>
          <Typography variant="h3">{showMoney(addOnPrice)}</Typography>
        </StyledPolicyDetailsNumericalValue>
      </StyledBoxSpaceBetween>
    </>
  );
};

const PolicyDetails = () => {
  const ready = useReady();
  const intl = useIntl();

  const [policyCoverageInfo, setPolicyCoverageInfo] = useState(null);
  const { loading, data, error } = useQuery(GET_CUSTOMER);
  const { customer } = data;
  const policyName = customer?.contract?.policyName;
  const policyCategory = customer?.contract?.policyCategory;
  const imCoverage = customer?.contract?.imCoverage;
  const monthlyPremium = customer?.contract?.monthlyPremium;

  const contractPremium = customer?.contract?.contractPremium;
  const contractBudgetSchedule = customer?.contract?.contractBudgetSchedule;
  const budgetScheduleAddon = customer?.contract?.addonBudgedSchedule;

  const budgetAmountInfo = budgetScheduleAddon
    ? budgetScheduleAddon?.limits
    : contractBudgetSchedule;

  const locale = intl?.locale;

  useEffect(() => {
    if (!loading && !!customer) {
      fetchPolicyCoverageInfo(policyCategory, imCoverage, locale).then(setPolicyCoverageInfo);
    }
  }, [loading, customer, policyCategory, imCoverage, locale]);

  if (customer?.contract) {
    return (
      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Container>
          <BackButton to="/policy-info" />

          <PageTitle>
            <Typography variant="h1">
              <FormattedMessage id="policy.all_details.title" />
            </Typography>
          </PageTitle>

          <Paper>
            <StyledVerticalContent>
              <Typography variant="h3">
                <FormattedMessage id={`policies.${formatPolicyName(policyName)}`} />
              </Typography>

              {customer?.contract?.status !== 'ended' && (
                <>
                  <StyledSeparator />

                  <StyledBoxSpaceBetween>
                    <Typography variant="h3">
                      <FormattedMessage id="policy.card.label.monthly_premium" />
                    </Typography>

                    <StyledPolicyDetailsNumericalValue>
                      <Typography variant="h3">{showMoney(monthlyPremium)}</Typography>
                    </StyledPolicyDetailsNumericalValue>
                  </StyledBoxSpaceBetween>
                </>
              )}

              {imCoverage && !!policyCoverageInfo && (
                <PriceBreakup
                  addons={policyCoverageInfo?.addons}
                  monthlyPremium={monthlyPremium}
                  policyName={policyName}
                />
              )}

              <StyledSeparator />

              <AddOns details={policyCoverageInfo?.addons} flipStyling />

              <MainDetails details={policyCoverageInfo?.main} />

              <Conditions
                conditions={policyCoverageInfo?.conditions}
                budgetInfo={policyCoverageInfo?.budget}
                budgetAmountInfo={budgetAmountInfo}
                hasAddon={!!budgetScheduleAddon}
              />

              <Premium
                premiumInfo={policyCoverageInfo?.premium}
                contractPremium={contractPremium}
              />
            </StyledVerticalContent>
          </Paper>
        </Container>
      </CSSTransition>
    );
  }

  if (error) {
    return <ErrorPlaceholder />;
  }

  return null;
};

export default PolicyDetails;
