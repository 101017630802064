import { css, styled } from '@mui/material';

export const StyledFailurePageContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;

    ${theme.breakpoints.up('desktop')} {
      flex-direction: row;
    }
  `
);

export const StyledTextContentContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};
    text-align: center;

    ${theme.breakpoints.up('desktop')} {
      text-align: left;
    }
  `
);
