import { styled, css } from '@mui/material';

export const StyledNoFavoriteDentistsMessage = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacing('LARGE')};
  `
);

export const StyledFavoriteDentistMessage = styled('div')(
  ({ theme }) => css`
    box-shadow: ${theme.shadows[1]};
    padding: ${theme.spacing('LARGE')};
    text-align: center;
  `
);

export const StyledFavoriteDentistDetailsExpandButton = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    margin-top: ${theme.spacing('SMALL')};
  `
);
