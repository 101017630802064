import React from 'react';

import PleaseSignIn from '../ui/PleaseSignIn';
import HeaderWithActions from '../Header/HeaderWithActions';
import StyledPageContent from './Page.styled';

const PageWithFullHeaderWithoutFooter = ({ children }) => {
  return (
    <StyledPageContent>
      <HeaderWithActions />
      <PleaseSignIn>
        <main>{children}</main>
      </PleaseSignIn>
    </StyledPageContent>
  );
};

export default PageWithFullHeaderWithoutFooter;
