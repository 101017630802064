import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import useDynamicLink from '../../../../hooks/useDynamicLink';
import { DA_DIREKT_CROSS_SELLING_DENTAL_URL } from '../../../../shared';

import DaDirektDentalCardDesktop from './DaDirektDentalCardDesktop';
import DaDirektDentalCardMobile from './DaDirektDentalCardMobile';

const daFont = [
  {
    href: 'https://www.da-direkt.de/-/media/Themes/DADirekt/Global/DADirektTheme/fonts/centrano2-medium-woff2.woff2',
    rel: 'preload',
    as: 'font',
    type: 'font/woff2',
  },
];

function DaDirektDentalCard({ trackingparams }) {
  useDynamicLink(daFont);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const dentalCrossSellingUrl = `${DA_DIREKT_CROSS_SELLING_DENTAL_URL}?${trackingparams}`;

  const goToCrossSellingLink = () => {
    window.open(dentalCrossSellingUrl, '_blank');
  };

  return isDesktop ? (
    <DaDirektDentalCardDesktop onGoToCrossSellingLink={goToCrossSellingLink} />
  ) : (
    <DaDirektDentalCardMobile onGoToCrossSellingLink={goToCrossSellingLink} />
  );
}

export default DaDirektDentalCard;
