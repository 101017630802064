import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, Container, Link, Typography } from '@mui/material';

import { Icon } from '../../components/common';
import ButtonContainer from '../../components/ButtonContainer';

import { StyledAkutschutzCancellationCompleteContainer } from './AkutschutzCancellation.styled';

export default function AkutschutzCancellationError() {
  const history = useHistory();

  const renderAnchorTagForContactUsPage = (chunk) => (
    <Link target="_blank" rel="noopener noreferrer" href="/contact">
      {chunk}
    </Link>
  );

  return (
    <Container>
      <StyledAkutschutzCancellationCompleteContainer>
        <div>
          <Icon icon="icon.error" alt="error" />
        </div>

        <Typography variant="h1">
          <FormattedMessage id="akutschutz_cancellation.error.title" />
        </Typography>

        <Typography variant="p">
          <FormattedMessage
            id="akutschutz_cancellation.error.content"
            values={{
              a: (chunk) => renderAnchorTagForContactUsPage(chunk),
            }}
          />
        </Typography>
      </StyledAkutschutzCancellationCompleteContainer>

      <ButtonContainer>
        <Button
          variant="outlined"
          type="button"
          onClick={() => history.replace('/akutschutz-cancellation')}
        >
          <Typography variant="buttons">
            <FormattedMessage id="akutschutz_cancellation.error.try_again" />
          </Typography>
        </Button>
      </ButtonContainer>
    </Container>
  );
}
