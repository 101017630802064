import React from 'react';
import { FormattedMessage } from 'react-intl';

import { formatDate, contractStatus, contractInTrialMonth } from '../../shared';

// eslint-disable-next-line import/prefer-default-export
export const parseContractStatusInfo = (startingAt, cancelationDate, status, addonTrialMonth) => {
  const getLabelColor = (statusLabel) =>
    [
      contractStatus.LABEL_START,
      contractStatus.LABEL_WILL_START,
      contractStatus.LABEL_WILL_END,
      contractStatus.LABEL_ACTIVE_TRIAL_MONTH,
    ].includes(statusLabel)
      ? 'green'
      : '';

  const getStatusLabel = () => {
    if (startingAt && cancelationDate) {
      // Widerrufen - (where contract_end_date before contract's starting_at)
      if (cancelationDate && new Date(startingAt) > new Date(cancelationDate)) {
        return contractStatus.LABEL_REVOKED;
      }

      // Beendet - (where contract_end_date has happened and after contract.starting_at)
      if (
        cancelationDate &&
        new Date(startingAt) < new Date(cancelationDate) &&
        new Date() > new Date(cancelationDate)
      ) {
        return contractStatus.LABEL_END;
      }

      // Wird Beendet - (where contract_end_date has not happened and after contract.starting_at)
      if (
        cancelationDate &&
        new Date(startingAt) < new Date(cancelationDate) &&
        new Date() < new Date(cancelationDate)
      ) {
        return contractStatus.LABEL_WILL_END;
      }
    }
    return contractStatus.LABEL_START;
  };

  const getStatusTitleForEnded = () => {
    return cancelationDate && new Date(cancelationDate) > new Date(startingAt)
      ? contractStatus.LABEL_END
      : null;
  };

  // to get `Probemonat aktiv` label when contract is in trial month and contract status is `accepted`. Contract is in `accepted` status during `trial_month`
  const getStatusLabelForAccepted = () => {
    const inTrialMonth = contractInTrialMonth(
      addonTrialMonth?.status,
      addonTrialMonth?.startingAt,
      startingAt
    );

    return inTrialMonth ? contractStatus.LABEL_ACTIVE_TRIAL_MONTH : contractStatus.LABEL_WILL_START;
  };

  // TODO: check mapping for other statuses
  const statusMapping = {
    [contractStatus.ACTIVE]: {
      statusLabel: getStatusLabel(),
      statusTitle: getStatusTitleForEnded(),
      date: cancelationDate,
    },
    [contractStatus.ACCEPTED]: {
      statusLabel: getStatusLabelForAccepted(),
      statusTitle: contractStatus.LABEL_START,
      date: startingAt,
    },
    [contractStatus.ENDED]: {
      statusLabel: getStatusLabel(),
      statusTitle: getStatusTitleForEnded(),
      date: cancelationDate,
    },
  };
  const statusInfo = statusMapping[`${status}`] || {};

  return {
    statusColor: getLabelColor(statusInfo.statusLabel),
    label: statusInfo.statusLabel && (
      <FormattedMessage id={`contract.status.${statusInfo.statusLabel}`} />
    ),
    title: statusInfo.statusTitle && (
      <FormattedMessage id={`contract.status.title.${statusInfo.statusTitle}`} />
    ),
    date: formatDate(statusInfo?.date),
  };
};
