import React from 'react';
import { SvgIcon, useTheme } from '@mui/material';

export default function CloseIcon(props) {
  const theme = useTheme();

  return (
    <SvgIcon inheritViewBox {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M1 1L13 13"
          stroke={theme.palette.main.professional_blue}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M1 13L13 1"
          stroke={theme.palette.main.professional_blue}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  );
}
