import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { useQuery } from '@apollo/client';
import { Container, Link, Paper, Typography } from '@mui/material';

import { LOCAL_CONFIRM_EMAIL, GET_CUSTOMER, useReady } from '../../shared';
import { Icon } from '../common';
import PageTitle from '../common/PageTitle';

import {
  StyledVerticalContent,
  StyledLoginActionStatusHeader,
  StyledTipContainer,
  StyledTipItemsList,
} from './Login.styled';

export default function LoginSuccess() {
  const history = useHistory();

  const { data: confirmEmailData } = useQuery(LOCAL_CONFIRM_EMAIL);

  const { data: customerData } = useQuery(GET_CUSTOMER);
  const customerKey = customerData?.customer?.key;

  const ready = useReady();

  useEffect(() => {
    if (customerKey) {
      history.push('/');
    }
  }, [customerKey, history]);

  return (
    <Container>
      <PageTitle>
        <Typography variant="h1">
          <FormattedMessage id="login.common.title" />
        </Typography>
      </PageTitle>

      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Paper>
          <StyledVerticalContent>
            <StyledLoginActionStatusHeader>
              <Icon icon="info.success" alt="success" />

              <Typography variant="h3">
                <FormattedMessage
                  id="login.status.email.sent"
                  values={{ email: confirmEmailData?.confirmEmail || '' }}
                />
              </Typography>
            </StyledLoginActionStatusHeader>

            <Typography variant="p">
              <FormattedMessage id="login.status.success.text_1" />
            </Typography>

            <Typography variant="p">
              <FormattedMessage id="login.status.success.text_2" />
            </Typography>

            <Link variant="p" href="/login">
              <FormattedMessage id="login.status.success.try_again" />
            </Link>

            <StyledTipContainer>
              <StyledVerticalContent>
                <div>
                  <Typography variant="h3">
                    <FormattedMessage id="login.status.success.tip.question" />
                  </Typography>

                  <Typography variant="p">
                    <FormattedMessage id="login.status.success.tip.description" />
                  </Typography>
                </div>

                <Typography variant="p" component="div">
                  <FormattedMessage id="login.status.success.tip.list.title" />

                  <StyledTipItemsList>
                    <li>
                      <FormattedMessage id="login.status.success.tip.list.item_1" />
                    </li>

                    <li>
                      <FormattedMessage id="login.status.success.tip.list.item_2" />
                    </li>
                  </StyledTipItemsList>
                </Typography>
              </StyledVerticalContent>
            </StyledTipContainer>
          </StyledVerticalContent>
        </Paper>
      </CSSTransition>
    </Container>
  );
}
