import { css, Paper, styled } from '@mui/material';

export const StyledVerticalContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledSelectCustomerPaper = styled(Paper)(
  ({ theme, active }) => css`
    align-items: center;
    border: none;
    column-gap: ${theme.spacing('LARGE')};
    cursor: pointer;
    display: flex;
    opacity: ${!active ? 0.5 : 1};
    text-align: left;
    transition: opacity 0.3s ease-in-out;
  `
);

export const StyledUserItemLogo = styled('div')`
  width: 4.5rem;
  height: 4.5rem;
`;
