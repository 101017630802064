import { css, styled, linkClasses } from '@mui/material';

import { MIN_WIDTH_LG } from '../../theme/breakpoints';

import { Paper } from '../../components/common';

export const StyledAlreadyUploadedDocumentsCard = styled(Paper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1em;
  padding: 1em;
`;

export const StyledAlreadyUploadedDocumentsTitle = styled('div')`
  margin-bottom: 1em;

  h5 {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  @media (min-width: ${MIN_WIDTH_LG}) {
    margin-bottom: 1.5em;

    h5 {
      line-height: 1.5rem;
    }
  }
`;

export const StyledAlreadyUploadedDocumentContainer = styled('div')(
  ({ theme }) => css`
    padding-bottom: 1.5em;
    padding-top: 1.5em;
    border-bottom: 1px solid ${theme.palette.gray.light_gray};

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }
  `
);

export const StyledAlreadyUploadedDocument = styled('div')`
  display: flex;
  column-gap: 1em;
`;

export const StyledAlreadyUploadedDocumentDate = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.main.product_blue};
    margin-top: 0.25em;
  `
);

export const StyledAlreadyUploadedDocumentLinkContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  column-gap: 0.25rem;
  margin-top: 1rem;

  .${linkClasses.button} {
    align-items: center;
    display: flex;
    column-gap: 0.25rem;
  }
`;
