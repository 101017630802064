import { css, styled } from '@mui/material';

import { MIN_WIDTH_LG } from '../../theme/breakpoints';
import { Paper } from '../common';
import { Paragraph } from '../ui';

export const StyledBackButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${MIN_WIDTH_LG}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledHelpCenterParagraph = styled(Paragraph)(
  ({ theme }) => css`
    margin-top: 1em;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.25em;
    color: ${theme.palette.main.professional_blue};
    background: ${theme.palette.gray.ultra_light_gray};
    padding: 0.5em 0.625em 0.5em 0.625em;
    border-radius: 0.125em;
  `
);

export const StyledClaimDetailsHeaderCard = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1em;
`;

export const StyledClaimDetailsHeaderContents = styled('div')`
  margin-bottom: 0.5em;
`;

export const StyledTreatmentCategory = styled('h5')`
  margin-bottom: 0.5em;

  @media (min-width: ${MIN_WIDTH_LG}) {
    line-height: 1.5rem;
    margin-bottom: 1em;
    margin-right: 0.5em;
  }
`;

export const StyledTreatmentInfo = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.main.professional_light_blue};
    opacity: 0.9;
  `
);
