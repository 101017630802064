import {
  VITOLO_HEALTH_POLICY_CATEGORIES_V2,
  VITOLO_V1_REFERRAL_URL,
  VITOLO_V2_REFERRAL_URL,
} from '../../../../shared';

export const elementIdWithReferralLink = 'element-with-vitolo-referral-link';

export const referralSteps = [
  {
    descriptionTestId: 'refertab_textblock1_vitolo',
    titleId: 'referral.page.vitolo.step.one.title',
    descriptionId: 'referral.page.vitolo.step.one.description',
  },
  {
    descriptionTestId: 'refertab_textblock2_vitolo',
    titleId: 'referral.page.vitolo.step.two.title',
    descriptionId: 'referral.page.vitolo.step.two.description',
  },
  {
    descriptionTestId: 'refertab_textblock3_vitolo',
    titleId: 'referral.page.vitolo.step.three.title',
    descriptionId: 'referral.page.vitolo.step.three.description',
    descriptionValues: { giftbrand: 'Amazon-Gutschein' },
    additionalInfo: [
      {
        testId: 'refertab_bullet1_vitolo',
        infoId: 'referral.page.vitolo.step.three.additional_info.one',
      },
      {
        testId: 'refertab_bullet2_vitolo',
        infoId: 'referral.page.vitolo.step.three.additional_info.two',
      },
    ],
  },
];

export const socialItemsData = {
  fb: {
    testId: 'referral_facebook_button_vitolo',
    icon: 'icon-vitolo-referral-fb',
  },
  whatsapp: {
    testId: 'referral_whatsapp_button_vitolo',
    icon: 'icon-vitolo-referral-whatsapp',
  },
  email: {
    testId: 'referral_email_button_vitolo',
    icon: 'icon-vitolo-referral-email',
  },
};

export const getVitoloReferralUrl = (policyCategory) => {
  return VITOLO_HEALTH_POLICY_CATEGORIES_V2.includes(policyCategory)
    ? VITOLO_V2_REFERRAL_URL
    : VITOLO_V1_REFERRAL_URL;
};
