// eslint-disable-next-line import/prefer-default-export
export const muiContainerComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      margin: `${theme.spacing('2XL')} auto ${theme.spacing('6XL')}`,
      padding: `${theme.spacing('NONE')} ${theme.spacing('LARGE')}`,
      width: '100%',

      [theme.breakpoints.up('desktop')]: {
        margin: `${theme.spacing('6XL')} auto ${theme.spacing('6XL')}`,
        maxWidth: theme.shape.container,
        padding: `${theme.spacing('NONE')}`,
      },
    }),
  },
};
