import { claimStatus } from '../../../../shared';
import { CLAIM_STATUS_DETAILS } from '../../../../shared/claimStatus';

export const isReimbursementPromised = (claim) => {
  return claim?.status === claimStatus.REIMBURSEMENT_ACKNOWLEDGED;
};

export const isMissingInvoice = (claim) => {
  const isClaimWaiting = claim?.status === claimStatus.WAITING_FOR_INFORMATION;
  const isInvoiceMissing = claim?.statusDetails === CLAIM_STATUS_DETAILS.INVOICE_MISSING;

  return isClaimWaiting && isInvoiceMissing;
};

export const isMissingTreatmentPlan = (claim) => {
  const isClaimWaiting = claim?.status === claimStatus.WAITING_FOR_INFORMATION;
  const isTreatmentPlanMissing =
    claim?.statusDetails === CLAIM_STATUS_DETAILS.TREATMENT_OR_COSTPLAN_MISSING;

  return isClaimWaiting && isTreatmentPlanMissing;
};
