import { alpha, hexToRgb } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const muiDialogComponent = {
  defaultProps: {
    disablePortal: true,
    scroll: 'paper',
    slotProps: {
      paper: {
        elevation: 1,
      },
    },
  },
  styleOverrides: {
    container: ({ theme }) => ({
      backgroundColor: alpha(hexToRgb(theme.palette.background.modalContainer), 0.8),
    }),
    paper: ({ theme }) => ({
      margin: theme.spacing('LARGE'),
      padding: '0',
      width: '100%',

      [theme.breakpoints.up('desktop')]: {
        maxWidth: theme.shape.container,
      },
    }),
  },
};
