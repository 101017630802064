import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Typography } from '@mui/material';

import { DA_DIREKT_REFERRAL_PAGE_LINK_CAROUSEL_PET } from '../../../shared';
import { Icon } from '../../../components/common';

import {
  StyledPromotionCarouselCard,
  StyledPromotionCarouselCardContent,
  StyledPromotionCarouselCardContentImageWrapper,
  StyledPromotionCarouselCardContentTitle,
  StyledPromotionCarouselButtonContainer,
} from './ReferralLinkCardPetolo.styled';

const ReferralLinkCardDaDirektPet = () => {
  const handleGotoReferralPage = () => {
    window.open(DA_DIREKT_REFERRAL_PAGE_LINK_CAROUSEL_PET, '_blank');
  };

  return (
    <StyledPromotionCarouselCard>
      <StyledPromotionCarouselCardContent>
        <StyledPromotionCarouselCardContentImageWrapper>
          <Icon icon="icon.user.referral.pet.present" alt="referral link image" />
        </StyledPromotionCarouselCardContentImageWrapper>

        <StyledPromotionCarouselCardContentTitle>
          <Typography variant="h2">
            <FormattedMessage id="promotion.carousel.da_direkt.pet.referral_link.title" />
          </Typography>
        </StyledPromotionCarouselCardContentTitle>
      </StyledPromotionCarouselCardContent>

      <StyledPromotionCarouselButtonContainer>
        <Button onClick={handleGotoReferralPage}>
          <Typography variant="buttons">
            <FormattedMessage id="promotion.carousel.da_direkt.pet.referral_link.button.label" />
          </Typography>
        </Button>
      </StyledPromotionCarouselButtonContainer>
    </StyledPromotionCarouselCard>
  );
};

export default ReferralLinkCardDaDirektPet;
