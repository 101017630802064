import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { CSSTransition } from 'react-transition-group';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Container, IconButton, Link, Paper, Typography } from '@mui/material';

import EditIcon from '../../icons/EditIcon';
import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import {
  GET_CUSTOMER,
  useReady,
  getBillingDays,
  INSURANCE_TYPE_PETOLO,
  PAYMENT_EDIT_STATUS_PENDING,
  PAYMENT_EDIT_STATUS_FAILED,
} from '../../shared';

import { BackButton, PageTitle } from '../../components/common';
import CustomerDetailsEditFormWrapper from './CustomerDetails/CustomerDetailsEditFormWrapper';
import BankDetailsEditor from './CustomerDetails/BankDetailsEditor';
import CustomerDetailsEditor from './CustomerDetails/CustomerDetailsEditor';
import InsuredPersonDetailsEditor from './CustomerDetails/InsuredPersonDetailsEditor';
import PetDetailsEditor from './CustomerDetails/PetDetailsEditor';
import DigitalPaymentInfo from './CustomerDetails/DigitalPaymentInfo';
import DigitalPaymentLoading from './CustomerDetails/DigitalPaymentLoading';
import DigitalPaymentEditor from './CustomerDetails/DigitalPaymentEditor';

import ChooseCancellation from '../akutschutzCancellation/ChooseCancellation';

import {
  StyledCustomerDetailsCardHeader,
  StyledCustomerDetailsContactUsContainer,
  StyledCustomerDetailsItem,
  StyledPaymentErrorContainer,
  StyledVerticalContent,
} from './ContractManagement.styled';

const getContactUsText = (intl, insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_PETOLO:
      return intl.formatMessage({ id: 'policy.details.contact_us.text.pet' });
    default:
      return intl.formatMessage({ id: 'policy.details.contact_us.text.person' });
  }
};

export default function ContractManagement() {
  const ready = useReady();
  const getCustomer = useQuery(GET_CUSTOMER);
  const intl = useIntl();

  const { type: insuranceType } = useInsuranceInfo();

  const [showBankDetailsEditor, setShowBankDetailsEditor] = useState(false);
  const [showCustomerDetailsEditor, setShowCustomerDetailsEditor] = useState(false);
  const [showInsuredPersonDetailsEditor, setShowInsuredPersonDetailsEditor] = useState(false);
  const [showPetDetailsEditor, setShowPetDetailsEditor] = useState(false);
  const [showDigitalPaymentEditor, setShowDigitalPaymentEditor] = useState(false);

  const { customer, digitalPaymentMethod, latestDigitalPaymentMethod } = getCustomer.data;
  const {
    gender,
    firstName,
    lastName,
    email,
    phoneNumber,
    streetName,
    houseNumber,
    postcode,
    city,
    addressDetails,
    bankAccountFirstName,
    bankAccountLastName,
    iban,
    insuredPersonIsCustomer,
    insuredPerson,
    insuredPet,
    contract: { billingDay, imCoverage, addonImCoverage, cancelationDate },
  } = customer;

  const { financialInstrument, active: hasActiveDigitalPaymentMethod } = digitalPaymentMethod || {};
  const { status } = latestDigitalPaymentMethod || {};

  const insuredPersonGender = insuredPerson?.gender;
  const insuredPersonFirstName = insuredPerson?.firstName;
  const insuredPersonLastName = insuredPerson?.lastName;
  const insuredPersonStreetName = insuredPerson?.streetName;
  const insuredPersonHouseNumber = insuredPerson?.houseNumber;
  const insuredPersonPostcode = insuredPerson?.postcode;
  const insuredPersonCity = insuredPerson?.city;

  const billingDayText = billingDay
    ? getBillingDays(intl).find((val) => val.value === billingDay)?.label
    : '';

  if (getCustomer.loading) return null;

  return (
    <>
      <CustomerDetailsEditFormWrapper
        isOpen={showBankDetailsEditor}
        title={
          hasActiveDigitalPaymentMethod
            ? intl.formatMessage({ id: 'bank.details.edit_refund_account.modal.title' })
            : intl.formatMessage({ id: 'bank.details.form.title' })
        }
        hasActiveDigitalPaymentMethod={hasActiveDigitalPaymentMethod}
        onClose={() => setShowBankDetailsEditor(false)}
        data={{
          firstName: bankAccountFirstName,
          lastName: bankAccountLastName,
          iban,
          billingDay,
        }}
        formComponent={<BankDetailsEditor />}
      />

      <CustomerDetailsEditFormWrapper
        isOpen={showCustomerDetailsEditor}
        title={intl.formatMessage({ id: 'personal.details.form.title' })}
        onClose={() => setShowCustomerDetailsEditor(false)}
        data={{
          gender,
          firstName,
          lastName,
          email,
          phoneNumber,
          streetName,
          houseNumber,
          postcode,
          city,
          addressDetails,
        }}
        formComponent={<CustomerDetailsEditor />}
      />

      {!insuredPersonIsCustomer && (
        <CustomerDetailsEditFormWrapper
          isOpen={showInsuredPersonDetailsEditor}
          title={intl.formatMessage({ id: 'insured.person.details.form.title' })}
          onClose={() => setShowInsuredPersonDetailsEditor(false)}
          data={{
            gender: insuredPersonGender,
            firstName: insuredPersonFirstName,
            lastName: insuredPersonLastName,
            streetName: insuredPersonStreetName,
            houseNumber: insuredPersonHouseNumber,
            postcode: insuredPersonPostcode,
            city: insuredPersonCity,
          }}
          formComponent={<InsuredPersonDetailsEditor />}
        />
      )}

      {insuranceType === INSURANCE_TYPE_PETOLO && (
        <CustomerDetailsEditFormWrapper
          isOpen={showPetDetailsEditor}
          title={intl.formatMessage({ id: 'pet.details.form.title' })}
          onClose={() => setShowPetDetailsEditor(false)}
          data={{
            name: insuredPet?.name,
            transponderCode: insuredPet?.transponderCode,
          }}
          formComponent={<PetDetailsEditor />}
        />
      )}

      {digitalPaymentMethod && hasActiveDigitalPaymentMethod && (
        <CustomerDetailsEditFormWrapper
          isOpen={showDigitalPaymentEditor}
          title={intl.formatMessage({ id: 'customer.details.digital.payment.edit.title' })}
          onClose={() => setShowDigitalPaymentEditor(false)}
          formComponent={<DigitalPaymentEditor />}
        />
      )}

      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Container>
          <BackButton to="/policy-info" />

          <PageTitle>
            <Typography variant="h1">
              <FormattedMessage id="policy.details.policy_management.title" />
            </Typography>
          </PageTitle>

          <StyledVerticalContent>
            <Typography variant="p">
              <FormattedMessage id="policy.details.policy_management.description" />
            </Typography>

            <Paper>
              <StyledVerticalContent>
                <StyledCustomerDetailsCardHeader>
                  <Typography variant="h2">
                    <FormattedMessage id="policy.details.my.contact.info" />
                  </Typography>

                  <IconButton
                    variant="round"
                    onClick={() => setShowCustomerDetailsEditor(true)}
                    aria-label="Edit Customer Details"
                  >
                    <EditIcon />
                  </IconButton>
                </StyledCustomerDetailsCardHeader>

                <DetailsItem
                  labelId="policy.details.contact.info.name"
                  text={`${firstName} ${lastName}`}
                />
                <DetailsItem labelId="policy.details.contact.info.email" text={email} />
                <DetailsItem labelId="policy.details.contact.info.phone" text={phoneNumber} />
                <DetailsItem
                  labelId="policy.details.contact.info.address"
                  text={`${streetName} ${houseNumber}, ${postcode} ${city}`}
                />
                {addressDetails && (
                  <DetailsItem
                    labelId="policy.details.contact.info.address_details"
                    text={addressDetails}
                  />
                )}
              </StyledVerticalContent>
            </Paper>

            {!insuredPersonIsCustomer && (
              <Paper>
                <StyledVerticalContent>
                  <StyledCustomerDetailsCardHeader>
                    <Typography variant="h2">
                      <FormattedMessage id="policy.details.insured.person.info.title" />
                    </Typography>

                    <IconButton
                      variant="round"
                      onClick={() => setShowInsuredPersonDetailsEditor(true)}
                      aria-label="Edit Insured Person Details"
                    >
                      <EditIcon />
                    </IconButton>
                  </StyledCustomerDetailsCardHeader>

                  <DetailsItem
                    labelId="policy.details.insured.person.info.name"
                    text={`${insuredPersonFirstName} ${insuredPersonLastName}`}
                  />

                  <DetailsItem
                    labelId="policy.details.insured.person.info.address"
                    text={`${insuredPersonStreetName} ${insuredPersonHouseNumber}, ${insuredPersonPostcode} ${insuredPersonCity}`}
                  />
                </StyledVerticalContent>
              </Paper>
            )}

            {insuranceType === INSURANCE_TYPE_PETOLO && (
              <Paper>
                <StyledVerticalContent>
                  <StyledCustomerDetailsCardHeader>
                    <Typography variant="h2">
                      <FormattedMessage id="policy.details.pet.info.title" />
                    </Typography>

                    <IconButton
                      variant="round"
                      onClick={() => setShowPetDetailsEditor(true)}
                      aria-label="Edit Pet Details"
                    >
                      <EditIcon />
                    </IconButton>
                  </StyledCustomerDetailsCardHeader>

                  <DetailsItem
                    labelId="policy.details.pet.info.name"
                    text={`${insuredPet?.name}`}
                  />

                  <DetailsItem
                    labelId="policy.details.pet.info.transponder_code"
                    text={
                      insuredPet?.transponderCode ? (
                        `${insuredPet?.transponderCode}`
                      ) : (
                        <FormattedMessage id="policy.details.pet.info.transponder_code.missing" />
                      )
                    }
                  />
                </StyledVerticalContent>
              </Paper>
            )}

            {/* digital payments */}
            {digitalPaymentMethod && hasActiveDigitalPaymentMethod && (
              <Paper>
                <StyledVerticalContent>
                  <StyledCustomerDetailsCardHeader>
                    <Typography variant="h2">
                      <FormattedMessage id="policy.details.my.digital.payment.info" />
                    </Typography>

                    <IconButton
                      variant="round"
                      onClick={() => setShowDigitalPaymentEditor(true)}
                      aria-label="Edit Digital Payment Data"
                    >
                      <EditIcon />
                    </IconButton>
                  </StyledCustomerDetailsCardHeader>

                  {status === PAYMENT_EDIT_STATUS_FAILED && (
                    <StyledPaymentErrorContainer>
                      <Typography variant="p" color="error">
                        <FormattedMessage id="policy.details.account.digital.payment.error" />
                      </Typography>
                    </StyledPaymentErrorContainer>
                  )}

                  <Typography variant="p">
                    <FormattedMessage id="policy.details.account.digital.payment.desc" />
                  </Typography>

                  {status === PAYMENT_EDIT_STATUS_PENDING && <DigitalPaymentLoading />}

                  {status !== PAYMENT_EDIT_STATUS_PENDING && (
                    <DigitalPaymentInfo financialInstrument={financialInstrument} />
                  )}

                  {billingDay && (
                    <DetailsItem
                      labelId="policy.details.billing.info.billing_day"
                      text={billingDayText}
                    />
                  )}
                </StyledVerticalContent>
              </Paper>
            )}

            <Paper>
              <StyledVerticalContent>
                <StyledCustomerDetailsCardHeader>
                  <Typography variant="h2">
                    {hasActiveDigitalPaymentMethod ? (
                      <FormattedMessage id="policy.details.my.refund_account.info" />
                    ) : (
                      <FormattedMessage id="policy.details.my.billing.info" />
                    )}
                  </Typography>

                  <IconButton
                    variant="round"
                    onClick={() => setShowBankDetailsEditor(true)}
                    aria-label="Edit Bank Details"
                  >
                    <EditIcon />
                  </IconButton>
                </StyledCustomerDetailsCardHeader>

                <Typography variant="p">
                  {hasActiveDigitalPaymentMethod ? (
                    <FormattedMessage id="policy.details.my.refund_account.desc" />
                  ) : (
                    <FormattedMessage id="policy.details.account.billing.desc" />
                  )}
                </Typography>

                <DetailsItem
                  labelId="policy.details.billing.info.account_holder"
                  text={`${bankAccountFirstName || ''} ${bankAccountLastName || ''}`}
                />

                <DetailsItem labelId="policy.details.billing.info.iban" text={iban} />

                {(!digitalPaymentMethod || !digitalPaymentMethod.active) && billingDay && (
                  <DetailsItem
                    labelId="policy.details.billing.info.billing_day"
                    text={billingDayText}
                  />
                )}
              </StyledVerticalContent>
            </Paper>

            <StyledCustomerDetailsContactUsContainer>
              <StyledVerticalContent>
                <Typography variant="p">{getContactUsText(intl, insuranceType)}</Typography>

                <Link variant="h3" href="/contact">
                  <FormattedMessage id="policy.details.contact.us" />
                </Link>
              </StyledVerticalContent>
            </StyledCustomerDetailsContactUsContainer>

            <ChooseCancellation
              imCoverage={imCoverage}
              addonImCoverage={addonImCoverage}
              cancelationDate={cancelationDate}
            />
          </StyledVerticalContent>
        </Container>
      </CSSTransition>
    </>
  );
}

const DetailsItem = ({ labelId, text }) => {
  return (
    <StyledCustomerDetailsItem>
      <div data-label>
        <Typography variant="p">
          <FormattedMessage id={labelId} />:
        </Typography>
      </div>

      <div data-value>
        <Typography variant="h3">{text}</Typography>
      </div>
    </StyledCustomerDetailsItem>
  );
};

DetailsItem.propTypes = {
  labelId: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};
