import { styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

export const StyledUserClaimsSlideGroupContainer = styled('div')`
  position: relative;
  min-height: 20rem;
`;

export const StyledNewReimbursementButtonContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  margin-top: 1.5em;
  margin-bottom: 1.5em;

  justify-content: space-between;
  & > a {
    margin-left: auto;
    width: -webkit-fill-available;
    height: 3.5rem;
  }

  & > a[disabled] {
    pointer-events: none;
  }
  flex-direction: column;
  row-gap: 1em;
  @media (min-width: ${MIN_WIDTH_MD}) {
    & > a {
      margin-left: 0;
      padding: 0.875rem;
      height: 4rem;
    }
    flex-direction: row;
    column-gap: 1em;
  }
`;
