import React, { useState, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { CSSTransition } from 'react-transition-group';
import { Button, Link, Paper, Typography } from '@mui/material';

import useConsole from '../../../../hooks/useConsole';
import { getReferralShareToSocialList } from '../referralPageHelper';
import { JOIN_REFERRAL_PROGRAM } from '../../../../graphql/mutations/referrals';
import {
  useReady,
  GET_CUSTOMER,
  PETOLO_REFERRAL_URL,
  PETOLO_REFERRAL_TERMS_LINK,
  GETOLO_UTM_PARAMS,
} from '../../../../shared';

import { Loading } from '../../../../components/ui';
import ErrorPlaceholder from '../../../errorPlaceholder';

import {
  StyledReferralAdditionalInformation,
  StyledReferralAdditionalInformationContainer,
  StyledReferralButtonContainer,
  StyledReferralIdBox,
  StyledReferralIdContainer,
  StyledReferralStepsContainer,
  StyledReferralStepTitle,
  StyledSeparator,
  StyledSocialGroup,
  StyledSocialImg,
  StyledVerticalContent,
} from '../ReferralPage.styled';

const referralSteps = [
  {
    id: 1,
    titleId: 'referral.page.petolo.step.one.title',
    descriptionId: 'referral.page.petolo.step.one.description',
  },
  {
    id: 2,
    titleId: 'referral.page.petolo.step.two.title',
    descriptionId: 'referral.page.petolo.step.two.description',
  },
  {
    id: 3,
    titleId: 'referral.page.petolo.step.three.title',
    descriptionId: 'referral.page.petolo.step.three.description',
    descriptionValues: { giftbrand: 'Fressnapf-Gutschein' },
    additionalInfo: [
      { infoId: 'referral.page.petolo.step.three.additional_info.one' },
      { infoId: 'referral.page.petolo.step.three.additional_info.two' },
      { infoId: 'referral.page.petolo.step.three.additional_info.three' },
    ],
  },
];

const transitionTimeout = 500;
const elementIdWithReferralLink = 'element-with-petolo-referral-link';
const utmSource = `utm_source=${GETOLO_UTM_PARAMS.source}`;
const utmCampaign = `utm_campaign=${GETOLO_UTM_PARAMS.campaignReferral}`;

function PetoloReferralInformation(props) {
  const { customer, customerLoading, onReferralInfoLinkClick } = props;
  const { referralCode: refId } = customer || {};

  const intl = useIntl();
  const ready = useReady();
  const { consoleError } = useConsole();

  const petoloReferralUrlWithUtmParams = `${PETOLO_REFERRAL_URL}&${utmSource}&${utmCampaign}`;
  const [refUrl, setRefUrl] = useState(petoloReferralUrlWithUtmParams);

  const tooltipNodeRef = useRef(null);

  const [
    generatePetHealthReferralCode,
    { loading: referralCodeLoading, error: referralCodeGenerationError },
  ] = useMutation(JOIN_REFERRAL_PROGRAM, {
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  const shareData = {
    fb: {
      refUrl,
      elementIdWithReferralLink,
    },
    whatsapp: {
      intl,
      refUrl,
      elementIdWithReferralLink,
      waMessageId: 'referral.page.petolo.whatsapp.message',
    },
    email: {
      intl,
      refUrl,
      elementIdWithReferralLink,
      emailContentIds: {
        to: '',
        subjectId: 'referral.page.petolo.email.subject',
        bodyId: 'referral.page.petolo.email.message',
      },
    },
  };

  const socialItemsData = {
    fb: {
      icon: 'icon.user.referral.pet.fb',
    },
    whatsapp: {
      icon: 'icon.user.referral.pet.whatsapp',
    },
    email: {
      icon: 'icon.user.referral.pet.email',
    },
  };

  // Get social items to share the referral link
  const shareSocialItems = getReferralShareToSocialList(socialItemsData);

  // Generate referral code if customer does not have one
  useEffect(() => {
    const hasReferralCode = !!customer?.referralCode;

    if (!hasReferralCode) {
      generatePetHealthReferralCode();
    }
  }, [customer, generatePetHealthReferralCode]);

  // Add referral `id` to the referral url
  useEffect(() => {
    if (refId && refUrl.indexOf('&refid=') < 0) {
      setRefUrl(`${refUrl}&refid=${refId}`);
    }
  }, [refId, refUrl]);

  // Copy to clipboard
  const onLinkCopy = async () => {
    if (!window?.navigator?.clipboard || !refUrl) return;

    try {
      await window.navigator.clipboard.writeText(refUrl);

      if (!tooltipNodeRef?.current) return;

      ReactTooltip.show(tooltipNodeRef.current);

      setTimeout(() => {
        ReactTooltip.hide(tooltipNodeRef.current);
      }, 1200);
    } catch (err) {
      consoleError('Could not copy text: ', err);
    }
  };

  const renderBTag = (chunk) => <strong>{chunk}</strong>;

  const renderAnchorTagForMoreInfo = (chunk) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link variant="p" component="button" onClick={onReferralInfoLinkClick}>
      {chunk}
    </Link>
  );

  const renderAnchorTagForTerms = (chunk) => (
    <Link target="_blank" rel="noopener noreferrer" href={PETOLO_REFERRAL_TERMS_LINK}>
      {chunk}
    </Link>
  );

  if (referralCodeLoading || customerLoading) return <Loading showLogo />;

  if ((!customerLoading && !customer) || referralCodeGenerationError) return <ErrorPlaceholder />;

  if (!customerLoading && !!customer) {
    return (
      <CSSTransition in={ready} timeout={transitionTimeout} classNames="fade" unmountOnExit>
        <Paper>
          <StyledVerticalContent>
            <Typography variant="h2">
              <FormattedMessage id="referral.page.information.header" />
            </Typography>

            <StyledReferralStepsContainer>
              {referralSteps?.map((step) => {
                const { titleId, descriptionId, descriptionValues, additionalInfo } = step || {};

                return (
                  <div key={titleId}>
                    <StyledReferralStepTitle variant="h3">
                      <FormattedMessage id={titleId} />
                    </StyledReferralStepTitle>

                    <Typography variant="p">
                      <FormattedMessage id={descriptionId} values={descriptionValues} />
                    </Typography>

                    {additionalInfo && (
                      <StyledReferralAdditionalInformationContainer>
                        {additionalInfo?.map(({ infoId }) => (
                          <StyledReferralAdditionalInformation variant="p" key={infoId}>
                            <FormattedMessage id={infoId} />
                          </StyledReferralAdditionalInformation>
                        ))}
                      </StyledReferralAdditionalInformationContainer>
                    )}
                  </div>
                );
              })}
            </StyledReferralStepsContainer>

            <StyledReferralIdContainer>
              <Typography variant="p">
                <FormattedMessage
                  id="referral.page.petolo.more_info.text"
                  values={{
                    a: (chunk) => renderAnchorTagForMoreInfo(chunk),
                  }}
                />
              </Typography>
            </StyledReferralIdContainer>
          </StyledVerticalContent>

          <StyledReferralButtonContainer>
            <Button
              variant="primary"
              data-for="link-copied"
              data-tip
              data-event="none"
              ref={tooltipNodeRef}
              onClick={onLinkCopy}
            >
              <Typography variant="buttons">
                <FormattedMessage id="referral.page.copy.button" />
              </Typography>
            </Button>
          </StyledReferralButtonContainer>

          <ReactTooltip id="link-copied" className="u3-referral-tooltip" effect="solid">
            <Typography variant="p">
              <FormattedMessage id="referral.page.link.copied" />
            </Typography>
          </ReactTooltip>

          <StyledVerticalContent>
            <Typography variant="h3" textAlign="center">
              <FormattedMessage id="referral.page.share_in_socials" />
            </Typography>

            <StyledSocialGroup>
              {shareSocialItems?.map((socialItem) => (
                <StyledSocialImg
                  key={socialItem?.id}
                  src={socialItem?.image}
                  alt={socialItem?.id}
                  data-testid={socialItem.testId}
                  onClick={() => socialItem?.click(shareData[socialItem.id])}
                />
              ))}
            </StyledSocialGroup>

            <StyledReferralIdContainer>
              <Typography variant="p">
                <FormattedMessage
                  id="referral.page.referral_id.description"
                  values={{
                    product: 'petolo',
                    insurance: 'Tierversicherung',
                    b: (chunk) => renderBTag(chunk),
                  }}
                />
              </Typography>

              <Typography variant="p" textAlign="center">
                <FormattedMessage id="referral.page.referral_id.title" />
              </Typography>

              <StyledReferralIdBox>
                <Typography variant="p">{refId}</Typography>
              </StyledReferralIdBox>
            </StyledReferralIdContainer>

            <StyledSeparator />

            <Typography variant="p">
              <FormattedMessage id="referral.page.privacy.text" />
            </Typography>

            <Typography variant="p">
              <FormattedMessage
                id="referral.page.terms_and_conditions.text"
                values={{
                  a: (chunk) => renderAnchorTagForTerms(chunk),
                }}
              />
            </Typography>
          </StyledVerticalContent>
        </Paper>
      </CSSTransition>
    );
  }

  return null;
}

export default PetoloReferralInformation;
