import { styled, css, keyframes } from '@mui/material';

import { MIN_WIDTH_LG, MIN_WIDTH_MD } from '../../../theme/breakpoints';

const homeTabletSlideIn = keyframes`
  0% {
        transform: translateX(-20.5em);
        opacity: 0;
    }
    100% {
        transform: translateX(-5.5em);
        opacity: 1;
    }
`;

const homePhoneSlideIn = keyframes`
  0% {
        transform: translateX(19.3em);
        opacity: 0;
    }
    100% {
        transform: translateX(6.3em);
        opacity: 1;
    }
`;
const homeShieldScaleIn = keyframes`
    0% {
        transform: translateX(-17em) translateZ(0) scale(0);
        opacity: 1;
    }
    100% {
        transform: translateX(0em) translateZ(0) scale(1);
        opacity: 1;
    }
`;

const homeHandScaleIn = keyframes`
    0% {
        transform: translateX(17em) translateZ(0) scale(0);
        opacity: 1;
    }
    100% {
        transform: translateX(0em) translateZ(0) scale(1);
        opacity: 1;
    }
`;

const homeMapPinsDrop = keyframes`
   0% {
        transform: translateY(-20em);
        opacity: 1;
    }
    100% {
        transform: translateY(0em);
        opacity: 1;
    }
`;

const homeDentoloCardScale = keyframes`
 0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

const fadeIn = keyframes`
  0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const homeTabletSlideInMd = keyframes`
      0% {
            transform: translateX(-20.5rem);
            opacity: 0;
        }
        100% {
            transform: translateX(-7.7rem);
            opacity: 1;
        }
`;
const homePhoneSlideInMd = keyframes`
     0% {
        transform: translateX(19.3rem);
        opacity: 0;
    }
    100% {
        transform: translateX(7.35rem);
        opacity: 1;
    }
`;

export const StyledOnboardCardParent = styled('div')`
  width: 100%;
  min-height: 14rem;
  flex: 0 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1rem;
  overflow: hidden;

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.6rem;
    min-height: 19rem;
  }
`;

export const StyledOnboardCardWrapper = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledOnboardCard2Wrapper = styled(StyledOnboardCardWrapper)`
  flex-direction: row;
  justify-content: center;
`;
export const StyledOnboardCard3Wrapper = styled(StyledOnboardCardWrapper)`
  transform: translateY(-5px);
`;

export const StyledLaptopImage = styled('img')`
  width: 11.5em;
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 2em;
  bottom: 0;
  margin: auto;
  animation: 0.7s ${fadeIn} 0.5s linear forwards;
  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 13.1875rem;
  }
`;

export const StyledTabletImage = styled('img')`
  width: 5.1875em;
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: -2.7em;
  bottom: 0;
  margin: auto;
  transform: translateΧ(-5.5em);
  animation: 0.25s ${homeTabletSlideIn} 1s ease-out forwards;
  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 5.9806rem;
    top: -2.5rem;
    animation: 0.25s ${homeTabletSlideInMd} 1s ease-out forwards;
  }
`;
export const StyledPhoneImage = styled('img')`
  width: 3.3125em;
  opacity: 0;
  position: absolute;
  right: 0;
  left: 0;
  top: -3.5em;
  bottom: 0;
  margin: auto;
  transform: translateΧ(6.3em);
  animation: 0.25s ${homePhoneSlideIn} 1.1s ease-out forwards;
  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 3.8394rem;
    top: -3.6rem;
    animation: 0.25s ${homePhoneSlideInMd} 1.1s ease-out forwards;
  }
`;

export const StyledShieldImage = styled('img')`
  width: 6.875em;
  opacity: 0;
  transform: translateX(-17em) scale(0);
  animation: 0.3s ${homeShieldScaleIn} 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  margin-right: 2.5rem;
  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 8.5756rem;
    margin-right: 4.1875rem;
  }
`;
export const StyledHandImage = styled('img')`
  width: 6.6875em;
  opacity: 0;
  transform: translateX(17em) scale(0);
  animation: 0.3s ${homeHandScaleIn} 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 8.5625rem;
  }
`;

export const StyledPinImage = styled('img')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  opacity: 0;
  animation: 0.4s ${homeMapPinsDrop} forwards ease-in-out;
`;

export const StyledPin1Image = styled(StyledPinImage)`
  width: 1.915em;
  left: -7.875em;
  top: -2.24em;
  animation-delay: 0.8s;
`;
export const StyledPin2Image = styled(StyledPinImage)`
  width: 2.2713em;
  left: -0.9375em;
  top: -4.375em;
  animation-delay: 0.85s;
`;
export const StyledPin3Image = styled(StyledPinImage)`
  width: 1.8231em;
  right: -5.6875em;
  top: -0.625em;
  animation-delay: 0.9s;
`;
export const StyledPin4Image = styled(StyledPinImage)`
  width: 1.3094em;
  right: -6.875em;
  top: -8.75em;
  animation-delay: 0.95s;
`;

export const StyledMapImage = styled('img')`
  width: 10.9375em;
  position: relative;
  top: -1.5em;
  animation: 0.4s ${fadeIn} forwards ease-in-out;
`;

export const StyledBoardingArticle = styled('article')(
  ({ theme }) => css`
    width: 15.9375rem;
    max-height: 4.875rem;
    background-color: ${theme.palette.common.white};
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    position: absolute;
    top: 7.6875em;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    opacity: 0;
    padding: 8px;
    animation: 0.2s ${fadeIn} 1.5s linear forwards;
    box-shadow: ${theme.shadows[1]};
    border-radius: 5px;
    @media (min-width: ${MIN_WIDTH_MD}) {
      width: 27.625rem;
      top: 9.5rem;
      padding: 16px;
    }
    @media (min-width: ${MIN_WIDTH_LG}) {
      top: 7em;
    }
  `
);

export const StyledDentistImage = styled('img')`
  height: 3.75rem;
  max-width: 100%;
  margin-right: 8px;

  @media (min-width: ${MIN_WIDTH_MD}) {
    height: 2.5rem;
    margin-right: 16px;
  }
`;

export const StyledCardImage = styled('img')`
  width: 11.125em;
  transform-origin: left bottom;
  opacity: 0;
  animation: 0.28s ${homeDentoloCardScale} 0.9s ease-in-out forwards;

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 14.5625rem;
  }
`;

export const StyledStarsImagesContainer = styled('div')`
  width: 3.125em;
  height: 2.8125em;
  position: absolute;
  top: -6.25em;
  bottom: 0;
  right: -12.5em;
  left: 0;
  margin: auto;

  @media (min-width: ${MIN_WIDTH_MD}) {
    top: -9.4rem;
    right: -16rem;
  }
`;

export const StyledStarImage = styled('img')`
  width: 0.8125em;
  height: 0.8125em;
  position: absolute;
  opacity: 0;
  animation: 0.3s ${fadeIn} 1.3s ease-in-out forwards;
`;

export const StyledStarImage2 = styled(StyledStarImage)`
  top: 0.5em;
  left: 1.75em;
  animation-delay: 1.45s;
`;

export const StyledStarImage3 = styled(StyledStarImage)`
  width: 1.5625em;
  height: 1.5625em;
  top: 0.5em;
  left: 0.3125em;
  animation-delay: 1.6s;
`;

export const StyledOnBoardCard = styled('div')`
  display: flex;
  justify-content: flex-start !important;
  flex-direction: column !important;
  text-align: center;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
`;
