import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import {
  imgLinks,
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
  INSURANCE_CATEGORY_HEALTH,
} from '../../shared';

import { StyledSelectCustomerPaper, StyledUserItemLogo } from './SelectUser.styled';

const getInsuranceCategoryIcon = (insuranceCategory) => {
  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_DENTAL:
      return imgLinks['icon.menu.green.tooth.lg'];
    case INSURANCE_CATEGORY_PET_HEALTH:
      return imgLinks['icon.menu.orange.paw.lg'];
    case INSURANCE_CATEGORY_PET_LIABILITY:
      return imgLinks['icon.menu.orange.paw.lg'];
    case INSURANCE_CATEGORY_HEALTH:
      return imgLinks['icon.vitolo.heart.violet'];
    default:
      return imgLinks['icon.menu.green.tooth.lg'];
  }
};

const SelectUserItem = (props) => {
  const { name, policyName, uuid, disabled, setChildUuid, isContractEnded, insuranceCategory } =
    props;

  return (
    <StyledSelectCustomerPaper
      component="button"
      onClick={() => setChildUuid(uuid)}
      active={!isContractEnded ? 'true' : undefined}
      disabled={disabled}
      aria-label={`${name} - ${policyName}`}
    >
      <StyledUserItemLogo>
        <img src={getInsuranceCategoryIcon(insuranceCategory)} alt={insuranceCategory || ''} />
      </StyledUserItemLogo>

      <div>
        <Typography variant="h3">{name}</Typography>
        <Typography variant="p">{policyName}</Typography>
      </div>
    </StyledSelectCustomerPaper>
  );
};

SelectUserItem.propTypes = {
  name: PropTypes.string.isRequired,
  policyName: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  uuid: PropTypes.string.isRequired,
  /** Sets the uuid of the customer */
  setChildUuid: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SelectUserItem;
