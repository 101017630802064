import React, { useState, useRef, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { CSSTransition } from 'react-transition-group';
import { Button, Link, Paper, Typography } from '@mui/material';

import useConsole from '../../../../hooks/useConsole';
import { getReferralShareToSocialList } from '../referralPageHelper';
import { JOIN_REFERRAL_PROGRAM } from '../../../../graphql/mutations/referrals';
import {
  useReady,
  GET_CUSTOMER,
  DENTOLO_REFERRAL_URL,
  DENTOLO_REFERRAL_TERMS_LINK,
  GETOLO_UTM_PARAMS,
} from '../../../../shared';

import { Loading } from '../../../../components/ui';
import ErrorPlaceholder from '../../../errorPlaceholder';

import {
  StyledReferralAdditionalInformation,
  StyledReferralAdditionalInformationContainer,
  StyledReferralButtonContainer,
  StyledReferralIdBox,
  StyledReferralIdContainer,
  StyledReferralStepsContainer,
  StyledReferralStepTitle,
  StyledSeparator,
  StyledSocialGroup,
  StyledSocialImg,
  StyledVerticalContent,
} from '../ReferralPage.styled';

const referralSteps = [
  {
    descriptionTestId: 'refertab_textblock1_dentolo',
    titleId: 'referral.page.dentolo.step.one.title',
    descriptionId: 'referral.page.dentolo.step.one.description',
  },
  {
    descriptionTestId: 'refertab_textblock2_dentolo',
    titleId: 'referral.page.dentolo.step.two.title',
    descriptionId: 'referral.page.dentolo.step.two.description',
  },
  {
    descriptionTestId: 'refertab_textblock3_dentolo',
    titleId: 'referral.page.dentolo.step.three.title',
    descriptionId: 'referral.page.dentolo.step.three.description',
    descriptionValues: { giftbrand: 'Amazon Gutschein' },
    additionalInfo: [
      {
        testId: 'refertab_bullet1_dentolo',
        infoId: 'referral.page.dentolo.step.three.additional_info.one',
      },
      {
        testId: 'refertab_bullet2_dentolo',
        infoId: 'referral.page.dentolo.step.three.additional_info.two',
      },
      {
        testId: 'refertab_bullet3_dentolo',
        infoId: 'referral.page.dentolo.step.three.additional_info.three',
      },
    ],
  },
];

const elementIdWithReferralLink = 'element-with-dentolo-referral-link';
const utmSource = `utm_source=${GETOLO_UTM_PARAMS.source}`;
const utmCampaign = `utm_campaign=${GETOLO_UTM_PARAMS.campaignReferralDentolo}`;

const renderBTag = (chunk) => (
  <Typography variant="h3" component="b">
    {chunk}
  </Typography>
);

const renderReferralTermsLink = (chunk) => (
  <Link target="_blank" rel="noopener noreferrer" href={DENTOLO_REFERRAL_TERMS_LINK}>
    {chunk}
  </Link>
);

function DentoloReferralInformation() {
  const intl = useIntl();
  const ready = useReady();
  const { consoleError } = useConsole();

  const transitionTimeout = 500;

  // using `?` for the first param becuase the url is a page without the funnel so it does not have any params
  const dentoloReferralWithUtmParams = `${DENTOLO_REFERRAL_URL}?${utmSource}&${utmCampaign}`;
  const [refUrl, setRefUrl] = useState(dentoloReferralWithUtmParams);

  const tooltipNodeRef = useRef(null);

  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const customer = customerData?.customer;
  const refId = customer?.referralCode;

  const [
    generateDentalReferralCode,
    { loading: referralCodeLoading, error: referralCodeGenerationError },
  ] = useMutation(JOIN_REFERRAL_PROGRAM, {
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  const shareData = {
    fb: {
      refUrl,
      elementIdWithReferralLink,
    },
    whatsapp: {
      intl,
      refUrl,
      elementIdWithReferralLink,
      waMessageId: 'referral.page.dentolo.whatsapp.message',
    },
    email: {
      intl,
      refUrl,
      elementIdWithReferralLink,
      emailContentIds: {
        to: '',
        subjectId: 'referral.page.dentolo.email.subject',
        bodyId: 'referral.page.dentolo.email.message',
      },
    },
  };

  const socialItemsData = {
    fb: {
      testId: 'referral_facebook_button_dentolo',
      icon: 'icon.user.referral.fb',
    },
    whatsapp: {
      testId: 'referral_whatsapp_button_dentolo',
      icon: 'icon.user.referral.whatsapp',
    },
    email: {
      testId: 'referral_email_button_dentolo',
      icon: 'icon.user.referral.email',
    },
  };

  // Get social items to share the referral link
  const shareSocialItems = getReferralShareToSocialList(socialItemsData);

  // Generate referral code if customer does not have one
  useEffect(() => {
    const hasReferralCode = !!customer?.referralCode;

    if (!hasReferralCode) {
      generateDentalReferralCode();
    }
  }, [customer, customerLoading, generateDentalReferralCode]);

  // Add referral `id` to the referral url
  useEffect(() => {
    if (refId && refUrl.indexOf('&refid=') < 0) {
      setRefUrl(`${refUrl}&refid=${refId}`);
    }
  }, [refId, refUrl]);

  // Copy to clipboard
  const onLinkCopy = async () => {
    if (!window?.navigator?.clipboard) return;

    const { current: btnCopyLink } = tooltipNodeRef || {};
    const linkToCopy = btnCopyLink?.dataset?.referralLink;

    if (!btnCopyLink || !linkToCopy) return;

    try {
      await window.navigator.clipboard.writeText(linkToCopy);

      ReactTooltip.show(btnCopyLink);

      setTimeout(() => ReactTooltip.hide(btnCopyLink), 1200);
    } catch (err) {
      consoleError('Could not copy text: ', err);
    }
  };

  if (referralCodeLoading) return <Loading showLogo />;

  if ((!customerLoading && !customer) || referralCodeGenerationError) return <ErrorPlaceholder />;

  if (!customerLoading && !!customer) {
    return (
      <CSSTransition in={ready} timeout={transitionTimeout} classNames="fade" unmountOnExit>
        <Paper>
          <StyledVerticalContent>
            <Typography variant="h2">
              <FormattedMessage id="referral.page.information.header" />
            </Typography>

            <StyledReferralStepsContainer>
              {referralSteps?.map((step) => {
                const {
                  titleId,
                  descriptionId,
                  descriptionValues,
                  additionalInfo,
                  descriptionTestId,
                } = step || {};

                return (
                  <div key={titleId}>
                    <StyledReferralStepTitle variant="h3">
                      <FormattedMessage id={titleId} />
                    </StyledReferralStepTitle>

                    <Typography variant="p" data-testid={descriptionTestId || ''}>
                      <FormattedMessage id={descriptionId} values={descriptionValues} />
                    </Typography>

                    {additionalInfo && (
                      <StyledReferralAdditionalInformationContainer>
                        {additionalInfo?.map(({ infoId, testId }) => (
                          <StyledReferralAdditionalInformation
                            variant="p"
                            key={infoId}
                            data-testid={testId || ''}
                          >
                            <FormattedMessage id={infoId} />
                          </StyledReferralAdditionalInformation>
                        ))}
                      </StyledReferralAdditionalInformationContainer>
                    )}
                  </div>
                );
              })}
            </StyledReferralStepsContainer>
          </StyledVerticalContent>

          <StyledReferralButtonContainer>
            <Button
              variant="primary"
              id={elementIdWithReferralLink}
              data-for="link-copied"
              data-tip
              data-event="none"
              ref={tooltipNodeRef}
              data-referral-link={refUrl}
              data-testid="referral_button_copylink_dentolo"
              onClick={onLinkCopy}
            >
              <Typography variant="buttons">
                <FormattedMessage id="referral.page.copy.button" />
              </Typography>
            </Button>
          </StyledReferralButtonContainer>

          <ReactTooltip id="link-copied" className="u3-referral-tooltip" effect="solid">
            <Typography variant="p">
              <FormattedMessage id="referral.page.link.copied" />
            </Typography>
          </ReactTooltip>

          <StyledVerticalContent>
            <Typography variant="h3" textAlign="center">
              <FormattedMessage id="referral.page.share_in_socials" />
            </Typography>

            <StyledSocialGroup>
              {shareSocialItems.map((socialItem) => (
                <StyledSocialImg
                  key={socialItem.id}
                  src={socialItem.image}
                  alt={socialItem.id}
                  data-testid={socialItem.testId}
                  onClick={() => socialItem.click(shareData[socialItem.id])}
                />
              ))}
            </StyledSocialGroup>

            <StyledReferralIdContainer>
              <Typography variant="p">
                <FormattedMessage
                  id="referral.page.referral_id.description"
                  values={{
                    product: 'dentolo',
                    insurance: 'Zahnzusatzversicherung',
                    b: (chunk) => renderBTag(chunk),
                  }}
                />
              </Typography>

              <Typography variant="p" textAlign="center">
                <FormattedMessage id="referral.page.referral_id.title" />
              </Typography>

              <StyledReferralIdBox>
                <Typography variant="p">{refId}</Typography>
              </StyledReferralIdBox>
            </StyledReferralIdContainer>

            <StyledSeparator />

            <Typography variant="p">
              <FormattedMessage id="referral.page.privacy.text" />
            </Typography>

            <Typography variant="p">
              <FormattedMessage
                id="referral.page.terms_and_conditions.text"
                values={{
                  a: (chunk) => renderReferralTermsLink(chunk),
                }}
              />
            </Typography>
          </StyledVerticalContent>
        </Paper>
      </CSSTransition>
    );
  }

  return null;
}

export default DentoloReferralInformation;
