import React from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Link, Paper, Typography } from '@mui/material';

import { showMoney } from '../../shared';

import {
  StyledPaymentStatusBlock,
  StyledPaymentStatusBlockIcon,
  StyledPaymentStatusBlockTag,
  StyledVerticalInternalContent,
} from './PaymentDetails.styled';

dayjs.extend(localizedFormat);

const imageStatus = (status) => {
  switch (status) {
    case 'failed':
    case 'blocked':
    case 'cancelled':
      return 'icon.failed';
    case 'paid':
      return 'icon.success';
    case 'exportable':
    case 'upcoming':
    case 'exported':
      return 'confirm.time';
    case 'refunded':
    case 'refundable':
      return 'flying.money';
    default:
      return 'icon.failed';
  }
};

function PaymentStatusBlock(props) {
  const { data } = props;

  const { locale } = useIntl();

  const {
    status,
    billingMonth,
    amountCents,
    policyAmountCents,
    addonAmountCents,
    collectAiClaimLink,
    executeAt,
  } = data || {};

  return (
    <Paper>
      <StyledPaymentStatusBlock>
        <StyledVerticalInternalContent>
          <Typography variant="h3">
            {dayjs(billingMonth).locale(locale).format('MMMM YYYY')}
          </Typography>

          <Typography variant="p">
            <FormattedMessage id="payment.status.total_premium" />
            :&nbsp;
            {showMoney(amountCents / 100)}
          </Typography>

          {Number.isInteger(addonAmountCents) && addonAmountCents > 0 && (
            <Typography variant="p">
              {Number.isInteger(policyAmountCents) && (
                <>
                  {`${showMoney(policyAmountCents / 100)} `}
                  <FormattedMessage id="payment.status.policy_premium" />
                </>
              )}
              &nbsp;
              {`+ ${showMoney(addonAmountCents / 100)} `}
              <FormattedMessage id="policies.addons.im_coverage" />
            </Typography>
          )}

          {status === 'exported' && executeAt && (
            <StyledPaymentStatusBlockTag variant="info">
              <Typography variant="p">
                <FormattedMessage
                  id="payment.exported.message"
                  values={{ date: dayjs(executeAt).locale(locale).format('L') }}
                />
              </Typography>
            </StyledPaymentStatusBlockTag>
          )}

          {(status === 'exportable' || status === 'upcoming') && (
            <StyledPaymentStatusBlockTag variant="info">
              <Typography variant="p">
                <FormattedMessage id="payment.exportable.message" />
              </Typography>
            </StyledPaymentStatusBlockTag>
          )}

          {status === 'cancelled' && (
            <StyledPaymentStatusBlockTag variant="transparent">
              <Typography variant="p">
                <FormattedMessage id="payment.cancelled.message" />
              </Typography>
            </StyledPaymentStatusBlockTag>
          )}

          {(status === 'refunded' || status === 'refundable') && (
            <StyledPaymentStatusBlockTag variant="info">
              <Typography variant="p">
                {status === 'refunded' ? (
                  <FormattedMessage id="payment.exportable.refunded" />
                ) : (
                  <FormattedMessage id="payment.exportable.refundable" />
                )}
              </Typography>
            </StyledPaymentStatusBlockTag>
          )}

          {(status === 'failed' || status === 'blocked') && (
            <>
              <StyledPaymentStatusBlockTag variant="error">
                <Typography variant="p">
                  <FormattedMessage id="payment.blocked.message" />
                </Typography>
              </StyledPaymentStatusBlockTag>

              {status === 'failed' && (
                <Link variant="p" href={collectAiClaimLink}>
                  <FormattedMessage id="payment.failed.re.payment.link" />
                </Link>
              )}
            </>
          )}

          {status === 'paid' && (
            <StyledPaymentStatusBlockTag variant="success">
              <Typography variant="p">
                <FormattedMessage id="payment.paid.message" />
              </Typography>
            </StyledPaymentStatusBlockTag>
          )}
        </StyledVerticalInternalContent>

        <StyledPaymentStatusBlockIcon icon={imageStatus(status)} alt="payment status" />
      </StyledPaymentStatusBlock>
    </Paper>
  );
}

PaymentStatusBlock.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string,
    billingMonth: PropTypes.string,
    amountCents: PropTypes.number,
    policyAmountCents: PropTypes.number,
    addonAmountCents: PropTypes.number,
    collectAiClaimLink: PropTypes.string,
    executeAt: PropTypes.string,
  }).isRequired,
};

export default PaymentStatusBlock;
