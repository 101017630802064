// eslint-disable-next-line import/prefer-default-export
export const muiTypographyComponent = {
  defaultProps: {
    variantMapping: {
      p: 'p',
      buttons: 'div',
    },
  },
  styleOverrides: {
    root: () => ({
      fontFamily: '"Circular TT", "Arial", sans-serif',
      wordBreak: 'break-word',
    }),
  },
  variants: [
    {
      props: { variant: 'h1' },
      style: ({ theme }) => ({
        fontSize: '1.5rem',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',

        [`${theme.breakpoints.up('desktop')}`]: {
          fontSize: '1.875rem',
        },
      }),
    },
    {
      props: { variant: 'h2' },
      style: ({ theme }) => ({
        fontSize: '1.25rem',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',

        [`${theme.breakpoints.up('desktop')}`]: {
          fontSize: '1.5rem',
        },
      }),
    },
    {
      props: { variant: 'h3' },
      style: ({ theme }) => ({
        fontSize: '1.125rem',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',

        [`${theme.breakpoints.up('desktop')}`]: {
          fontSize: '1.25rem',
        },
      }),
    },

    {
      props: { variant: 'p' },
      style: ({ theme }) => ({
        fontSize: '1.125rem',
        fontStyle: 'normal',
        fontWeight: 450,
        lineHeight: '1.5rem',

        [`${theme.breakpoints.up('desktop')}`]: {
          fontSize: '1.25rem',
          lineHeight: '1.625rem',
        },
      }),
    },
    {
      props: { variant: 'legalTexts' },
      style: ({ theme }) => ({
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: 450,
        lineHeight: '1.5rem',

        [`${theme.breakpoints.up('desktop')}`]: {
          fontSize: '1.125rem',
          lineHeight: '1.375rem',
        },
      }),
    },
    {
      props: { variant: 'buttons' },
      style: ({ theme }) => ({
        fontSize: '1.25rem',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '1.5rem',

        [`${theme.breakpoints.up('desktop')}`]: {
          fontSize: '1.5rem',
          lineHeight: '2rem',
        },
      }),
    },
    {
      props: { color: 'professional_blue' },
      style: ({ theme }) => ({
        color: theme.palette.main.professional_blue,
      }),
    },
    {
      props: { color: 'product_blue' },
      style: ({ theme }) => ({
        color: theme.palette.main.product_blue,
      }),
    },
    {
      props: { color: 'error' },
      style: ({ theme }) => ({
        color: theme.palette.error.main,
      }),
    },
    {
      props: { color: 'white' },
      style: ({ theme }) => ({
        color: theme.palette.common.white,
      }),
    },
    {
      props: { color: 'subtext' },
      style: ({ theme }) => ({
        color: theme.palette.text.subtext,
      }),
    },
    {
      props: { color: 'gray' },
      style: ({ theme }) => ({
        color: theme.palette.gray.gray,
      }),
    },
  ],
};
