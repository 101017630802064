import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Typography } from '@mui/material';

import { DENTOLO_HELPCENTER_TAX_RETURN_LINK, imgLinks } from '../../shared';

import {
  StyledDentoloPaymentOverviewHelpContentPaper,
  StyledDentoloPaymentOverviewImage,
} from './PaymentOverview.styled';

export default function DentoloPaymentOverviewHelpContent() {
  return (
    <>
      <StyledDentoloPaymentOverviewHelpContentPaper>
        <Typography variant="h3" color="white">
          <FormattedMessage id="payment.overview.about.taxes" />
        </Typography>

        <Link
          variant="p"
          color="white"
          href={DENTOLO_HELPCENTER_TAX_RETURN_LINK}
          target="_blank"
          textAlign="right"
        >
          <Typography variant="p">
            <FormattedMessage id="payment.overview.helpcenter" />
          </Typography>
        </Link>
      </StyledDentoloPaymentOverviewHelpContentPaper>

      <StyledDentoloPaymentOverviewImage
        src={imgLinks['icon.payment.overview.background']}
        alt="payment overview background"
      />
    </>
  );
}
