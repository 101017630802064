import { css, Paper, styled } from '@mui/material';

export const StyledDentoloReferralStatusEmptyContainer = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing('1XL')};
  `
);

export const StyledDentoloReferralStatusEmptyImagePaper = styled(Paper)(
  ({ theme }) => css`
    margin: ${theme.spacing('1XL')} auto;
    padding: 0;
    width: 16.25rem;
    height: 6.25rem;
    overflow: hidden;
  `
);

export const StyledDentoloReferralStatusEmptyImg = styled('img')`
  max-width: 100%;
  width: 100%;
  height: auto;
`;
