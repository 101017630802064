import React from 'react';
import { Typography } from '@mui/material';

import CheckIcon from './CheckIcon';

import {
  StyledBoxAlignCenter,
  StyledBoxSpaceBetween,
  StyledSeparator,
  StyledVerticalContent,
} from '../PolicyInfo.styled';

const AdditionalAdvantagesPetolo = ({ details }) => {
  if (!Array.isArray(details)) return null;

  return (
    <>
      {details.map((item, index) => {
        const { title, subtitle, advantages } = item || {};

        return (
          <StyledVerticalContent key={title || subtitle}>
            <Typography variant="h3">{title}</Typography>

            {advantages?.map((advantage) => (
              <StyledBoxAlignCenter key={advantage?.text}>
                <CheckIcon />

                <Typography variant="p" color="subtext">
                  {advantage?.text}
                </Typography>
              </StyledBoxAlignCenter>
            ))}

            {subtitle && (
              <StyledBoxSpaceBetween>
                <div />
                <Typography variant="h3">{subtitle}</Typography>
              </StyledBoxSpaceBetween>
            )}

            {index !== details.length - 1 && <StyledSeparator />}
          </StyledVerticalContent>
        );
      })}
    </>
  );
};

export default AdditionalAdvantagesPetolo;
