import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Button, Container, Typography } from '@mui/material';

import { imgLinks, useReady } from '../../../shared';

import { PageTitle } from '../../../components/common';
import FressnapfDesktopSlides from './FressnapfDesktopSlides';
import FressnapfMobileSlides from './FressnapfMobileSlides';
import { StyledOnlineVetCoverImage, StyledOnlineVetFlexContainer } from '../OnlineVets.styled';
import { StyledDesktopButtonContainer, StyledMobileButtonContainer } from './Fressnapf.styled';

const Fressnapf = () => {
  const ready = useReady();
  const fressnapfAccount = 'https://dr.fressnapf.de/tierkrankenversicherung-petolo/';

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <PageTitle>
          <Typography variant="h1">
            <FormattedMessage id="fressnapf.header" />
          </Typography>
        </PageTitle>

        <StyledOnlineVetFlexContainer>
          <StyledOnlineVetCoverImage src={imgLinks['fressnapf.banner']} alt="Dr. Fressnapf" />

          <StyledMobileButtonContainer>
            <Button onClick={() => window.open(fressnapfAccount, '_blank')}>
              <Typography variant="buttons">
                <FormattedMessage id="fressnapf.button.text" />
              </Typography>
            </Button>
          </StyledMobileButtonContainer>
        </StyledOnlineVetFlexContainer>

        <FressnapfDesktopSlides />

        <StyledDesktopButtonContainer>
          <Button onClick={() => window.open(fressnapfAccount, '_blank')}>
            <Typography variant="buttons">
              <FormattedMessage id="fressnapf.button.text" />
            </Typography>
          </Button>
        </StyledDesktopButtonContainer>

        <FressnapfMobileSlides />
      </Container>
    </CSSTransition>
  );
};

export default Fressnapf;
