import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { CCDOCS_LS_SHOW_KEY, CLEAR_CERTIF_DOC_NOTIFICATION, GET_CCDOCUMENTS } from '../../shared';

export default function useShowContributionConfirmationDocumentNotification() {
  const [showCCDocumentNotification, setShowContributionNotif] = useState(false);

  const { data: ccdocsData, loading: ccdocsLoading } = useQuery(GET_CCDOCUMENTS);
  const [ccdocs, setCcdocs] = useState([]);
  const [clearCertifDocNotif] = useMutation(CLEAR_CERTIF_DOC_NOTIFICATION, {
    refetchQueries: [{ query: GET_CCDOCUMENTS }],
  });

  const setCCDocumentSeen = async () => {
    if (ccdocs?.length) {
      await clearCertifDocNotif();
    }
  };

  const dismissCCDocumentNotification = async () => {
    await setCCDocumentSeen();
    window.sessionStorage.setItem(CCDOCS_LS_SHOW_KEY, false);
  };

  useEffect(() => {
    if (!ccdocsLoading && ccdocsData) {
      const docs = ccdocsData?.contributionConfirmationsDocuments || [];
      setCcdocs(docs);
      const lastCcdocs = Array.isArray(docs) && docs.length > 0 ? docs[docs.length - 1] : null;
      if (docs?.length && lastCcdocs) {
        const showNotif = !lastCcdocs?.seenByCustomer;
        if (showNotif) {
          window.sessionStorage.setItem(CCDOCS_LS_SHOW_KEY, true);
          setShowContributionNotif(showNotif);
        } else {
          setShowContributionNotif(
            showNotif || JSON.parse(window.sessionStorage.getItem(CCDOCS_LS_SHOW_KEY))
          );
        }
      }
    }
  }, [ccdocsData, ccdocsLoading]);

  return { showCCDocumentNotification, setCCDocumentSeen, dismissCCDocumentNotification };
}
