import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Container, Link, Typography } from '@mui/material';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import useConsole from '../../hooks/useConsole';
import {
  CONTACT_US,
  useReady,
  GET_CUSTOMER,
  SUBJECTS_ENUMS,
  INSURANCE_TYPE_PETOLO,
} from '../../shared';

import { showSelfCancellationMap } from '../../utils/mappings';
import { DEFAULT_PHONE_ICON, getForwardToDaDirektValue, phoneIconMap } from './contactUtils';
import ContactForm from './ContactForm';
import ErrorPlaceholder from '../errorPlaceholder';
import { Accordion, PageTitle } from '../../components/common';
import { StyledContactUsByPhoneContent, StyledVerticalContent } from './Contact.styled';
import BacklogBanner from './BacklogBanner';

const renderAnchorTagForCancellationPage = (chunk) => (
  <Link href="/contract-cancellation">{chunk}</Link>
);

function Contact() {
  const history = useHistory();
  const intl = useIntl();
  const { consoleError } = useConsole();

  const { type: insuranceType, partner: insurancePartner, isPatolo } = useInsuranceInfo();
  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const { customer } = customerData || {};

  const ready = useReady();

  const [formLoading, setFormLoading] = useState(false);
  const [body, setBody] = useState('');
  const [subject, setSubject] = useState('');

  const isDaDirektCustomer = insurancePartner === 'da-direkt';

  const forwardToDaDirekt = isDaDirektCustomer ? getForwardToDaDirektValue(subject) : null;

  const isPetoloInsurance = insuranceType === INSURANCE_TYPE_PETOLO;
  const [contactUs] = useMutation(CONTACT_US, {
    variables: {
      subject: subject
        ? intl.formatMessage({
            id: `contact.page.menu.${subject}`,
          })
        : '',
      body,
      forwardToDaDirekt,
    },
  });

  const isSubjectDaDirektReferral =
    subject === SUBJECTS_ENUMS.SUBJECT_OPTION_REFERRAL && isDaDirektCustomer;

  const submitForm = async () => {
    setFormLoading(true);

    try {
      const resp = await contactUs();

      if (resp.data.contactUs && Object.keys(resp.data.contactUs).length) {
        const { errors } = resp?.data?.contactUs || {};

        let status = 'failure';
        if (!errors.length) {
          status = 'success';
        }

        history.push(`/contact/${status}`, {
          selectedSubject: subject,
          isDaDirektCustomer,
        });
        window.scrollTo(0, 0);
      }
    } catch (err) {
      consoleError(err);
    }

    setFormLoading(false);
  };

  const contractNotCancelled = !customer?.contract?.cancelationDate;
  const showContractCancelLink = showSelfCancellationMap[`${insuranceType}`];

  const customerFullName = `${customer?.firstName} ${customer?.lastName}`;

  const contactPhoneNumber = intl.formatMessage({ id: `customer.service.${insuranceType}` });
  const phoneIcon = phoneIconMap[`${insuranceType}`] || DEFAULT_PHONE_ICON;

  if (!customerLoading && !!customer && !!Object.keys(customer).length) {
    return (
      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Container>
          {isPetoloInsurance && !isPatolo && <BacklogBanner />}
          <PageTitle>
            <Typography variant="h1">
              <FormattedMessage id="contact.page.main.title" />
            </Typography>
          </PageTitle>

          <StyledVerticalContent>
            <Typography variant="p">
              <FormattedMessage id="contact.page.main.description" />
            </Typography>

            {showContractCancelLink && contractNotCancelled && (
              <Typography variant="p">
                <FormattedMessage
                  id="contact.page.main.cancellation.text"
                  values={{
                    a: (chunk) => renderAnchorTagForCancellationPage(chunk),
                  }}
                />
              </Typography>
            )}

            <ContactForm
              maxLength="10000"
              fullName={customerFullName}
              email={customer?.email}
              onFormSubmit={submitForm}
              formLoading={formLoading}
              body={body}
              setBody={setBody}
              subject={subject}
              setSubject={setSubject}
              isSubjectDaDirektReferral={isSubjectDaDirektReferral}
            />

            <Accordion
              title={
                <Typography variant="p">
                  {intl.formatMessage({ id: 'contact.page.main.phone' })}
                </Typography>
              }
              titleIcon={phoneIcon}
              expandText="Expand"
              collapseText="Collapse"
            >
              <StyledContactUsByPhoneContent>
                <Typography variant="p">
                  <FormattedMessage id="contact.page.main.open_hours" />
                </Typography>

                <Typography variant="p">{contactPhoneNumber}</Typography>
              </StyledContactUsByPhoneContent>
            </Accordion>
          </StyledVerticalContent>
        </Container>
      </CSSTransition>
    );
  }

  if (!customerLoading) {
    return (
      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <ErrorPlaceholder />
      </CSSTransition>
    );
  }

  return null;
}

export default Contact;
