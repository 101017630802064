import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Button, Typography } from '@mui/material';

import {
  GET_CUSTOMER,
  PAYMENT_FAILURE_STATUS,
  PAYMENT_SUCCESS_STATUS,
  SET_BILLING_DAY,
  useReady,
} from '../shared';

import { Container, Paper, Icon } from './common';
import {
  StyledNotificationTitle,
  StyledPaymentStatusContainer,
  StyledPStatusIconContainer,
} from './styled/PaymentStatus.styled';

const StatusBody = ({ status }) => {
  if (status === PAYMENT_SUCCESS_STATUS) {
    return (
      <>
        <StyledPStatusIconContainer>
          <Icon icon="notification.success" alt="success" />
        </StyledPStatusIconContainer>

        <StyledNotificationTitle>
          <Typography variant="h3">
            <FormattedMessage id="customer.details.digital.payment.success.title" />
          </Typography>
        </StyledNotificationTitle>
      </>
    );
  }

  if (status === PAYMENT_FAILURE_STATUS) {
    return (
      <>
        <StyledPStatusIconContainer>
          <Icon icon="notification.warning" alt="success" />
        </StyledPStatusIconContainer>

        <StyledNotificationTitle>
          <Typography variant="h3">
            <FormattedMessage id="customer.details.digital.payment.failure.title" />
          </Typography>
        </StyledNotificationTitle>

        <StyledNotificationTitle>
          <Typography variant="p">
            <FormattedMessage id="customer.details.digital.payment.failure.desc" />
          </Typography>
        </StyledNotificationTitle>
      </>
    );
  }

  return null;
};

const PaymentStatus = () => {
  const history = useHistory();
  const ready = useReady();
  const { status } = useParams();
  const { data: customerData } = useQuery(GET_CUSTOMER);
  const { customer } = customerData || {};
  const { key } = customer || {};
  const billingDayFromStorage = localStorage.getItem(`edit-payment-billing-day-${key}`);
  const [changeContractBillingDay] = useMutation(SET_BILLING_DAY, {
    variables: {
      billingDay: Number(billingDayFromStorage),
    },
  });
  const updateBillingDay = () => {
    changeContractBillingDay().then(() => {
      localStorage.removeItem(`edit-payment-billing-day-${key}`);
    });
  };

  if (status === PAYMENT_SUCCESS_STATUS && Number(billingDayFromStorage)) {
    // send the billing day from novalnet form page to the backend to set after the webhook received if the payment succeded
    updateBillingDay();
  }

  const handleStatusButton = () => {
    history.push(`/user-info`);
  };
  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <Paper>
          <StyledPaymentStatusContainer>
            <StatusBody status={status} />

            <Button variant="outlined" onClick={handleStatusButton}>
              <Typography variant="buttons">
                <FormattedMessage id="customer.details.digital.payment.success.btn" />
              </Typography>
            </Button>
          </StyledPaymentStatusContainer>
        </Paper>
      </Container>
    </CSSTransition>
  );
};

export default PaymentStatus;
