import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';

import { GET_CUSTOMER } from '../../shared';
import { Loading } from '../../components/ui';

import DentistsMapOnboard from './DentistsMapOnboard';
import DentistsMapUI from './DentistsMapUI';
import {
  DentistDetailsModal,
  PartnerDentistDetailsMobile,
  OtherDentistDetailsMobile,
} from './DentistsDetails';
import DentistsMapTitle from './DentistsMapTitle';

import {
  StyledDentistsMap,
  StyledDentistsMapOverlay,
  StyledDentistsMapTitleContainer,
  StyledDentistsMapWrapper,
} from './DentistsMap.styled';

const mapOptions = {
  center: { lat: 51.165691, lng: 10.451526000000058 }, // Germany
  zoom: 5,
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  clickableIcons: false,
};

const DentistsMap = () => {
  const intl = useIntl();

  const [dentist, setDentist] = useState(null);
  const [onboard, setOnboard] = useState(false);
  const [menuHeight, setMenuHeight] = useState(null);
  const [mapHeight, setMapHeight] = useState(null);
  const [ready, setReady] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);

  const handleResize = () => {
    const headerMenu = document.querySelector('[data-header-menu]');
    const navigationMenu = document.querySelector('[data-nav-menu-desktop]');

    if (headerMenu && navigationMenu) {
      const newMenuHeight = headerMenu.offsetHeight + navigationMenu.offsetHeight;

      setMapHeight(window.innerHeight - newMenuHeight);
      setMenuHeight(newMenuHeight - 50);
    }
  };

  useEffect(() => {
    if (mapHeight === null || menuHeight === null) handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuHeight, mapHeight]);

  useEffect(() => {
    const elBody = document.body;
    elBody.classList.add('no-scroll');

    const localDentistsMapOnboard = localStorage.getItem('dentists-map-onboard');
    if (localDentistsMapOnboard !== 'skip') {
      setOnboard(true);
    }

    return () => {
      elBody.classList.remove('no-scroll');
    };
  }, []);

  const saveOnboardClose = () => {
    localStorage.setItem('dentists-map-onboard', 'skip');
    setOnboard(false);
  };

  if (!customerLoading && !!customerData?.customer) {
    return (
      <>
        {!ready && (
          <StyledDentistsMapOverlay>
            <Loading text={intl.formatMessage({ id: 'dentist.map.loading' })} showLogo />
          </StyledDentistsMapOverlay>
        )}

        <StyledDentistsMapWrapper style={{ height: mapHeight }}>
          <StyledDentistsMapTitleContainer>
            <DentistsMapTitle
              menuHeight={menuHeight}
              dentist={dentist}
              setOnboard={setOnboard}
              setDentist={setDentist}
            />
          </StyledDentistsMapTitleContainer>

          <DentistDetailsModal
            dentist={dentist}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
          />

          {!dentist?.dentoloPartner && (
            <OtherDentistDetailsMobile
              customer={customerData?.customer}
              dentist={dentist}
              setDentist={setDentist}
            />
          )}

          {dentist?.dentoloPartner && (
            <PartnerDentistDetailsMobile
              dentist={dentist}
              setDentist={setDentist}
              setShowDetails={setShowDetails}
            />
          )}

          <StyledDentistsMap style={{ height: mapHeight }}>
            <DentistsMapOnboard onboard={onboard} saveOnboardClose={saveOnboardClose} />

            <DentistsMapUI
              id="dentists-map"
              options={mapOptions}
              setDentist={setDentist}
              setReady={setReady}
              customer={customerData?.customer}
            />
          </StyledDentistsMap>
        </StyledDentistsMapWrapper>
      </>
    );
  }

  return null;
};

export default DentistsMap;
