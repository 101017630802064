import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link, Typography, Dialog, DialogContent } from '@mui/material';

import { DENTOLO_DENTOLO_DENTISTS_HELPCENTER_LINK } from '../../../shared';
import useLocalStorage from '../../../hooks/useLocalStorage';
import DialogHeader from '../../../components/DialogHeader';

import { StyledDentistMapOnboardContent } from './DentistsMapOnboard.styled';

const renderAnchorTag = (chunk, link) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      variant="p"
      component="button"
      onClick={(e) => {
        e.preventDefault();
        window.open(link, '_blank');
      }}
    >
      {chunk}
    </Link>
  );
};

export default function DentistsMapOnboard({ onboard, saveOnboardClose }) {
  const [getFromLocalStorage] = useLocalStorage();

  const gduidFromLocalStorage = getFromLocalStorage('gduid');
  const linkToUse = gduidFromLocalStorage
    ? `${DENTOLO_DENTOLO_DENTISTS_HELPCENTER_LINK}?gduid=${gduidFromLocalStorage}`
    : DENTOLO_DENTOLO_DENTISTS_HELPCENTER_LINK;

  return (
    <Dialog
      open={onboard}
      onClose={saveOnboardClose}
      aria-labelledby="dentist_map_onboard_title"
      aria-describedby="dentist_map_onboard_content"
    >
      <DialogHeader handleClose={saveOnboardClose}>
        <Typography variant="h2" color="professional_blue" id="dentist_map_onboard_title">
          <FormattedMessage id="dentist.map.modal.title" />
        </Typography>
      </DialogHeader>

      <DialogContent>
        <StyledDentistMapOnboardContent id="dentist_map_onboard_content">
          <Typography variant="p">
            <FormattedMessage id="dentist.map.modal.content_1" />
          </Typography>

          <Typography variant="p">
            <FormattedMessage
              id="dentist.map.modal.content_2"
              values={{
                button: (chunk) => renderAnchorTag(chunk, linkToUse),
              }}
            />
          </Typography>
        </StyledDentistMapOnboardContent>
      </DialogContent>
    </Dialog>
  );
}

DentistsMapOnboard.propTypes = {
  onboard: PropTypes.bool,
  saveOnboardClose: PropTypes.func,
};
