import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import { StyledBacklogBannerContainer, StyledBannerIcon } from './Contact.styled';
import { imgLinks } from '../../shared';

function BacklogBanner() {
  return (
    <StyledBacklogBannerContainer>
      <StyledBannerIcon src={imgLinks['exclamation.mark.icon']} />
      <Typography variant="p" color="white">
        <FormattedMessage id="contact.page.backlog.banner" />
      </Typography>
    </StyledBacklogBannerContainer>
  );
}

export default BacklogBanner;
