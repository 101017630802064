import React, { Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { Link, Typography } from '@mui/material';

import { showMoney } from '../../../shared';

import CheckIcon from './CheckIcon';

import {
  StyledBoxAlignStart,
  StyledBoxSpaceBetween,
  StyledVerticalContent,
} from '../PolicyInfo.styled';

const markdownComponents = {
  p: Fragment,
  a(props) {
    const { children, href } = props;
    return (
      <Link variant="p" href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </Link>
    );
  },
};

const getBudgetLabel = (year, firstYear, isFirst, isLast, intl) => {
  const prefix = intl.formatMessage({ id: 'policy.all_details.budget.prefix' });
  const suffix = intl.formatMessage({ id: 'policy.all_details.budget.suffix' });

  if (isFirst && isLast) {
    const onlyPrefix = intl.formatMessage({ id: 'policy.all_details.budget.only_prefix' });
    return `${onlyPrefix} ${year}. ${suffix}`;
  }

  if (isFirst) {
    return `${prefix} ${year}. ${suffix}`;
  }

  if (isLast) {
    const lastPrefix = intl.formatMessage({ id: 'policy.all_details.budget.last_prefix' });
    return `${lastPrefix} ${year}. ${suffix}`;
  }

  return `${prefix} ${firstYear}.-${year}. ${suffix}`;
};

const Budget = ({ budgetInfo, budgetAmountInfo, hasAddon }) => {
  const intl = useIntl();

  if (!budgetInfo) return null;

  const { title, text, text_addon: textAddon } = budgetInfo || {};

  return (
    <StyledBoxAlignStart>
      <CheckIcon />

      <StyledVerticalContent>
        <Typography variant="h3">{title || ''}</Typography>

        {Array.isArray(budgetAmountInfo) &&
          budgetAmountInfo.map(({ year, amountCents }, index) => {
            const { year: firstYear } = budgetAmountInfo[0];
            const isFirst = index === 0;
            const isLast = index === budgetAmountInfo.length - 1;
            const amountCentsInt = parseInt(amountCents, 10);
            const unlimited = Number.isNaN(amountCentsInt);

            return (
              <StyledBoxSpaceBetween key={year}>
                <Typography variant="p" color="subtext">
                  {getBudgetLabel(year, firstYear, isFirst, isLast, intl)}
                </Typography>

                <Typography variant="h3">
                  {unlimited ? (
                    <FormattedMessage id="policy.all_details.budget.unlimited" />
                  ) : (
                    showMoney(amountCentsInt / 100)
                  )}
                </Typography>
              </StyledBoxSpaceBetween>
            );
          })}

        <Typography variant="p" color="subtext">
          <ReactMarkdown components={markdownComponents}>
            {!hasAddon ? text || '' : textAddon || ''}
          </ReactMarkdown>{' '}
        </Typography>
      </StyledVerticalContent>
    </StyledBoxAlignStart>
  );
};

export default Budget;
