import { styled, css } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

const buttonContainerAlign = (align) =>
  ({
    left: css`
      > button {
        margin-left: 0;
      }
    `,
    center: css`
      > button {
        margin-left: auto;
        margin-right: auto;
      }
    `,
    right: css`
      > button {
        margin-left: auto;
        margin-right: 0;
      }
    `,
  })[`${align}`];

const StyledFormSectionContainer = styled('div')(
  ({ theme }) => css`
    box-shadow: none;
    padding: 0em;
    background-color: transparent;
    border-radius: 0px;

    @media only screen and (min-width: ${MIN_WIDTH_MD}) {
      padding: 1em;
      border-radius: 5px;
      background-color: ${theme.palette.common.white};
      box-shadow: ${theme.shadows[1]};
    }
  `
);

const StyledErrorMessage = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing('SMALL')};
    color: ${theme.palette.error.main};
  `
);

const StyledButtonContainer = styled('div')(
  ({ align }) => css`
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    ${buttonContainerAlign(align || 'center')};
    button {
      margin: auto;
    }
    @media only screen and (min-width: ${MIN_WIDTH_MD}) {
      ${buttonContainerAlign(align || 'center')};
    }
  `
);

const StyledLabel = styled('label')(
  ({ theme, disabled }) => css`
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 450;

    ${disabled &&
    css`
      color: ${theme.palette.gray.gray};
    `}
  `
);

export {
  StyledFormSectionContainer as FormSectionContainer,
  StyledErrorMessage as ErrorMessage,
  StyledButtonContainer as ButtonContainer,
  StyledLabel as FormLabel,
};
