import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';

import { FRESSNAPF_FRIENDS_PAGE_LINK_PROMOTION_CAROUSEL, imgLinks } from '../../../shared';

import {
  StyledFressnapfFriendsCardButtonContainer,
  StyledFressnapfFriendsPaper,
  StyledFressnapfFriendsCardContent,
  StyledFressnapfFriendsCardImage,
  StyledFressnapfFriendsCardImageWrapper,
} from './FressnapfFriendsCard.styled';

const renderBTag = (chunk) => <Typography variant="h3">{chunk}</Typography>;

const handleGotoFressnapfFriendsPage = () => {
  window.open(FRESSNAPF_FRIENDS_PAGE_LINK_PROMOTION_CAROUSEL, '_blank');
};

function FressnapfFriendsCard() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const coverImageToUse = isDesktop ? imgLinks['fressnapf.desktop'] : imgLinks['fressnapf.mobile'];

  return (
    <StyledFressnapfFriendsPaper>
      <StyledFressnapfFriendsCardImageWrapper>
        <StyledFressnapfFriendsCardImage src={coverImageToUse} alt="Fressnapf Friends" />
      </StyledFressnapfFriendsCardImageWrapper>

      <StyledFressnapfFriendsCardContent>
        <Typography variant="p">
          <FormattedMessage
            id="promotion.carousel.fressnapf.fn_friends.text"
            values={{
              b: (chunk) => renderBTag(chunk),
            }}
          />
        </Typography>

        <StyledFressnapfFriendsCardButtonContainer>
          <Button onClick={handleGotoFressnapfFriendsPage}>
            <Typography variant={isDesktop ? 'buttons' : 'h3'}>
              <FormattedMessage id="promotion.carousel.fressnapf.fn_friends.button.label" />
            </Typography>
          </Button>
        </StyledFressnapfFriendsCardButtonContainer>
      </StyledFressnapfFriendsCardContent>
    </StyledFressnapfFriendsPaper>
  );
}

export default FressnapfFriendsCard;
