import React from 'react';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { IconButton, Link } from '@mui/material';

import DownloadIcon from '../../icons/DownloadIcon';
import { fetchFile } from '../../shared';

import { StyledDownloadLink } from './styled/DownloadLink.styled';

const handleDownload = (link, name) => {
  const options = {
    method: 'GET',
    responseType: 'blob',
  };
  const request = fetchFile(link, options);
  request((blob) => fileDownload(blob, `${name}.pdf`));
};

const DownloadLink = (props) => {
  const { children, name, link, target, onLinkClick, onButtonClick } = props;

  const handleHrefClick = () => {
    if (onLinkClick) onLinkClick();
    window.open(link, target);
  };

  const handleButtonClick = () => {
    if (onButtonClick) onButtonClick();
    handleDownload(link, name);
  };

  return (
    <StyledDownloadLink>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link component="button" onClick={handleHrefClick}>
        {children}
      </Link>

      <IconButton variant="round" onClick={handleButtonClick} aria-label="Download">
        <DownloadIcon />
      </IconButton>
    </StyledDownloadLink>
  );
};

DownloadLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  link: PropTypes.string,
  name: PropTypes.string,
  target: PropTypes.string,
};

export default DownloadLink;
