import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Alert, Button, Link, Paper, Typography } from '@mui/material';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import {
  SUBJECTS_DEFAULT,
  daDirektReferralPageLinkMap,
  formSubjectsMap,
  SUBJECTS_LINKS,
  DA_DIREKT_SUBJECTS_LINKS,
} from './contactUtils';
import { ContactFormTipIcon } from '../../components/common/Icon';

import { TextArea } from '../../components/common';
import ButtonContainer from '../../components/ButtonContainer';
import InfoIcon from '../../icons/InfoIcon';

import {
  StyledContactFormField,
  StyledTipContainer,
  StyledVerticalContent,
  StyledContactFormCustomerName,
  StyledTipIconContainer,
} from './Contact.styled';

const ContactForm = (props) => {
  const {
    maxLength,
    fullName,
    email,
    onFormSubmit,
    formLoading,
    body,
    setBody,
    subject,
    setSubject,
    isSubjectDaDirektReferral,
    isPatolo,
  } = props;
  const intl = useIntl();
  const { category: insuranceCategory, partner: insurancePartner } = useInsuranceInfo();
  const isDaDirektCustomer = insurancePartner === 'da-direkt';

  const formSubjects = formSubjectsMap[`${insuranceCategory}`] || SUBJECTS_DEFAULT;
  const subjectsLinks = isDaDirektCustomer
    ? DA_DIREKT_SUBJECTS_LINKS[`${insuranceCategory}`]
    : SUBJECTS_LINKS[`${insuranceCategory}`];

  useEffect(() => {
    if (!Array.isArray(formSubjects) || formSubjects.length < 1) return;

    setSubject(formSubjects[0]);
  }, [formSubjects, setSubject]);

  const handleSubmitForm = (ev) => {
    if (ev) ev.preventDefault();

    onFormSubmit();
  };

  const DA_DIREKT_REFERRAL_PAGE_LINK = daDirektReferralPageLinkMap[`${insuranceCategory}`] || null;
  const isExternalLink = ['https', 'www'].some((keyword) =>
    subjectsLinks[`${subject}`]?.link.includes(keyword)
  );

  const renderDADirektReferralPageLink = (chunk) => (
    <Link
      variant="p"
      color="white"
      target="_blank"
      rel="noopener noreferrer"
      href={DA_DIREKT_REFERRAL_PAGE_LINK}
    >
      {chunk}
    </Link>
  );

  return (
    <>
      <Typography variant="h2">
        <FormattedMessage id="contact.page.main.form.compose" />
      </Typography>

      <Paper>
        <form onSubmit={handleSubmitForm}>
          <StyledVerticalContent>
            <StyledContactFormCustomerName title={email}>
              <Typography variant="h3">
                <FormattedMessage id="contact.page.main.form.sender" values={{ fullName }} />
              </Typography>
              <Typography variant="p">{fullName}</Typography>
            </StyledContactFormCustomerName>

            {Array.isArray(formSubjects) && formSubjects.length > 0 && (
              <StyledContactFormField>
                <Typography variant="h3">
                  <FormattedMessage id="contact.page.main.form.reference" />
                </Typography>

                <select
                  name="subject"
                  id="formSubject"
                  className="select-sm select-primary u3-mb-2"
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                >
                  {formSubjects.map((sub) => (
                    <option key={sub} value={sub}>
                      {intl.formatMessage({ id: `contact.page.menu.${sub}` })}
                    </option>
                  ))}
                </select>
              </StyledContactFormField>
            )}

            {subject && !isPatolo && (
              <StyledTipContainer>
                <StyledTipIconContainer>
                  <ContactFormTipIcon />
                </StyledTipIconContainer>

                <div>
                  <Typography variant="h3" component="span">
                    <FormattedMessage id="contact.tip.title" />
                  </Typography>
                  &nbsp;
                  <Typography variant="p" component="span">
                    <FormattedMessage id={subjectsLinks[`${subject}`]?.textId || ''} />
                  </Typography>
                  &nbsp;
                  {isExternalLink ? (
                    <Link
                      variant="p"
                      href={subjectsLinks[`${subject}`]?.link || ''}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id={subjectsLinks[`${subject}`]?.linkTextId || ''} />
                    </Link>
                  ) : (
                    <Link variant="p" href={subjectsLinks[`${subject}`]?.link || ''}>
                      <FormattedMessage id={subjectsLinks[`${subject}`]?.linkTextId || ''} />
                    </Link>
                  )}
                </div>
              </StyledTipContainer>
            )}

            {isSubjectDaDirektReferral && (
              <Alert severity="info" variant="filled" icon={<InfoIcon />}>
                <Typography variant="p">
                  <FormattedMessage
                    id="contact.page.form.banner.subject.referral.dadirekt.info"
                    values={{
                      br: <br />,
                      a: (chunk) => renderDADirektReferralPageLink(chunk),
                    }}
                  />
                </Typography>
              </Alert>
            )}

            <StyledContactFormField>
              <Typography variant="h3">
                <FormattedMessage id="contact.page.main.form.message" />
              </Typography>

              <TextArea
                name="1"
                id="23"
                cols="30"
                rows="8"
                className="input-primary input-sm u3-mb-2"
                maxLength={maxLength}
                onChange={(e) => setBody(e.target.value)}
                value={body}
                required
              />
            </StyledContactFormField>
          </StyledVerticalContent>

          <ButtonContainer>
            <Button type="submit" disabled={formLoading}>
              <Typography variant="buttons">
                <FormattedMessage id="contact.page.main.form.send" />
              </Typography>
            </Button>
          </ButtonContainer>
        </form>
      </Paper>
    </>
  );
};

ContactForm.propTypes = {
  maxLength: PropTypes.string,
  fullName: PropTypes.string,
  email: PropTypes.string,
  onFormSubmit: PropTypes.func,
  formLoading: PropTypes.bool,
  body: PropTypes.string.isRequired,
  setBody: PropTypes.func.isRequired,
  subject: PropTypes.string.isRequired,
  setSubject: PropTypes.func.isRequired,
  isSubjectDaDirektReferral: PropTypes.bool,
};

export default ContactForm;
