import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Typography } from '@mui/material';

import { Icon } from '../common';
import { Paragraph } from '../ui';
import {
  StyledMissingIbanBannerContainer,
  StyledMissingIbanBannerBody,
  StyledMissingIbanBannerText,
  StyledDissmissButton,
  StyledMissingIbanBannerIcon,
} from './MissingIban.styled';

const renderLink = (chunk, onClick) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link component="button" variant="p" onClick={onClick}>
    {chunk}
  </Link>
);

const MissingIban = (props) => {
  const { onClick, onDismiss } = props;

  return (
    <StyledMissingIbanBannerContainer dismissable={Boolean(onDismiss)}>
      <StyledMissingIbanBannerBody dismissable={Boolean(onDismiss)}>
        <StyledMissingIbanBannerIcon icon="icon.menu.orange.doc" />

        <div>
          <Typography variant="h3">
            <FormattedMessage id="policy.info.missing.iban.title" />
          </Typography>

          <Paragraph>
            <StyledMissingIbanBannerText dismissable={Boolean(onDismiss)}>
              <Typography variant="p">
                <FormattedMessage
                  id="policy.info.missing.iban.message"
                  values={{
                    a: (chunk) => renderLink(chunk, onClick),
                  }}
                />
              </Typography>
            </StyledMissingIbanBannerText>
          </Paragraph>
        </div>
      </StyledMissingIbanBannerBody>

      {onDismiss && (
        <StyledDissmissButton type="button" onClick={onDismiss}>
          <Icon icon="icon.x" alt="Dismiss button" />
        </StyledDissmissButton>
      )}
    </StyledMissingIbanBannerContainer>
  );
};

export default MissingIban;
