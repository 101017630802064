import React from 'react';
import { IconButton } from '@mui/material';

import CloseIcon from '../../icons/CloseIcon';

import { StyledDialogHeaderContainer } from './DialogHeader.styled';

export default function DialogHeader(props) {
  const { children, handleClose, showCloseButton = true } = props;

  return (
    <StyledDialogHeaderContainer hasTitle={Boolean(children)}>
      <div>{children}</div>

      {showCloseButton && handleClose && (
        <IconButton onClick={handleClose} aria-label="Close dialog">
          <CloseIcon />
        </IconButton>
      )}
    </StyledDialogHeaderContainer>
  );
}
