import { alpha, css, hexToRgb, Paper, styled } from '@mui/material';
import Slider from 'react-slick';

export const promotionCarouselSliderHeight = {
  desktop: '18.75rem',
  mobile: '13.75rem',
};

export const StyledPromotionCarouselPaper = styled(Paper)`
  padding: 0;
`;

export const StyledPromotionCarouselSlider = styled(Slider)(
  ({ theme }) => css`
    height: ${promotionCarouselSliderHeight.mobile};
    border-radius: 5px;
    clip-path: border-box;

    ${({ slideCount }) =>
      slideCount > 1 &&
      css`
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      `}

    ${theme.breakpoints.up('desktop')} {
      height: ${promotionCarouselSliderHeight.desktop};
    }
  `
);

export const StyledPromotionCarouselControls = styled('div')(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 3.125rem;
    padding: 0 ${theme.spacing('SMALL')};
    border-top: 1px solid ${theme.palette.gray.light_gray};

    ${theme.breakpoints.up('desktop')} {
      padding: 0 ${theme.spacing('LARGE')};
      height: 3.375rem;
    }
  `
);

export const StyledPromotionCarouselControlsDots = styled('div')(
  ({ theme }) => css`
    align-items: center;
    column-gap: ${theme.spacing('SMALL')};
    display: flex;
    justify-content: space-between;
  `
);

export const StyledPromotionCarouselControlsDot = styled('button')(
  ({ theme, active }) => css`
    background-color: transparent;
    border: 1px solid ${theme.palette.main.professional_blue};
    border-radius: 50%;
    cursor: pointer;
    height: 0.5rem;
    padding: 0;
    width: 0.5rem;

    &:hover {
      background: ${alpha(hexToRgb(theme.palette.main.professional_blue), 0.15)};
      border: 1px solid ${theme.palette.main.professional_blue};
    }

    &:focus {
      box-shadow: ${theme.shadows[5]};
    }

    ${active &&
    css`
      background-color: ${theme.palette.main.professional_blue};
      cursor: default;
      pointer-events: none;
    `}
  `
);
