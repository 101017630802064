// adding datadog initialization to the top
import './configs/datadog.config';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import cssVars from 'css-vars-ponyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { IntlProvider } from 'react-intl';
import { datadogLogs } from '@datadog/browser-logs';

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import { apolloClient } from './configs/apollo.config';
import { getLocale, messages } from './locale';
import polyfillReactIntl from './locale/polyfillReactIntl';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPlaceholder from './features/errorPlaceholder';

// TODO: configure properly if IE support required
cssVars({
  include: 'style',
  onlyLegacy: true,
  watch: true,
});

const locale = getLocale();

const polyfillReactIntlWithErrorReporting = async () => {
  try {
    await polyfillReactIntl(locale);
  } catch (error) {
    const errorLog = `PolyfillError: ${error}`;

    // logging `PolyfillError` to datadog
    datadogLogs.logger.error(errorLog, {}, error);
  }
};

const rootElement = document.getElementById('root');

const cache = createCache({
  key: 'getolo',
  container: rootElement,
});

polyfillReactIntlWithErrorReporting().then(() => {
  ReactDOM.render(
    <IntlProvider locale={locale} messages={messages[`${locale}`]}>
      <ErrorBoundary datadog={datadogLogs} fallback={<ErrorPlaceholder />}>
        <ApolloProvider client={apolloClient}>
          <CacheProvider value={cache}>
            <App />
          </CacheProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </IntlProvider>,
    rootElement
  );
});
