import { css, styled } from '@mui/material';

export const StyledPetoloReferralStatusEmptyImageContainer = styled('div')(
  ({ theme }) => css`
    margin: ${theme.spacing('4XL')} auto;
    text-align: center;

    ${theme.breakpoints.up('desktop')} {
      margin-top: ${theme.spacing('6XL')};
      margin-bottom: ${theme.spacing('6XL')};
    }
  `
);

export const StyledPetoloReferralStatusEmptyImg = styled('img')(
  ({ theme }) => css`
    max-width: 100%;
    width: 10.625rem;
    transform-origin: center;
    transform: rotate(-10.437deg);
    border-radius: 6px;
    box-shadow: -8.126px 8.126px 9.752px rgba(27, 53, 98, 0.25);

    ${theme.breakpoints.up('desktop')} {
      width: 19rem;
    }
  `
);
