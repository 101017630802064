import React from 'react';
import PropTypes from 'prop-types';
import { Container, Paper } from '@mui/material';

import DentistDetails from '.';
import { BackButton } from '../../../components/common';

import { StyledDentistDetailsModal } from './DentistsDetails.styled';

export default function DentistDetailsModal({ dentist, showDetails, setShowDetails }) {
  if (!showDetails) return null;

  return (
    <StyledDentistDetailsModal>
      <Container>
        <BackButton onClick={() => setShowDetails(false)} />

        <Paper>
          <DentistDetails dentist={dentist} hideDismissButton />
        </Paper>
      </Container>
    </StyledDentistDetailsModal>
  );
}

DentistDetailsModal.propTypes = {
  dentist: PropTypes.shape({
    practiceName: PropTypes.string,
  }),
  setShowDetails: PropTypes.func,
  showDetails: PropTypes.bool,
};
