import { css, styled } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const ButtonContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: ${theme.spacing('1XL')};
    row-gap: ${theme.spacing('LARGE')};
    width: 100%;

    ${theme.breakpoints.up('desktop')} {
      column-gap: ${theme.spacing('LARGE')};
      flex-direction: row;
    }
  `
);
