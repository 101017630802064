import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Typography } from '@mui/material';

import { DocumentIcon, OpenLinkIcon } from '../../components/common/Icon';
import { formatDate } from '../../shared';

import {
  StyledAlreadyUploadedDocument,
  StyledAlreadyUploadedDocumentContainer,
  StyledAlreadyUploadedDocumentDate,
  StyledAlreadyUploadedDocumentLinkContainer,
  StyledAlreadyUploadedDocumentsCard,
  StyledAlreadyUploadedDocumentsTitle,
} from './AlreadyUploadedDocuments.styled';

export default function AlreadyUploadedDocuments({ documentsAttachments }) {
  return (
    <StyledAlreadyUploadedDocumentsCard>
      <StyledAlreadyUploadedDocumentsTitle>
        <Typography variant="h3">
          <FormattedMessage id="user_claims_info.already_uploaded_documents.title" />
        </Typography>
      </StyledAlreadyUploadedDocumentsTitle>

      <div>
        {Array.isArray(documentsAttachments) &&
          documentsAttachments.map((document) => (
            <StyledAlreadyUploadedDocumentContainer key={document?.filename}>
              <StyledAlreadyUploadedDocument>
                <DocumentIcon />

                <div>
                  <Typography variant="p">{document?.filename}</Typography>
                  {document?.date && (
                    <StyledAlreadyUploadedDocumentDate>
                      <Typography variant="p">
                        <FormattedMessage id="user_claims_info.already_uploaded_documents.document_date_label" />
                        &nbsp;
                        {formatDate(document?.date)}
                      </Typography>
                    </StyledAlreadyUploadedDocumentDate>
                  )}
                </div>
              </StyledAlreadyUploadedDocument>

              <StyledAlreadyUploadedDocumentLinkContainer>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  component="button"
                  variant="h3"
                  onClick={() => (document?.url ? window.open(document?.url, '_blank') : null)}
                >
                  <FormattedMessage id="user_claims_info.already_uploaded_documents.document_open_link" />
                  <OpenLinkIcon />
                </Link>
              </StyledAlreadyUploadedDocumentLinkContainer>
            </StyledAlreadyUploadedDocumentContainer>
          ))}
      </div>
    </StyledAlreadyUploadedDocumentsCard>
  );
}
