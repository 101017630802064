import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, Link, Container } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import useInsuranceInfo from '../../hooks/useInsuranceInfo';

import {
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
  imgLinks,
  useReady,
} from '../../shared';
import { StyledFailurePageContainer, StyledTextContentContainer } from './ErrorPlaceholder.styled';

const getImage = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return imgLinks['dental.fail.auth.page'];
    case INSURANCE_TYPE_VITOLO:
      return imgLinks['vit.fail.auth.page'];
    case INSURANCE_TYPE_PETOLO:
      return imgLinks['pet.fail.auth.page'];
    default:
      return imgLinks['dentolo.fail.auth.page'];
  }
};
const renderLink = (chunk) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link variant="p" href={`mailto:${chunk}`}>
    {chunk}
  </Link>
);
function ErrorPlaceholder() {
  const ready = useReady();

  const { type: insuranceType } = useInsuranceInfo();

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <StyledFailurePageContainer>
          <img src={getImage(insuranceType)} alt="fail" />

          <StyledTextContentContainer>
            <Typography variant="h1">
              <FormattedMessage id="auth.failure.page.title" />
            </Typography>

            <Typography variant="p">
              <FormattedMessage
                id={`auth.failure.page.email.support.${insuranceType}`}
                values={{
                  a: (chunk) => renderLink(chunk),
                }}
              />
            </Typography>
            <Typography variant="p">
              <FormattedMessage id="auth.failure.page.email.thanks" />
            </Typography>
            <Typography variant="p">
              <FormattedMessage id={`auth.failure.page.email.your_team.${insuranceType}`} />
            </Typography>
          </StyledTextContentContainer>
        </StyledFailurePageContainer>
      </Container>
    </CSSTransition>
  );
}

export default ErrorPlaceholder;
