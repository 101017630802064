import React from 'react';
import { useMutation } from '@apollo/client';
import { Button, Typography } from '@mui/material';

import { CREATE_DENTOLO_LEAD_WITH_CUSTOMER_DATA } from '../../../graphql/mutations/leads';
import { imgLinks, DENTOLO_ADDITIONAL_INSURANCE_URL, GETOLO_UTM_PARAMS } from '../../../shared';

import {
  StyledAdditionalDentoloInsuranceCard,
  StyledAdditionalDentoloInsuranceCardContent,
  StyledAdditionalDentoloInsuranceCardText,
  StyledAdditionalDentoloInsuranceCardTitle,
} from './AdditionalDentoloInsuranceCard.styled';

const utmSource = `utm_source=${GETOLO_UTM_PARAMS.source}`;
const utmCampaign = `utm_campaign=${GETOLO_UTM_PARAMS.campaignAdditionalDentoloInsurance}`;

const goToFunnel = ({ uuid }) => {
  if (!uuid) return;

  const urlToInsureAnotherPetFunnel = `${DENTOLO_ADDITIONAL_INSURANCE_URL}&uuid=${uuid}&${utmSource}&${utmCampaign}`;

  window.open(urlToInsureAnotherPetFunnel, '_blank');
};

const handleRequestComplete = (data) => {
  const newUuid = data?.insureAnotherPerson?.uuid;

  if (newUuid) {
    goToFunnel({ uuid: newUuid });
  }
};

function AdditionalDentoloInsuranceCard() {
  const [
    createDentoloLeadWithCustomerData,
    { data: response, called: newUuidCalled, loading: newUuidLoading },
  ] = useMutation(CREATE_DENTOLO_LEAD_WITH_CUSTOMER_DATA);

  const additionalDentoloInsuranceBtnClick = () => {
    const newLeadUuid = response?.insureAnotherPerson?.uuid;

    if (newLeadUuid) {
      goToFunnel({ uuid: newLeadUuid });
      return;
    }

    createDentoloLeadWithCustomerData({
      onCompleted: handleRequestComplete,
    });
  };

  const disableButton = newUuidCalled && newUuidLoading;

  return (
    <StyledAdditionalDentoloInsuranceCard
      image={imgLinks['carousel.another.dentolo.contract.image']}
    >
      <StyledAdditionalDentoloInsuranceCardContent>
        <StyledAdditionalDentoloInsuranceCardTitle>
          <Typography variant="h3">
            Jetzt <span>weitere Familienmitglieder</span> absichern
          </Typography>
        </StyledAdditionalDentoloInsuranceCardTitle>

        <StyledAdditionalDentoloInsuranceCardText>
          <Typography variant="p">
            Sie können jederzeit Kinder in Ihren dentolo Unfallschutz aufnehmen oder neue Verträge
            für weitere Familienmitglieder abschließen.
          </Typography>
        </StyledAdditionalDentoloInsuranceCardText>

        <Button type="button" onClick={additionalDentoloInsuranceBtnClick} disabled={disableButton}>
          <Typography variant="buttons">Zur Anmeldung</Typography>
        </Button>
      </StyledAdditionalDentoloInsuranceCardContent>
    </StyledAdditionalDentoloInsuranceCard>
  );
}

export default AdditionalDentoloInsuranceCard;
