import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { showMoney } from '../../../shared';

import {
  StyledBoxSpaceBetween,
  StyledSeparator,
  StyledVerticalContent,
} from '../PolicyInfo.styled';

const getLabel = (customLabels, intl) => (age, isLast) => {
  const validatedcustomLabels = Array.isArray(customLabels) ? customLabels : [];

  const validatedAge = Array.isArray(age) && age.length === 2 ? age : [0, 0];
  const [startAge, endAge] = validatedAge;
  const validatedStartAge = Number.isNaN(parseInt(startAge, 10)) ? 0 : startAge;
  const validatedEndAge = Number.isNaN(parseInt(endAge, 10)) ? 0 : endAge;

  const customLabelKey = `${validatedStartAge}-${validatedEndAge}`;
  const customLabelInfo = validatedcustomLabels.find((info) => info?.age === customLabelKey);

  const yearsText = intl.formatMessage({ id: 'policy.all_details.premium.years' });

  if (customLabelInfo) {
    return customLabelInfo?.label;
  }

  if (isLast) {
    return `${validatedStartAge - 1}+ ${yearsText}`;
  }

  return `${validatedStartAge} - ${validatedEndAge} ${yearsText}`;
};

const Premium = ({ premiumInfo, contractPremium }) => {
  const intl = useIntl();

  const { isHidden, customLabels } = premiumInfo || {};
  const getAgeLabel = getLabel(customLabels, intl);

  if (isHidden || !Array.isArray(contractPremium)) return null;

  return (
    <>
      <StyledSeparator />

      <Typography variant="h3">
        <FormattedMessage id="policy.all_details.premium.title" />:
      </Typography>

      {contractPremium.map(({ age, price }, index) => {
        const isLast = index === contractPremium.length - 1;

        return (
          <StyledVerticalContent key={price}>
            {index !== 0 && <StyledSeparator />}

            <StyledBoxSpaceBetween>
              <Typography variant="p" color="subtext">
                {getAgeLabel(age, isLast)}
              </Typography>

              <Typography variant="h3">{showMoney(price)}</Typography>
            </StyledBoxSpaceBetween>
          </StyledVerticalContent>
        );
      })}
    </>
  );
};

export default Premium;
