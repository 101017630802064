import { PALETTE } from '../palette';

// eslint-disable-next-line import/prefer-default-export
export const muiAlertComponent = {
  styleOverrides: {
    filledInfo: ({ theme }) => ({
      background: PALETTE.BLUE_STATUS,
      color: theme.palette.common.white,
      padding: theme.spacing('LARGE'),
    }),
    icon: ({ theme }) => ({
      alignItems: 'center',
      marginRight: theme.spacing('LARGE'),
      opacity: 1,
      padding: '0',

      [`& > svg, & > img`]: {
        height: theme.spacing('2XL'),
        width: theme.spacing('2XL'),
      },
    }),
  },
};
