import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { CSSTransition } from 'react-transition-group';
import { Paper } from '@mui/material';

import { useReady, GET_CUSTOMER } from '../../../../shared';
import { JOIN_REFERRAL_PROGRAM } from '../../../../graphql/mutations/referrals';

import VitoloReferralSteps from './VitoloReferralSteps';
import VitoloReferralShare from './VitoloReferralShare';

import { Loading } from '../../../../components/ui';
import ErrorPlaceholder from '../../../errorPlaceholder';

function VitoloReferralInformation() {
  const ready = useReady();
  const transitionTimeout = 500;

  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const customer = customerData?.customer;

  const [
    generateHealthReferralCode,
    { loading: referralCodeLoading, error: referralCodeGenerationError },
  ] = useMutation(JOIN_REFERRAL_PROGRAM, {
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  // Generate referral code if customer does not have one
  useEffect(() => {
    const hasReferralCode = !!customer?.referralCode;

    if (!hasReferralCode) {
      generateHealthReferralCode();
    }
  }, [customer, customerLoading, generateHealthReferralCode]);

  if (referralCodeLoading) return <Loading showLogo />;

  if ((!customerLoading && !customer) || referralCodeGenerationError) return <ErrorPlaceholder />;

  if (!customerLoading && !!customer) {
    return (
      <CSSTransition in={ready} timeout={transitionTimeout} classNames="fade" unmountOnExit>
        <Paper>
          {/* renders referral steps */}
          <VitoloReferralSteps />

          {/* renders referral share section */}
          <VitoloReferralShare customer={customer} />
        </Paper>
      </CSSTransition>
    );
  }

  return null;
}

export default VitoloReferralInformation;
