import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import Icon from './Icon';
import {
  StyledNotificationContainer,
  StyledNotificationBody,
  StyledNotificationContent,
  StyledNotificationText,
  StyledDissmissButton,
  StyledNotificationIcon,
} from '../styled/Notification.styled';

const icons = {
  info: 'notification.information',
  success: 'notification.success',
  processing: 'notification.processing',
  warning: 'notification.warning',
};

const getIcon = (type) => {
  switch (type) {
    case 'info':
      return icons.info;

    case 'success':
      return icons.success;

    case 'processing':
      return icons.processing;

    case 'warning':
    case 'danger':
      return icons.warning;

    default:
      return null;
  }
};

const Notification = (props) => {
  const { children, type, title, message, onClick, onDismiss } = props;

  const iconToUse = getIcon(type);

  return (
    <StyledNotificationContainer type={type}>
      <StyledNotificationBody dismissable={!!onDismiss}>
        {iconToUse && <StyledNotificationIcon icon={iconToUse} />}

        {children ? (
          <StyledNotificationContent>{children}</StyledNotificationContent>
        ) : (
          <StyledNotificationContent clickable={!!onClick} onClick={onClick || null}>
            {title && (
              <StyledNotificationText $title>
                <Typography variant="h3">{title}</Typography>
              </StyledNotificationText>
            )}
            {message && (
              <StyledNotificationText>
                <Typography variant="p">{message}</Typography>
              </StyledNotificationText>
            )}
          </StyledNotificationContent>
        )}
      </StyledNotificationBody>

      {!!onDismiss && (
        <StyledDissmissButton type="button" onClick={onDismiss}>
          <Icon icon="icon.x" alt="Dismiss button" />
        </StyledDissmissButton>
      )}
    </StyledNotificationContainer>
  );
};

export default Notification;

Notification.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
};
