import { css, styled } from '@mui/material';

import { imgLinks, INSURANCE_TYPE_VITOLO } from '../../../shared';
import ButtonContainer from '../../../components/ButtonContainer';

export const StyledImageContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-bottom: ${theme.spacing('SMALL')};
    margin-top: ${theme.spacing('LARGE')};

    ${theme.breakpoints.up('desktop')} {
      margin-top: ${theme.spacing('2XL')};
    }
  `
);

export const StyledDesktopImage = styled('img')(
  ({ theme }) => css`
    display: none;

    ${theme.breakpoints.up('desktop')} {
      display: block;
    }
  `
);

export const StyledMobileImage = styled('img')(
  ({ theme }) => css`
    display: block;

    ${theme.breakpoints.up('desktop')} {
      display: none;
    }
  `
);

export const StyledButtonContainer = styled(ButtonContainer)(
  ({ theme }) => css`
    flex-direction: column-reverse;

    ${theme.breakpoints.up('desktop')} {
      flex-direction: row-reverse;
    }
  `
);

export const StyledLandingPageNormalText = styled('div')(
  ({ theme }) => css`
    text-align: center;
    margin-bottom: ${theme.spacing('LARGE')};
    padding: 0 ${theme.spacing('LARGE')};
  `
);

const vitoloCancellationDateBackground = css`
  background-image: url(${imgLinks['icon.vitolo.underline']});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  padding-bottom: 0.125rem;
`;

export const StyledCancellationDate = styled(StyledLandingPageNormalText)(
  ({ theme, type }) => css`
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 0 ${theme.spacing('SMALL')};
    background: ${theme.palette.background.cancellationDate};
    ${type === INSURANCE_TYPE_VITOLO && vitoloCancellationDateBackground};
    color: ${theme.palette.main.professional_blue};
    border-radius: 3px;
  `
);
