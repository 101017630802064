import { inputBaseClasses } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const muiInputLabelComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.main.professional_blue,
      maxWidth: '100%',
      position: 'relative',
      transform: 'none',

      [`&.Mui-focused`]: {
        boxShadow: 'none',
        color: theme.palette.main.professional_blue,
      },

      [`&.Mui-error`]: {
        color: theme.palette.main.professional_blue,
      },

      [`&.Mui-disabled`]: {
        backgroundColor: 'transparent',
        textFillColor: theme.palette.main.professional_blue,
      },

      [`& + .${inputBaseClasses.root}`]: {
        marginTop: theme.spacing('SMALL'),
      },
    }),
  },
};
