import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import QueryString from 'query-string';
import { Button, Container, Typography } from '@mui/material';

import { Icon } from '../../components/common';
import ButtonContainer from '../../components/ButtonContainer';

import { StyledAkutschutzCancellationCompleteContainer } from './AkutschutzCancellation.styled';

export default function AkutschutzCancellationSuccess() {
  const history = useHistory();

  const { search } = useLocation();
  const params = QueryString.parse(search);

  const isWithdrawal = params?.withdraw === 'true';

  return (
    <Container>
      <StyledAkutschutzCancellationCompleteContainer>
        <div>
          <Icon icon="info.success" alt="success" />
        </div>
        {isWithdrawal && (
          <>
            <Typography variant="h1">
              <FormattedMessage id="akutschutz_cancellation.success.withdraw.title" />
            </Typography>

            <Typography variant="p">
              <FormattedMessage id="akutschutz_cancellation.success.withdraw.content" />
            </Typography>
          </>
        )}

        {!isWithdrawal && (
          <>
            <Typography variant="h1">
              <FormattedMessage id="akutschutz_cancellation.success.cancel.title" />
            </Typography>

            <Typography variant="p">
              <FormattedMessage id="akutschutz_cancellation.success.cancel.content" />
            </Typography>
          </>
        )}
      </StyledAkutschutzCancellationCompleteContainer>

      <ButtonContainer>
        <Button variant="outlined" type="button" onClick={() => history.push('/')}>
          <Typography variant="buttons">
            <FormattedMessage id="akutschutz_cancellation.success.back_to_start_page" />
          </Typography>
        </Button>
      </ButtonContainer>
    </Container>
  );
}
