import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import CheckIcon from './CheckIcon';

import { StyledBoxAlignCenter } from '../PolicyInfo.styled';

const AdditionalAdvantages = ({ details }) => {
  if (!Array.isArray(details)) return null;

  return (
    <>
      <Typography variant="h3">
        <FormattedMessage id="policy.covergae_info.additional_advantages.title" />
      </Typography>

      {details.map((item) => (
        <StyledBoxAlignCenter key={item}>
          <CheckIcon />

          <Typography variant="p" color="subtext">
            {item}
          </Typography>
        </StyledBoxAlignCenter>
      ))}
    </>
  );
};

export default AdditionalAdvantages;
