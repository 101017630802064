import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import JWTDecode from 'jwt-decode';
import { CSSTransition } from 'react-transition-group';
import queryString from 'query-string';
import { Button, Container, Link, Paper, TextField, Typography } from '@mui/material';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import {
  GET_CUSTOMER,
  CONFIRM_EMAIL,
  SET_CONFIRM_EMAIL_MUTATION,
  directlyLoadFromLocalStorage,
  useReady,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_HEALTH,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
} from '../../shared';

import ButtonContainer from '../ButtonContainer';
import { Checkbox, PageTitle } from '../common';
import Icon, { TipIcon } from '../common/Icon';

import {
  StyledVerticalContent,
  StyledLoginActionStatusHeader,
  StyledTipContainer,
  StyledTipIconContainer,
} from './Login.styled';

const getHomePageLink = (insuranceType, isPatolo) => {
  if (isPatolo) {
    return 'https://www.patolo.fr/';
  }
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return '//dentolo.de/';
    case INSURANCE_TYPE_PETOLO:
      return '//petolo.de/';
    case INSURANCE_TYPE_VITOLO:
      return '//vitolo.de/';
    default:
      return '//dentolo.de/';
  }
};

const getInsuranceCategory = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return INSURANCE_CATEGORY_DENTAL;
    case INSURANCE_TYPE_PETOLO:
      return INSURANCE_CATEGORY_PET_HEALTH;
    case INSURANCE_TYPE_VITOLO:
      return INSURANCE_CATEGORY_HEALTH;
    default:
      return INSURANCE_CATEGORY_DENTAL;
  }
};

const Login = () => {
  const [email, setEmail] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const { type: insuranceType, isPatolo } = useInsuranceInfo();
  const location = useLocation();
  const history = useHistory();
  const ready = useReady();
  const gduid = directlyLoadFromLocalStorage('gduid');
  const search = location?.search || '';
  const intl = useIntl();
  useEffect(() => {
    const params = queryString.parse(search);

    if (params?.logout === 'true' && !isLogout) {
      setIsLogout(true);
    }

    if (params?.invalid === 'true' && !isInvalid) {
      setIsInvalid(true);
      const jwt = params?.auth;
      const decodedJwt = jwt ? JWTDecode(jwt) : {};
      const decodedJwtEmail = decodedJwt?.email || '';
      setEmail(decodedJwtEmail);

      const rememberMeString = params?.remember_me;
      const isRememberMe = rememberMeString === 'true';
      setRememberMe(isRememberMe);
    }
  }, [isInvalid, isLogout, search]);

  const { data: customerData } = useQuery(GET_CUSTOMER);
  const customerUuid = customerData?.customer?.uuid;

  useEffect(() => {
    if (customerUuid) {
      history.push('/');
    }
  }, [customerUuid, history]);

  const [setConfirmEmail] = useMutation(SET_CONFIRM_EMAIL_MUTATION, {
    variables: {
      email,
    },
  });

  const [confirmEmail] = useMutation(CONFIRM_EMAIL, {
    variables: {
      email,
      gduid,
      rememberMe,
      insuranceCategory: getInsuranceCategory(insuranceType),
    },
  });

  const onFormSubmit = (e) => {
    e.preventDefault();
    setDisableForm(true);

    confirmEmail()
      .then(() => {
        const params = queryString.parse(location.search);
        const customerSourceZurich = params?.customer_source === 'zurich';
        const url = `/login/success${customerSourceZurich ? 'customer_source=zurich' : ''}`;

        setConfirmEmail().then(() => history.push(url));
        setDisableForm(false);
      })
      .catch();
  };
  const [showTip, setShowTip] = useState(false);
  return (
    <Container>
      <PageTitle>
        <Typography variant="h1">
          <FormattedMessage id="login.common.title" />
        </Typography>
      </PageTitle>

      <StyledVerticalContent>
        <CSSTransition in={isLogout} timeout={400} classNames="fade" unmountOnExit>
          <Paper>
            <StyledVerticalContent>
              <StyledLoginActionStatusHeader>
                <Icon icon="info.success" alt="success" />

                <Typography variant="h3">
                  <FormattedMessage id="login.info.message.logout_success" />
                </Typography>
              </StyledLoginActionStatusHeader>

              <Link variant="p" href={getHomePageLink(insuranceType, isPatolo)}>
                <FormattedMessage id={`login.link_to_homepage.${insuranceType}`} />
              </Link>
            </StyledVerticalContent>
          </Paper>
        </CSSTransition>

        <CSSTransition in={isInvalid} timeout={400} classNames="fade" unmountOnExit>
          <Paper>
            <StyledVerticalContent>
              <StyledLoginActionStatusHeader>
                <Icon icon="info.warning" alt="failure" />

                <Typography variant="h3">
                  <FormattedMessage id="login.info.title.invalid_link" />
                </Typography>
              </StyledLoginActionStatusHeader>

              <Typography variant="p">
                <FormattedMessage id="login.info.message.invalid_link" />
              </Typography>
            </StyledVerticalContent>
          </Paper>
        </CSSTransition>

        <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
          <Paper>
            <form onSubmit={(e) => onFormSubmit(e)}>
              <StyledVerticalContent>
                <TextField
                  id="login-email"
                  name="login-email"
                  type="email"
                  placeholder={intl.formatMessage({ id: 'placeholder.login.email' })}
                  label={
                    <Typography variant="h3" component="span">
                      <FormattedMessage id="login.form.label" />
                    </Typography>
                  }
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => setShowTip(true)}
                  disabled={disableForm}
                />

                <Checkbox
                  id="login-checkbox"
                  name="login-checkbox"
                  label={<FormattedMessage id="login.form.check" />}
                  value={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                  disabled={disableForm}
                />

                {showTip && (
                  <StyledTipContainer>
                    <StyledTipIconContainer>
                      <TipIcon />
                    </StyledTipIconContainer>

                    <div>
                      <Typography variant="h3" component="span">
                        <FormattedMessage id="login.common.tip.title" />
                      </Typography>

                      <Typography variant="p" component="span">
                        <FormattedMessage id="login.common.tip.description" />
                      </Typography>
                    </div>
                  </StyledTipContainer>
                )}

                <Typography variant="p">
                  <FormattedMessage id="login.common.send.email" />
                </Typography>
              </StyledVerticalContent>

              <ButtonContainer>
                <Button type="submit" data-testid="btn_send_login_link" disabled={disableForm}>
                  <Typography variant="buttons">
                    <FormattedMessage
                      data-testid="btn_send_login_link"
                      id="login.common.send.link"
                    />
                  </Typography>
                </Button>
              </ButtonContainer>
            </form>
          </Paper>
        </CSSTransition>
      </StyledVerticalContent>
    </Container>
  );
};

export default Login;
