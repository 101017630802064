import { css, styled } from '@mui/material';

export const StyledAkutschutzCancellationContentContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing('1XL')};
    row-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledAkutschutzCancellationDateContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spacing('LARGE')};
    row-gap: ${theme.spacing('SMALL')};
    text-align: center;
  `
);

export const StyledAkutschutzCancellationDate = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.background.cancellationDate};
    margin: 0 auto;
    padding: 0.125rem ${theme.spacing('SMALL')};
    width: fit-content;
  `
);

export const StyledAkutschutzCancellationCompleteContainer = styled(
  StyledAkutschutzCancellationContentContainer
)`
  text-align: center;
`;
