import { css, styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';

// eslint-disable-next-line import/prefer-default-export
export const StyledDropdownSearch = styled('div')(
  ({ theme }) => css`
    .dropdown-search {
      position: relative;
      width: 100%;

      &__control {
        border-radius: 5px;
        height: 3rem;
        border: 1px solid ${theme.palette.main.professional_blue};
        font-family: 'Circular TT', 'Arial', sans-serif;

        &--is-focused {
          box-shadow: ${theme.shadows[3]};
        }
      }

      &__menu {
        margin: 2px 0 0;
        border-radius: 0;
      }

      &__menu-notice--no-options,
      &__menu-notice--loading {
        color: ${theme.palette.background.viewport};
      }

      &__option {
        display: flex;
        align-items: center;
        padding-left: 1.5rem;
        min-height: 3rem;
        color: ${theme.palette.main.professional_blue};
        font-family: 'Circular TT', 'Arial', sans-serif;

        &--is-selected {
          background-color: ${theme.palette.common.white} !important;
        }

        &--is-focused {
          background-color: ${theme.palette.background.viewport} !important;
        }
      }

      &__placeholder {
        color: ${theme.palette.main.professional_blue};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &__input {
        color: ${theme.palette.main.professional_blue};

        > input {
          &:focus {
            box-shadow: none;
          }
        }
      }

      &__single-value {
        color: ${theme.palette.main.professional_blue};
      }

      &__value-container {
        padding-left: 1rem;
      }

      &__indicator-separator {
        display: none;
      }

      &__indicator {
        > svg {
          width: 1.7rem;
          height: 1.7rem;
          stroke: transparent;
          stroke: ${theme.palette.common.white};
          stroke-width: 1px;
          fill: ${theme.palette.main.professional_blue};
        }
      }

      @media (min-width: ${MIN_WIDTH_MD}) {
        &__control {
          height: 3.5rem;
        }
      }
    }
  `
);
