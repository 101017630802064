import React from 'react';
import { Typography } from '@mui/material';

import CheckIcon from './CheckIcon';

import { StyledBoxAlignStart, StyledSeparator } from '../PolicyInfo.styled';

const AddOns = ({ details }) => {
  const contents = details?.contents;

  if (!Array.isArray(contents)) return null;

  return (
    <>
      <Typography variant="h3">{details?.title}</Typography>

      {contents.map((item) => (
        <StyledBoxAlignStart key={item?.text || item?.subtext}>
          <CheckIcon />

          <div>
            <Typography variant="p" color="subtext">
              {item?.text}
            </Typography>

            <Typography variant="h3">{item?.subtext}</Typography>
          </div>
        </StyledBoxAlignStart>
      ))}

      <StyledSeparator />
    </>
  );
};

export default AddOns;
