import { styled, css } from '@mui/material';
import { Link } from 'react-router-dom';

import { MAX_WIDTH_SM, MIN_WIDTH_LG } from '../../theme/breakpoints';

export const StyledHeader = styled('header')(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    height: ${theme.shape.headerDesktop};
    z-index: 1000;
  `
);

export const StyledHeaderSticky = styled('div')(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: ${theme.shape.headerDesktop};
    background-color: ${theme.palette.common.white};
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    z-index: 99;
  `
);

export const StyledHeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  margin: 0 auto;

  @media (min-width: ${MAX_WIDTH_SM}) {
    max-width: 720px;
  }
  @media (min-width: ${MIN_WIDTH_LG}) {
    max-width: 57.063rem;
  }
`;

export const StyledHeaderLogoLinks = styled('div')`
  display: flex;
  align-items: center;
  column-gap: 1.5rem;

  img {
    transition: all 0.3s;
  }
`;

export const StyledCompanyLogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  opacity: 1;
  transition: 0.6s opacity 0.2s ease-out;

  > img {
    width: auto;
    height: 2rem;
  }
`;

export const StyledDaDirektLogo = styled('img')`
  width: 5rem;
  height: auto;
`;

export const StyledHeaderBurgerMenu = styled('div')`
  position: relative;
  z-index: 99;
  cursor: pointer;

  @media (min-width: ${MAX_WIDTH_SM}) {
    display: none;
  }
`;

export const StyledHeaderBurgerMenuButton = styled('div')(
  ({ theme, disabled }) => css`
    padding: 0.25rem 0.2rem;
    width: 2rem;
    height: 2rem;
    background-color: ${theme.palette.common.white};
    border: 2px solid ${theme.palette.gray.light_gray};
    border-radius: 0.125rem;

    ${disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `};
  `
);

export const StyledHeaderBurgerMenuLine = styled('div')(
  ({ theme, open }) => css`
    width: 100%;
    height: 0.25rem;
    background-color: ${theme.palette.main.professional_blue};
    transition:
      opacity 0.1s linear,
      transform 0.3s ease-in-out;

    & + & {
      margin-top: 0.25rem;
    }

    ${open &&
    css`
      &:first-child {
        transform: rotate(45deg) translate3d(5px, 5px, 0);
      }

      &:nth-child(2) {
        opacity: 0;
        transition-delay: 0.1s;
      }

      &:last-child {
        transform: rotate(-45deg) translate3d(6px, -6px, 0);
      }
    `};
  `
);

export const StyledMaintenanceBanner = styled('div')(
  ({ theme }) => css`
    background: ${theme.palette.main.product_blue};
    padding: 1rem;
  `
);

export const StyledMaintenanceBannerContent = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    margin: 0 auto;
    max-width: ${theme.shape.container};
  `
);
