import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import { CREDIT_CARD, PAYPAL, GOOGLEPAY, APPLEPAY, MASTERCARD, VISA } from '../../../shared';

import {
  MasterCardIcon,
  VisaIcon,
  PaypalIcon,
  GooglePayIcon,
  ApplePayIcon,
} from './DigitalPaymentsIcon';
import { StyledDigitalPaymentDetails } from './DigitalPayment.styled';

const DigitalPaymentInfo = (props) => {
  const { financialInstrument } = props;
  const { kind, cardDetails } = financialInstrument || {};

  const getCreditCardInfo = () => {
    const { expiryMonth, expiryYear, accountNumber, cardBrand: cardType } = cardDetails || {};

    const convertedExpiryMonth = Number.isInteger(expiryMonth)
      ? expiryMonth?.toString().padStart(2, '0')
      : '-';

    const convertedExpiryYear = Number.isInteger(expiryYear)
      ? expiryYear?.toString().padStart(4, '20')
      : '-';

    return { accountNumber, cardType, convertedExpiryMonth, convertedExpiryYear };
  };

  switch (kind) {
    case CREDIT_CARD: {
      const { cardType, accountNumber, convertedExpiryMonth, convertedExpiryYear } =
        getCreditCardInfo();

      return (
        <StyledDigitalPaymentDetails>
          <CreditCardIcon cardType={cardType} />

          <div>
            <Typography variant="p">{accountNumber}</Typography>

            <Typography variant="p">
              <FormattedMessage id="policy.details.account.digital.payment.expire" />
              {convertedExpiryMonth}/{convertedExpiryYear}
            </Typography>
          </div>
        </StyledDigitalPaymentDetails>
      );
    }

    case GOOGLEPAY:
      return (
        <StyledDigitalPaymentDetails>
          <GooglePayIcon />

          <Typography variant="p">
            <FormattedMessage id="policy.details.account.digital.payment.googlePay" />
          </Typography>
        </StyledDigitalPaymentDetails>
      );

    case APPLEPAY:
      return (
        <StyledDigitalPaymentDetails>
          <ApplePayIcon />

          <Typography variant="p">
            <FormattedMessage id="policy.details.account.digital.payment.applePay" />
          </Typography>
        </StyledDigitalPaymentDetails>
      );

    case PAYPAL:
      return (
        <StyledDigitalPaymentDetails>
          <PaypalIcon />

          <Typography variant="p">
            <FormattedMessage id="policy.details.account.digital.payment.paypal" />
          </Typography>
        </StyledDigitalPaymentDetails>
      );

    default:
      return null;
  }
};

export default DigitalPaymentInfo;

function CreditCardIcon({ cardType }) {
  if (cardType === VISA) {
    return <VisaIcon />;
  }

  if (cardType === MASTERCARD) {
    return <MasterCardIcon />;
  }

  return null;
}

DigitalPaymentInfo.propTypes = {
  financialInstrument: PropTypes.shape({
    kind: PropTypes.string.isRequired,
    cardDetails: PropTypes.shape({
      expiryMonth: PropTypes.number,
      expiryYear: PropTypes.number,
      accountNumber: PropTypes.string,
      cardBrand: PropTypes.string,
    }).isRequired,
  }),
};
