import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import useInsuranceInfo from '../../../hooks/useInsuranceInfo';
import {
  imgLinks,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  VITOLO_CROSS_SELLING_URL,
} from '../../../shared';

import {
  StyledSpacer,
  StyledVitoloCrossSellingCard,
  StyledVitoloCrossSellingCardButton,
  StyledVitoloCrossSellingCardText,
  StyledVitoloCrossSellingCardTitle,
} from './VitoloCrossSellingCard.styled';

const titleIdMap = {
  [INSURANCE_TYPE_DENTOLO]: 'promotion.carousel.vitolo.cross_selling.dentolo.title',
  [INSURANCE_TYPE_PETOLO]: 'promotion.carousel.vitolo.cross_selling.petolo.title',
};

const descriptionIdMap = {
  [INSURANCE_TYPE_DENTOLO]: 'promotion.carousel.vitolo.cross_selling.dentolo.description',
  [INSURANCE_TYPE_PETOLO]: 'promotion.carousel.vitolo.cross_selling.petolo.description',
};

const desktopImageMap = {
  [INSURANCE_TYPE_DENTOLO]: imgLinks['crossselling.dental.desktop'],
  [INSURANCE_TYPE_PETOLO]: imgLinks['crossselling.pet.desktop'],
};

const mobileImageMap = {
  [INSURANCE_TYPE_DENTOLO]: imgLinks['crossselling.dental.mobile'],
  [INSURANCE_TYPE_PETOLO]: imgLinks['crossselling.pet.mobile'],
};

function VitoloCrossSellingCard() {
  const { type: insuranceType } = useInsuranceInfo();

  const titleId = titleIdMap[`${insuranceType}`] || '';
  const descriptionId = descriptionIdMap[`${insuranceType}`] || '';
  const imgDesktop = desktopImageMap[`${insuranceType}`] || '';
  const imgMobile = mobileImageMap[`${insuranceType}`] || '';

  const renderUTag = (chunk) => <u>{chunk}</u>;

  return (
    <StyledVitoloCrossSellingCard imgDesktop={imgDesktop} imgMobile={imgMobile}>
      <StyledVitoloCrossSellingCardTitle>
        <Typography variant="h1" color="professional_blue">
          <FormattedMessage id={titleId} />
        </Typography>
      </StyledVitoloCrossSellingCardTitle>

      <StyledVitoloCrossSellingCardText>
        <Typography variant="p" color="professional_blue">
          <FormattedMessage
            id={descriptionId}
            values={{
              u: (chunk) => renderUTag(chunk),
            }}
          />
        </Typography>
      </StyledVitoloCrossSellingCardText>

      <StyledSpacer />

      <StyledVitoloCrossSellingCardButton
        component="a"
        href={VITOLO_CROSS_SELLING_URL}
        target="_blank"
        variant="cross_sell_vitolo"
      >
        <Typography variant="buttons">
          <FormattedMessage id="promotion.carousel.vitolo.cross_selling.petolo.button.label" />
        </Typography>
      </StyledVitoloCrossSellingCardButton>
    </StyledVitoloCrossSellingCard>
  );
}

export default VitoloCrossSellingCard;
