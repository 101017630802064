import React from 'react';
import { useQuery } from '@apollo/client';

import { GET_CUSTOMER } from '../shared';

import ErrorPlaceholder from '../features/errorPlaceholder';
import Dashboard from '../features/dashboard/Dashboard';

const Homepage = () => {
  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);

  if (!customerLoading && !!customerData) {
    return <Dashboard />;
  }

  if (!customerLoading) {
    return <ErrorPlaceholder />;
  }

  return null;
};

export default Homepage;
