import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill';

import { promiseWithRetry } from '../shared';
import { ACCOUNT_AREA_LOCALE } from '.';

async function polyfillReactIntl(locale) {
  if (shouldPolyfillGetCanonicalLocales()) {
    await promiseWithRetry(() => import('@formatjs/intl-getcanonicallocales/polyfill'));
  }

  if (shouldPolyfillLocale()) {
    await promiseWithRetry(() => import('@formatjs/intl-locale/polyfill'));
  }

  if (shouldPolyfillPluralRules()) {
    await promiseWithRetry(() => import('@formatjs/intl-pluralrules/polyfill'));
  }

  if (window?.Intl?.PluralRules?.polyfilled) {
    switch (locale) {
      case ACCOUNT_AREA_LOCALE.DE:
        await promiseWithRetry(() => import('@formatjs/intl-pluralrules/locale-data/de'));
        break;
      case ACCOUNT_AREA_LOCALE.FR:
        await promiseWithRetry(() => import('@formatjs/intl-pluralrules/locale-data/fr'));
        break;
      default:
        await promiseWithRetry(() => import('@formatjs/intl-pluralrules/locale-data/de'));
        break;
    }
  }

  if (shouldPolyfillNumberFormat()) {
    await promiseWithRetry(() => import('@formatjs/intl-numberformat/polyfill'));
  }

  if (window?.Intl?.NumberFormat?.polyfilled) {
    switch (locale) {
      case ACCOUNT_AREA_LOCALE.DE:
        await promiseWithRetry(() => import('@formatjs/intl-numberformat/locale-data/de'));
        break;
      case ACCOUNT_AREA_LOCALE.FR:
        await promiseWithRetry(() => import('@formatjs/intl-numberformat/locale-data/fr'));
        break;
      default:
        await promiseWithRetry(() => import('@formatjs/intl-numberformat/locale-data/de'));
        break;
    }
  }

  if (shouldPolyfillDateTimeFormat()) {
    await promiseWithRetry(() => import('@formatjs/intl-datetimeformat/polyfill'));
  }

  if (window?.Intl?.DateTimeFormat?.polyfilled) {
    const dataPolyfills = [
      promiseWithRetry(() => import('@formatjs/intl-datetimeformat/add-all-tz')),
    ];

    switch (locale) {
      case ACCOUNT_AREA_LOCALE.DE:
        dataPolyfills.push(
          promiseWithRetry(() => import('@formatjs/intl-datetimeformat/locale-data/de'))
        );
        break;
      case ACCOUNT_AREA_LOCALE.FR:
        dataPolyfills.push(
          promiseWithRetry(() => import('@formatjs/intl-datetimeformat/locale-data/fr'))
        );
        break;
      default:
        dataPolyfills.push(
          promiseWithRetry(() => import('@formatjs/intl-datetimeformat/locale-data/de'))
        );
        break;
    }
    await Promise.all(dataPolyfills);
  }
}

export default polyfillReactIntl;
