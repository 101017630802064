// eslint-disable-next-line import/prefer-default-export
export const SPACING = {
  'NONE': '0rem',
  'XSMALL': '0.25rem',
  'SMALL': '0.5rem',
  'MEDIUM': '0.75rem',
  'LARGE': '1rem',
  '1XL': '1.5rem',
  '2XL': '2rem',
  '3XL': '2.5rem',
  '4XL': '3rem',
  '5XL': '3.5rem',
  '6XL': '4rem',
  '7XL': '4.5rem',
};
