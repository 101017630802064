import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { parseFloatToCents, VITOLO_DATA_PROTECTION_CHECKBOX_LINK } from '../../../../shared';
import { useEditIcon } from '../../../../hooks/useIcons';
import useVitoloClaims from '../../useVitoloClaims';
import {
  VITOLO_DOCUMENT_DATE,
  VITOLO_DOCUMENT_TYPE,
  VITOLO_TREATMENT_AMOUNT,
  VITOLO_CUSTOMER_NOTE,
  useNewVitoloFormDataValue,
  VITOLO_TREATMENT_CATEGORY_OTHERS,
} from '../../claimFormData/newClaimFormData';
import {
  useEditDetailsLinkVitolo,
  useEditDocumentsLinkVitolo,
  useSubmitClaimFormVitoloBackButtonLink,
} from './submitClaimFormVitoloHooks';

import SubmitClaimForm from './SubmitClaimForm';

const SubmitClaimFormVitolo = (props) => {
  const { selectedDocuments, setSelectedDocuments, setClaimSubmitted } = props;

  const { claimId } = useParams();

  const intl = useIntl();

  const [submitVitoloClaim] = useVitoloClaims(claimId);

  const editIcon = useEditIcon();

  const treatmentCategoryTextMappingId =
    'vitolo.new_claim.submit_claim_form.treatment_category.other';

  const [documentType] = useNewVitoloFormDataValue(VITOLO_DOCUMENT_TYPE);
  const documentTypeMappingId = documentType
    ? `vitolo.new_claim.select_document_type.${documentType}`
    : '';

  const [documentDate] = useNewVitoloFormDataValue(VITOLO_DOCUMENT_DATE);
  const formattedDocumentDate = documentDate
    ? intl.formatDate(documentDate, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : '';

  const [treatmentAmount] = useNewVitoloFormDataValue(VITOLO_TREATMENT_AMOUNT);
  const formattedTreatmentAmount = treatmentAmount
    ? intl.formatNumber(treatmentAmount, { style: 'currency', currency: 'EUR' })
    : '';

  const [customerNote] = useNewVitoloFormDataValue(VITOLO_CUSTOMER_NOTE);
  const formattedCustomerNote = customerNote || '';

  const cardContent = [
    {
      label: 'vitolo.new_claim.submit_claim_form.card_label.document_type',
      value: documentTypeMappingId ? intl.formatMessage({ id: documentTypeMappingId }) : '',
    },
    {
      label: 'vitolo.new_claim.submit_claim_form.card_label.document_date',
      value: formattedDocumentDate,
    },

    {
      label: 'vitolo.new_claim.submit_claim_form.card_label.amount',
      value: formattedTreatmentAmount,
    },
    {
      label: 'vitolo.new_claim.submit_claim_form.card_label.treatment_answer',
      value: formattedCustomerNote,
    },
  ];

  const uploadClaimForm = () => {
    const validDocuments = Array.isArray(selectedDocuments)
      ? selectedDocuments.filter((d) => d?.valid)
      : [];

    const payload = {
      files: validDocuments.map((item) => item.file),
      amountCents: parseFloatToCents(treatmentAmount),
      documentType,
      lossDate: documentDate,
      treatmentStartDate: documentDate,
      treatmentInfo: customerNote,
      treatmentCategories: [VITOLO_TREATMENT_CATEGORY_OTHERS],
      dentistKey: null,
      dentistName: null,
      fillingsCount: null,
      claimId: claimId || null,
    };

    submitVitoloClaim({
      payload,
      setSelectedDocuments,
      setClaimSubmitted,
    });
  };

  const backButtonLink = useSubmitClaimFormVitoloBackButtonLink(claimId);
  const editDetailsButtonLink = useEditDetailsLinkVitolo(claimId);
  const editUploadedFilesLink = useEditDocumentsLinkVitolo(claimId);

  return (
    <SubmitClaimForm
      selectedDocuments={selectedDocuments}
      backButtonLink={backButtonLink}
      editDetailsButtonLink={editDetailsButtonLink}
      editUploadedFilesLink={editUploadedFilesLink}
      pageTitleId="vitolo.new_claim.submit_claim_form.page_title"
      editIcon={editIcon}
      formDataCardHeaderId={treatmentCategoryTextMappingId}
      cardContent={cardContent}
      uploadedFilesCardHeaderId="vitolo.new_claim.submit_claim_form.uploaded_files.title"
      uploadClaimForm={uploadClaimForm}
      submitButtonId="vitolo.new_claim.submit_claim_form.send_button"
      dataProtectionLink={VITOLO_DATA_PROTECTION_CHECKBOX_LINK}
    />
  );
};

export default SubmitClaimFormVitolo;
