import { styled, css, Paper } from '@mui/material';

export const StyledDentistDetailsModal = styled('div')(
  ({ theme }) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 60;
    background-color: ${theme.palette.background.viewport};
    overflow: auto;
  `
);

export const StyledDentistDetailsMobilePaper = styled(Paper)(
  ({ theme }) => css`
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 50;

    ${theme.breakpoints.up('desktop')} {
      display: none;
    }
  `
);

export const StyledDentistDetailsCardContainer = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacing('LARGE')};
  `
);

export const StyledDentistDetailsCardWrapper = styled('div')(
  ({ blurred }) => css`
    position: relative;

    ${blurred &&
    css`
      pointer-events: none;
      filter: blur(2px);
    `}
  `
);

export const StyledDentistDetailsCardHeader = styled('div')(
  ({ theme }) => css`
    align-items: center;
    column-gap: ${theme.spacing('LARGE')};
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing('LARGE')};
  `
);

export const StyledDentistDetailsAvatarContainer = styled('div')(
  ({ theme }) => css`
    align-items: center;
    column-gap: ${theme.spacing('LARGE')};
    display: flex;
    margin-bottom: ${theme.spacing('LARGE')};
    width: fit-content;
  `
);

export const StyledDentistDetailsAvatar = styled('img')(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.gray.ultra_light_gray};
    height: ${theme.spacing('5XL')};
    border-radius: 50%;
    width: ${theme.spacing('5XL')};
  `
);

export const StyledDentistDetailsContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('SMALL')};
  `
);

export const StyledDentistDetailsCardItem = styled('div')(
  ({ theme }) => css`
    align-items: center;
    column-gap: ${theme.spacing('SMALL')};
    display: flex;

    > img {
      width: ${theme.spacing('1XL')};
      height: ${theme.spacing('1XL')};
    }
  `
);

export const StyledDentistDetailsOpeningHoursContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding-left: ${theme.spacing('2XL')};
    row-gap: ${theme.spacing('SMALL')};
  `
);

export const StyledDentistDetailsOpeningHoursItem = styled('div')(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 0.4fr 1fr 1fr;
    column-gap: ${theme.spacing('SMALL')};
  `
);

export const StyledToggleDentistAsFavorite = styled('div')(
  ({ theme }) => css`
    align-items: center;
    column-gap: ${theme.spacing('SMALL')};
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing('1XL')};
  `
);

export const StyledBarLoaderWrapper = styled('div')`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;
