import { styled } from '@mui/material';

export const StyledCardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledCardContentLine = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledCheckBoxContainer = styled('div')`
  margin-top: 1.5em;
`;
