import { styled, css } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { MAX_WIDTH_MD, MIN_WIDTH_MD } from '../../../theme/breakpoints';

export const navMenuUnderlineBaseStyle = ({ theme }) => css`
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    bottom: 0.6rem;
    left: 0;
    right: 0;
    width: 100%;
    background-color: ${theme.palette.main.professional_light_blue};
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.2s ease-out;
  }
`;

export const navMenuUnderlineActiveBaseStyle = css`
  &:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

export const navMenuBaseStyle = ({ theme }) => css`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 700;
  color: ${theme.palette.main.professional_blue};
  text-decoration: none;
  box-shadow: none;
  transition: color 0.25s ease-out;

  ${navMenuUnderlineBaseStyle({ theme })};

  &.active,
  &:hover,
  &:focus,
  &:active {
    color: ${theme.palette.main.professional_light_blue};
  }

  @media (max-width: ${MAX_WIDTH_MD}) {
    font-size: 1rem;
  }
`;

// ===  BASE styles above this line  ===

export const StyledNavMenuDesktop = styled('section')(
  ({ theme }) => css`
    position: relative;
    height: 48px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    z-index: 50;
    background-color: ${theme.palette.common.white};
    color: ${theme.palette.main.professional_blue};
    display: none;

    @media (min-width: ${MIN_WIDTH_MD}) {
      display: block;
    }
  `
);

export const StyledNavMenuDesktopContainer = styled('nav')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  column-gap: 1rem;
  margin: 0 auto;
  max-width: 57.0625rem;
`;

export const StyledNavLink = styled(NavLink)`
  ${navMenuBaseStyle};

  position: relative;
  height: 100%;

  &.active,
  &:focus,
  &:active {
    &:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`;

export const StyledNavExternalLink = styled('a')`
  ${navMenuBaseStyle};

  position: relative;
  height: 100%;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.25rem;

  &.active,
  &:focus,
  &:active {
    &:after {
      display: none;
    }
  }
`;
