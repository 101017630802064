import { css, styled } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const StyledDownloadLink = styled('div')(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing('LARGE')} ${theme.spacing('NONE')};

    &:first-of-type {
      padding-top: ${theme.spacing('NONE')};
    }

    &:last-of-type {
      padding-bottom: ${theme.spacing('NONE')};
    }

    & + & {
      border-top: 1px solid ${theme.palette.gray.light_gray};
    }
  `
);
