import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, DialogActions, DialogContent, TextField, Typography } from '@mui/material';

import useInsuranceInfo from '../../../hooks/useInsuranceInfo';
import useForm from '../../../shared/useForm';
import { GET_CUSTOMER, UPDATE_INSURED_PERSON_DETAILS } from '../../../shared';

import { Radio, RadioLabel } from '../../../components/common';
import { ErrorMessage } from '../../../components/common/FormElements';

import {
  StyledForm,
  StyledFormLegend,
  StyledRadioInputAndLabel,
  StyledInputGroup,
  StyledBigInputContainer,
  StyledSmallInputContainer,
  StyledVerticalContent,
} from './CustomerDetailsEditor.styled';

function validateForm(values) {
  const errors = {};

  if (!values?.streetName) {
    errors.streetName = 'insured.person.details.form.address.error.message';
  }

  if (!values?.houseNumber) {
    errors.houseNumber = 'insured.person.details.form.address.error.message';
  }

  if (!values?.postcode || !(values?.postcode.length === 5)) {
    errors.postcode = 'insured.person.details.form.address.error.message';
  }

  if (!values?.city) {
    errors.city = 'insured.person.details.form.address.error.message';
  }

  return errors;
}

export default function InsuredPersonDetailsEditor({ data, onSuccess, onError }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const intl = useIntl();

  const {
    values,
    errors: formErrors,
    isDirty,
    handleChange,
    handleSubmit,
  } = useForm(data, handleFormSubmit, validateForm);

  const { firstName, lastName, ...otherValues } = values;

  const [updateInsuredPersonDetails] = useMutation(UPDATE_INSURED_PERSON_DETAILS, {
    variables: {
      ...otherValues,
    },
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  async function handleFormSubmit(ev) {
    if (ev) ev.preventDefault();

    try {
      setIsProcessing(true);

      const res = await updateInsuredPersonDetails();
      const { insuredPerson } = res?.data || {};

      if (insuredPerson?.status !== 'success') throw new Error(insuredPerson?.errors);

      onSuccess();
    } catch (err) {
      onError();
    } finally {
      setIsProcessing(false);
    }
  }

  const isBtnDisabled = !isDirty || Object.keys(formErrors).length > 0 || isProcessing;
  const { type: insuranceType } = useInsuranceInfo();

  return (
    <>
      <DialogContent>
        <StyledForm onSubmit={handleSubmit} id="insured_person_details_editor_form">
          <StyledVerticalContent>
            <fieldset>
              <StyledFormLegend>
                <Typography variant="h3">
                  <FormattedMessage id="insured.person.details.form.gender.label" />
                </Typography>
              </StyledFormLegend>

              <StyledInputGroup>
                <StyledRadioInputAndLabel>
                  <Radio
                    id="radio_gender_male"
                    name="gender"
                    onChange={(e) => {
                      e.stopPropagation();
                      handleChange(e);
                    }}
                    value="male"
                    checked={values?.gender === 'male'}
                  />

                  <RadioLabel htmlFor="radio_gender_male">
                    <Typography variant="p">
                      <FormattedMessage id="insured.person.details.form.gender.male.label" />
                    </Typography>
                  </RadioLabel>
                </StyledRadioInputAndLabel>

                <StyledRadioInputAndLabel>
                  <Radio
                    id="radio_gender_female"
                    name="gender"
                    onChange={(e) => {
                      e.stopPropagation();
                      handleChange(e);
                    }}
                    value="female"
                    checked={values?.gender === 'female'}
                  />

                  <RadioLabel htmlFor="radio_gender_female">
                    <Typography variant="p">
                      <FormattedMessage id="insured.person.details.form.gender.female.label" />
                    </Typography>
                  </RadioLabel>
                </StyledRadioInputAndLabel>
              </StyledInputGroup>
            </fieldset>

            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              placeholder="Alex"
              label={
                <Typography variant="h3" component="span">
                  <FormattedMessage id="common.first.name" />
                </Typography>
              }
              value={values?.firstName || ''}
              disabled
            />

            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              placeholder="Müller"
              label={
                <Typography variant="h3" component="span">
                  <FormattedMessage id="common.last.name" />
                </Typography>
              }
              value={values?.lastName || ''}
              disabled
            />

            <fieldset>
              <StyledFormLegend>
                <Typography variant="h3">
                  <FormattedMessage id="insured.person.details.form.street.house.number.label" />
                </Typography>
              </StyledFormLegend>

              <StyledInputGroup>
                <StyledBigInputContainer>
                  <TextField
                    fullWidth
                    id="streetName"
                    name="streetName"
                    placeholder={intl.formatMessage({ id: 'placeholder.contact.street' })}
                    value={values?.streetName || ''}
                    onChange={handleChange}
                  />
                </StyledBigInputContainer>

                <StyledSmallInputContainer>
                  <TextField
                    fullWidth
                    id="houseNumber"
                    name="houseNumber"
                    placeholder="5"
                    value={values?.houseNumber || ''}
                    onChange={handleChange}
                  />
                </StyledSmallInputContainer>
              </StyledInputGroup>
            </fieldset>

            <fieldset>
              <StyledFormLegend>
                <Typography variant="h3">
                  <FormattedMessage id="insured.person.details.form.postcode.city.label" />
                </Typography>
              </StyledFormLegend>

              <StyledInputGroup>
                <StyledSmallInputContainer>
                  <TextField
                    fullWidth
                    id="postcode"
                    name="postcode"
                    placeholder="10234"
                    value={values?.postcode || ''}
                    onChange={handleChange}
                  />
                </StyledSmallInputContainer>

                <StyledBigInputContainer>
                  <TextField
                    fullWidth
                    id="city"
                    name="city"
                    placeholder={intl.formatMessage({ id: 'placeholder.account.city' })}
                    value={values?.city || ''}
                    onChange={handleChange}
                  />
                </StyledBigInputContainer>
              </StyledInputGroup>

              {(formErrors?.streetName ||
                formErrors?.houseNumber ||
                formErrors?.postcode ||
                formErrors?.city) && (
                <ErrorMessage>
                  <Typography variant="p" color="error">
                    <FormattedMessage id="insured.person.details.form.address.error.message" />
                  </Typography>
                </ErrorMessage>
              )}
            </fieldset>

            <Typography variant="p">
              <FormattedMessage id={`personal.details.form.additional.details.${insuranceType}`} />
            </Typography>
          </StyledVerticalContent>
        </StyledForm>
      </DialogContent>

      <DialogActions>
        <Button type="submit" form="insured_person_details_editor_form" disabled={isBtnDisabled}>
          <Typography variant="buttons">
            <FormattedMessage id="insured.person.details.form.submit.button" />
          </Typography>
        </Button>
      </DialogActions>
    </>
  );
}
