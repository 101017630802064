import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { getInsuredPersonName } from '../../utils/customerUtils';
import {
  formatDate,
  formatPolicyName,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  contractInTrialMonth,
  showMoney,
} from '../../shared';

import PolicyAndAddonName from './PolicyAndAddonName';
import { Paper } from '../common';
import {
  StyledPolicyCardItem,
  StyledPolicyCardText,
} from '../../features/dashboard/Dashboard.styled';

const MonthlyPremium = ({ customer, shouldFormatPolicyName, monthlyPremium, addons }) => {
  const intl = useIntl();

  if (!!addons && !!addons?.title) {
    const addOnTitle = addons?.title || '';
    const addOnPrice = addons?.price || 0;
    const premiumPrice = monthlyPremium - addOnPrice;
    const { policyName } = customer?.contract || {};
    const policyNameToUse = shouldFormatPolicyName
      ? intl.formatMessage({ id: `policies.${formatPolicyName(policyName)}` })
      : policyName;

    return (
      <>
        <StyledPolicyCardText>
          <Typography variant="p" component="span">
            {showMoney(premiumPrice)}
          </Typography>

          <Typography variant="p" component="span">
            {policyNameToUse}
          </Typography>
        </StyledPolicyCardText>

        <StyledPolicyCardText>
          <Typography variant="p" component="span">
            {showMoney(addOnPrice)}
          </Typography>

          <Typography variant="p" component="span">
            {addOnTitle}
          </Typography>
        </StyledPolicyCardText>
      </>
    );
  }

  return (
    <StyledPolicyCardText>
      <Typography variant="p">{showMoney(monthlyPremium)}</Typography>{' '}
    </StyledPolicyCardText>
  );
};

const PolicyCard = ({ customer, addons, contractStatusInfo }) => {
  const { type: insuranceType } = useInsuranceInfo();
  const isDentolo = insuranceType === INSURANCE_TYPE_DENTOLO;
  const isPetolo = insuranceType === INSURANCE_TYPE_PETOLO;

  const { date: contractDate, title: contractStatusTitle } = contractStatusInfo || {};
  const { name: petName } = customer?.insuredPet || {};

  const addonTrialMonth = customer?.contract?.addOnTrialMonth;
  const showTrialMonth =
    contractInTrialMonth(
      addonTrialMonth?.status,
      addonTrialMonth?.startingAt,
      customer?.contract?.startingAt
    ) ||
    (addonTrialMonth?.status === 'active' && dayjs(addonTrialMonth?.startingAt).isAfter(dayjs()));

  if (!customer?.contract) return null;

  return (
    <Paper hasShadow={false} filled style={{ marginBottom: '1rem' }}>
      <StyledPolicyCardItem>
        <Typography variant="h3" color="white" data-hj-suppress="true">
          {getInsuredPersonName(customer)}
        </Typography>

        <Typography variant="h3" color="white" data-hj-suppress="true">
          {customer?.contract?.key}
        </Typography>
      </StyledPolicyCardItem>

      {isPetolo && (
        <StyledPolicyCardItem>
          <Typography variant="h3">
            <FormattedMessage id="policy.card.label.pet.name" />
          </Typography>

          <StyledPolicyCardText>
            <Typography variant="h3">{petName || ''}</Typography>
          </StyledPolicyCardText>
        </StyledPolicyCardItem>
      )}

      <StyledPolicyCardItem>
        <Typography variant="h3" color="white">
          <FormattedMessage id="policy.card.label.policy" />
        </Typography>

        <StyledPolicyCardText>
          <PolicyAndAddonName
            customer={customer}
            addons={addons}
            shouldFormatPolicyName={isDentolo}
          />
        </StyledPolicyCardText>
      </StyledPolicyCardItem>

      {!showTrialMonth && contractDate && contractStatusTitle && (
        <StyledPolicyCardItem>
          <Typography variant="h3" color="white">
            {contractStatusTitle}
          </Typography>

          <StyledPolicyCardText>
            <Typography variant="p">{contractDate}</Typography>
          </StyledPolicyCardText>
        </StyledPolicyCardItem>
      )}

      {customer?.contract?.status !== 'ended' && (
        <StyledPolicyCardItem>
          <Typography variant="h3" color="white">
            <FormattedMessage id="policy.card.label.monthly_premium" />
          </Typography>

          <MonthlyPremium
            customer={customer}
            shouldFormatPolicyName={isDentolo}
            monthlyPremium={customer?.contract?.monthlyPremium}
            addons={addons}
          />
        </StyledPolicyCardItem>
      )}

      {showTrialMonth && (
        <StyledPolicyCardItem>
          <Typography variant="h3" color="white">
            <FormattedMessage id="policy.card.label.addon.trial_month" />
          </Typography>
          <StyledPolicyCardText>
            <Typography variant="p">{formatDate(addonTrialMonth?.startingAt)}</Typography>
          </StyledPolicyCardText>
        </StyledPolicyCardItem>
      )}
    </Paper>
  );
};

export default PolicyCard;

PolicyCard.propTypes = {
  customer: PropTypes.shape({
    contract: PropTypes.shape({
      key: PropTypes.string,
      monthlyPremium: PropTypes.string,
      policyName: PropTypes.string,
      startingAt: PropTypes.string,
      status: PropTypes.string,
      addOnTrialMonth: PropTypes.shape({
        status: PropTypes.string,
        startingAt: PropTypes.string,
      }),
    }),
    insuredPet: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  addons: PropTypes.shape({
    price: PropTypes.number,
    title: PropTypes.string,
  }),
  contractStatusInfo: PropTypes.shape({
    date: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
};
