import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const JOIN_REFERRAL_PROGRAM = gql`
  mutation {
    joinReferralProgram(input: {}) {
      referralCode
      referralJoined
      referralJoinedAt
      errors
    }
  }
`;
