import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Typography } from '@mui/material';

import {
  USER_CLAIMS_ONBOARD_KEY,
  GET_CLAIMS,
  GET_PAYMENT,
  CLEAR_CLAIM_NOTIFICATION,
  claimStatus,
} from '../shared';

import { Link } from './ui';
import { Notification } from './common';
import { StyledNotificationText } from './styled/Notification.styled';

const claimStatusMapping = {
  [claimStatus.NEW]: {
    type: 'info',
    heading: 'Bearbeitung gestartet',
    content: 'Wir kümmern uns so schnell wie möglich um Ihre Kostenerstattung',
  },
  [claimStatus.WAITING_FOR_INFORMATION]: {
    type: 'warning',
    heading: 'Weitere Unterlagen',
    content: 'Für Ihre Kostenerstattung benötigen wir noch weitere Dokumente',
  },
  [claimStatus.REIMBURSEMENT_ACKNOWLEDGED]: {
    type: 'success',
    heading: 'Erstattung zugesagt',
    content: 'Wir erstatten die Kosten Ihrer Behandlung',
  },
  [claimStatus.PARTIALLY_CLOSED]: {
    type: 'success',
    heading: 'Erstattung erfolgt',
    content: 'Das Geld ist unterwegs auf Ihr Konto',
  },
  [claimStatus.BLOCKED]: {
    type: 'warning',
    heading: 'Bearbeitung pausiert',
    content: 'Klicken Sie hier, um mehr zu erfahren',
  },
  [claimStatus.CLOSED]: {
    type: 'success',
    heading: 'Erstattung erfolgt',
    content: 'Das Geld ist unterwegs auf Ihr Konto',
  },
  [claimStatus.DECLINED]: {
    type: 'processing',
    heading: 'Erstattung abgelehnt',
    content: 'Leider können wir Ihre Behandlung nicht erstatten',
  },
};

const skipUserClaimsOnboarding = () => {
  if (window?.localStorage) {
    window.localStorage.setItem(USER_CLAIMS_ONBOARD_KEY, 'skip');
  }
};

const ClaimStatusNotifications = () => {
  const [notificationItems, setNotificationItems] = useState([]);
  const [clearClaimNotification] = useMutation(CLEAR_CLAIM_NOTIFICATION, {
    refetchQueries: [{ query: GET_CLAIMS }],
  });

  const clearNotification = (claim) => {
    const newNotificationItems = notificationItems.filter((c) => c?.key !== claim?.key);

    if (claim?.id) {
      clearClaimNotification({
        variables: {
          claimId: claim?.id,
        },
      });
    }

    setNotificationItems(newNotificationItems);
  };

  const getPayments = useQuery(GET_PAYMENT);
  const payments = getPayments?.data?.payments;
  const hasFailedPayments = Array.isArray(payments)
    ? payments.find((item) => item?.status === 'failed')
    : null;

  const getClaims = useQuery(GET_CLAIMS);

  useEffect(() => {
    const claims = getClaims?.data?.claims || [];

    if (!hasFailedPayments && claims.length > 0) {
      const newClaims = claims.filter((c) => !!c?.statusUpdated);

      const waitingForDocuments = newClaims.find(
        (c) => c?.status === claimStatus.WAITING_FOR_INFORMATION
      );
      const completed = newClaims.find((c) => c?.status === claimStatus.CLOSED);
      const reimbursementPromised = newClaims.find(
        (c) => c?.status === claimStatus.REIMBURSEMENT_ACKNOWLEDGED
      );
      const partialPayment = newClaims.find((c) => c?.status === claimStatus.PARTIALLY_CLOSED);
      const declined = newClaims.find((c) => c?.status === claimStatus.DECLINED);
      const newlyCreated = newClaims.find((c) => c?.status === claimStatus.NEW);
      const blocked = newClaims.find((c) => c?.status === claimStatus.BLOCKED);

      const claimsInSortedOrder = [
        waitingForDocuments,
        completed,
        reimbursementPromised,
        partialPayment,
        declined,
        newlyCreated,
        blocked,
      ];
      const filteredClaims = claimsInSortedOrder.filter((c) => !!c);
      setNotificationItems(filteredClaims);
    } else {
      setNotificationItems([]);
    }
  }, [getClaims, hasFailedPayments]);

  return (
    <>
      {notificationItems.slice(0, 2).map((claim) => {
        const { id, status } = claim || {};

        const statusContent = claimStatusMapping[`${status}`];
        const dismiss = () => clearNotification(claim);

        return (
          <Notification type={statusContent?.type} onDismiss={dismiss} key={id}>
            <Link
              variant="nostyle"
              to={`/user-claims-info/${id}`}
              onClick={skipUserClaimsOnboarding}
            >
              <StyledNotificationText $title>
                <Typography variant="h3">{statusContent?.heading}</Typography>
              </StyledNotificationText>

              <StyledNotificationText>
                <Typography variant="p">{statusContent?.content}</Typography>
              </StyledNotificationText>
            </Link>
          </Notification>
        );
      })}
    </>
  );
};

export default ClaimStatusNotifications;
