import { styled, css } from '@mui/material';
import { Tag } from '../../../components/common';

export const StyledDigitalPaymentStatus = styled(Tag)(
  ({ theme }) => css`
    width: fit-content;
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.main.product_blue};
  `
);

export const StyledDigitalPaymentDetails = styled('div')(
  ({ theme }) => css`
    align-items: center;
    column-gap: ${theme.spacing('SMALL')};
    display: flex;
  `
);
