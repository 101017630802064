import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Button, Container, Link, Typography } from '@mui/material';

import { imgLinks, useReady } from '../../../shared';
import { PageTitle } from '../../../components/common';

import FirstVetPromoSlider from './FirstVetPromoSlider';

import { StyledOnlineVetCoverImage, StyledOnlineVetFlexContainer } from '../OnlineVets.styled';

const FirstVet = () => {
  const ready = useReady();
  const firstvetAccount = 'https://account.firstvet.com/de?insurance=petolo';
  const googlePlayLink =
    'https://play.google.com/store/apps/details?id=com.firstvet.firstvetlocalized&hl=de&gl=US';
  const appleStoreLink = 'https://apps.apple.com/app/id1349241101';

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <PageTitle>
          <Typography variant="h1">
            <FormattedMessage id="first.vet.online.vets" />
          </Typography>
        </PageTitle>

        <StyledOnlineVetFlexContainer>
          <StyledOnlineVetCoverImage src={imgLinks['icon.petolo.firstvet']} alt="FirstVet" />

          <Button onClick={() => window.open(firstvetAccount, '_blank')}>
            <Typography variant="buttons">
              <FormattedMessage id="first.vet.go.to.link" />
            </Typography>
          </Button>
        </StyledOnlineVetFlexContainer>

        <FirstVetPromoSlider />

        <StyledOnlineVetFlexContainer>
          <Typography variant="p" style={{ marginTop: '2.5rem', marginBottom: '1rem' }}>
            <FormattedMessage id="first.vet.app.find" />
          </Typography>

          <Link href={googlePlayLink} target="_blank" rel="noopener noreferrer">
            <img src={imgLinks['icon.google.play']} style={{ width: '11rem' }} alt="Google Play" />
          </Link>

          <Link href={appleStoreLink} target="_blank" rel="noopener noreferrer">
            <img src={imgLinks['icon.apple.store']} style={{ width: '10rem' }} alt="Apple Store" />
          </Link>
        </StyledOnlineVetFlexContainer>
      </Container>
    </CSSTransition>
  );
};

export default FirstVet;
