import 'dayjs/locale/en';
import {
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
  SUBJECTS_ENUMS,
} from '../shared';
import { CLAIM_STATUS_DETAILS } from '../shared/claimStatus';

const en = {
  // Common
  'common.button.back.home': 'Zur Startseite',
  'common.button.back': 'Zurück',
  'common.button.next': 'Weiter',
  'common.button.yes': 'Ja',
  'common.button.no': 'Nein',
  'common.button.update': 'Bearbeiten',
  'common.button.upload.invoice': 'Rechnungen hochladen',
  'common.button.close': 'Schließen',
  'common.button.upload.document': 'Dokumente hochladen',
  'common.title.history': 'Historie',
  'common.title.status': 'Status',
  'common.first.name': 'Vorname',
  'common.last.name': 'Nachname',
  'common.title.imprint': 'Impressum',
  'common.title.data.protection': 'Datenschutz',
  'common.title.help': 'Hilfe',
  'common.title.cookie.settings': 'Cookie Einstellungen',

  'common.form.error.invalid_amount': 'Bitte geben Sie einen gültiges Betrag ein',
  'common.form.error.invalid_time': 'Bitte geben Sie eine gültige Zeit ein',
  'common.form.error.invalid_email': 'Bitte geben Sie eine gültige Email-Adresse ein',

  // Menu
  'menu.header.item.my_profile': 'Mein Profil',
  'menu.header.item.reimbursement': 'Kostenerstattungen',
  'menu.header.item.claim_report': 'Schadenmeldungen',
  'menu.header.item.find_dentist': 'Zahnärzt:innen finden',
  'menu.header.item.find_vet': 'Online Tierärzt:innen',
  'menu.header.item.referrals': 'Freund:innen werben',
  'menu.header.item.visit_dentolo': 'Besuchen Sie dentolo.de',
  'menu.header.item.help': 'Hilfe',

  'menu.header.item.dashboard': 'Dashboard',
  'menu.header.item.tarif': 'Tarifdetails',
  'menu.header.item.contact_details': 'Vertragsmanagement',
  'menu.header.item.docs': 'Vertragsunterlagen',
  'menu.header.item.premiums': 'Versicherungsbeiträge',
  'menu.header.item.contribution_statement': 'Beitragsbescheinigung',
  'menu.header.item.help_center': 'Hilfe-Center',
  'menu.header.item.contacts': 'Kontakt',

  // Contact
  'contact.page.main.title': 'Kontakt',
  'contact.page.main.description':
    'Bei Fragen oder Problemen, können Sie unserem Expertenteam jederzeit eine Nachricht senden.',
  'contact.page.main.phone':
    'In dringenden Fällen oder bei komplexen Sachverhalten können Sie uns telefonisch erreichen.',
  'contact.page.main.open_hours': 'Montag - Freitag: 09:00 - 18:00 Uhr',
  'contact.page.main.form.compose': 'Nachricht verfassen',
  'contact.page.main.form.sender': 'Absender:',
  'contact.page.main.form.reference': 'Betreff: ',
  'contact.page.main.form.message': 'Ihre Nachricht',
  'contact.page.main.form.send': 'Absenden',
  'contact.page.main.sent': 'Ihre Nachricht wurde erfolgreich versendet.',
  'contact.page.main.register.email':
    'Wir werden uns so bald wie möglich per E-Mail bei Ihnen melden.',
  'contact.page.main.back': 'Zurück zur Startseite',
  'contact.page.main.fail': 'Fehler! <br /> Es gab ein Problem beim Versenden der Nachricht.',
  'contact.page.main.cancel': 'Abbrechen',
  'contact.page.main.try.again': 'Erneut versuchen',
  'contact.page.main.cancellation.text':
    'Wenn Sie Ihren Vertrag kündigen oder widerrufen wollen, können Sie dies <a>hier</a> selbst durchführen.',
  'contact.page.form.success.text.referral.dadirekt':
    'Ihre Anfrage wurde an den DA Direkt Kundenservice weitergeleitet.',
  'contact.page.form.banner.subject.referral.dadirekt.info':
    ' Bitte beachten Sie, dass alle Anfragen bezüglich Ihrer Teilnahme am Freund:innen werben-Programm durch den DA Direkt Kundenservice bearbeitet werden. {br} <a>Hier</a> können Sie erfahren, wie das DA Direkt Werbeprogramm funktioniert.',
  'contact.tip.title': 'TIPP:',
  'contact.tip.contract.text': 'Weitere Informationen finden Sie in unserem',
  'contact.tip.contract.link': 'Hilfecenter.',
  'contact.tip.fee.text': 'Einen Überblick zu Ihren Beitragszahlungen finden Sie',
  'contact.tip.fee.link': 'hier',
  'contact.tip.reimbursement.text':
    'Um eine neue Erstattung anzufragen oder Details zu bestehenden Leistungsfällen einzusehen, finden Sie hier eine',
  'contact.tip.reimbursement.link': 'Übersicht.',
  'contact.tip.dentists.text': 'Finden Sie die nächstgelegenen dentolo-Zahnärzt:innen in unserem',
  'contact.tip.dentists.link': 'Zahnarztnetzwerk',
  'contact.tip.tarif.text': 'Alle Ihre Vertragsbedingungen finden Sie in',
  'contact.tip.tarif.link': 'Ihren Versicherungsunterlagen.',
  'contact.tip.change.contacts.text': 'Um persönliche Daten zu aktualisieren, klicken Sie',
  'contact.tip.change.contacts.link': 'hier.',
  'contact.tip.referral.text':
    'Alles zu unserem Empfehlungsprogramm und zum Status Ihrer aktuellen Empfehlungen finden Sie',
  'contact.tip.referral.link': 'hier.',
  'contact.tip.other.text': 'Weitere Informationen finden Sie in unserem ',
  'contact.tip.other.link': 'Hilfecenter.',
  [`contact.page.menu.${SUBJECTS_ENUMS.CONTRACT_QUESTIONS}`]: 'Fragen zum Vertrag',
  [`contact.page.menu.${SUBJECTS_ENUMS.MONTHLY_PAYMENT}`]: 'Monatsbeitrag',
  [`contact.page.menu.${SUBJECTS_ENUMS.REIMBURSEMENT_QUESTIONS}`]:
    'Fragen zur Erstattung oder Auszahlung',
  [`contact.page.menu.${SUBJECTS_ENUMS.COVERAGE_BY_PLANS}`]: 'Abdeckung durch Tarife',
  [`contact.page.menu.${SUBJECTS_ENUMS.CONTACT_CONTRACT_CHANGES}`]:
    'Änderung von Kontakt- oder Vertragsdaten',
  [`contact.page.menu.${SUBJECTS_ENUMS.DENTOLO_DENTISTS}`]: 'dentolo Zahnärzte',
  [`contact.page.menu.${SUBJECTS_ENUMS.REFERRAL_QUESTIONS}`]:
    'Fragen zum Freund:innen Werbeprogramm',
  [`contact.page.menu.${SUBJECTS_ENUMS.OTHER}`]: 'Sonstiges',

  // Dashboard
  'dashbaord.title': 'Dashboard',
  'policy.info.your.policy': 'Ihr {policy} auf einen Blick',
  'policy.info.policy.details': 'Details zum Tarif',
  'policy.info.policy.status.title': 'Vertragsstatus',
  'policy.info.policy.status.accepted': 'Aktiv',
  'policy.info.policy.status.active': 'Aktiv',
  'policy.info.policy.status.pending': 'Pending',
  'policy.info.policy.status.ended': 'Beendet',
  'policy.info.policy.active.now-or-past': 'Schutz aktiv seit',
  'policy.info.policy.active.future': 'Schutz aktiv ab',
  'policy.info.policy.active.canceled': 'Schutz aktiv bis',
  'policy.info.policy.montly.price': 'Monatliche Prämie',
  'policy.info.my.claims': 'Meine Leistungsfälle',
  'policy.info.new.claim.description':
    'Sie möchten einen neuen Leistungsfall einreichen oder Unterlagen zu einem bestehenden hinzufügen?',
  'policy.info.document.notification.submitted': 'Unterlagen erhalten',
  'policy.info.document.notification.description':
    'Aktuell werden Ihre Unterlagen bearbeitet. Sie werden per E-Mail benachrichtigt, sobald Sie weitere Details einsehen können.',
  'policy.info.see.contracts': 'Vertragsunterlagen ansehen',
  'policy.info.your_contact_details': 'Ihre Kontaktdaten',
  'policy.info.your_contract_documents': 'Ihre Vertragsunterlagen',
  'policy.info.your_claims': 'Ihre Leistungsfälle',
  'policy.info.your_payments': 'Ihre Versicherungsbeiträge',
  'policy.cancellation.text': 'Klicken Sie <a>hier</a>, wenn Sie Ihren Vertrag kündigen möchten.',

  'policy.info.missing.iban.title': 'Für besonders schnelle Rückerstattungen',
  'policy.info.missing.iban.message':
    'Geben Sie für eine noch schnellere Abwicklung <a>hier Ihre IBAN</a> an',
  'bank.details.add.missed.iban.modal.title':
    'Bitte geben Sie eine gültige IBAN für Ihre Rückerstattungen an',
  'bank.details.add.missed.iban.modal.desc': `Beachten Sie, dass somit alle Rückerstattungen an die neuen Kontodaten überwiesen werden. Bitte prüfen Sie, ob Ihre Kontodaten korrekt sind, um einen reibungslosen Zahlungsvorgang gewährleisten zu können.`,
  // Policy Card
  'policy.card.label.name': 'Name',
  'policy.card.label.policy': 'Tarif',
  'policy.card.label.pet.name': 'Ihr Haustier',
  'policy.card.label.addon': 'AKUT-Soforthilfe',
  'policy.card.label.contract_number': 'Versichertennummer',
  'policy.card.label.monthly_premium': 'Monatlicher Beitrag',
  'policy.card.label.account_number': 'Kundennummer',
  'policy.card.contact.and.billing.info': 'Kontakt- und Rechnungsinformationen',
  'policy.card.label.addon.trial_month': 'Probemonat',
  'policy.card.addon.trial_month.from': 'Ab',

  // Policy Covergae Info
  'policy.covergae_info.title': 'Tarifdetails',
  'policy.covergae_info.additional_advantages.title': 'Weitere Vorteile',
  'policy.covergae_info.all_details.button': 'Alle Leistungen im Detail',

  // Policy All Details
  'policy.all_details.title': 'Ihr Schutz im Detail',
  'policy.all_details.conditions.title': 'Rahmenbedingungen',
  'policy.all_details.premium.title': 'Prämienstaffelung',
  'policy.all_details.budget.prefix': 'Im',
  'policy.all_details.budget.last_prefix': 'Ab dem',
  'policy.all_details.budget.only_prefix': 'Ab',
  'policy.all_details.budget.suffix': 'Jahr',
  'policy.all_details.budget.unlimited': 'unbegrenzt',
  'policy.all_details.premium.years': 'Jahre',

  // Policy Personal Details
  'policy.details.policy_management.title': 'Vertragsmanagement',
  'policy.details.policy_management.description':
    'Sie können auf den Stift klicken, um eine Änderung vorzunehmen. ',
  'policy.details.my.contact.info': 'Meine Kontaktdaten',
  'policy.details.contact.info.name': 'Name',
  'policy.details.contact.info.email': 'Email',
  'policy.details.contact.info.phone': 'Telefon',
  'policy.details.contact.info.address': 'Adresse',
  'policy.details.contact.info.dob': 'Geburtstag',
  'policy.details.contact.info.address_details': 'Adresszusatz',
  'policy.details.my.billing.info': 'Meine Kontoverbindung',
  'policy.details.account.billing.desc':
    'Auf folgendem Konto werden die monatlichen Prämien abgebucht sowie Erstattungen ausgezahlt.',
  'policy.details.billing.info.account_holder': 'Kontoinhaber',
  'policy.details.billing.info.iban': 'IBAN',
  'policy.details.billing.info.billing_day': 'Abbuchung des Beitrags',
  'policy.details.contact_us.text.person':
    'Wollen Sie die versicherte Person ändern oder einen weiteren Vertrag abschließen? Haben Sie noch Fragen oder wollen Sie weitere Daten ändern?',
  'policy.details.contact_us.text.pet':
    'Wollen Sie das versicherte Tier ändern oder einen weiteren Vertrag abschließen? Haben Sie noch Fragen oder wollen Sie weitere Daten ändern?',
  'policy.details.contact.us': 'Kontaktieren Sie uns einfach.',
  'policy.details.to.contact': 'Zum Kundendienst',
  'policy.details.billing_day_1': 'Zum 15. des Monats',
  'policy.details.billing_day_15': 'Zum 15. des Monats',
  'policy.details.insured.person.info.title': 'Versicherte Person',
  'policy.details.insured.person.info.name': 'Name',
  'policy.details.insured.person.info.address': 'Adresse',
  'policy.details.pet.info.title': 'Versichertes Tier',
  'policy.details.pet.info.name': 'Name',
  'policy.details.pet.info.transponder_code': 'Transpondercode',
  'policy.details.pet.info.transponder_code.missing': 'Kein Transpondercode vorhanden',

  // Digital payment
  'policy.details.my.refund_account.info': 'Ihr Konto für Erstattungen',
  'policy.details.my.refund_account.desc':
    'Die Kostenerstattungen werden auf das folgende Konto ausgezahlt',
  'bank.details.edit_refund_account.modal.title': 'Änderung des Erstattungskontos',
  'bank.details.edit_refund_account.modal.desc':
    'Bitte beachten Sie, dass durch die Aktualisierung Ihrer angegebenen Kontoinformationen alle künftigen Rückerstattungen auf das neue Konto überwiesen werden. Bitte überprüfen Sie, ob Ihre neuen Kontodaten korrekt sind, um einen reibungslosen Zahlungsvorgang zu gewährleisten.',

  'policy.details.my.digital.payment.info': 'Monatliche Beitragszahlung',
  'policy.details.account.digital.payment.desc':
    'Ihre monatlichen Beiträge werden über die folgende Zahlungsmethode abgebucht',
  'policy.details.account.digital.payment.expire': 'Ablauf: ',
  'policy.details.account.digital.payment.paypal': 'PayPal',
  'policy.details.account.digital.payment.googlePay': 'Google Pay',
  'policy.details.account.digital.payment.applePay': 'Apple Pay',
  'policy.details.account.digital.payment.error':
    'Ihre Zahlungsmethode konnte nicht, wie gewünscht, geändert werden. Bitte erneut versuchen.',
  'policy.details.account.digital.payment.card.numper.placeholder': '**** **** **** XXXX',
  'policy.details.account.digital.payment.card.numper.expire.placeholder': 'MM/JJJJ',
  'policy.details.my.digital.payment.loading.status': 'Wird aktualisiert',

  // Personal Details Edit Form
  'personal.details.form.title': 'Meine Kontaktdaten ändern',
  'personal.details.form.gender.label': 'Anrede',
  'personal.details.form.gender.male.label': 'Herr',
  'personal.details.form.gender.female.label': 'Frau',
  'personal.details.form.email.label': 'E-Mail',
  'personal.details.form.email.error.message': 'E-Mail muss vollständig ausgefüllt werden',
  'personal.details.form.phone.label': 'Telefonnummer',
  'personal.details.form.phone.error.message': 'Telefonnummer muss vollständig ausgefüllt werden',
  'personal.details.form.street.house.number.label': 'Straße, Nummer',
  'personal.details.form.postcode.city.label': 'Postleitzahl, Ort',
  'personal.details.form.address.error.message': 'Adresse muss vollständig ausgefüllt werden',
  'personal.details.form.address.details.label': 'Adresszusatz',
  [`personal.details.form.additional.details.${INSURANCE_TYPE_DENTOLO}`]:
    'Für Änderungen zum Namen schreiben Sie uns bitte eine Email an service@dentolo.de oder nutzen Sie dazu das Kontaktformular.',
  [`personal.details.form.additional.details.${INSURANCE_TYPE_PETOLO}`]:
    'Für Änderungen zum Namen schreiben Sie uns bitte eine Email an service@petolo.de oder nutzen Sie dazu das Kontaktformular.',
  [`personal.details.form.additional.details.${INSURANCE_TYPE_VITOLO}`]:
    'Für Änderungen zum Namen schreiben Sie uns bitte eine Email an service@vitolo.de oder nutzen Sie dazu das Kontaktformular.',
  'personal.details.form.submit.button': 'Angaben ändern',

  // Insured Person Details Edit Form
  'insured.person.details.form.title': 'Versicherte Person ändern',
  'insured.person.details.form.gender.label': 'Anrede',
  'insured.person.details.form.gender.male.label': 'Herr',
  'insured.person.details.form.gender.female.label': 'Frau',
  'insured.person.details.form.street.house.number.label': 'Straße, Nummer',
  'insured.person.details.form.postcode.city.label': 'Postleitzahl, Ort',
  'insured.person.details.form.address.error.message': 'Adresse muss vollständig ausgefüllt werden',
  [`insured.person.details.form.additional.details.${INSURANCE_TYPE_DENTOLO}`]:
    'Für Änderungen zum Namen schreiben Sie uns bitte eine Email an service@dentolo.de oder nutzen Sie dazu das Kontaktformular.',
  [`insured.person.details.form.additional.details.${INSURANCE_TYPE_PETOLO}`]:
    'Für Änderungen zum Namen schreiben Sie uns bitte eine Email an service@petolo.de oder nutzen Sie dazu das Kontaktformular.',
  [`insured.person.details.form.additional.details.${INSURANCE_TYPE_VITOLO}`]:
    'Für Änderungen zum Namen schreiben Sie uns bitte eine Email an service@vitolo.de oder nutzen Sie dazu das Kontaktformular.',
  'insured.person.details.form.submit.button': 'Angaben ändern',

  // Pet Details Edit Form
  'pet.details.form.title': 'Versichertes Tier',
  'pet.details.form.name.label': 'Name',
  'pet.details.form.transponder_code.label': 'Transpondercode',
  'pet.details.form.transponder_code.placeholder': 'Diese ist eine 15-stellige Zahl',
  'pet.details.form.transponder_code.validation.message': 'Transpondercode muss 15 Ziffern haben.',
  'pet.details.form.additional.details.line.1':
    'Zur Erstattung eines Leistungsfalls, benötigen wir Ihre 15-stellige Chipnummer, die auf dem Transponder (Chip) Ihres Tieres gespeichert ist.',
  'pet.details.form.additional.details.line.2':
    'Für Änderungen zum Namen schreiben Sie uns bitte eine Email an service@petolo.de oder nutzen Sie dazu das Kontaktformular.',
  'pet.details.form.submit.button': 'Angaben ändern',

  // digital payment edit form
  'customer.details.digital.payment.edit.title': 'Monatliche Beitragszahlung anpassen',
  'customer.details.digital.payment.billing.date.title': 'Abbuchungsdatum ändern',
  'customer.details.digital.payment.billing.date.start.label': ' Zum 1. des Monats',
  'customer.details.digital.payment.billing.date.middle.label': 'Zum 15. des Monats',
  'customer.details.digital.payment.details.title': 'Aktuelle Zahlunsgmethode',
  'customer.details.digital.payment.novalnet.form.title': 'Andere Zahlungsart wählen',
  'customer.details.digital.payment.success.title': 'Wir haben Ihre Angaben erfolgreich geändert.',
  'customer.details.digital.payment.failure.title':
    'Änderung der Zahlungsmethode aktuell nicht möglich',
  'customer.details.digital.payment.failure.desc':
    'Leider steht dieser Service aktuell nicht zur Verfügung. Wir entschuldigen uns für die Unannehmlichkeiten und bitten Sie, es später erneut zu versuchen.',
  'customer.details.digital.payment.success.btn': 'Zurück zu Ihren Kontaktdaten',

  // Bank Details Edit Form
  'bank.details.form.title': 'Meine Kontoverbindung ändern',
  'bank.details.form.error.firstname': 'Vorname muss ausgefüllt werden',
  'bank.details.form.error.lastname': 'Name muss ausgefüllt werden',
  'bank.details.form.error.iban_invalid': 'Ungültiges IBAN-Format. Bitte überprüfen',
  'bank.details.form.error.iban_empty': 'IBAN muss ausgefüllt werden',
  'bank.details.form.error.iban_not_sepa':
    'Diese IBAN ist nicht gültig. Bitte geben Sie eine IBAN der offiziellen SEPA Länder an, um fortzufahren.',
  'bank.details.form.error.billingDay': 'Abbuchung des beitrags muss ausgefüllt werden',
  'bank.details.form.note_1':
    'Mit dieser Änderung wird ein neues SEPA Mandat für Sie generiert, damit wir zukünftige Beiträge direkt von Ihrem Konto abbuchen können.',
  'bank.details.form.note_2':
    'Bitte beachten Sie, dass die Änderungen erst ab dem nächsten Abbuchungszeitraum gelten und für bereits angeforderte Zahlungen noch die alten Kontodaten verwendet werden.',
  'bank.details.form.submit_button': 'Angaben ändern',
  'bank.details.update.success.title': 'Wir haben Ihre Angaben erfolgreich geändert.',
  'bank.details.update.success.message':
    'Falls Sie noch weitere Fragen haben, kontaktieren Sie bitte unseren Kundendienst.',
  'bank.details.update.failure.title': 'Fehler! Es gab ein Problem mit der Änderung Ihrer Daten.',
  'bank.details.update.failure.message':
    'Bei bestehenden Problemen kontaktieren Sie bitte unseren Kundendienst.',

  // Policies
  'policies.vorsorgeschutz': 'Vorsorgeschutz',
  'policies.akutschutz': 'Akutschutz',
  'policies.vorsorgeschutz-kompakt': 'Vorsorgeschutz Kompakt',
  'policies.akutschutz-kompakt': 'Akutschutz Kompakt',
  'policies.rundumschutz': 'Rundumschutz',
  'policies.zahnschutz-prophylaxe': 'Zahnschutz Prophylaxe',
  'policies.premium': 'Premium',
  'policies.premium_plus': 'Zahnschutz Premium Plus',
  'policies.zahnschutz-basis': 'Zahnschutz Basis',
  'policies.zahnschutz-komfort': 'Zahnschutz Komfort',
  'policies.zahnschutz-premium': 'Zahnschutz Premium',
  'policies.zahnschutz-premium-plus': 'Zahnschutz Premium Plus',
  'policies.addons.im_coverage': 'AKUT-Soforthilfe',
  'policies.addons.trial_month': 'Probemonat',
  'policies.hundekrankenversicherung-komfort': 'Hundekrankenversicherung Komfort',
  'policies.hundekrankenversicherung-premium': 'Hundekrankenversicherung Premium',
  'policies.hundekrankenversicherung-premium-plus': 'Hundekrankenversicherung Premium Plus',
  'policies.katzenkrankenversicherung-komfort': 'Katzenkrankenversicherung Komfort',
  'policies.katzenkrankenversicherung-premium': 'Katzenkrankenversicherung Premium',
  'policies.katzenkrankenversicherung-premium-plus': 'Katzenkrankenversicherung Premium Plus',
  'policies.tierkrankenversicherung-komfort': 'Tierkrankenversicherung Komfort',
  'policies.tierkrankenversicherung-premium': 'Tierkrankenversicherung Premium',
  'policies.tierkrankenversicherung-premium-plus': 'Tierkrankenversicherung Premium Plus',
  'policies.krankenzusatzversicherung-komfort': 'Krankenzusatzversicherung Komfort',
  'policies.krankenzusatzversicherung-premium': 'Krankenzusatzversicherung Premium',
  'policies.krankenzusatzversicherung-premium-plus': 'Krankenzusatzversicherung Premium Plus',

  // File Upload Status
  'file.upload.title': 'Datei Upload',
  'file.upload.success.title.html': 'Hochladen erfolgreich!',
  'file.upload.success.text.html.plural':
    'Es wurden {filesNum} Dateien übertragen.  \n Wir werden diese prüfen und Sie per E-Mail über weitere Schritte kontaktieren.',
  'file.upload.success.text.html.singular':
    'Es wurde {filesNum} Datei übertragen.  \n Wir werden diese prüfen und Sie per E-Mail über weitere Schritte kontaktieren.',
  'file.upload.success.text.html.plural.delay':
    'Es wurden {filesNum} Dateien übertragen.  \n Aufgrund der erhöhten Anzahl an Anfragen, kommt es zu einer Verzögerung bei der Bearbeitung Ihres Leistungsfalles. Die Bearbeitung kann aktuell bis zu 14 Tage dauern. Wir bedauern die Verzögerung, werden Ihre Unterlagen aber schnellstmöglich prüfen und Sie per E-Mail über weitere Schritte informieren.',
  'file.upload.success.text.html.singular.delay':
    'Es wurde {filesNum} Datei übertragen.  \n Aufgrund der erhöhten Anzahl an Anfragen, kommt es zu einer Verzögerung bei der Bearbeitung Ihres Leistungsfalles. Die Bearbeitung kann aktuell bis zu 14 Tage dauern. Wir bedauern die Verzögerung, werden Ihre Unterlagen aber schnellstmöglich prüfen und Sie per E-Mail über weitere Schritte informieren.',
  'file.upload.success.back.home.btn': 'Zurück zur Startseite',
  'file.upload.failure.title.html': 'Fehler! <br />Es gab ein Problem mit dem Datei-Upload.',
  'file.upload.failure.text.html':
    'Bei bestehenden Problemen kontaktieren Sie bitte unseren Kundendienst.',
  'file.upload.failure.cancel.btn': 'Abbrechen',
  'file.upload.failure.try.again.btn': 'Erneut versuchen',
  'file.upload.pet_liability.success.title.html':
    'Vielen Dank, wir haben Ihre Schadenmeldung erhalten.',
  'file.upload.pet_liability.success.text.html':
    'Für die Schadenbearbeitung erhalten wir Unterstützung von einem Experten-Team unseres Versicherungspartners DA Direkt.  \n &nbsp;  \n &nbsp; Dieser wird Sie zeitnah über die nächsten Schritte informieren.',

  // # User claims
  'user_claims.page.title': 'Meine Kostenerstattungen',
  'user_claims.page.description':
    'Hier können Sie einen neuen Leistungsfall erstellen und Details zu aktuellen oder vergangenen Erstattungen einsehen. Wenn Sie bereits einen Leistungsfall erstellt haben, können Sie mit einem Klick auf "Bestehender Leistungsfall" fehlende Dokumente hochladen.',
  'user_claims.button.new_claim_document': 'Neuer Leistungsfall',
  'user_claims.button.existing_claim_document': 'Bestehender Leistungsfall',
  'user_claims.pet_liability.page.title': 'Meine Schadenmeldungen',
  'user_claims.pet_liability.page.description':
    'Hier können Sie Details zu allen aktuellen oder bisherigen Schadenmeldungen sehen oder eine neue Meldung einreichen.',
  'user_claims.pet_liability.button.new_claim_report': 'Schaden melden',

  // # Treatment categories - DENTAL
  'treatment.category.null': 'Zahnbehandlung',
  'treatment.category.dummy_dental_category_for_account_area': 'Zahnbehandlung',
  'treatment.category.implant_basic': 'Implantatbehandlung',
  'treatment.category.implant_complex': 'Implantatbehandlung',
  'treatment.category.total': 'Prothesenbehandlung',
  'treatment.category.combined': 'Prothetikbehandlung',
  'treatment.category.crown': 'Kronenbehandlung',
  'treatment.category.bridge': 'Brückenbehandlung',
  'treatment.category.fixed': 'Prothetikbehandlung',
  'treatment.category.telescope': 'Prothesenbehandlung',
  'treatment.category.conservative': 'Zahnerhaltungsbehandlung',
  'treatment.category.endodontic': 'Wurzelkanalbehandlung',
  'treatment.category.veneer': 'Veneerbehandlung',
  'treatment.category.misc': 'Zahnbehandlung',
  'treatment.category.pzr': 'Zahnreinigung',
  'treatment.category.periodontic': 'Parodontitisbehandlung',
  'treatment.category.interim_care': 'Interimsversorgung',
  'treatment.category.broken_jaw': 'Kieferbruch',
  'treatment.category.request_for_info': 'Auskunftsersuchen',
  'treatment.category.x_ray': 'Röntgen',
  'treatment.category.orthodontics': 'Kieferorthopädie',
  'treatment.category.general_anesthetic': 'Vollnarkose',

  // # Treatment categories - PET
  'treatment.category.pet_null': 'Tiermedizinische Behandlung',
  'treatment.category.dummy_pet_category_for_account_area': 'Tiermedizinische Behandlung',
  'treatment.category.pet_diagnosis': 'Diagnostik',
  'treatment.category.pet_worming': 'Wurmkur',
  'treatment.category.pet_health_check': 'Gesundheitscheck',
  'treatment.category.pet_vaccination': 'Schutzimpfung',
  'treatment.category.pet_fleas_and_ticks': 'Floh- und Zeckenvorsorge',
  'treatment.category.pet_gastrointestinal_disease': 'Magen-Darm-Erkrankung',
  'treatment.category.pet_dental_prophylaxis': 'Zahnprophylaxe',
  'treatment.category.pet_castration_or_sterilization': 'Kastration oder Sterilisation',
  'treatment.category.pet_euthanasia': 'Einschläferung',
  'treatment.category.pet_rescue': 'Such-, Rettungs- und Bergungskosten',
  'treatment.category.pet_desensitization': 'Hyposensibilisierung',
  'treatment.category.pet_entering_medication': 'Eingeben von Medikamenten',
  'treatment.category.pet_implant': 'Implantation eines Arzneimittels',
  'treatment.category.pet_injection': 'Injektion, Instillation, Infusion',
  'treatment.category.pet_eyes_treatment': 'Behandlungen an den Augen',
  'treatment.category.pet_respiratory_treatment': 'Behandlungen am Atmungsapparat',
  'treatment.category.pet_muscles_treatment': 'Behandlungen am Bewegungsapparat',
  'treatment.category.pet_fracture': 'Frakturbehandlung',
  'treatment.category.pet_digestive_system': 'Behandlungen am Verdauungsapparat',
  'treatment.category.pet_dental': 'Zahnbehandlung',
  'treatment.category.pet_spine_and_nervous_system_treatment':
    'Behandlungen an der Wirbelsäule und Nervensystem',
  'treatment.category.pet_eyes_operation': 'Operationen an den Augen',
  'treatment.category.pet_respiratory_operation': 'Operationen am Atmungsapparat',
  'treatment.category.pet_muscles_operation': 'Operationen am Bewegungsapparat',
  'treatment.category.pet_amputation': 'Amputation',
  'treatment.category.pet_heart_operation': 'Operation am Herzen',
  'treatment.category.pet_digestive_system_operation': 'Operationen am Verdauungsapparat',
  'treatment.category.pet_spine_and_nervous_system_operation':
    'Operation an der Wirbelsäule und Nervensystem',
  'treatment.category.pet_prosthesis': 'Prothese',
  'treatment.category.pet_telemedicine': 'Telemedizin',
  'treatment.category.pet_misdevelopment': 'Fehlentwicklung',

  // # Treatment categories - PET LIABILITY
  'treatment.category.pet_liability_damage_to_small_object': 'Kleinerer Sachschaden',
  'treatment.category.pet_liability_damage_to_large_object': 'Größerer Sachschaden',
  'treatment.category.pet_liability_damage_to_motor_vehicle': 'Beschädigtes KFZ',
  'treatment.category.pet_liability_damage_to_bicycle_or_similar': 'Beschädigtes Verkehrsmittel',
  'treatment.category.pet_liability_damage_to_an_apartment': 'Wohnungsschaden',
  'treatment.category.pet_liability_damage_to_a_building': 'Gebäudeschaden',
  'treatment.category.pet_liability_damage_to_a_property': 'Grundstückschaden',
  'treatment.category.pet_liability_injured_animal': 'Verletztes Tier',
  'treatment.category.pet_liability_injured_person': 'Verletzte Person',
  'treatment.category.pet_liability_other': 'Sonstiger Schaden',

  // Treatment category Vitolo
  'treatment.category.dummy_health_category_for_account_area': 'Gesundheitsbehandlung',
  'treatment.category.health_preventive_examination': 'Vorsorgeuntersuchung',
  'treatment.category.health_ophthalmology': 'Augenheilkunde',
  'treatment.category.health_alternative_medicine': 'Alternativmedizin',
  'treatment.category.health_statutory_additional_payment': 'Gesetzliche Zuzahlung',
  'treatment.category.health_medicines_and_dressings': 'Arznei- und Verbandmittel',
  'treatment.category.health_remedies_and_aids': 'Heil- und Hilfsmittel',
  'treatment.category.health_additional_outpatient_services': 'Ambulante Zusatzleistungen',
  'treatment.category.health_inpatient_additional_services': 'Stationäre Zusatzleistungen',
  'treatment.category.health_rooming_in': 'Rooming-In',
  'treatment.category.health_daily_hospital_allowance': 'Krankenhaus-Tagegeld',
  'treatment.category.health_diagnosis': 'Diagnose',
  'treatment.category.health_sos_budget': 'SOS-Budget',

  // Claim Statuses
  'claim.status.last.update': 'Letzte Aktualisierung:',
  'claim.status.label.new': 'In Bearbeitung',
  'claim.status.label.waiting_for_information': 'Offen - Aktion nötig',
  'claim.status.label.reimbursement_acknowledged': 'Deckungszusage',
  'claim.status.label.waiting_for_payout': 'In Bearbeitung',
  'claim.status.label.closed': 'Ausgezahlt',
  'claim.status.label.declined': 'Abgelehnt',
  'claim.status.label.blocked': 'Blockiert',
  'claim.status.label.cancelled': 'Storniert',

  // History item claim statuses
  'claim.history.status.new': 'In Bearbeitung',
  'claim.history.status.processing': 'In Bearbeitung',
  'claim.history.status.waiting_for_information': 'Offen - Aktion nötig',
  'claim.history.status.processing_completed': 'Bearbeitung abgeschlossen',
  'claim.history.status.declined': 'Abgelehnt',
  'claim.history.status.reimbursement_acknowledged': 'Deckungszusage',
  'claim.history.status.checking_invoice': 'Rechnung wird geprüft',
  'claim.history.status.waiting_for_payout': 'In Bearbeitung',
  'claim.history.status.closed': 'Ausgezahlt',
  'claim.history.status.blocked': 'Blockiert',
  'claim.history.status.cancelled': 'Storniert',
  'claim.history.status.updated': 'In Bearbeitung',

  // Claim Status details
  'claim.status.details.updated_at':
    '{updatedAt, date, ::dd.MM.yyyy} {updatedAt, time, ::HH:mm} Uhr',
  // Neu
  'claim.status.details.claim_opened': 'Leistungsfall eröffnet',
  // Updated
  'claim.status.details.claim_updated': 'Leistungsfall aktualisiert',
  // Wartend
  'claim.status.details.treatment_or_costplan_missing': 'Genehmigter Heil- und Kostenplan fehlt',
  'claim.status.details.information_request_missing': 'Auskunftsersuchen fehlt',
  'claim.status.details.patient_file_missing': 'Patientenakte fehlt',
  'claim.status.details.invoice_missing': 'Rechnung fehlt',
  'claim.status.details.documents_missing': 'Unterlagen fehlen',
  'claim.status.details.pet_medical_card_is_missing': 'Krankenakte fehlt',
  // Deckungszusage
  'claim.status.details.reimbursement_acknowledged': 'Deckung zugesagt',
  'claim.status.details.partial_reimbursement_acknowledged': 'Teildeckung zugesagt',
  // Auszahlung
  'claim.status.details.reimbursement_will_be_paid': 'Leistungsfall in Bearbeitung',
  'claim.status.details.partial_reimbursement_will_be_paid': 'Teil-Erstattung wird ausgezahlt',
  'claim.status.details.reimbursement_paid': 'Erstattung ausgezahlt',
  'claim.status.details.partial_reimbursement_paid': 'Teil-Erstattung ausgezahlt',

  'claim.status.details.help.center.text1':
    'Weitere Informationen über den Erstattungsbetrag auf der Rechnung finden Sie',
  'claim.status.details.help.center.link': 'hier.',
  // Blockiert
  'claim.status.details.contract_signature_missing': 'Vertragsbestätigung fehlt',
  'claim.status.details.withdrawal_period_still_running': 'Widerrufsfrist läuft noch',
  'claim.status.details.outstanding_payments': 'Ausstehende Beitragszahlungen',
  'claim.status.details.trial_month': 'Versicherung/Tarif noch nicht aktiv (Probemonat)',
  // Abgelehnt - TODO: update details
  'claim.status.details.treatment_before_contract_start':
    'Behandlung erfolgte vor Versicherungsbeginn',
  'claim.status.details.known_of_treatment_before_contract_start_by_plan':
    'Behandlungsbedürftigkeit laut Heil- und Kostenplan vor Versicherungsbeginn bekannt',
  'claim.status.details.known_of_treatment_before_contract_start_by_dentist_request':
    'Behandlungsbedürftigkeit laut Auskunftsersuchen des Zahnarztes vor Versicherungsbeginn bekannt',
  'claim.status.details.known_of_treatment_before_contract_start_by_pretreatment_provide':
    'Behandlungsbedürftigkeit laut Auskunftsersuchen des Vorbehandlers vor Versicherungsbeginn bekannt',
  'claim.status.details.treatment_before_contract_start_by_invoice':
    'Behandlung erfolgte laut Rechnung vor Versicherungsbeginn',
  'claim.status.details.no_budget': 'Leistungsbegrenzung ausgeschöpft',
  'claim.status.details.no_coverage': 'Keine Deckung durch den Versicherungstarif',
  'claim.status.details.no_coverage_no_medical_need':
    'Keine Deckung durch den Versicherungstarif, da keine medizinische Notwendigkeit',
  'claim.status.details.no_coverage_orthodontics':
    'Keine Deckung durch den Versicherungstarif / Kieferorthopädie durch Tarif nicht abgedeckt',
  'claim.status.details.coverage_for_children_only_for_accident':
    'Abdeckung von Kindern nur bei Unfall',
  'claim.status.details.max_amount_of_tooth_cleanings_reached':
    'Anzahl maximal gedeckter Professioneller Zahnreinigungen pro Jahr erreicht',
  'claim.status.details.no_coverage_cosmetic_measures': 'Keine Deckung von kosmetischen Maßnahmen',
  'claim.status.details.not_the_insured_animal': 'Behandeltes Tier ist nicht das versicherte Tier',
  'claim.status.details.pet_health_budget_exhausted': 'Gesundheitsbudget ausgeschöpft',
  'claim.status.details.pet_sos_budget_exhausted': 'SOS-Budget ausgeschöpft',
  'claim.status.details.known_of_treatment_before_contract_start_by_veterinarian_request':
    'Behandlungsbedürftigkeit laut Auskunftsersuchen Ihres Tierarztes vor Versicherungsbeginn bekannt',
  'claim.status.details.known_of_treatment_before_contract_start_by_vaterinarian_pretreatment_provide':
    'Behandlungsbedürftigkeit laut Auskunftsersuchen des vorbehandelten Tierarztes vor Versicherungsbeginn bekannt',
  'claim.status.details.firstvet_treatment_needed':
    'Behandlungsbedürftigkeit laut Videokonsultation vor Versicherungsbeginn bekannt',
  'claim.status.details.treatment_person_is_not_insured':
    'Behandelte Person entspricht nicht versicherter Person',
  'claim.status.details.reimbursement_is_already_covered':
    'Festzuschuss der Krankenkasse deckt Erstattungsbetrag',
  'claim.status.details.copayment_is_already_covered':
    'Krankenkasse deckt den verbleibenden Eigenanteil',
  'claim.status.details.treatment_was_known_before_insurance':
    'Behandlungsbedürftigkeit laut Patientenakte vor Versicherungsbeginn bekannt',
  'claim.status.details.treatment_before_contract_sign':
    'Behandlung erfolgte vor Vertragsabschluss',
  'claim.status.details.treatment_needs_before_contract_sign':
    'Behandlungsbedürftigkeit vor Vertragsabschluss bekannt',
  'claim.status.details.treatment_was_known_before_insurance_by_dentist':
    'Behandlungsbedürftigkeit laut Auskunftsersuchen Ihres Kieferorthopäden vor Versicherungsbeginn bekannt',
  'claim.status.details.known_of_treatment_before_contract_start_by_doctor_request':
    'Behandlungsbedürftigkeit laut Auskunftsersuchen Ihrer Arztpraxis vor Versicherungsbeginn bekannt',
  'claim.status.details.coverage_of_costs_through_third_party_damage_claims':
    'Kostendeckung durch Schadensansprüche Dritte',
  // Storniert
  'claim.status.details.canceled_by_dentolo': 'Auf Veranlassung von dentolo',
  'claim.status.details.canceled_by_customer': 'Auf Veranlassung des Kunden',
  'claim.status.details.other.payouts': 'Weitere Auszahlungen',
  'claim.status.details.invoice.from': 'Rechnung vom {createdAt, date, ::dd.MM.yyyy}',

  'claim.details.general.amount': 'Betrag',
  'claim.details.general.hour': 'Uhr',
  'claim.details.general.load.invoice':
    'Für die Auszahlung laden Sie bitte die Rechnungen zu der Behandlung hoch.',

  'claim.user.claims.make.appointment.title': 'Termin vereinbaren',
  'claim.user.claims.no.reimbursement': 'Bisher noch keine Kostenerstattungen',
  'claim.user.claims.make.appointment.description':
    'Jetzt Termin bei einem dentolo Zahnarzt vereinbaren und eine höhere Erstattung bekommen.',

  // Claim contact person details
  'claim.contact.person.title': 'Persönlicher Ansprechpartner',
  'claim.contact.person.text':
    'Bei Rückfragen geben Sie bitte immer die Referenznummer {external_reference_number} an.',

  // Claim payout helpcenter article
  'claim.payout.helpcenter_article.text':
    'Sie haben Fragen zu der Höhe Ihrer Rückerstattung? <a>Hier</a> finden Sie weitere Informationen.',

  // Dentists Map
  'dentist.details.open.hours.day.monday.short': 'Mo',
  'dentist.details.open.hours.day.tuesday.short': 'Di',
  'dentist.details.open.hours.day.wednesday.short': 'Mi',
  'dentist.details.open.hours.day.thursday.short': 'Do',
  'dentist.details.open.hours.day.friday.short': 'Fr',
  'dentist.details.open.hours.day.saturday.short': 'Sa',
  'dentist.details.open.hours.day.sunday.short': 'So',
  'dentist.details.open.hours.now.open': 'Jetzt geöffnet',
  'dentist.details.open.hours.closing.soon': 'Schließt bald',
  'dentist.details.open.hours.closed': 'Jetzt geschlossen',
  'dentist.details.open.hours.day.closed': 'geschlossen',
  'dentist.details.dentolo.dentist': 'dentolo Zahnarzt',
  'dentist.details.common.save.favourites': 'Für später speichern',
  'dentist.details.book.appointment': 'Jetzt Termin vereinbaren',
  'dentist.details.back.to.map': 'Zurück zur Zahnarztkarte',
  'dentist.map.loading': 'Zahnarztkarte wird geladen',
  'dentist.map.float_card.details': 'Details',
  'dentist.details.phone_number.label': 'Termin buchen: ',
  'dentist.details.website.label': 'Online unter: ',
  // Map tabs
  'map.dentists.tabs.dentist.favourite': 'Meine Lieblingszahnärzte',
  'map.dentists.tabs.dentist.favourite.message': 'Wir kümmern uns um Ihr strahlendes Lächeln',
  'map.dentists.tabs.dentist.favourite.message.no_dentist':
    'Sie haben noch keinen dentolo Zahnarzt zu Ihrer Favoritenliste hinzugefügt.',
  'map.dentists.tabs.dentist.favourite.expand_details': 'Details',
  'map.dentists.tabs.dentist.find': 'dentolo Zahnärzte finden',
  'map.dentists.network.about': 'Über das dentolo Netzwerk',
  // Map modal
  'dentist.map.modal.title': 'Über das dentolo Netzwerk',
  'dentist.map.modal.content_1':
    'Hier finden Sie eine Karte aller dentolo Zahnärzte bei denen Sie als dentolo Kunde eine höhere Kostenerstattung bekommen.',
  'dentist.map.modal.content_2':
    'Am besten vereinbaren Sie gleich einen Termin bei einem Zahnarzt oder Zahnärztin in Ihrer Nähe. Bei Fragen rund um die dentolo Zahnärzte finden Sie ausführliche Informationen in unserem <button>Hilfecenter</button>.',

  // Sign out
  'sign.out': 'Abmelden',
  'sign.out.change.user': 'Versicherten wechseln',

  // Select customer
  'select.customer.insured.title': 'Bitte wählen Sie einen Versicherten aus:',
  'select.customer.insured.note':
    'Sie können jederzeit einen anderen Versicherten über das Menü bzw. Benutzersymbol auswählen.',

  // Second Auth
  'second.auth.title': 'Zwei Faktor Authentifizierung für Ihr Kundenkonto',
  [`second.auth.description.${INSURANCE_TYPE_DENTOLO}`]:
    'Für die zusätzliche Sicherheit Ihres Kundenkontos benötigen wir Ihr Geburtsdatum zum Einloggen.',
  [`second.auth.description.${INSURANCE_TYPE_PETOLO}`]:
    'Für die Sicherheit Ihres Kundenkontos benötigen wir beim Einloggen Ihr Geburtsdatum - bitte nicht das Ihres Tieres.',
  [`second.auth.description.${INSURANCE_TYPE_VITOLO}`]:
    'Für die zusätzliche Sicherheit Ihres Kundenkontos benötigen wir Ihr Geburtsdatum zum Einloggen.',
  'second.auth.input.label.day': 'Tag',
  'second.auth.input.label.month': 'Monat',
  'second.auth.input.label.year': 'Jahr',
  'second.auth.wrong.details': 'Bitte überprüfen Sie Ihre Angaben.',
  'second.auth.login.btn': 'Login',
  'second.auth.fields.required': 'Erforderliche Angaben',
  'second.auth.status.title': 'Zwei Faktor Authentifizierung',
  'second.auth.status.failure.title':
    'Leider waren die letzten drei Anmeldeversuche nicht erfolgreich.',
  'second.auth.status.failure.text':
    'Aus Sicherheitsgründen muss der Anmeldeprozess neu gestartet werden.',
  'second.auth.status.failure.button.text': 'Zurück zur Startseite',

  // Login
  'login.common.title': 'Mein Kundenkonto',
  'login.common.send.email':
    'Wir senden Ihnen umgehend eine E-Mail mit einem Link, welcher Sie direkt und sicher in Ihrem Kundenkonto anmeldet.',
  'login.common.send.link': 'Anmeldelink senden',
  'login.common.tip.title': 'TIPP: ',
  'login.common.tip.description':
    'Bitte überprüfen Sie die eingegebene E-Mail-Adresse auf Tippfehler.',

  // Login Status
  'login.status.success.text_1':
    'Wenn ein Vertrag zu dieser E-Mail existiert, erhalten Sie in Kürze einen Anmeldelink, um sich in Ihrem Kundenkonto anzumelden.',
  'login.status.success.text_2':
    'Falls Sie keine E-Mail erhalten haben, prüfen Sie bitte Ihren Spam Ordner.',
  'login.status.email.sent': 'Wir haben eine E-Mail an {email} gesendet.',
  'login.status.success.try_again': 'Erneut versuchen',
  'login.info.message.logout_success': 'Sie haben sich erfolgreich abgemeldet!',
  'login.info.title.invalid_link': 'Ungültiger oder abgelaufener Anmeldelink.',
  'login.info.message.invalid_link':
    'Bitte geben Sie unten erneut Ihre E-Mail an, damit wir Ihnen einen gültigen Anmeldelink per E-Mail zusenden können.',
  [`login.link_to_homepage.${INSURANCE_TYPE_DENTOLO}`]: 'Zurück zur dentolo Startseite',
  [`login.link_to_homepage.${INSURANCE_TYPE_PETOLO}`]: 'Zurück zur petolo Startseite',
  [`login.link_to_homepage.${INSURANCE_TYPE_VITOLO}`]: 'Zurück zur vitolo Startseite',
  'login.form.label': 'E-Mail-Adresse',
  'login.form.check': 'Login-Daten merken',

  'login.status.success.tip.question': 'Sie haben keine E-Mail erhalten?',
  'login.status.success.tip.description': 'Bitte übeprüfen Sie Ihren Spam-Ordner.',
  'login.status.success.tip.list.title': 'Wenn Sie weiterhin keine E-Mail erhalten:',
  'login.status.success.tip.list.item_1': 'Versuchen Sie, einen anderen Browser zu benutzen',
  'login.status.success.tip.list.item_2':
    'Leeren Sie den Browser- und Cookies-Cache in Ihrem Browser',
  // Contract status title
  'contract.status.title.start': 'Versicherungsbeginn',
  'contract.status.title.end': 'Versicherungsende',
  // Contract status label
  'contract.status.start': 'Aktiv',
  'contract.status.will.start': 'Wird Aktiv',
  'contract.status.revoked': 'Widerrufen',
  'contract.status.will.end': 'Wird Beendet',
  'contract.status.end': 'Beendet',
  'contract.status.active.trial_month': 'Probemonat aktiv',

  // Contract docs
  'contract.docs.title': 'Meine Vertragsunterlagen',
  'contract.docs.description':
    'Hier können Sie alle relevanten Vertragsunterlagen als PDF Dateien einsehen und herunterladen.',
  'contract.docs.download.all': 'Alle Dokumente herunterladen',
  'contract.docs.download.all.subtext':
    'Hier können Sie alle Dokumente in einer ZIP-Datei herunterladen.',
  // Contract status/placeholder
  'contract.placeholder.inactive': 'Dieser Vertrag ist nicht aktiv',

  // Sign docs
  'sign.docs.title': 'Ihre Vertragsunterlagen',
  'sign.docs.description':
    'Hier finden Sie alle Unterlagen zu Ihrem Versicherungsvertrag. Unter "Mein Schutz" können Sie diese jederzeit abrufen.',
  'sign.docs.accordion.toggle.text': 'Weitere Dokumente',
  'sign.docs.accordion.show.text': 'anzeigen',
  'sign.docs.accordion.hide.text': 'schließen',
  'sign.docs.go.to.account': 'Zum Kundenkonto',
  'sign.docs.input.label.text':
    'Ich bestätige den Versicherungsschein und die obigen Dokumente erhalten und gespeichert zu haben, sowie mit deren Inhalt einverstanden zu sein.',

  // Documents
  'document.Allgemeine_Versicherungsbedingungen': 'Versicherungsbedingungen',
  'document.Produktinformationsblatt': 'Produktinformationsblatt',
  'document.Kundeninformationsblatt': 'Kundeninformationsblatt',
  'document.Kundeninformation': 'Kundeninformation',
  'document.Einwilligungserklärung': 'Einwilligungserklärung',
  'document.Datenschutzerklärung': 'Datenschutzerklärung',
  'document.protocol': 'Beratungsprotokoll',
  'document.contract': 'Versicherungsschein',
  'document.sepa': 'SEPA Mandat',
  'document.opt-in_document': 'Einverständniserklärung',
  'document.Versicherungsbedingungen': 'Versicherungsbedingungen',
  'document.Nutzungsbedingungen Kundenkonto': 'Nutzungsbedingungen Kundenkonto',
  'document.Extra Vorteile': 'Extra Vorteile',
  'document.dentolo Zahnarztnetzwerk': 'dentolo Zahnarztnetzwerk',
  'document.Weitere Verbraucherinformationen': 'Weitere Verbraucherinformationen',
  'document.Vertragsunterlagen': 'Vertragsunterlagen',
  'document.Sondervereinbarung Probemonat': 'Sondervereinbarung Probemonat',
  'document.Tarifblatt Akutschutz Modul.pdf': 'Tarifblatt Akutschutz Modul',

  // rfi text
  'document.rfi.text.head': 'Ihr Auskunftsersuchen',
  'document.rfi.text.title': 'Laden Sie hier das PDF Dokument runter.',
  'document.rfi.dentolo.text.desc':
    'Nachdem die Dokumente von Ihnen beziehungsweise Ihrer Arztpraxis ausgefüllt wurden, laden Sie diese bitte wieder hier in Ihrem Kundenkonto hoch. Nutzen Sie dafür die Funktion Dokumente hochladen.',
  'document.rfi.petolo.text.desc':
    'Nachdem die Dokumente von Ihnen beziehungsweise Ihrer Tierarztpraxis ausgefüllt wurden, laden Sie diese bitte wieder hier in Ihrem Kundenkonto hoch. Nutzen Sie dafür die Funktion Dokumente hochladen.',

  // Payment status block
  'payment.status.total_premium': 'Beitrag',
  'payment.status.policy_premium': 'Tarif',

  // Payment general
  'payment.general.to.document': 'Zu den Dokumenten',
  'payment.general.access.documents': 'Beitragsbescheingungen herunterladen',

  // Payment overview
  'payment.overview.download.certificate':
    'Hier finden Sie Ihre Bescheinigungen über alle gezahlten Versicherungsbeiträge. Diese können immer ab dem 1. Februar für das Vorjahr heruntergeladen werden.',
  'payment.overview.heading': 'Meine Beitragsbescheinigungen',
  'payment.overview.contribution.document': 'Beitragsbescheinigung {year}',
  'payment.overview.about.taxes': 'Mehr zum Thema Steuern und Versicherungen?',
  'payment.overview.helpcenter': 'Zum Hilfe-Center',
  'payment.overview.notification.title': 'Ihre Beitragsbescheinigung',
  'payment.overview.notification.text': 'Hier können Sie das Dokument herunterladen',
  'payment.overview.message.no_contribution': 'Noch keine Beitragsbescheinigung vorhanden',

  // Payment error messages
  'payment.failed.title': 'Meine Versicherungsbeiträge',
  'payment.failed.desc': 'Versicherungsbeiträge verwalten',
  'payment.failed.subText':
    'Hier können Sie den aktuellen Status ihrer monatliche Beiträge überschauen.',
  'payment.failed.payment.link': 'Versicherungsbeiträge anzeigen',
  'payment.failed.notification.text.one':
    'Sie haben einen offenen Versicherungsbeitrag. Klicken Sie hier, um Ihren Schutz zu reaktivieren. ',
  'payment.failed.notification.text.two':
    'Sie haben offene Versicherungsbeiträge. Klicken Sie hier, um Ihren Schutz zu reaktivieren.',
  'payment.failed.re.payment.heading': 'Der Beitrag konnte nicht abgebucht werden ',
  'payment.failed.re.payment.heading.continue': ' konnte nicht abgebucht werden.',
  'payment.failed.re.payment.link': 'Jetzt bezahlen',
  'payment.paid.message': 'Bezahlt',
  'payment.page.main.heading': 'Meine Versicherungsbeiträge',
  'payment.page.main.sub.heading':
    'Hier können Sie Details zu Ihren Versicherungsbeiträgen sehen und eventuell offene Beiträge bezahlen.',
  'payment.page.no.payments': 'Bisher noch keine Versicherungsbeiträge',
  'payment.page.network.error': 'Etwas ist schief gelaufen',
  'payment.exported.message': 'Ihr Konto wird am {date} belastet',
  'payment.exportable.message': 'Ihr Konto wird belastet',
  'payment.exportable.refunded': 'Wurde erstattet',
  'payment.exportable.refundable': 'Wird erstattet',
  'payment.cancelled.message': 'Storniert',
  'payment.blocked.message': 'Der Beitrag konnte nicht abgebucht werden',

  // ErrorMessage common
  'auth.failure.page.title': 'Ohje! Ein Fehler ist aufgetreten!',
  [`auth.failure.page.email.support.${INSURANCE_TYPE_DENTOLO}`]:
    'Bei dringenden Anfragen schreiben Sie uns bitte an: <a>service@dentolo.de</a>',
  [`auth.failure.page.email.support.${INSURANCE_TYPE_PETOLO}`]:
    'Bei dringenden Anfragen schreiben Sie uns bitte an: <a>service@petolo.de</a>',
  [`auth.failure.page.email.support.${INSURANCE_TYPE_VITOLO}`]:
    'Bei dringenden Anfragen schreiben Sie uns bitte an: <a>service@vitolo.de</a>',
  'auth.failure.page.email.thanks': 'Vielen Dank für Ihr Verständnis.',
  [`auth.failure.page.email.your_team.${INSURANCE_TYPE_DENTOLO}`]: 'Ihr dentolo Team',
  [`auth.failure.page.email.your_team.${INSURANCE_TYPE_PETOLO}`]: 'Ihr petolo Team',
  [`auth.failure.page.email.your_team.${INSURANCE_TYPE_VITOLO}`]: 'Ihr vitolo Team',

  // # Pomotion carousel
  'promotion.carousel.dentolo.referral_car.text1': 'dentolo empfiehlt: ',
  'promotion.carousel.dentolo.referral_car.text2': 'Autoversicherung von  ',
  'promotion.carousel.dentolo.referral_car.text3': 'DA Direkt. Bis 30.11. ',
  'promotion.carousel.dentolo.referral_car.text4': 'wechseln und bis ',
  'promotion.carousel.dentolo.referral_car.text5': 'zu 60% sparen!',
  'promotion.carousel.dentolo.car_link.button.label': 'Mehr erfahren',
  'promotion.carousel.dentolo.referral_link.title': 'Freund:innen <span>werben lohnt sich!</span>',
  'promotion.carousel.dentolo.referral_link.button.label': '50€ Gutschein sichern',
  'promotion.carousel.petolo.referral_link.title': 'Jetzt petolo empfehlen und 50€ Prämie erhalten',
  'promotion.carousel.petolo.referral_link.button.label': 'Zu meiner Prämie',
  'promotion.carousel.petolo.confirmation.title': 'Freunde werben und doppelt profitieren',
  'promotion.carousel.petolo.confirmation.accept_joining_text_1': 'Ich akzeptiere die  ',
  'promotion.carousel.petolo.confirmation.accept_joining_text_link': 'Teilnahmebedingungen',
  'promotion.carousel.petolo.confirmation.accept_joining_text_2':
    ' und möchte am petolo Empfehlungsprogramm teilnehmen.',
  'promotion.carousel.petolo.confirmation.accept': 'petolo empfehlen',
  'promotion.carousel.petolo.confirmation.reject': 'Vielleicht später',
  'promotion.carousel.vitolo.referral_link.title':
    'Freund:innen {br} <span>werben</span> {br} <span>lohnt sich!</span>',
  'promotion.carousel.vitolo.referral_link.button.label': '50€ Gutschein sichern',
  'promotion.carousel.vitolo.cross_selling.dentolo.title': 'Ganzheitlich vorsorgen',
  'promotion.carousel.vitolo.cross_selling.dentolo.description':
    'Der <u>vitolo</u> Zusatzschutz für Ihre Gesundheit.',
  'promotion.carousel.vitolo.cross_selling.dentolo.button.label': 'Mehr erfahren',
  'promotion.carousel.vitolo.cross_selling.petolo.title': 'Gesundbleiben für Ihr Tier',
  'promotion.carousel.vitolo.cross_selling.petolo.description':
    'Der <u>vitolo</u> Zusatzschutz bietet starke Vorsorge.',
  'promotion.carousel.vitolo.cross_selling.petolo.button.label': 'Mehr erfahren',
  'promotion.carousel.vitolo.better_doc.vitolo.title': 'Facharztsuche leicht gemacht',
  'promotion.carousel.vitolo.better_doc.vitolo.description':
    'Jetzt mit BetterDoc Fachärzt:innen finden.',
  'promotion.carousel.vitolo.better_doc.vitolo.button.label': 'Mehr erfahren',
  'promotion.carousel.fressnapf.fn_friends.text':
    '<b>Exklusive Angebote und Rabatte mit Fressnapf Friends</b> bei jedem Einkauf im Markt und Online.',
  'promotion.carousel.fressnapf.fn_friends.button.label': 'Jetzt Rabatt sichern',

  // # Pomotion carousel - DA Direkt
  'promotion.carousel.da_direkt.dental.referral_link.title':
    'Jetzt Zahnzusatzversicherung empfehlen und 90€ Prämie erhalten',
  'promotion.carousel.da_direkt.dental.referral_link.button.label': 'Zu meiner Prämie',
  'promotion.carousel.da_direkt.pet.referral_link.title':
    'Jetzt Tierkrankenversicherung empfehlen und 70€ Prämie erhalten',
  'promotion.carousel.da_direkt.pet.referral_link.button.label': 'Zu meiner Prämie',
  'promotion.carousel.da_direkt.cross_selling.button.label': 'Mehr erfahren',
  'promotion.carousel.da_direkt.dental.cross_selling.title.desktop':
    'Unser Top-Schutz für gesunde Zähne: DA Direkt Zahnzusatzversicherung',
  'promotion.carousel.da_direkt.dental.cross_selling.text.desktop':
    'Sichern Sie sich umfassenden Schutz und hohe Kostenerstattung für Zahnersatz, professionelle Zahnreinigung und mehr, ohne Wartezeit und monatlich kündbar.',
  'promotion.carousel.da_direkt.dental.cross_selling.title.mobile': 'Top-Schutz für gesunde Zähne',
  'promotion.carousel.da_direkt.dental.cross_selling.list.title.mobile': 'Zahnzusatzversicherung:',
  'promotion.carousel.da_direkt.dental.cross_selling.list.item_1.mobile':
    'Bis zu 100 % Kostenerstattung',
  'promotion.carousel.da_direkt.dental.cross_selling.list.item_2.mobile':
    'Zahnersatz, Zahnreinigung & mehr',
  'promotion.carousel.da_direkt.dental.cross_selling.list.item_3.mobile': 'Monatlich kündbar',
  'promotion.carousel.da_direkt.pet.cross_selling.title.desktop':
    'Für die Gesundheit Ihrer Lieblinge: DA Direkt Tierkrankenversicherung',
  'promotion.carousel.da_direkt.pet.cross_selling.text.desktop':
    'OP- & Krankenversicherung für Ihren Hund oder Ihre Katze mit Sofortschutz ohne Wartezeit, Erstattungen bis 4-facher GOT-Satz und kostenloser Videosprechstunde.',
  'promotion.carousel.da_direkt.pet.cross_selling.title.mobile':
    'Für die Gesundheit Ihrer Lieblinge',
  'promotion.carousel.da_direkt.pet.cross_selling.list.title.mobile': 'Tierkrankenversicherung:',
  'promotion.carousel.da_direkt.pet.cross_selling.list.item_1.mobile': 'OP- & Krankenversicherung',
  'promotion.carousel.da_direkt.pet.cross_selling.list.item_2.mobile':
    'Erstattung bis 4-facher GOT-Satz',
  'promotion.carousel.da_direkt.pet.cross_selling.list.item_3.mobile':
    'Kostenlose Videosprechstunde',
  'promotion.carousel.da_direkt.health.cross_selling.title.desktop':
    'Ergänzung zur gesetzlichen Krankenkasse: DA Direkt Krankenzusatzversicherung',
  'promotion.carousel.da_direkt.health.cross_selling.text.desktop':
    'Sichern Sie sich umfassenden Schutz und schließen Sie die Versorgungslücke. Wir übernehmen Leistungen wie Hautkrebsvorsorge, Naturheilkunde & Sehhilfen.',
  'promotion.carousel.da_direkt.health.cross_selling.title.mobile':
    'Ergänzung zur gesetzlichen Krankenkasse',
  'promotion.carousel.da_direkt.health.cross_selling.list.title.mobile':
    'Krankenzusatzversicherung:',
  'promotion.carousel.da_direkt.health.cross_selling.list.item_1.mobile': 'Hautkrebsvorsorge',
  'promotion.carousel.da_direkt.health.cross_selling.list.item_2.mobile': 'Naturheilkunde',
  'promotion.carousel.da_direkt.health.cross_selling.list.item_3.mobile': 'Sehhilfen',

  // Referral banner
  'referral.banner.link': 'Mehr Informationen',
  'referral.banner.content': 'Jetzt dentolo empfehlen. 50€ Prämie erhalten!',

  // # Join referral page
  'referral_permission.dental.page.title': 'Freunde werben und doppelt profitieren',
  'referral_permission.dental.form.checkbox.label_1': 'Ich akzeptiere die',
  'referral_permission.dental.form.checkbox.label.terms_link': 'Teilnahmebedingungen',
  'referral_permission.dental.form.checkbox.label_2':
    'und möchte am dentolo Empfehlungsprogramm teilnehmen.',
  'referral_permission.dental.form.button.agree': 'dentolo empfehlen',
  'referral_permission.dental.form.button.later': 'Vielleicht später',

  // Referrals - Common
  'referral.page.title': 'Freund:innen werben',
  'referral.page.content': 'Jetzt {contract} empfehlen und 50€ Prämie erhalten',
  'referral.page.copy.button': 'Empfehlungslink kopieren',
  'referral.page.link.copied': 'Link kopiert',
  'referral.page.share_in_socials': 'oder Link direkt via Facebook, WhatsApp oder E-Mail teilen',
  'referral.page.referral_id.description':
    'Möchten Ihre Freund:innen die {product} {insurance} lieber <b>im Rahmen eines Beratungsgesprächs abschließen?</b> Damit unsere Expert:innen Ihre Empfehlung auch in diesem Fall korrekt zuordnen können, benötigen Ihre Freund:innen den folgenden Empfehlungscode:',
  'referral.page.referral_id.title': 'Ihr Empfehlungscode',
  'referral.page.privacy.text':
    'Aus datenschutzrechtlichen Gründen raten wir davon ab, zusätzlich Ihre Telefonnummer oder E-Mail-Adresse weiterzugeben.',
  'referral.page.terms_and_conditions.text':
    'Mehr Informationen zu Ihrer Teilnahme am Freunde:innen-werben-Programm und unseren AGBs finden Sie <a>hier</a>.',
  'referral.page.tab.label.one': 'Freund:innen werben',
  'referral.page.tab.label.two': 'Ihre Empfehlungen',
  'referral.page.information.header': "So geht's",
  'referral.page.status.title': 'Prüfen Sie den Status Ihrer Empfehlungen',
  'referral.page.status.empty.title': 'Noch keine Empfehlung erfasst',
  'referral.page.status.empty.description':
    'Teilen Sie jetzt Ihr Glück und empfehlen {product} Ihren Liebsten.',
  'referral.page.list.title.email': 'E-Mail Ihrer Freund:innen',
  'referral.page.list.title.status': 'Status',
  'referral.reward.title': 'Ihr {rewardbrand} Gutschein-Code:',
  'referral.status.label.pending': 'Ausstehend',
  'referral.status.label.cancelled': 'Abgebrochen',
  'referral.status.label.sent': 'Abgeschlossen',
  'referral.status.description.title': 'Bedeutung des Status',
  'referral.status.description.pending':
    '<b>Ausstehend:</b> Ihre Empfehlung wird zurzeit bearbeitet. <b>Dieser Prozess kann bis zu 31 Tage dauern, gerechnet ab dem Startdatum der Versicherung Ihrer Freund:innen.</b> Sobald dieser erfolgreich abgeschlossen ist, werden wir Sie benachrichtigen.',
  'referral.status.description.cancelled':
    '<b>Abgebrochen:</b> Ihre Empfehlung konnte leider nicht erfolgreich abgeschlossen werden, weil die erforderlichen Kriterien nicht erfüllt wurden. Probieren Sie es gerne erneut & empfehlen Sie {product} weiteren Freund:innen.',
  'referral.status.description.completed':
    '<b>Abgeschlossen:</b> Ihre Empfehlung wurde erfolgreich abgeschlossen und Ihr {amount}€ {giftbrand} wartet in Ihrem E-Mail Postfach auf Sie. Sie können den Gutschein-Code aus der E-Mail nutzen oder direkt von oben kopieren.',

  // Referrals - Dentolo
  'referral.page.dentolo.title': 'dentolo empfehlen & {amount}€ {couponbrand} sichern',
  'referral.page.dentolo.step.one.title': 'Link mit Freund:innen teilen',
  'referral.page.dentolo.step.one.description':
    'Klicken Sie jetzt auf den untenstehenden Button “Empfehlungslink kopieren” und leiten Sie diesen an Ihre Freund:innen weiter.',
  'referral.page.dentolo.step.two.title': 'Freund:in schließt einen Vertrag mit dentolo ab',
  'referral.page.dentolo.step.two.description':
    'Nachdem Ihr:e Freund:in eine dentolo Zahnzusatzversicherung über den Empfehlungslink abgeschlossen hat, spart sich Ihr:e Freund:in den ersten Monatsbeitrag.',
  'referral.page.dentolo.step.three.title': 'Gutschein erhalten',
  'referral.page.dentolo.step.three.description':
    'Ihren {giftbrand} erhalten Sie, nachdem Ihr:e Freund:in:',
  'referral.page.dentolo.step.three.additional_info.one':
    'den kostenfreien Probemonat erfolgreich getestet,',
  'referral.page.dentolo.step.three.additional_info.two': 'die Vertragsunterlagen bestätigt und',
  'referral.page.dentolo.step.three.additional_info.three': 'den ersten Monatsbeitrag bezahlt hat.',
  'referral.page.dentolo.whatsapp.message':
    'Suchst du noch eine Zahnzusatzversicherung? Dann empfehle ich dir dentolo. Hier kannst du dir einen passenden Tarif aussuchen %26 einen kostenfreien Probemonat sichern: {referralUrl}',
  'referral.page.dentolo.email.subject': 'Meine Empfehlung: dentolo Zahnzusatzversicherung',
  'referral.page.dentolo.email.message':
    'Hallo,%0D%0Dsuchst du noch eine Zahnzusatzversicherung? Dann empfehle ich dir dentolo. Über diesen Link kannst du dir einen passenden Tarif aussuchen %26 einen kostenfreien Probemonat sichern: {referralUrl}%0D%0DViele Grüße',

  // Referrals - Petolo
  'referral.page.petolo.title': 'petolo empfehlen & {amount}€ {couponbrand} sichern',
  'referral.page.petolo.more_info.title': 'Haben Sie Fragen?',
  'referral.page.petolo.more_info.text':
    'Alles Wichtige zum Freund:innen-werben-Programm finden Sie <a>hier</a>.',
  'referral.page.petolo.step.one.title': 'Link mit Freund:innen teilen',
  'referral.page.petolo.step.one.description':
    'Klicken Sie jetzt auf den untenstehenden Button “Empfehlungslink kopieren” und leiten Sie diesen an Ihre Freund:innen weiter.',
  'referral.page.petolo.step.two.title': 'Freund:in schließt einen Vertrag mit petolo ab',
  'referral.page.petolo.step.two.description':
    'Nachdem Ihr:e Freund:in eine petolo Tierversicherung über den Empfehlungslink abgeschlossen hat, spart sich Ihr:e Freund:in den ersten Monatsbeitrag.',
  'referral.page.petolo.step.three.title': 'Gutschein erhalten',
  'referral.page.petolo.step.three.description':
    'Ihren {giftbrand} erhalten Sie, nachdem Ihr:e Freund:in:',
  'referral.page.petolo.step.three.additional_info.one':
    'den kostenfreien Probemonat erfolgreich getestet,',
  'referral.page.petolo.step.three.additional_info.two': 'die Vertragsunterlagen bestätigt und',
  'referral.page.petolo.step.three.additional_info.three': 'den ersten Monatsbeitrag bezahlt hat.',
  'referral.page.petolo.whatsapp.message':
    'Suchst du noch eine Tierkrankenversicherung? Dann empfehle ich dir petolo. Hier kannst du dir einen passenden Tarif aussuchen %26 einen kostenfreien Probemonat sichern: {referralUrl}',
  'referral.page.petolo.email.subject': 'Meine Empfehlung: petolo Tierkrankenversicherung',
  'referral.page.petolo.email.message':
    'Hallo,%0D%0Dsuchst du noch einen starken Krankenschutz für deinen Vierbeiner? Dann empfehle ich dir petolo. Über diesen Link kannst du dir einen passenden Tarif aussuchen %26 einen kostenfreien Probemonat sichern: {referralUrl}%0D%0DViele Grüße',

  // Referrals - Vitolo
  'referral.page.vitolo.title': 'vitolo empfehlen & {amount}€ {couponbrand} sichern',
  'referral.page.vitolo.step.one.title': 'Link mit Freund:innen teilen',
  'referral.page.vitolo.step.one.description':
    'Klicken Sie jetzt auf den untenstehenden Button „Empfehlungslink kopieren“ und leiten Sie diesen an Ihre Freund:innen weiter.',
  'referral.page.vitolo.step.two.title': 'Freund:in schließt einen Vertrag mit vitolo ab',
  'referral.page.vitolo.step.two.description':
    'Ihr:e Freund:in schließt über den Empfehlungslink eine vitolo Krankenzusatzversicherung ab.',
  'referral.page.vitolo.step.three.title': 'Gutschein erhalten',
  'referral.page.vitolo.step.three.description':
    'Ihren {giftbrand} erhalten Sie, nachdem Ihr:e Freund:in:',
  'referral.page.vitolo.step.three.additional_info.one': 'die Vertragsunterlagen bestätigt und',
  'referral.page.vitolo.step.three.additional_info.two': 'den ersten Monatsbeitrag bezahlt hat.',
  'referral.page.vitolo.whatsapp.message':
    'Suchst du noch einen starken Krankenzusatzschutz als Ergänzung zu deiner gesetzlichen Krankenkasse? Mit vitolo musst du bei deiner Gesundheit keine Abstriche mehr machen. Finde über diesen Link deinen passenden Tarif:  {referralUrl}',
  'referral.page.vitolo.email.subject': 'Meine Empfehlung: vitolo Krankenzusatzversicherung',
  'referral.page.vitolo.email.message':
    'Hallo,%0D%0Dsuchst du noch einen starken Krankenzusatzschutz als Ergänzung zu deiner gesetzlichen Krankenkasse? Mit vitolo musst du bei deiner Gesundheit keine Abstriche mehr machen. Finde über diesen Link deinen passenden Tarif: {referralUrl}%0D%0DHerzliche Grüße',

  // First Vet
  'first.vet.go.to.link': 'Zu FirstVet',
  'first.vet.online.vets': 'Online-Tierärzte',
  'first.vet.app.find': 'Die FirstVet App finden Sie hier:',
  'first.vet.slider.create.account': 'Kostenfreies Konto erstellen',
  'first.vet.slider.appointment': 'Termin buchen',
  'first.vet.slider.secure': 'Sorgenfrei abgesichert',
  'first.vet.slider.create.account.description':
    'Erstellen Sie ein Konto auf der FirstVet Homepage oder laden Sie die FirstVet App herunter. Registieren Sie ihr Haustier und erhalten eine höhere Erstattung nach einer digitalen Erstberatung.',
  'first.vet.slider.appointment.description':
    'Sie können die Videosprechstunde mit einem Tierarzt rund um die Uhr in Anspruch nehmen. Die Kosten für die digitale Erstberatung tragen wir für Sie.',
  'first.vet.slider.secure.description':
    'Ihr Tier ist in guten Händen: dank der professionellen Beratung oder Überweisung an eine örtliche Tierarztpraxis oder -klinik, falls nötig.',

  // Fressnapf
  'fressnapf.header': 'Online Tierärzte',
  'fressnapf.button.text': 'Zu Dr. Fressnapf',
  'fressnapf.slide1.title': 'Anmelden',
  'fressnapf.slide2.title': 'Termin buchen',
  'fressnapf.slide3.title': 'Gesund werden',
  'fressnapf.slide1.description':
    'Erstellen Sie sich mit Ihrer E-Mail Adresse ein Kundenkonto bei Dr.{nbsp}Fressnapf oder melden sich in Ihrem Fressnapf-Konto an.',
  'fressnapf.slide2.description':
    'Buchen Sie einen Termin für eine Videosprechstunde mit einem Tierarzt und erhalten Ihre digitale Erstberatung.',
  'fressnapf.slide3.description':
    'Ihr Tier ist in guten Händen: dank der professionellen Beratung oder Überweisung an eine örtliche Tierarztpraxis oder -klinik.',

  // First Vet France
  // dessn't need translation

  'first.vet.france.header.title': `Vétérinaires en ligne`,
  'first.vet.france.header.description': `Service de consultation vidéo avec un vétérinaire directement depuis votre téléphone.`,
  'first.vet.france.content.header.title': `Comment utiliser le service`,
  'first.vet.france.content.header.description': `En tant que client Patolo, vous pouvez utiliser le service Liv’vet gratuitement en quelques étapes:`,
  'first.vet.france.content.section1.title': `Créer un compte`,
  'first.vet.france.content.section1.description': `Accédez à Liv’vet en ligne ou téléchargez l’application Liv’vet et créez un compte.`,
  'first.vet.france.content.section1.link.button': `Vers la page Liv’vet`,
  'first.vet.france.content.section2.title': `Sélectionnez Patolo comme assurance pour votre animal`,
  'first.vet.france.content.section2.description': `Lorsque vous devez sélectionner votre assurance pour animaux, choisissez Patolo.`,
  'first.vet.france.content.section3.title': `Indiquez votre adresse e-mail et votre numéro d'assurance.`,
  'first.vet.france.content.section3.description': `Copiez votre numéro d'assurance ci-dessous et collez-le dans le champ requis “Informations d'assurance”, accompagné de votre adresse e-mail.`,
  'first.vet.france.content.section3.insurance_number': `Votre numéro de contract Patolo`,
  'first.vet.france.link.copied': 'Lien copié',

  'first.vet.france.content.section4.title': `Obtenez votre consultation gratuitement`,
  'first.vet.france.content.section4.description': `Après avoir fourni les informations requises, vous serez facturé·e {dummyPrice} pour rejoindre la salle d'attente et accéder à un vétérinaire Liv’vet en quelques minutes.`,
  'first.vet.france.content.section4.dumy.price': `0,00 €`,

  // Petolo cross selling banner
  'petolo.cross-selling.banner.title': 'Wir versichern jetzt auch Vierbeiner!',
  'petolo.cross-selling.banner.content':
    'Die Hundekranken- & OP-Versicherung von petolo schützt Sie jetzt auch vor hohen Tierarztkosten.',
  'petolo.cross-selling.banner.button': 'Mehr Information',

  // datepicker placeholder
  'dentolo.day_placeholder': 'Tag',
  'dentolo.month_placeholder': 'Monat',
  'dentolo.year_placeholder': 'Jahr',

  // New Claim Creation Flow common
  'new_claim.prompt.message':
    'Sind Sie sicher, dass Sie die Seite verlassen wollen? Bereits ausgewählte Dateien müssen dann erneut ausgewählt werden.',
  'new_claim.file.too.big': 'Datei zu groß (max. 20 MB)',
  'new_claim.file.wrong.type': 'Ungültiger Dateityp',
  'new_claim.file.ready.to.upload': 'Bereit zum Absenden',
  'new_claim.invalid.date': 'Bitte geben Sie ein gültiges Datum ein',
  'new_claim.invalid.date_past': 'Das Datum darf maximal fünf Jahre zurückliegen',
  'new_claim.invalid.postcode': 'Ungültige Postleitzahl',
  'new_claim.upload.page.title.with.files': 'Ihre ausgewählten Dokumente',
  'new_claim.upload.page.title.without.files': 'Dokumente hochladen',
  'new_claim.upload.page.description':
    'Bitte laden Sie die Dokumente nur für eine Kostenerstattung hoch. Sollten Sie Dokumente für weitere Kostenerstattungen besitzen, starten Sie nach erfolgreichem Hochladen bitte von vorn.',
  'new_claim.upload.page.reminder':
    'Bitte laden Sie auch Überweisungen hoch, die Ihnen von Ihren Ärtz:innen ausgestellt wurden.',
  'new_claim.upload.page.received_refund':
    'Bitte laden Sie die entsprechenden Dokumente hoch, die den Erhalt der genannten Erstattungen bestätigen.',
  'new_claim.upload.page.hint.description':
    'Um eine schnellere Bearbeitung Ihrer Erstattung zu gewährleisten, stellen Sie bitte sicher, dass Sie eine korrekte Rechnung hochladen mit:',
  'new_claim.upload.page.hint.good_stuff_pet_1': 'Behandeltes Tier',
  'new_claim.upload.page.hint.good_stuff_dental_1': 'Behandelte Person',
  'new_claim.upload.page.hint.good_stuff_2': 'Datum',
  'new_claim.upload.page.hint.good_stuff_3': 'Behandlungen',
  'new_claim.upload.page.hint.good_stuff_4': 'Rechnungsbetrag',
  'new_claim.upload.page.hint.bad_stuff_title': 'Nicht gültig sind',
  'new_claim.upload.page.hint.bad_stuff_1': 'Zahlungsanweisung',
  'new_claim.upload.page.hint.bad_stuff_2': 'Kostenübersicht',
  'new_claim.upload.page.hint.bad_stuff_3': 'Überweisungsträger',
  'new_claim.file.uploader.title.with_files': 'Weitere Dateien auswählen',
  'new_claim.file.uploader.title.without_files': 'Dateien auswählen',
  'new_claim.file.uploader.description.with_files':
    'Bitte überprüfen Sie vor dem Absenden, dass Sie alle gewünschten Dateien ausgewählt haben. Alle Dateien mit dem Status "Bereit zum Absenden" werden übertragen.',
  'new_claim.file.uploader.description.without_files':
    '**Klicken Sie hier**, um Dateien hochzuladen. Alternativ können Sie Dateien auch direkt per Drag & Drop in dieses Feld ziehen.',
  'new_claim.date.info_text_max_error_message': 'Maximal 50 Wörter',
  'new_claim.select_treatment_creation_date.helper_text':
    'Bitte geben Sie das früheste Behandlungsdatum an, das auf der Rechnung aufgeführt ist.',
  'new_claim.treatment_amount.helper_text':
    'Um die Bearbeitungszeit zu verkürzen, geben Sie bitte den exakten, ungerundeten Rechnungsbetrag an.',
  'new_claim.submit_claim_form.data_protection_checkbox':
    'Ich stimme der Verarbeitung meiner Daten gemäß der <a>Datenschutzrichtlinie</a> für die Schadensfallbearbeitung zu.',

  // New Claim Creation Flow Dentolo
  'dentolo.new_claim.select_treatment_category.page_title': 'Behandlungsart auswählen',
  'dentolo.new_claim.select_treatment_category.page_description':
    'Hier können Sie die Art Ihrer Behandlung auswählen, um einen Leistungsfall einzureichen.',
  'dentolo.new_claim.select_treatment_category.teeth_cleaning': 'Zahnreinigung',
  'dentolo.new_claim.select_treatment_category.tooth_filling': 'Füllung',
  'dentolo.new_claim.select_treatment_category.other_treatments': 'Andere Behandlung',
  'dentolo.new_claim.select_document_type.page_title':
    'Was für ein Dokument möchten Sie hochladen?',
  'dentolo.new_claim.select_document_type.invoice': 'Rechnung',
  'dentolo.new_claim.select_document_type.treatment_plan': 'Kostenvoranschlag',
  'dentolo.new_claim.select_document_creation_date.page_title': 'Wann wurde das Dokument erstellt?',
  'dentolo.new_claim.select_document_creation_date.form_description': 'Datum des Dokumentes',
  'dentolo.new_claim.select_treatment_creation_date.page_title':
    'Wann war der erste Tag der Behandlung?',
  'dentolo.new_claim.select_treatment_creation_date.form_description': 'Behandlungsdatum',
  'dentolo.new_claim.select_treatment_creation_date.check_label_text':
    'Vorheriges Datum übernehmen',
  'dentolo.new_claim.date.error_message': 'Bitte geben Sie ein gültiges Datum ein.',
  'dentolo.new_claim.treatment_amount.page.title': 'Wie hoch ist der ausgewiesene Betrag?',
  'dentolo.new_claim.treatment_amount.form.label.amount': 'Betrag',
  'dentolo.new_claim.treatment_amount.form.error.invalid_treatment_amount':
    'Bitte geben Sie einen gültiges Betrag ein',
  'dentolo.new_claim.dentist_details.page_title': 'Wie heißt Ihr Zahnarzt?',
  'dentolo.new_claim.dentist_details.input_label': 'Name des Zahnarztes',
  'dentolo.new_claim.filling_count.page.title': 'Wie viele Füllungen haben Sie bekommen?',
  'dentolo.new_claim.treatment_amount.form.label.filling_count': 'Anzahl der Füllungen',
  'dentolo.new_claim.treatment_amount.form.error.invalid_filling_count':
    'Bitte geben Sie eine gültige Anzahl ein',
  'dentolo.new_claim.submit_claim_form.page_title': 'Letzter Schritt: Bestätigung Ihrer Angaben',
  'dentolo.new_claim.submit_claim_form.treatment_category.pzr': 'Zahnreinigung',
  'dentolo.new_claim.submit_claim_form.treatment_category.filling': 'Füllung',
  'dentolo.new_claim.submit_claim_form.treatment_category.other': 'Zahnbehandlung',
  'dentolo.new_claim.submit_claim_form.card_label.document_type': 'Art des Dokumentes:',
  'dentolo.new_claim.submit_claim_form.card_label.document_date':
    'Erstellungsdatum des Dokumentes:',
  'dentolo.new_claim.submit_claim_form.card_label.treatment_date': 'Datum der ersten Behandlung:',
  'dentolo.new_claim.submit_claim_form.card_label.amount': 'Betrag:',
  'dentolo.new_claim.submit_claim_form.card_label.fillings': 'Anzahl der Füllungen:',
  'dentolo.new_claim.submit_claim_form.card_label.dentist': 'Zahnarzt:',
  'dentolo.new_claim.submit_claim_form.uploaded_files.title': 'Dokumente',
  'dentolo.new_claim.submit_claim_form.send_button': 'Absenden',
  'dentolo.new_claim.existing_claim_question.page.title':
    'Handelt sich diese Rechnung um eine Folgebehandlung oder einen bestehenden Leistungsfall?',
  'dentolo.new_claim.existing_claim_question.option.yes': 'Yes',
  'dentolo.new_claim.existing_claim_question.option.no': 'No',
  'dentolo.new_claim.existing_claims.page.title':
    'Um welchen bestehenden Leistungsfall handelt es sich?',
  'dentolo.new_claim.existing_claims.form.label.not_selected': 'Keiner dieser Leistungsfälle',

  // New Claim Creation Flow Petolo
  'pet_health.new_claim.select_treatment_category.page_title':
    'Welche Behandlung wurde durchgeführt?',
  'pet_health.new_claim.select_treatment_category.other': 'Andere Behandlungen',
  'petolo.new_claim.select_document_type.page_title': 'Was für ein Dokument möchten Sie hochladen?',
  'petolo.new_claim.select_document_type.invoice': 'Rechnung',
  'petolo.new_claim.select_document_type.treatment_plan': 'Kostenvoranschlag',
  'petolo.new_claim.select_document_creation_date.page_title': 'Wann wurde das Dokument erstellt?',
  'petolo.new_claim.select_document_creation_date.form_description': 'Datum des Dokumentes',
  'petolo.new_claim.select_treatment_creation_date.page_title':
    'Wann war der erste Tag der Behandlung?',
  'petolo.new_claim.select_treatment_creation_date.form_description': 'Behandlungsdatum',
  'petolo.new_claim.select_treatment_creation_date.check_label_text': 'Vorheriges Datum übernehmen',
  'petolo.new_claim.treatment_amount.page.title': 'Wie hoch ist der ausgewiesene Betrag?',
  'petolo.new_claim.treatment_amount.form.label.amount': 'Betrag',
  'petolo.new_claim.treatment_amount.form.error.invalid_treatment_amount':
    'Bitte geben Sie einen gültiges Betrag ein',
  'petolo.new_claim.treatment_amount.form.hint_text':
    'Rechnungen, die Leistungen für mehrere versicherte Tiere umfassen, müssen im jeweiligen Vertrag hochgeladen werden. Dabei kann die gleiche Rechnung mehrfach verwendet werden.',
  'petolo.new_claim.consultation_question.page.title':
    'Haben Sie vor der ärztlichen Sprechstunde eine digitale Erstberatung in Anspruch genommen?',
  'petolo.new_claim.consultation_question.option.yes': 'Ja',
  'petolo.new_claim.consultation_question.option.no': 'Nein',
  'petolo.new_claim.treatment_info_text.page_title':
    'Wann sind die Symptome aufgetreten und was war der Grund für die Behandlung?',
  'petolo.new_claim.treatment_info_text.page_description':
    'Bitte geben Sie das Datum an wann die Symptome erstmals aufgetreten sind und warum die Behandlung notwendig war',
  'petolo.new_claim.submit_claim_form.treatment_category.other': 'Übersicht',
  'petolo.new_claim.submit_claim_form.page_title': 'Letzter Schritt: Bestätigung Ihrer Angaben',
  'petolo.new_claim.submit_claim_form.uploaded_files.title': 'Dokumente',
  'petolo.new_claim.submit_claim_form.send_button': 'Absenden',
  'petolo.new_claim.submit_claim_form.card_label.document_type': 'Art des Dokumentes:',
  'petolo.new_claim.submit_claim_form.card_label.document_date': 'Erstellungsdatum des Dokumentes:',
  'petolo.new_claim.submit_claim_form.card_label.treatment_date': 'Datum der ersten Behandlung:',
  'petolo.new_claim.submit_claim_form.card_label.amount': 'Betrag:',
  'petolo.new_claim.submit_claim_form.card_label.treatment_necessary_answer':
    'Grund der Behandlung:',
  'petolo.new_claim.submit_claim_form.card_label.videoConsultation': 'Digitale Erstberatung:',
  'petolo.new_claim.select_video_consultation.page.title':
    'Wann hat die digitale Erstberatung stattgefunden?',
  'petolo.new_claim.select_video_consultation.option.default.label': 'Keine passende Erstberatung',
  'petolo.new_claim.select_video_consultation.label':
    '{timestamp, date, ::dd.MM.yyyy} - {timestamp, time, ::HH:mm} Uhr',
  'petolo.new_claim.treatment_info_text.placeholder': 'Maximal 80 Wörter',
  'petolo.new_claim.treatment_info_text.error': 'Maximal 80 Wörter',
  'petolo.new_claim.existing_claim_question.page.title':
    'Handelt es sich bei dieser Rechnung um eine Folgebehandlung zu einem bestehenden Leistungsfall?',
  'petolo.new_claim.existing_claim_question.option.yes': 'Ja',
  'petolo.new_claim.existing_claim_question.option.no': 'Nein',
  'petolo.new_claim.existing_claims.page.title':
    'Um welchen bestehenden Leistungsfall handelt es sich?',
  'petolo.new_claim.existing_claims.form.label.not_selected': 'Keiner dieser Leistungsfälle',

  // New Claim Creation Flow `Pet Liability`
  'pet_liability.new_claim.liability_reason.page_title': 'Was genau ist passiert?',
  'pet_liability.new_claim.liability_reason.item': 'Gegenstand beschädigt',
  'pet_liability.new_claim.liability_reason.another_animal': 'Anderes Tier wurde verletzt',
  'pet_liability.new_claim.liability_reason.vehicle': 'KFZ/Verkehrsmittel beschädigt',
  'pet_liability.new_claim.liability_reason.property': 'Gebäude/Grundstück beschädigt',
  'pet_liability.new_claim.liability_reason.person': 'Person wurde verletzt',
  'pet_liability.new_claim.liability_reason.other': 'Sonstiges',
  'pet_liability.new_claim.liability_damage_caused_by.page_title.damage':
    'Wer hat den Schaden verursacht?',
  'pet_liability.new_claim.liability_damage_caused_by.page_title.injury':
    'Wer hat die Verletzung verursacht?',
  'pet_liability.new_claim.liability_damage_caused_by.other': 'Jemand anderes',
  'pet_liability.new_claim.pet_accompanied_by.page_title':
    'Wer war zum relevanten Zeitpunkt mit {petName} unterwegs?',
  'pet_liability.new_claim.pet_accompanied_by.myself': 'Ich selbst',
  'pet_liability.new_claim.pet_accompanied_by.partner': 'Partner:in',
  'pet_liability.new_claim.pet_accompanied_by.family': 'Familenmitglied/Verwandte',
  'pet_liability.new_claim.pet_accompanied_by.flatmate': 'Mitbewohner:in',
  'pet_liability.new_claim.pet_accompanied_by.work_colleague': 'Arbeitskolleg:in',
  'pet_liability.new_claim.pet_accompanied_by.no_relation': 'Keine Beziehung',
  'pet_liability.new_claim.damage_estimate.page_title': 'Wie hoch schätzen Sie den Gesamtschaden?',
  'pet_liability.new_claim.damage_estimate.upto_500': '0 bis 500 €',
  'pet_liability.new_claim.damage_estimate.501_1000': '501 bis 1,000 €',
  'pet_liability.new_claim.damage_estimate.1001_5000': '1,001 bis 5,000 €',
  'pet_liability.new_claim.damage_estimate.5001_10000': '5,001 bis 10,000 €',
  'pet_liability.new_claim.damage_estimate.unknown': 'Unbekannt',
  'pet_liability.new_claim.affected_party.page_title.injured_animal':
    'Wer ist Halter:in des verletzten Tieres?',
  'pet_liability.new_claim.affected_party.page_title.injured_person': 'Wer wurde verletzt?',
  'pet_liability.new_claim.affected_party.page_title.who_is_affected':
    'Wer ist von dem Schaden betroffen?',
  'pet_liability.new_claim.affected_party.option.myself': 'Ich selbst',
  'pet_liability.new_claim.affected_party.option.other': 'Jemand anderes',
  'pet_liability.new_claim.relation_with_affected_party.page_title.injured_animal':
    'In welcher Beziehung stehen Sie zur Halter:in?',
  'pet_liability.new_claim.relation_with_affected_party.page_title.damage':
    'In welcher Beziehung stehen Sie zu der geschädigten Person?',
  'pet_liability.new_claim.relation_with_affected_party.page_title.injured_person':
    'In welcher Beziehung stehen Sie zur verletzten Person?',
  'pet_liability.new_claim.relation_with_affected_party.partner': 'Partner:in',
  'pet_liability.new_claim.relation_with_affected_party.family': 'Familenmitglied/Verwandte',
  'pet_liability.new_claim.relation_with_affected_party.flatmate': 'Mitbewohner:in',
  'pet_liability.new_claim.relation_with_affected_party.work_colleague': 'Arbeitskolleg:in',
  'pet_liability.new_claim.relation_with_affected_party.no_relation': 'Keine Beziehung',
  'pet_liability.new_claim.medical_exam.page_title_person':
    'Kam es zu einer ärztlichen Untersuchung?',
  'pet_liability.new_claim.medical_exam.page_title_animal':
    'Kam es zu einer tierärztlichen Untersuchung?',
  'pet_liability.new_claim.medical_exam.yes': 'Ja',
  'pet_liability.new_claim.medical_exam.no': 'Nein',
  'pet_liability.new_claim.incident_location.page_title': 'Wo genau ist es passiert?',
  'pet_liability.new_claim.details_of_affected_party.page_title.injured_animal':
    'Details zur Halter:in',
  'pet_liability.new_claim.details_of_affected_party.page_title.injured_person':
    'Wer wurde verletzt?',
  'pet_liability.new_claim.details_of_affected_party.page_title.damage':
    'Details zur geschädigten Person.',
  'pet_liability.new_claim.incident_note.page_title': 'Wie ist es dazu gekommen?',
  'pet_liability.new_claim.incident_note.page_description':
    'Beschreiben Sie bitte kurz, was passiert ist.',
  'pet_liability.new_claim.incident_note.placeholder': 'Maximal 150 Wörter',
  'pet_liability.new_claim.upload_document.page.description':
    'Bitte laden Sie relevante Dokumente wie Rechnungen, Bilder vom Schaden oder Ähnliches hoch.',
  'pet_liability.new_claim.upload_document.file_uploader.description_with_files':
    'Bitte überprüfen Sie, ob Sie alle Ihre gewünschten Dateien korrekt ausgewählt haben. Alle Dateien mit dem Status "Bereit zum Absenden" werden übertragen.',
  'pet_liability.new_claim.incident_date_time.page_title.injury': 'Wann kam es zu der Verletzung?',
  'pet_liability.new_claim.incident_date_time.page_title.damage': 'Wann ist der Schaden passiert?',
  'pet_liability.new_claim.incident_date_time.checkbox.label.dont_know_time':
    'Ich weiß die genaue Uhrzeit nicht',
  'pet_liability.new_claim.damaged_item.page_title': 'Was wurde beschädigt?',
  'pet_liability.new_claim.damaged_item.form.label.damaged_item': 'Beschädigtes Objekt',
  'pet_liability.new_claim.damaged_item.form.label.damaged_item_age':
    'Alter des beschädigten Objekts',
  'pet_liability.new_claim.damaged_item.form.label.damaged_item_price': 'Anschaffungspreis',
  'pet_liability.new_claim.damaged_item.form.error.invalid_price': 'Der Betrag ist ungültig',
  'pet_liability.new_claim.submit_claim_form.page_title':
    'Letzter Schritt: Bestätigung Ihrer Angaben',
  'pet_liability.new_claim.submit_claim_form.data_card.title': 'Übersicht',
  'pet_liability.new_claim.submit_claim_form.card_label.tariff': 'Tarif:',
  'pet_liability.new_claim.submit_claim_form.card_label.incident_date': 'Datum:',
  'pet_liability.new_claim.submit_claim_form.card_label.city': 'Ort:',
  'pet_liability.new_claim.submit_claim_form.uploaded_files_card.title': 'Dokumente',
  'pet_liability.new_claim.submit_claim_form.button.send': 'Absenden',

  'new_claim.form.label.date': 'Datum',
  'new_claim.form.label.time': 'Uhrzeit',
  'new_claim.form.label.name': 'Name',
  'new_claim.form.label.street_number': 'Straße, Nummer',
  'new_claim.form.label.postcode_city': 'Postleitzahl, Ort',
  'new_claim.form.label.email.optional': 'Email (optional)',
  'new_claim.form.label.phone.optional': 'Telefonnummer (optional)',

  // New Claim Creation Flow Vitolo
  'vitolo.new_claim.select_document_type.invoice': 'Rechnung',
  'vitolo.new_claim.select_document_type.treatment_plan': 'Kostenvoranschlag',
  'vitolo.new_claim.select_document_type.page_title': 'Was für ein Dokument haben Sie hochgeladen?',
  'vitolo.new_claim.submit_claim_form.send_button': 'Absenden',
  'vitolo.new_claim.select_document_creation_date.page_title':
    'Wann wurde das erste Dokument, von den soeben hochgeladenen Dateien, erstellt?',
  'vitolo.new_claim.select_document_creation_date.form_description': 'Datum des Dokumentes',
  'vitolo.new_claim.treatment_amount.page.title': 'Wie hoch ist der ausgewiesene Betrag?',
  'vitolo.new_claim.treatment_amount.form.label.amount': 'Betrag',
  'vitolo.new_claim.treatment_amount.form.error.invalid_treatment_amount':
    'Bitte geben Sie einen gültiges Betrag ein',
  'vitolo.new_claim.treatment_info_text.page_title':
    'Haben Sie bereits eine Erstattung von anderen Versicherern für Ihren Anspruch erhalten?',
  'vitolo.new_claim.treatment_info_text.page_description':
    'Wenn ja, teilen Sie uns bitte mit, welche Leistungen Sie erhalten haben oder noch erhalten werden, z.B. von Ihrer gesetzlichen Krankenkasse, Unfallversicherung, Zahnzusatzversicherung oder anderen.',
  'vitolo.new_claim.treatment_info_text.placeholder': 'Maximal 80 Wörter',
  'vitolo.new_claim.treatment_info_text.error': 'Maximal 80 Wörter',
  'vitolo.new_claim.treatment_info_text.page_skip_text':
    'Sollten Sie keine zusätzlichen Erstattungen von anderen Versicherern erhalten, bitte mit dem nächsten Schritt fortfahren.',
  'vitolo.new_claim.submit_claim_form.card_label.document_type': 'Art des Dokumentes:',
  'vitolo.new_claim.submit_claim_form.card_label.document_date': 'Erstellungsdatum des Dokumentes:',
  'vitolo.new_claim.submit_claim_form.card_label.amount': 'Betrag:',
  'vitolo.new_claim.submit_claim_form.card_label.treatment_answer':
    'Ersatzleistungen von anderen Versicherung:',
  'vitolo.new_claim.submit_claim_form.treatment_category.other': 'Übersicht',
  'vitolo.new_claim.existing_claims.page.title':
    'Um welchen bestehenden Leistungsfall handelt es sich?',
  'vitolo.new_claim.existing_claims.form.label.not_selected': 'Keiner dieser Leistungsfälle',
  'vitolo.new_claim.submit_claim_form.page_title': 'Letzter Schritt: Bestätigung Ihrer Angaben',
  'vitolo.new_claim.submit_claim_form.uploaded_files.title': 'Dokumente',

  // 404 page
  'petolo.404.page.message': 'Oje, diese Seite existiert nicht',

  // cancellation contract
  'cancellation.contract.cancel.exit': 'Abbrechen',
  'cancellation.contract.confirm.continue': 'Widerruf bestätigen',
  'cancellation.contract.confirm.cancel': 'Kündigung bestätigen',
  'cancellation.contract.title': 'Schade, dass Sie gehen möchten!',
  'cancellation.contract.withdrawl.success.title':
    'Ihr Vertrag wurde erfolgreich widerrufen. Sie erhalten eine Bestätigung per E-Mail.',
  'cancellation.contract.withdrawl.success.description':
    'Bitte geben Sie einen Grund für Ihren Widerruf an damit wir unsere Services verbessern können.',
  'cancellation.contract.regular.success.title':
    'Ihr Vertrag wurde erfolgreich gekündigt. Sie erhalten eine Bestätigung per E-Mail.',
  'cancellation.contract.regular.success.description':
    'Bitte geben Sie einen Grund für Ihre Kündigung an damit wir unsere Services verbessern können.',
  'cancellation.contract.button.send': 'Absenden',
  'cancellation.contract.button.backToHome': 'Zurück zur Startseite',
  'contract.cancellation.success.title': 'Vielen Dank für Ihr Feedback!!',
  'contract.cancellation.success.button': 'Zurück zur Startseite',
  'contract.cancellation.failure.title':
    'Fehler! Es gab ein Problem mit der Kündigung Ihres Vertrags.',
  'contract.cancellation.failure.text':
    'Bei bestehenden Problemen kontaktieren Sie bitte unseren Kundendienst.',
  'contract.cancellation.failure.button': 'Zum Kundendienst',
  'cancellation.contract.landing.page.question': 'Möchten Sie Ihren Vertrag wirklich widerrufen?',
  'cancellation.contract.landing.page.warning1':
    'Sie befinden sich noch innerhalb der Widerrufsfrist. Wenn Sie fortfahren, verlieren Sie Ihren Versicherungsschutz.',
  'cancellation.contract.landing.page.warning2':
    'Zuviel gezahlte Beiträge erstatten wir Ihnen automatisch auf das uns bekannte Konto in den nächsten Tagen.',
  'cancellation.contract.landing.page.warning3':
    'Ihre offenen Leistungsfälle werden automatisch geschlossen. Eine Erstattung ist nicht mehr möglich.',
  'cancellation.contract.landing.page.warning4':
    'Ihre offenen Leistungsfälle werden automatisch geschlossen und eine Erstattung ist nicht mehr möglich.',
  'cancellation.contract.landing.page.regular.next_possible_date':
    'Das nächstmögliche Kündigungsdatum ist:',
  'cancellation.contract.landing.page.regular.text1':
    'Da Sie bereits eine Auszahlung von uns erhalten haben, greift die Mindestlaufzeit von Ihrem Vertrag gemäß Ziffer 5 der Versicherungsbedingungen.',
  'cancellation.contract.landing.page.regular.text2':
    'Wir werden Ihren Vertrag zum nächstmöglichen Zeitpunkt zum Monatsende kündigen.',
  'cancellation.contract.landing.page.regular.text3':
    'Zuviel gezahlte Beiträge erstatten wir Ihnen automatisch auf das uns bekannte Konto in den nächsten Tagen.',
  'cancellation.contract.landing.page.regular.text4':
    'Ihre offenen Leistungsfälle werden  automatisch geschlossen. Eine Erstattung ist nicht mehr möglich.',
  'cancellation.contract.landing.page.regular.text5':
    'Aufgrund des Akutschutz Moduls ist eine Kündigung erst nach Ablauf der Mindestvertragsdauer möglich.',

  // Block screen when contract is not approved
  'contract_not_approved.title.dentolo': 'Danke, dass Sie dentolo gewählt haben!',
  'contract_not_approved.title.petolo': 'Danke, dass Sie petolo gewählt haben!',
  'contract_not_approved.title.vitolo': 'Danke, dass Sie vitolo gewählt haben!',
  'contract_not_approved.text_1':
    'Ihr Vertrag wird zur Zeit geprüft, aber das sollte nicht lange dauern.',
  'contract_not_approved.text_2':
    'Bitte warten Sie ein paar Minuten und aktualisieren Sie die Seite.',
  'contract_not_approved.button': 'Seite aktualisieren',

  // Insure Another Pet Banner
  'insure_another_pet.banner.title': 'Jetzt weitere Fellnasen absichern!',
  'insure_another_pet.banner.description':
    'Sie haben mehr als ein Haustier, das Sie versichern möchten? Klicken Sie jetzt auf “Weitere Haustiere absichern”, um weitere Fellnasen zu versichern.',
  'insure_another_pet.banner.button.label': 'Weitere Haustiere absichern',

  // Maintenance Banner
  'maintenance_banner.text':
    'Wartungsarbeiten am 07.02. von 6:30 Uhr bis etwa 7:30 Uhr, der Zugriff auf Ihr Kundenkonto ist in dieser Zeit nicht möglich.',

  // Link to claims page
  'link_to_claims.title': 'Kostenerstattung',
  'link_to_claims.description':
    'Um eine Kostenerstattung zu beantragen, können Sie <a>hier</a> einfach Ihre Rechnung hochladen',

  // Already uploaded documents
  'user_claims_info.already_uploaded_documents.title': 'Hochgeladene Dokumente',
  'user_claims_info.already_uploaded_documents.document_date_label': 'Hochgeladen am',
  'user_claims_info.already_uploaded_documents.document_open_link': 'Anzeigen',

  // Decline cat ATB upgrade
  'decline_cat_atb_upgrade.title': 'Aktualisierung ablehnen',
  'decline_cat_atb_upgrade.description.paragraph_1':
    'Gemäß unserer E-Mail bieten wir Ihnen hier die Möglichkeit, die Aktualisierung Ihrer Versicherungsbedingungen, einschließlich des Zuschusses von Fehlentwicklungen, abzulehnen.',
  'decline_cat_atb_upgrade.description.paragraph_2':
    'Hier können Sie die bisherigen sowie die aktualisierten Versicherungsbedingungen einsehen:',
  'decline_cat_atb_upgrade.description.previous_document':
    'Bisherige Allgemeine Versicherungsbedingungen',
  'decline_cat_atb_upgrade.description.current_document':
    'Aktualisierte Allgemeine Versicherungsbedingungen',
  'decline_cat_atb_upgrade.description.paragraph_3':
    'Durch den Klick auf „Aktualisierung ablehnen“ wird Ihre Katzenkrankenversicherung nicht aktualisiert und es gelten weiterhin die bisherigen Allgemeinen Versicherungsbedingungen für Sie.',
  'decline_cat_atb_upgrade.confirm': 'Aktualisierung ablehnen',
  'decline_cat_atb_upgrade.confirm.success.title':
    'Ihre Versicherungsbedingungen werden nicht aktualisiert',
  'decline_cat_atb_upgrade.confirm.success.description':
    'Ihre Katzenkrankenversicherung wird nicht aktualisiert und es gelten weiterhin die ursprünglichen Versicherungsbedingungen für Sie.',
  'decline_cat_atb_upgrade.banner.title': 'Aktualisierung der Versicherungsbedingungen',
  'decline_cat_atb_upgrade.banner.contents':
    'Weitere Informationen und die Möglichkeit, die aktualisierten Versicherungsbedingungen abzulehnen, finden Sie [hier](/decline-cat-atb-upgrade).',

  // Akutschutz cancellation
  'choose_cancellation.title': 'Zahnschutz AKUT oder gesamten Versicherungsschutz kündigen',
  'choose_cancellation.cancel_only_akutschutz.title': 'Zahnschutz AKUT kündigen',
  'choose_cancellation.cancel_only_akutschutz.description':
    'Klicken Sie hier, um die AKUT-Soforthilfe aus Ihrem Versicherungsschutz zu entfernen.',
  'choose_cancellation.radio_cancel_contract.title': 'Gesamten Versicherungsschutz kündigen',
  'choose_cancellation.radio_cancel_contract.description':
    'Klicken Sie hier, wenn Sie Ihren gesamten dentolo Versicherungsschutz kündigen möchten.',
  'choose_cancellation.next_button': 'Weiter',

  'akutschutz_cancellation.contract_within_withdraw_period.title': 'Zahnschutz AKUT widerrufen',
  'akutschutz_cancellation.contract_within_withdraw_period.content_1':
    'Sie widerrufen den Abschluss der AKUT-Soforthilfe. Damit sinkt Ihr monatlicher Beitrag. Ihr Zahnschutz Komfort und der damit verbundene Versicherungsschutz bleibt bestehen.',
  'akutschutz_cancellation.contract_within_withdraw_period.content_2':
    'Um die Tarifdetails und Vorteile der AKUT-Soforthilfe einsehen zu können, bevor Sie mit dem Widerruf fortfahren, besuchen Sie gerne unseren <a>Artikel im Hilfecenter.</a>',
  'akutschutz_cancellation.contract_within_withdraw_period.confirm_cancel_button':
    'AKUT-Schutz widerrufen',
  'akutschutz_cancellation.contract_within_withdraw_period.abort_cancel_button': 'Abbrechen',

  'akutschutz_cancellation.contract_within_minimum_duration.title': 'Zahnschutz AKUT kündigen',
  'akutschutz_cancellation.contract_within_minimum_duration.content_1':
    'Durch Ihre Kündigung der AKUT-Soforthilfe wird sich Ihr monatlicher Beitrag reduzieren. Ihr Zahnschutz Komfort und der damit verbundene Versicherungsschutz bleibt bestehen.',
  'akutschutz_cancellation.contract_within_minimum_duration.content_2':
    'Bitte beachten Sie, dass die AKUT-Soforthilfe eine Mindestlaufzeit von 24 Monaten beinhaltet.',
  'akutschutz_cancellation.contract_within_minimum_duration.next_possible_cancellation_date':
    'Daher ist Ihr frühstmögliches Kündigungsdatum der',
  'akutschutz_cancellation.contract_within_minimum_duration.confirm_cancel_button':
    'AKUT-Schutz kündigen',
  'akutschutz_cancellation.contract_within_minimum_duration.abort_cancel_button': 'Abbrechen',

  'akutschutz_cancellation.contract_outside_minimum_duration.title': 'Zahnschutz AKUT kündigen',
  'akutschutz_cancellation.contract_outside_minimum_duration.content_1':
    'Durch Ihre Kündigung der AKUT-Soforthilfe wird sich Ihr monatlicher Beitrag reduzieren. Ihr Zahnschutz Komfort und der damit verbundene Versicherungsschutz bleibt bestehen.',
  'akutschutz_cancellation.contract_outside_minimum_duration.content_2':
    'Die Kündigung der AKUT-Soforthilfe tritt zum Ende des Monats in Kraft.',
  'akutschutz_cancellation.contract_outside_minimum_duration.next_possible_cancellation_date':
    'Ihr Kündigungsdatum ist der',
  'akutschutz_cancellation.contract_outside_minimum_duration.confirm_cancel_button':
    'AKUT-Schutz kündigen',
  'akutschutz_cancellation.contract_outside_minimum_duration.abort_cancel_button': 'Abbrechen',

  'akutschutz_cancellation.success.withdraw.title':
    'Ihr Zahnschutz AKUT wurde erfolgreich widerrufen.',
  'akutschutz_cancellation.success.withdraw.content':
    'Ihr Vertrag wurde dementsprechend angepasst.',
  'akutschutz_cancellation.success.cancel.title':
    'Ihr Zahnschutz AKUT wurde erfolgreich gekündigt.',
  'akutschutz_cancellation.success.cancel.content': 'Ihr Vertrag wurde dementsprechend angepasst.',
  'akutschutz_cancellation.success.back_to_start_page': 'Zurück zur Startseite',

  'akutschutz_cancellation.abort.withdraw.content':
    'Ihr Zahnschutz AKUT bleibt bestehen. Sie können ihn innerhalb der Widerrufsfrist in Ihrem Kundenkonto widerrufen.',
  'akutschutz_cancellation.abort.cancel.content':
    'Ihr Zahnschutz AKUT bleibt bestehen. Sie können ihn jederzeit in Ihrem Kundenkonto kündigen.',
  'akutschutz_cancellation.abort.back_to_page': 'Zurück',

  'akutschutz_cancellation.error.title': 'Leider ist ein Fehler aufgetreten.',
  'akutschutz_cancellation.error.content':
    'Bitte versuchen Sie es erneut. Sollte der Fehler weiterhin bestehen, wenden Sie sich bitte an unseren <a>Kundenservice.</a>',
  'akutschutz_cancellation.error.try_again': 'Erneut versuchen',

  // footer
  [`footer.copyright.${INSURANCE_TYPE_DENTOLO}`]: 'dentolo ist eine Marke der © getolo GmbH',
  [`footer.copyright.${INSURANCE_TYPE_PETOLO}`]: 'petolo ist eine Marke der © getolo GmbH',
  [`footer.copyright.${INSURANCE_TYPE_VITOLO}`]: 'vitolo ist eine Marke der © getolo GmbH',

  // Claim Status Details
  [`claim_status_details.${CLAIM_STATUS_DETAILS.CLAIM_OPENED}`]: `
      {insuranceType, select,
        ${INSURANCE_TYPE_PETOLO} {Ihr Leistungsfall wurde angelegt. Die Bearbeitung kann aktuell bis zu 14 Tage dauern. Wir bedauern die Verzögerung, werden Ihre Unterlagen aber schnellstmöglich prüfen und Sie per E-Mail über weitere Schritte informieren.}
        ${INSURANCE_TYPE_DENTOLO} {Ihr Leistungsfall wurde angelegt. Die Bearbeitung kann aktuell bis zu 14 Tage dauern. Wir bedauern die Verzögerung, werden Ihre Unterlagen aber schnellstmöglich prüfen und Sie per E-Mail über weitere Schritte informieren.}
        other {Ihr Leistungsfall wurde angelegt. Die Prüfung dauert in der Regel bis zu fünf Werktage. Wir melden uns bei Ihnen sobald es Neuigkeiten gibt.}
      }`,
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_OR_COSTPLAN_MISSING}`]:
    'Zur Bearbeitung Ihres Leistungsfalls benötigen wir noch den von Ihrer Krankenkasse genehmigten Heil- und Kostenplan.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.INFORMATION_REQUEST_MISSING}`]:
    'Wir benötigen die ausgefüllten Dokumente, um Ihre Kostenerstattung zu bearbeiten.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.PATIENT_FILE_MISSING}`]:
    'Zur Bearbeitung Ihres Leistungfalls benötigen wir eine Kopie Ihrer Patientenakte. Diese können Sie in der Praxis anfordern, die die Behandlung durchführt.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.INVOICE_MISSING}`]:
    'Zur abschließenden Bearbeitung Ihres Leistungfalls benötigen wir noch die Rechnung Ihrer Behandlung.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.DOCUMENTS_MISSING}`]:
    'Zur Bearbeitung Ihres Leistungfalls benötigen wir noch weitere Unterlagen.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.PET_MEDICAL_CARD_IS_MISSING}`]: `Zur Bearbeitung Ihres Leistungfalls benötigen wir eine Kopie Ihrer Krankenakte. Diese können Sie bei Ihrem {insuranceType, select, ${INSURANCE_TYPE_DENTOLO} {Zahnarzt} ${INSURANCE_TYPE_PETOLO} {Tierarzt} other {Arzt}} anfordern.`,
  [`claim_status_details.${CLAIM_STATUS_DETAILS.REIMBURSEMENT_ACKNOWLEDGED}`]: `
      {reimbursableAmountAvailable, select,
        true {Gemäß unseren Versicherungsbedingungen erstatten wir <b>{reimbursableAmount, number, ::sign-auto currency/EUR}</b> für Ihre anstehende Behandlung. Unsere Zusage basiert auf den von Ihnen eingereichten Unterlagen.}
        other {Wir freuen uns Ihnen eine Deckungszusage geben zu können. Für die Erstattung benötigen wir noch die Rechnung Ihrer Behandlung.}
      }`,
  [`claim_status_details.${CLAIM_STATUS_DETAILS.PARTIAL_REIMBURSEMENT_ACKNOWLEDGED}`]: `
      {reimbursableAmountAvailable, select,
        true {Gemäß unseren Versicherungsbedingungen erstatten wir <b>{reimbursableAmount, number, ::sign-auto currency/EUR}</b> für Ihre anstehende Behandlung. Unsere Zusage basiert auf den von Ihnen eingereichten Unterlagen.}
        other {Wir freuen uns Ihnen eine Deckungszusage geben zu können, benötigen jedoch noch die Rechnung Ihrer Behandlung.}
      }`,
  [`claim_status_details.${CLAIM_STATUS_DETAILS.REIMBURSEMENT_WILL_BE_PAID}`]:
    'Ihr Leistungsfall wurde angelegt. Wir prüfen Ihre Unterlagen schnellstmöglich und informieren Sie per E-Mail über weitere Schritte.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.PARTIAL_REIMBURSEMENT_WILL_BE_PAID}`]: `
      {sameIban, select,
        true {Die Erstattung in Höhe von {payoutAmount, number, ::sign-auto currency/EUR} wird auf folgendes Konto überwiesen {recepientIban}.}
        other {Die Erstattung in Höhe von {payoutAmount, number, ::sign-auto currency/EUR} wird auf das Konto von {recepientName} mit der IBAN {recepientIban} überwiesen.}
      }`,
  [`claim_status_details.${CLAIM_STATUS_DETAILS.REIMBURSEMENT_PAID}`]: `
      {sameIban, select,
        true {Die Erstattung in Höhe von {payoutAmount, number, ::sign-auto currency/EUR} wurde auf folgendes Konto überwiesen {recepientIban}.}
        other {Die Erstattung in Höhe von {payoutAmount, number, ::sign-auto currency/EUR} wurde auf das Konto von {recepientName} mit der IBAN {recepientIban} überwiesen.}
      }`,
  [`claim_status_details.${CLAIM_STATUS_DETAILS.PARTIAL_REIMBURSEMENT_PAID}`]: `
      {sameIban, select,
        true {Die Erstattung in Höhe von {payoutAmount, number, ::sign-auto currency/EUR} wurde auf folgendes Konto überwiesen {recepientIban}.}
        other {Die Erstattung in Höhe von {payoutAmount, number, ::sign-auto currency/EUR} wurde auf das Konto von {recepientName} mit der IBAN {recepientIban} überwiesen.}
      }`,
  [`claim_status_details.${CLAIM_STATUS_DETAILS.CONTRACT_SIGNATURE_MISSING}`]:
    'Wir freuen uns Ihnen eine Deckungszusage geben zu können.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.WITHDRAWAL_PERIOD_STILL_RUNNING}`]:
    'Wir freuen uns Ihnen eine Deckungszusage geben zu können. Die Auszahlung der Erstattung erfolgt nach Ablauf der 14-tägigen Widerrufsfrist.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.OUTSTANDING_PAYMENTS}`]:
    'Wir freuen uns Ihnen eine Deckungszusage geben zu können. Für die Auszahlung der Erstattung möchten wir Sie bitten zunächst die noch ausstehenden Beitragszahlungen auszugleichen.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TRIAL_MONTH}`]:
    'Wir freuen uns Ihnen eine Deckungszusage geben zu können. Die Auszahlung der Erstattung erfolgt nach Ablauf Ihres kostenlosen Probemonats.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_BEFORE_CONTRACT_START}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da die Behandlung bereits vor Versicherungsbeginn erfolgte.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.NO_COVERAGE}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da diese Behandlung durch Ihren Tarif nicht abgedeckt ist.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_PLAN}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da die durch den Heil- und Kostenplan angegebenen Behandlungen bereits vor dem Beginn Ihres Versicherungsschutzes als erforderlich erachtet wurden.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_DENTIST_REQUEST}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da die behandelten Zähne laut Auskunftsersuchen Ihres Zahnarztes bereits vor Versicherungsbeginn behandlungsbedürftig sind / waren.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_PRETREATMENT_PROVIDE}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da die behandelten Zähne laut Auskunftsersuchen Ihres Vorbehandlers bereits vor Versicherungsbeginn behandlungsbedürftig sind / waren.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_BEFORE_CONTRACT_START_BY_INVOICE}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da die Behandlung vor Versicherungsbeginn erfolgt ist.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.NO_BUDGET}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da ihr Leistungsbudget bereits ausgeschöpft ist.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.NO_COVERAGE_NO_MEDICAL_NEED}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da keine medizinische Notwendigkeit der Behandlung vorliegt.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.NO_COVERAGE_ORTHODONTICS}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da kieferorthopädische Behandlungen nicht von Ihrem Zahnschutz-Tarif abgedeckt sind.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.COVERAGE_FOR_CHILDREN_ONLY_FOR_ACCIDENT}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da bei Ihnen mitversicherte Kinder nur bei Unfall abgedeckt sind.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.MAX_AMOUNT_OF_TOOTH_CLEANINGS_REACHED}`]:
    'Sie haben leider die maximal erstattbare Anzahl an Professionellen Zahnreinigungen für dieses Versicherungsjahr erreicht.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.NO_COVERAGE_COSMETIC_MEASURES}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da es sich bei der Behandlung um eine rein kosmetische Maßnahme handelt.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.NOT_THE_INSURED_ANIMAL}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da das behandelte Tier nicht über Ihren Vertrag versichert ist.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.PET_HEALTH_BUDGET_EXHAUSTED}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da ihr Gesundheitsbudget für dieses Versicherungsjahr bereits ausgeschöpft ist.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.PET_SOS_BUDGET_EXHAUSTED}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da ihr SOS-Budget für dieses Versicherungsjahr bereits ausgeschöpft ist.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_VETERINARIAN_REQUEST}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da Ihr Tier laut Auskunftsersuchen Ihres Tierarztes bereits vor Versicherungsbeginn behandlungsbedürftig war.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_VATERINARIAN_PRETREATMENT_PROVIDE}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da die Behandlungsbedürftigkeit gemäß Videokonsultation bereits vor Versicherungsbeginn bekannt war.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.FIRSTVET_TREATMENT_NEEDED}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da die Behandlungsbedürftigkeit gemäß Videokonsultation bereits vor Versicherungsbeginn bekannt war.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_PERSON_IS_NOT_INSURED}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da die behandelte Person nicht der versicherten Person entspricht.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.REIMBURSEMENT_IS_ALREADY_COVERED}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da die Krankenversicherung bereits den gemäß Ihres Tarifs erstattungsfähigen Betrag abdeckt.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.COPAYMENT_IS_ALREADY_COVERED}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da nach erfolgreichem Abschluss der Behandlung Ihnen der Versichertenanteil von der gesetzl. Krankenversicherung erstattet wird.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_WAS_KNOWN_BEFORE_INSURANCE}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da die Behandlungsbedürftig laut der Patientenakte bereits vor Versicherungsbeginn bestand.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_BEFORE_CONTRACT_SIGN}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da die Behandlung bereits vor Vertragsabschluss erfolgte.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_NEEDS_BEFORE_CONTRACT_SIGN}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da die Behandlungsbedürftigkeit bereits vor Vertragsabschluss bekannt war.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_WAS_KNOWN_BEFORE_INSURANCE_BY_DENTIST}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da die behandelten Zähne laut Auskunftsersuchen Ihres Kieferorthopäden bereits vor Versicherungsbeginn behandlungsbedürftig sind/waren.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_DOCTOR_REQUEST}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da die Notwendigkeit der Behandlung laut Auskunftsersuchen bereits vor Versicherungsbeginn bekannt war.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.COVERAGE_OF_COSTS_THROUGH_THIRD_PARTY_DAMAGE_CLAIMS}`]:
    'Leider können wir Ihnen keine Erstattung zusagen, da Ihnen der Versichertenanteil durch einen weiteren Schadensanspruch zusteht.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.CANCELED_BY_DENTOLO}`]:
    'Wir haben Ihren Leistungsfall storniert.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.CANCELED_BY_CUSTOMER}`]:
    'Wir haben Ihren Leistungsfall auf Ihren Wunsch hin storniert.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.CLAIM_UPDATED}`]:
    'Ihr Dokument wurde erstellt. Die Bearbeitung kann derzeit bis zu 14 Tage dauern. Wir bedauern die Verzögerung, werden Ihre Unterlagen aber so schnell wie möglich prüfen und Sie per E-Mail über weitere Schritte informieren.',

  // Claim Details - Additional Payments
  'claim_details.additional_payments.payout_confirmed': `
    {sameIban, select,
      true {Die Erstattung in Höhe von {payoutAmount, number, ::sign-auto currency/EUR} wurde am {confirmedAt, date, ::dd.MM.yyyy} auf Ihr Konto überwiesen.}
      other {Die Erstattung in Höhe von {payoutAmount, number, ::sign-auto currency/EUR} wurde am {confirmedAt, date, ::dd.MM.yyyy} auf das Konto von {recepientName} mit der IBAN {recepientIban} überwiesen.}
    }`,

  'claim_details.additional_payments.payout_not_confirmed_yet': `
    {sameIban, select,
      true {Die Erstattung in Höhe von {payoutAmount, number, ::sign-auto currency/EUR} wird auf Ihr Konto überwiesen.}
      other {Die Erstattung in Höhe von {payoutAmount, number, ::sign-auto currency/EUR} wird auf das Konto von {recepientName} mit der IBAN {recepientIban} überwiesen.}
    }`,

  // Pet onboard
  'pet_onboard.card_1.title': 'Willkommen im petolo <br /> Kundenkonto!',
  'pet_onboard.card_1.body':
    'Von hier aus haben Sie Zugang zu allen wichtigen Informationen und Funktionen Ihres petolo Versicherungsschutzes.',
  'pet_onboard.card_1.button': 'Überspringen',

  'pet_onboard.card_2.title': 'Übersicht Ihrer petolo <br /> Leistungen',
  'pet_onboard.card_2.body':
    'In Ihrem Kundenkonto finden Sie alle Informationen zu Ihren Tarif-Leistungen, können Rechnungen in wenigen Schritten hochladen und z.B. den Status Ihrer Kostenerstattung einsehen.',
  'pet_onboard.card_2.button': 'Überspringen',

  'pet_onboard.card_3.title': 'Neue Funktionen &<br />exklusive Vorteile',
  'pet_onboard.card_3.body':
    'Ihre petolo Services & Vorteile werden stetig erweitert und verbessert, damit Sie einfach, schnell und unkompliziert von unseren Leistungen profitieren können. Über Änderungen in Ihrem Kundenkonto informieren wir Sie rechtzeitig.',
  'pet_onboard.card_3.button': 'Zum Kundenkonto',

  'contact.page.backlog.banner':
    'Wegen hoher Nachfrage kommt es zu Verzögerungen. Danke für Ihre Geduld.',
  // placeholder
  'placeholder.login.email': 'maria.muster@web.de',
  'placeholder.contact.email': 'alex.mueller@email.de',
  'placeholder.contact.phone': '0123 33959473',
  'placeholder.contact.street': 'Sonnenstraße',
  'placeholder.contact.house.number': '5',
  'placeholder.contact.address': 'z.B. Vorderhaus',
  'placeholder.account.first_name': 'Alex',
  'placeholder.account.last_name': 'Müller',
  'placeholder.account.iban': 'DE12 1111 2222 3333 4444 55',
  'placeholder.account.city': 'Berlin',

  //  customer service numbers

  [`customer.service.${INSURANCE_TYPE_DENTOLO}`]: '030 959 99 33 00',
  [`customer.service.${INSURANCE_TYPE_PETOLO}`]: '030 814 58 62 96',
  [`customer.service.${INSURANCE_TYPE_VITOLO}`]: '030 417 35010',
  'customer.service.patolo': '0458212002',
};

export default en;
