import React from 'react';
import { css, styled, Typography } from '@mui/material';

import { Input as InputField } from '../../../../components/common';

const StyledInputContainer = styled('div')`
  position: relative;
`;

const StyledInput = styled(InputField)`
  padding-right: 2.5rem;
`;

const StyledSuffix = styled('span')`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
`;

export const Input = (props) => {
  const { suffix, ...rest } = props;

  return (
    <StyledInputContainer>
      <StyledSuffix>
        <Typography variant="p">{suffix}</Typography>
      </StyledSuffix>
      <StyledInput {...rest} />
    </StyledInputContainer>
  );
};

export const StyledHintContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.gray.light_gray};
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 4px;
  `
);
