import { css, styled, Typography } from '@mui/material';

import { PALETTE } from '../../../theme/palette';
import ButtonContainer from '../../../components/ButtonContainer';

export const StyledVerticalContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledReferralButtonContainer = styled(ButtonContainer)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing('1XL')};
  `
);

export const StyledSocialGroup = styled('div')(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;

    ${theme.breakpoints.up('tablet')} {
      margin: 0 auto;
      width: 60%;
    }
  `
);

export const StyledSocialImg = styled('img')`
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
`;

export const StyledReferralStepsContainer = styled(StyledVerticalContent)`
  counter-reset: list-number;
`;

export const StyledReferralStepTitle = styled(Typography)(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: ${theme.spacing('SMALL')};
    margin-bottom: ${theme.spacing('SMALL')};

    &::before {
      content: counter(list-number) '.';
      counter-increment: list-number;
    }
  `
);

export const StyledReferralAdditionalInformationContainer = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing('SMALL')};
  `
);

export const StyledReferralAdditionalInformation = styled(Typography)(
  ({ theme }) => css`
    &::before {
      content: '';
      display: inline-block;
      width: ${theme.spacing('SMALL')};
      height: ${theme.spacing('SMALL')};
      background-color: ${theme.palette.background.referralBulletPoint};
      border-radius: 50%;
      margin-right: ${theme.spacing('SMALL')};
    }
  `
);

export const StyledReferralIdContainer = styled(StyledVerticalContent)(
  ({ theme }) => css`
    padding: ${theme.spacing('LARGE')};
    border-radius: 3px;
    background-color: ${theme.palette.gray.ultra_light_gray};
  `
);

export const StyledReferralIdBox = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: ${theme.spacing('SMALL')};
    width: 100%;
    max-width: 18.75rem;
    border: 1px solid ${theme.palette.main.product_blue};
    border-radius: 3px;
  `
);

export const StyledSeparator = styled('hr')(
  ({ theme }) => css`
    background-color: ${theme.palette.main.product_blue};
    border: none;
    height: 1px;
    margin: 0;
    width: 100%;
  `
);

export const StyledReferralTitle = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing('LARGE')};
  `
);

export const StyledReferralStatusTitle = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing('1XL')};
  `
);

export const StyledReferralListContainer = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacing('LARGE')} 0;
    border-bottom: 1px solid ${theme.palette.gray.light_gray};
  `
);

export const StyledReferralListItem = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    column-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledReferralListItemReferee = styled(Typography)`
  flex: 1;
  word-break: break-all;
  overflow-wrap: break-word;
`;

export const StyledReferraRewardBox = styled('div')(
  ({ theme }) => css`
    margin: ${theme.spacing('LARGE')} auto 0;
    padding: ${theme.spacing('SMALL')} 0;
    width: 100%;
    border-radius: 5px;
    border: 1px dashed ${theme.palette.main.professional_light_blue};
    text-align: center;
  `
);

export const StyledReferraRewardText = styled(Typography)(
  ({ theme }) => css`
    & + & {
      margin-top: ${theme.spacing('XSMALL')};
    }
  `
);

export const StyledReferralStatusDescriptionContainer = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing('2XL')};
  `
);

export const StyledReferralStatusDescription = styled(Typography)(
  ({ theme }) => css`
    & + & {
      margin-top: ${theme.spacing('1XL')};
    }
  `
);

export const StyledReferralMoreInformationText = styled(Typography)(
  ({ theme }) => css`
    margin-top: ${theme.spacing('SMALL')};
  `
);

const labelStatusStyles = (theme, status) =>
  ({
    pending: {
      color: PALETTE.BLUE_STATUS,
    },
    cancelled: {
      color: theme.palette.error.main,
    },
    sent: {
      color: theme.palette.success.main,
    },
  })[`${status}`];

export const StyledReferralStatusLabel = styled('div')(
  ({ theme, status }) => css`
    display: flex;
    column-gap: ${theme.spacing('SMALL')};
    align-items: center;
    white-space: nowrap;

    > img {
      width: 1.5rem;
      height: auto;
    }

    > span {
      ${labelStatusStyles(theme, status)};
    }
  `
);
