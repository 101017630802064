import React from 'react';
import { styled } from '@mui/material';

const StyledPath = styled('path')(({ theme, stroke }) => ({
  stroke: stroke || theme.palette.error.main,
}));

const IconXRoundedOutline = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <StyledPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        strokeWidth="2"
        {...props}
      />
      <StyledPath d="M9 9L15 15" strokeWidth="2" strokeLinecap="round" {...props} />
      <StyledPath d="M9 15L15 9" strokeWidth="2" strokeLinecap="round" {...props} />
    </svg>
  );
};

export default IconXRoundedOutline;
