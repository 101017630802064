import { styled } from '@mui/material';

export const StyledNotificationTitle = styled('div')`
  text-align: center;
  margin-bottom: 1rem;
`;

export const StyledPaymentStatusContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledPStatusIconContainer = styled('div')`
  margin-bottom: 1rem;
`;
