import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';

import { Icon } from '../../../components/common';

import DentistDetails from '../DentistsDetails';
import FavoriteDentist from '../FavoriteDentist';

import {
  StyledDentistsMapTabPanel,
  StyledDentistsMapTitlePaper,
  StyledDentistsMapToggleHelpButton,
} from './DentistsMapTitle.styled';

const DentistsMapTitle = ({ setOnboard, menuHeight, dentist, setDentist }) => {
  const [maxHeight, setMaxHeight] = useState('auto');
  const [selectedIndex, setSelectedIndex] = useState(0);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const handleResize = useCallback(() => {
    setMaxHeight(`${window.innerHeight - menuHeight - 200}px`);
  }, [setMaxHeight, menuHeight]);

  const handleSelect = (event, index) => {
    event.persist();
    setSelectedIndex(index);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [maxHeight, menuHeight, dentist, handleResize]);

  useEffect(() => {
    if (dentist) {
      setSelectedIndex(1);
    }
  }, [dentist, setSelectedIndex]);

  return (
    <StyledDentistsMapTitlePaper>
      <Tabs value={selectedIndex} onChange={handleSelect} aria-label="dentist details">
        <Tab
          label={
            <Typography variant={selectedIndex === 0 ? 'h3' : 'p'}>
              <FormattedMessage id="map.dentists.tabs.dentist.favourite" />
            </Typography>
          }
          id="favourite_dentists_tab"
          aria-controls="favorite_dentists_tabpanel"
        />

        <Tab
          label={
            <Typography variant={selectedIndex === 1 ? 'h3' : 'p'}>
              <FormattedMessage id="map.dentists.tabs.dentist.find" />
            </Typography>
          }
          id="selected_dentist_tab"
          aria-controls="selected_dentist_tabpanel"
        />
      </Tabs>

      <StyledDentistsMapTabPanel
        role="tabpanel"
        hidden={selectedIndex !== 0}
        id="favorite_dentists_tabpanel"
        aria-labelledby="favourite_dentists_tab"
        style={{ maxHeight }}
      >
        <FavoriteDentist />
      </StyledDentistsMapTabPanel>

      <StyledDentistsMapTabPanel
        role="tabpanel"
        hidden={selectedIndex !== 1}
        id="selected_dentist_tabpanel"
        aria-labelledby="selected_dentist_tab"
        style={{ maxHeight }}
      >
        <StyledDentistsMapToggleHelpButton>
          <Icon icon="icon.info" alt="help" />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link variant="p" component="button" onClick={() => setOnboard(true)}>
            <FormattedMessage id="map.dentists.network.about" />
          </Link>
        </StyledDentistsMapToggleHelpButton>

        {isDesktop && (
          <DentistDetails dentist={dentist} setDentist={setDentist} hidden={!isDesktop} />
        )}
      </StyledDentistsMapTabPanel>
    </StyledDentistsMapTitlePaper>
  );
};

DentistsMapTitle.propTypes = {
  setOnboard: PropTypes.func,
  menuHeight: PropTypes.number,
  setDentist: PropTypes.func,
};

export default DentistsMapTitle;
