import { useEffect } from 'react';

import useInsuranceInfo from './useInsuranceInfo';
import {
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
  THEME_DENTOLO,
  THEME_PETOLO,
  THEME_VITOLO,
} from '../shared';

const getFavIcon = (theme) => {
  switch (theme) {
    case THEME_DENTOLO:
      return '/favicon/favicon.ico';
    case THEME_PETOLO:
      return '/favicon/favicon-petolo.ico';
    case THEME_VITOLO:
      return '/favicon/favicon-vitolo.svg';
    default:
      return '';
  }
};

const updateFavicon = (theme) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  if (theme) {
    link.href = getFavIcon(theme);
  }
};

const getTheme = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return THEME_DENTOLO;
    case INSURANCE_TYPE_PETOLO:
      return THEME_PETOLO;
    case INSURANCE_TYPE_VITOLO:
      return THEME_VITOLO;
    default:
      return THEME_DENTOLO;
  }
};

const useApplicationTheme = () => {
  const { type: insuranceType } = useInsuranceInfo();
  const theme = getTheme(insuranceType);

  useEffect(() => {
    updateFavicon(theme);
  }, [theme]);

  return theme;
};

export default useApplicationTheme;
