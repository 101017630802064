import { css, styled } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const StyledDentistMapOnboardContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('SMALL')};
  `
);
