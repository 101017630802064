import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, DialogActions, DialogContent, TextField, Typography } from '@mui/material';

import useForm from '../../../shared/useForm';
import { GET_CUSTOMER, UPDATE_PET_DETAILS } from '../../../shared';
import { onlyDigitRegex, whitespaceRegex } from '../../../shared/regex';

import { StyledForm, StyledVerticalContent } from './CustomerDetailsEditor.styled';

const isNumber = (value) => onlyDigitRegex.test(value);

function validateForm(values) {
  const { transponderCode } = values || {};
  const codeWithoutWhitespaces = transponderCode
    ? transponderCode.replace(whitespaceRegex, '')
    : '';

  const errors = {};

  if (
    !codeWithoutWhitespaces ||
    codeWithoutWhitespaces?.length !== 15 ||
    !isNumber(codeWithoutWhitespaces) ||
    !Number.isInteger(Number.parseInt(codeWithoutWhitespaces, 10))
  ) {
    errors.transponderCode = 'pet.details.form.transponder_code.validation.message';
  }

  return errors;
}

const customValidateForm = (values) => {
  const { transponderCode } = values;
  const codeWithoutWhitespaces = transponderCode
    ? transponderCode.replace(whitespaceRegex, '')
    : '';

  const errors = {};

  if (
    !!codeWithoutWhitespaces &&
    (!isNumber(codeWithoutWhitespaces) ||
      !Number.isInteger(Number.parseInt(codeWithoutWhitespaces, 10)) ||
      codeWithoutWhitespaces?.length > 15)
  ) {
    errors.transponderCode = 'pet.details.form.transponder_code.validation.message';
  }

  return errors;
};

function PetDetailsEditor({ data, onSuccess, onError }) {
  const intl = useIntl();

  const [isProcessing, setIsProcessing] = useState(false);
  const [customErrors, setCustomErrors] = useState({});

  const {
    values,
    errors: formErrors,
    isDirty,
    handleChange,
    handleSubmit,
  } = useForm(data, handleFormSubmit, validateForm);

  const { name, transponderCode, ...otherValues } = values;

  const [updatePetDetails] = useMutation(UPDATE_PET_DETAILS, {
    variables: {
      ...otherValues,
      transponderCode: transponderCode ? transponderCode.replace(whitespaceRegex, '') : null,
    },
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  React.useEffect(() => {
    const errs = customValidateForm(values);
    setCustomErrors(errs);
  }, [values]);

  async function handleFormSubmit(ev) {
    if (ev) ev.preventDefault();

    try {
      setIsProcessing(true);

      const res = await updatePetDetails();
      const { status, errors: updateErrors } = res?.data?.updatePetDetails || {};

      if (status !== 'success') throw new Error(updateErrors);

      onSuccess();
    } catch (err) {
      onError();
    } finally {
      setIsProcessing(false);
    }
  }

  const isBtnDisabled =
    !isDirty ||
    isProcessing ||
    Object.keys(formErrors).length > 0 ||
    Object.keys(customErrors)?.length > 0;

  return (
    <>
      <DialogContent>
        <StyledForm onSubmit={handleSubmit} novalidate id="pet_details_editor_form">
          <StyledVerticalContent>
            <TextField
              fullWidth
              id="petName"
              name="name"
              placeholder="Luna"
              label={
                <Typography variant="h3" component="span">
                  <FormattedMessage id="pet.details.form.name.label" />
                </Typography>
              }
              value={values?.name || ''}
              disabled
            />

            <TextField
              fullWidth
              id="transponderCode"
              name="transponderCode"
              placeholder={intl.formatMessage({
                id: 'pet.details.form.transponder_code.placeholder',
              })}
              label={
                <Typography variant="h3" component="span">
                  <FormattedMessage id="pet.details.form.transponder_code.label" />
                </Typography>
              }
              value={values?.transponderCode || ''}
              onChange={handleChange}
              error={Boolean(customErrors?.transponderCode || formErrors?.transponderCode)}
              helperText={
                (customErrors?.transponderCode || formErrors?.transponderCode) && (
                  <Typography variant="p" color="error" component="span">
                    <FormattedMessage
                      id={customErrors?.transponderCode || formErrors?.transponderCode}
                    />
                  </Typography>
                )
              }
              slotProps={{
                htmlInput: {
                  inputMode: 'numeric',
                },
              }}
            />

            <Typography variant="p">
              <FormattedMessage id="pet.details.form.additional.details.line.1" />
            </Typography>

            <Typography variant="p">
              <FormattedMessage id="pet.details.form.additional.details.line.2" />
            </Typography>
          </StyledVerticalContent>
        </StyledForm>
      </DialogContent>

      <DialogActions>
        <Button type="submit" form="pet_details_editor_form" disabled={isBtnDisabled}>
          <Typography variant="buttons">
            <FormattedMessage id="pet.details.form.submit.button" />
          </Typography>
        </Button>
      </DialogActions>
    </>
  );
}

export default PetDetailsEditor;
