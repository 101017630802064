import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, Typography } from '@mui/material';

import {
  EDITOR_MODAL_MODE_FORM,
  EDITOR_MODAL_MODE_SUCCESS,
  EDITOR_MODAL_MODE_FAILURE,
} from '../../../shared';
import InfoWindow from '../../../components/InfoWindow';
import DialogHeader from '../../../components/DialogHeader';

const infoMessages = {
  success: {
    title: 'bank.details.update.success.title',
    message: 'bank.details.update.success.message',
  },
  failure: {
    title: 'bank.details.update.failure.title',
    message: 'bank.details.update.failure.message',
  },
};

function CustomerDetailsEditFormWrapper({
  isOpen,
  title,
  onClose,
  data,
  formComponent,
  hasActiveDigitalPaymentMethod,
  missingIban,
}) {
  const intl = useIntl();

  const [mode, setMode] = useState(EDITOR_MODAL_MODE_FORM);

  useEffect(() => {
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  useEffect(() => {
    if (!isOpen && mode !== EDITOR_MODAL_MODE_FORM) {
      setMode(EDITOR_MODAL_MODE_FORM);
    }

    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isOpen, mode]);

  let intlTitle = '';
  let intlMessage = '';

  if (mode === EDITOR_MODAL_MODE_SUCCESS || mode === EDITOR_MODAL_MODE_FAILURE) {
    intlTitle = intl.formatMessage({ id: infoMessages[`${mode}`].title });
    intlMessage = intl.formatMessage({ id: infoMessages[`${mode}`].message });
  }

  const isFormModalOpen = isOpen && mode === EDITOR_MODAL_MODE_FORM;
  const isInfoModalOpen =
    isOpen && (mode === EDITOR_MODAL_MODE_SUCCESS || mode === EDITOR_MODAL_MODE_FAILURE);

  const backButtonForFailureInfoWindow = {
    text: intl.formatMessage({ id: 'common.button.back' }),
    func: () => setMode(EDITOR_MODAL_MODE_FORM),
  };

  return (
    <>
      <Dialog
        open={isFormModalOpen}
        onClose={onClose}
        aria-labelledby="customer_details_edit_form_title"
        aria-describedby="customer_details_edit_form_content"
      >
        <DialogHeader handleClose={onClose}>
          {title && (
            <Typography variant="h2" id="customer_details_edit_form_title">
              {title}
            </Typography>
          )}
        </DialogHeader>

        <div id="customer_details_edit_form_content">
          {!!formComponent &&
            React.cloneElement(formComponent, {
              data,
              hasActiveDigitalPaymentMethod,
              missingIban,
              onSuccess: () => setMode(EDITOR_MODAL_MODE_SUCCESS),
              onError: () => setMode(EDITOR_MODAL_MODE_FAILURE),
            })}
        </div>
      </Dialog>

      <Dialog
        open={isInfoModalOpen}
        onClose={onClose}
        aria-describedby="customer_details_edit_status_content"
      >
        <DialogHeader handleClose={onClose} />

        <div id="customer_details_edit_status_content">
          <DialogContent>
            <InfoWindow
              mode={mode}
              titleHtml={intlTitle}
              textHtml={intlMessage}
              button={mode === EDITOR_MODAL_MODE_FAILURE ? backButtonForFailureInfoWindow : null}
            />
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
}

export default CustomerDetailsEditFormWrapper;

CustomerDetailsEditFormWrapper.propTypes = {
  isOpen: PropTypes.bool,
  missingIban: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.shape({
    billingDay: PropTypes.number,
    firstName: PropTypes.string,
    iban: PropTypes.string,
    lastName: PropTypes.string,
    // below props are pet data
    name: PropTypes.string,
    transponderCode: PropTypes.string,
  }),
  onClose: PropTypes.func,
  formComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
};
