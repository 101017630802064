import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_REFERRAL_LIST = gql`
  query {
    referrals {
      referee {
        email
      }
      rewardCouponCode
      rewardCouponStoreName
      status
    }
  }
`;
