// eslint-disable-next-line import/prefer-default-export
export const muiInputBaseComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      border: `1px solid ${theme.palette.main.professional_blue}`,
      borderRadius: '4px',
      fontFamily: '"Circular TT", "Arial", sans-serif',

      [`&.Mui-focused`]: {
        boxShadow: theme.shadows[5],
      },

      [`&.Mui-error`]: {
        borderColor: theme.palette.error.main,
      },

      [`&.Mui-disabled`]: {
        backgroundColor: theme.palette.gray.light_gray,
        borderColor: theme.palette.gray.gray,
        textFillColor: theme.palette.gray.gray,
      },
    }),
    input: ({ theme }) => ({
      boxSizing: 'border-box',
      color: theme.palette.main.professional_blue,
      height: '3.5rem',
      padding: `${theme.spacing('LARGE')} ${theme.spacing('MEDIUM')}}`,

      [`&::placeholder`]: {
        color: theme.palette.main.product_blue,
        opacity: 1,
      },
    }),
    formControl: () => ({}),
    hiddenLabel: {
      paddingTop: 0,
    },
    multiline: {
      padding: 0,
    },
  },
};
