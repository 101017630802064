import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { IconButton, Link, Typography } from '@mui/material';

import { useTrackDtEvent } from '../../../DtlTracker';
import { GET_FAVORITE_DENTISTS, REMOVE_FAVORITE_DENTIST } from '../../../shared';
import { Icon, Separator } from '../../../components/common';
import FavoriteIcon from '../../../icons/FavoriteIcon';

import DentistOpeningHours from '../DentistsDetails/DentistOpeningHours';

import {
  StyledDentistDetailsAvatar,
  StyledDentistDetailsAvatarContainer,
  StyledDentistDetailsCardContainer,
  StyledDentistDetailsCardHeader,
  StyledDentistDetailsCardItem,
  StyledDentistDetailsCardWrapper,
  StyledDentistDetailsContent,
} from '../DentistsDetails/DentistsDetails.styled';
import { StyledFavoriteDentistDetailsExpandButton } from './FavoriteDentist.styled';

const FavoriteDentistBlock = ({ customer, favouriteDentists }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showDescription, setShowDescription] = useState(null);
  const [sendUserData] = useTrackDtEvent();
  const setHeartIcon = useState('icon.blank.heart');

  const [removeFavoriteDentistMutation] = useMutation(REMOVE_FAVORITE_DENTIST, {
    refetchQueries: [{ query: GET_FAVORITE_DENTISTS }],
  });

  const trackDtEvent = (dentist = '', eventType = '') => {
    const trackData = {
      userData: {
        eventType: eventType || '',
        time: new Date().toISOString(),
        url: window?.location?.href,
        uuid: customer?.uuid,
        dentist: dentist?.referenceKey,
        website: dentist?.websiteUrl,
      },
    };
    sendUserData(trackData);
  };

  const handleLinkClick = (dentist) => () => {
    window.open(dentist?.websiteUrl, '_blank');
    trackDtEvent(dentist, 'click dentist website');
  };

  const removeFavoriteDentist = (favouriteDentist) => {
    const { referenceKey } = favouriteDentist;
    trackDtEvent(favouriteDentist, 'click remove dentist from favourites');
    removeFavoriteDentistMutation({ variables: { dentistKey: referenceKey } }).then((res) => {
      if (res?.data?.addFavoriteDentist && !res?.data?.addFavoriteDentist?.errors?.length) {
        setShowLoader(false);
        setHeartIcon('icon.blank.heart');
      } else {
        setShowLoader(false);
      }
    });
  };

  const triggerFavoriteDentist = (favouriteDentist) => {
    setShowLoader(true);
    removeFavoriteDentist(favouriteDentist);
  };

  const handleExpandDetails = (index) => {
    // toggle item if user clicked on the same details button again
    // else show the item matched with index
    if (showDescription === index) {
      setShowDescription(null);
    } else {
      setShowDescription(index);
    }
  };

  return (
    <StyledDentistDetailsCardContainer>
      <StyledDentistDetailsCardWrapper blurred={showLoader}>
        {favouriteDentists?.map((favDentist, i, { length }) => {
          const avatarSrc = favDentist?.avatar;
          const address = `${favDentist?.streetName.trim()} ${favDentist?.houseNumber},${
            favDentist?.postcode
          }`;

          return (
            <React.Fragment key={favDentist?.practiceName}>
              <StyledDentistDetailsCardHeader>
                <Typography variant="p">{favDentist?.practiceName}</Typography>

                <IconButton
                  aria-label="Favourite Dentist"
                  onClick={() => triggerFavoriteDentist(favDentist)}
                  size="large"
                >
                  <FavoriteIcon filled />
                </IconButton>
              </StyledDentistDetailsCardHeader>

              <StyledDentistDetailsAvatarContainer>
                {avatarSrc && <StyledDentistDetailsAvatar src={avatarSrc} alt="dentist avatar" />}

                <div>
                  <Typography variant="h3">
                    {favDentist?.title} {favDentist?.name}
                  </Typography>
                </div>
              </StyledDentistDetailsAvatarContainer>

              <StyledDentistDetailsContent>
                <StyledDentistDetailsCardItem>
                  <Icon icon="icon.pin" alt="address" />
                  <Typography variant="p">{address}</Typography>
                </StyledDentistDetailsCardItem>

                <StyledFavoriteDentistDetailsExpandButton>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link variant="p" component="button" onClick={() => handleExpandDetails(i)}>
                    <FormattedMessage id="map.dentists.tabs.dentist.favourite.expand_details" />
                  </Link>
                </StyledFavoriteDentistDetailsExpandButton>

                {showDescription === i && (
                  <>
                    {favDentist?.phoneNumber && (
                      <StyledDentistDetailsCardItem>
                        <Icon icon="icon.phone" alt="phone number" />
                        <Typography variant="p">{favDentist?.phoneNumber}</Typography>
                      </StyledDentistDetailsCardItem>
                    )}

                    {favDentist?.websiteUrl && (
                      <StyledDentistDetailsCardItem>
                        <Icon icon="icon.globe" alt="website" />
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link variant="p" component="button" onClick={handleLinkClick(favDentist)}>
                          {favDentist?.websiteUrl}
                        </Link>
                      </StyledDentistDetailsCardItem>
                    )}

                    <DentistOpeningHours dentist={favDentist} />
                  </>
                )}
              </StyledDentistDetailsContent>

              {i < length - 1 && <Separator />}
            </React.Fragment>
          );
        })}
      </StyledDentistDetailsCardWrapper>
    </StyledDentistDetailsCardContainer>
  );
};

FavoriteDentistBlock.propTypes = {
  customer: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  favouriteDentists: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      practiceName: PropTypes.string,
      title: PropTypes.string,
      name: PropTypes.string,
      streetName: PropTypes.string,
      houseNumber: PropTypes.string,
      postcode: PropTypes.string,
      phoneNumber: PropTypes.string,
      websiteUrl: PropTypes.string,
      openingHours: PropTypes.shape({}),
      referenceKey: PropTypes.string,
    })
  ).isRequired,
};

export default FavoriteDentistBlock;
