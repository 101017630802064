import React from 'react';
import { Typography } from '@mui/material';

import IconClockRoundedOutline from '../../../components/Icons/IconClockRoundedOutline';
import IconXRoundedOutline from '../../../components/Icons/IconXRoundedOutline';
import IconCheckRoundedOutline from '../../../components/Icons/IconCheckRoundedOutline';
import { StyledReferralStatusLabel } from './ReferralPage.styled';

const iconMap = {
  cancelled: <IconXRoundedOutline />,
  pending: <IconClockRoundedOutline />,
  sent: <IconCheckRoundedOutline />,
};

function ReferralStatusLabel({ children, status }) {
  return (
    <StyledReferralStatusLabel status={status}>
      {iconMap[`${status}`]}
      <Typography variant="legalTexts">{children}</Typography>
    </StyledReferralStatusLabel>
  );
}

export default ReferralStatusLabel;
