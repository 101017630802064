import React from 'react';
import { Typography } from '@mui/material';

import {
  StyledBoxSpaceBetween,
  StyledPolicyDetailsNumericalValue,
  StyledSeparator,
  StyledVerticalContent,
} from '../PolicyInfo.styled';

const MainDetails = ({ details }) => {
  if (!Array.isArray(details)) return null;

  return details.map((item) => (
    <StyledVerticalContent key={item?.title || item?.subText}>
      <Typography variant="h3">{item?.title}</Typography>

      {Array.isArray(item?.rates) &&
        item.rates.map((rate) => (
          <StyledBoxSpaceBetween key={rate?.name}>
            <Typography variant="p" color="subtext">
              {rate?.name}
            </Typography>

            <StyledPolicyDetailsNumericalValue>
              <Typography variant="h3">{rate?.value}</Typography>
            </StyledPolicyDetailsNumericalValue>
          </StyledBoxSpaceBetween>
        ))}

      {!!item?.subText && (
        <Typography variant="p" color="subtext">
          {item.subText}
        </Typography>
      )}

      <StyledSeparator />
    </StyledVerticalContent>
  ));
};

export default MainDetails;
