import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Typography } from '@mui/material';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { useFileUploadIcon } from '../../hooks/useIcons';
import { CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT } from '../../features/claims/claimUploadFlowSource';
import {
  DENTOLO_EDIT_STEP_MAIN,
  PETOLO_EDIT_STEP_MAIN,
  VITOLO_EDIT_STEP_MAIN,
} from '../../features/claims/edit/editClaimFormSteps';
import {
  claimStatus,
  epoch,
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
  showMoney,
} from '../../shared';
import { isSameIBAN, maskIBAN } from '../../utils/iban';

import DownloadLink from './DownloadLink';
import { Icon, Separator } from '../common';
import { StyledClaimStatus } from './styled/ClaimStatus.styled';
import {
  StyledClaimStatusInfoCard,
  StyledClaimStatusUpdatedTime,
  StyledClaimStatusDetails,
  StyledClaimStatusTitle,
  StyledUserClaimComments,
  StyledClaimDocumentsDownloadContainer,
  StyledHelpCenterArticleContainer,
} from './styled/ClaimDetailsInfo.styled';
import {
  StyledUploadButtonContainer,
  StyledUploadButtonLabel,
} from '../../features/claims/components/UploadDocument/FileUploader.styled';
import { CLAIM_STATUS_DETAILS } from '../../shared/claimStatus';

const getUploadDocumentLink = (insuranceType, claimId) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return `${DENTOLO_EDIT_STEP_MAIN}/${claimId}?source=${CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT}`;
    case INSURANCE_TYPE_PETOLO:
      return `${PETOLO_EDIT_STEP_MAIN}/${claimId}?source=${CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT}`;
    case INSURANCE_TYPE_VITOLO:
      return `${VITOLO_EDIT_STEP_MAIN}/${claimId}?source=${CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT}`;
    default:
      return `${DENTOLO_EDIT_STEP_MAIN}/${claimId}?source=${CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT}`;
  }
};
const GetRfiText = ({ insuranceCategory, intl }) => {
  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_DENTAL:
      return intl.formatMessage({ id: 'document.rfi.dentolo.text.desc' });
    default:
      return intl.formatMessage({ id: 'document.rfi.petolo.text.desc' });
  }
};

const renderAnchorTagForTerms = (chunk, url) => (
  <Link variant="p" target="_blank" rel="noopener noreferrer" href={url}>
    {chunk}
  </Link>
);

const renderBoldText = (chunk) => (
  <Typography variant="h3" component="span">
    {chunk}
  </Typography>
);

const ClaimDetailsInfo = (props) => {
  const { claim, latestPayout, customer } = props;

  const intl = useIntl();
  const history = useHistory();
  const { id: claimId } = useParams();
  const { category: insuranceCategory, type: insuranceType } = useInsuranceInfo();

  const reimbursementAcknowledged = claim?.status === claimStatus.REIMBURSEMENT_ACKNOWLEDGED;
  const reimbursableAmountAvailable =
    claim?.treatmentPlan && reimbursementAcknowledged && claim?.lockedAmountCents > 0;

  const uploadDocumentLink = getUploadDocumentLink(insuranceType, claimId);
  const uploadDocumentCtaIcon = useFileUploadIcon();
  const informationRequestDocumentUrl = claim?.informationRequestDocumentUrl;

  const goToUploadStep = () => {
    history.push(uploadDocumentLink);
  };

  return (
    <StyledClaimStatusInfoCard>
      <StyledClaimStatusUpdatedTime>
        <Typography variant="p">
          <FormattedMessage
            id="claim.status.details.updated_at"
            values={{ updatedAt: epoch(claim?.updatedAt), updatedAtTime: epoch(claim?.updatedAt) }}
          />
        </Typography>
      </StyledClaimStatusUpdatedTime>

      {claim?.statusDetails && (
        <>
          <StyledClaimStatusTitle>
            <Typography variant="h3">
              <FormattedMessage id={`claim.status.details.${claim?.statusDetails}`} />
            </Typography>

            {reimbursableAmountAvailable && (
              <StyledClaimStatus claimStatus={claim?.status}>
                <Typography variant="p">
                  <FormattedMessage id="claim.details.general.amount" />
                  <span>: {showMoney((claim?.lockedAmountCents ?? 0) / 100)}</span>
                </Typography>
              </StyledClaimStatus>
            )}
          </StyledClaimStatusTitle>

          {/* Important: there can be no payouts for a claim with status [WAITING_FOR_PAYOUT, CLOSED, NEW] */}
          <StyledClaimStatusDetails>
            <Typography variant="p">
              <FormattedMessage
                id={`claim_status_details.${claim?.statusDetails}`}
                values={{
                  b: renderBoldText,
                  insuranceType,
                  reimbursableAmountAvailable,
                  sameIban: isSameIBAN(customer?.iban, latestPayout?.recipientIban),
                  payoutAmount: (latestPayout?.amountCents ?? 0) / 100,
                  recepientIban: maskIBAN(latestPayout?.recipientIban),
                  recepientName: latestPayout?.recipientName,
                  reimbursableAmount: (claim?.lockedAmountCents ?? 0) / 100,
                }}
              />
            </Typography>
          </StyledClaimStatusDetails>
        </>
      )}

      {latestPayout?.helpcenterArticleUrl && (
        <StyledHelpCenterArticleContainer>
          <Typography variant="p">
            <FormattedMessage
              id="claim.payout.helpcenter_article.text"
              values={{
                a: (chunk) => renderAnchorTagForTerms(chunk, latestPayout.helpcenterArticleUrl),
              }}
            />
          </Typography>
        </StyledHelpCenterArticleContainer>
      )}

      {claim?.statusDetails === CLAIM_STATUS_DETAILS.INFORMATION_REQUEST_MISSING &&
        informationRequestDocumentUrl && (
          <StyledClaimDocumentsDownloadContainer>
            <DownloadLink
              link={informationRequestDocumentUrl}
              name="auskunftsersuchen"
              key={informationRequestDocumentUrl}
              target="_blank"
            >
              <FormattedMessage id="document.rfi.text.head" />
            </DownloadLink>

            <Typography variant="p">
              <FormattedMessage id="document.rfi.text.title" />
            </Typography>

            <Separator />

            <Typography variant="p">
              <GetRfiText insuranceCategory={insuranceCategory} intl={intl} />
            </Typography>
          </StyledClaimDocumentsDownloadContainer>
        )}

      {reimbursableAmountAvailable && (
        <StyledClaimStatusDetails>
          <Typography variant="p">
            <FormattedMessage id="claim.details.general.load.invoice" />
          </Typography>
        </StyledClaimStatusDetails>
      )}

      {claim?.note &&
        (claimStatus.WAITING_FOR_INFORMATION === claim?.status ||
          claimStatus.DECLINED === claim?.status) && (
          <StyledUserClaimComments>
            <Typography variant="p">{claim?.note}</Typography>
          </StyledUserClaimComments>
        )}

      {(reimbursementAcknowledged || claimStatus.WAITING_FOR_INFORMATION === claim?.status) && (
        <>
          <Separator />

          <StyledUploadButtonContainer onClick={goToUploadStep}>
            <Icon
              icon={uploadDocumentCtaIcon}
              alt="claim upload step button"
              data-testid="btn-goto-upload-step"
              style={{ width: '3.5rem', height: '3.5rem' }}
            />

            <StyledUploadButtonLabel>
              <Typography variant="h3">
                <FormattedMessage
                  id={`common.button.upload.${reimbursableAmountAvailable ? 'invoice' : 'document'}`}
                />
              </Typography>
            </StyledUploadButtonLabel>
          </StyledUploadButtonContainer>
        </>
      )}
    </StyledClaimStatusInfoCard>
  );
};

export default ClaimDetailsInfo;
