import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link, Typography } from '@mui/material';

import { days, imgLinks } from '../../../shared';

import { Icon } from '../../../components/common';

import {
  StyledDentistDetailsCardItem,
  StyledDentistDetailsOpeningHoursContainer,
  StyledDentistDetailsOpeningHoursItem,
} from './DentistsDetails.styled';

const isInRange = (value, range) => {
  return value >= range[0] && value <= range[1];
};

const setHoursText = (openingHours) => {
  const today = `${dayjs().day() - 1}`;
  const day = Object.entries(openingHours).filter((item) => item[0] === today);
  let text = 'closed';

  if (!Array.isArray(day) || day.length < 1) return text;

  const shifts = day[0][1];

  shifts.some((shift) => {
    if (!Object.values(shift).filter((item) => item).length) return true;

    const shiftRange = [shift.from, shift.to];
    const now = dayjs().format('HH:mm');

    if (isInRange(now, shiftRange)) {
      const time = shiftRange[1].split(':');

      const thirtyMinsBeforeClosing = dayjs()
        .set('hour', time[0])
        .set('minute', time[1])
        .subtract(30, 'minute')
        .format('HH:mm');

      if (isInRange(now, [thirtyMinsBeforeClosing, shiftRange[1]])) {
        text = 'closing.soon';
      } else {
        text = 'now.open';
      }

      return true;
    }

    return false;
  });

  return text;
};

export default function DentistOpeningHours(props) {
  const { dentist } = props;

  const intl = useIntl();

  const [fullOpenHours, setFullOpenHours] = useState(false);

  if (dentist && dentist?.dentoloPartner && dentist?.openingHours) {
    return (
      <>
        <StyledDentistDetailsCardItem>
          <Icon icon="icon.hours" alt="opening hours" />

          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link variant="p" component="button" onClick={() => setFullOpenHours(!fullOpenHours)}>
            <FormattedMessage
              id={`dentist.details.open.hours.${setHoursText(dentist.openingHours)}`}
            />
            <img src={imgLinks['icon.accordion.arrow.down']} alt="" />
          </Link>
        </StyledDentistDetailsCardItem>

        {fullOpenHours && (
          <StyledDentistDetailsOpeningHoursContainer>
            {Object.entries(dentist?.openingHours).map((item) => {
              const day = days.filter((d) => d.id === item[0]);
              const today = `${dayjs().day() - 1}`;
              const dayName = intl.formatMessage({
                id: `dentist.details.open.hours.day.${day[0].name}.short`,
              });

              return (
                <StyledDentistDetailsOpeningHoursItem key={item[0]}>
                  <Typography variant={today === item[0] ? 'h3' : 'p'}>
                    {day.length ? dayName : ''}:
                  </Typography>

                  {item[1].length && item[1].filter((open) => open.from && open.to).length ? (
                    item[1].map((shift) => {
                      return (
                        <Typography
                          variant={today === item[0] ? 'h3' : 'p'}
                          key={`${item[0]}-${shift?.from}-${shift?.to}`}
                        >
                          {shift.from} - {shift.to}
                        </Typography>
                      );
                    })
                  ) : (
                    <Typography variant="p">
                      <FormattedMessage id="dentist.details.open.hours.day.closed" />
                    </Typography>
                  )}
                </StyledDentistDetailsOpeningHoursItem>
              );
            })}
          </StyledDentistDetailsOpeningHoursContainer>
        )}
      </>
    );
  }
  return null;
}

DentistOpeningHours.propTypes = {
  dentist: PropTypes.shape({
    avatar: PropTypes.string,
    city: PropTypes.string,
    dentoloPartner: PropTypes.bool,
    houseNumber: PropTypes.string,
    name: PropTypes.string,
    openingHours: PropTypes.shape({}),
    phoneNumber: PropTypes.string,
    postcode: PropTypes.string,
    practiceName: PropTypes.string,
    referenceKey: PropTypes.string,
    streetName: PropTypes.string,
    title: PropTypes.string,
    websiteUrl: PropTypes.string,
  }),
};
