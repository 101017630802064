import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import {
  LOCAL_SHOW_ONBOARDS,
  DASHBOARD_ONBOARD_KEY,
  PETOLO_DASHBOARD_ONBOARD_KEY,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
} from '../../shared';

export default function useShowOnboarding() {
  const history = useHistory();
  const { type: insuranceType } = useInsuranceInfo();

  const showOnboards = useQuery(LOCAL_SHOW_ONBOARDS);
  const showOnboardsData = showOnboards?.data?.showOnboards;
  const showOnboardForDentoloDashboard = Array.isArray(showOnboardsData)
    ? showOnboardsData.find((item) => item?.name === DASHBOARD_ONBOARD_KEY)
    : null;
  const showOnboardDentoloFromCache = showOnboardForDentoloDashboard?.show;
  const showOnboardForPetoloDashboard = Array.isArray(showOnboardsData)
    ? showOnboardsData.find((item) => item?.name === PETOLO_DASHBOARD_ONBOARD_KEY)
    : null;
  const showOnboardPetoloFromCache = showOnboardForPetoloDashboard?.show;

  useEffect(() => {
    const showOnBoardForDentoloDashboardLocalStorageToken =
      window.localStorage.getItem(DASHBOARD_ONBOARD_KEY);
    const showOnboardDentoloFromLocalStorage =
      showOnBoardForDentoloDashboardLocalStorageToken !== 'skip';
    const showOnboardDentolo = showOnboardDentoloFromCache || showOnboardDentoloFromLocalStorage;

    const showOnBoardForPetoloDashboardLocalStorageToken = window.localStorage.getItem(
      PETOLO_DASHBOARD_ONBOARD_KEY
    );
    const showOnboardPetoloFromLocalStorage =
      showOnBoardForPetoloDashboardLocalStorageToken !== 'skip';
    const showOnboardPetolo = showOnboardPetoloFromCache || showOnboardPetoloFromLocalStorage;

    if (insuranceType === INSURANCE_TYPE_DENTOLO && showOnboardDentolo) {
      history.push('/intro-dentolo/1');
    } else if (insuranceType === INSURANCE_TYPE_PETOLO && showOnboardPetolo) {
      history.push('/intro-petolo/1');
    }
  }, [showOnboardDentoloFromCache, showOnboardPetoloFromCache, insuranceType, history]);
}
