import { buttonClasses } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const muiPaperComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.common.white,
      borderRadius: '4px',
      color: theme.palette.main.professional_blue,
    }),
    elevation0: ({ theme }) => ({
      backgroundColor: 'transparent',
      padding: `${theme.spacing('LARGE')} 0`,

      [`.${buttonClasses.root}`]: {
        justifySelf: 'center',
      },
    }),
    elevation1: ({ theme }) => ({
      boxShadow: theme.shadows[1],
      padding: theme.spacing('LARGE'),

      [`.${buttonClasses.root}`]: {
        justifySelf: 'center',
      },

      [`${theme.breakpoints.up('desktop')}`]: {
        [`.${buttonClasses.root}`]: {
          minWidth: '20.375rem',
          maxWidth: '21.375rem',
          width: '100%',
        },
      },
    }),
  },
};
