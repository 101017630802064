import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { epoch } from '../../shared';
import { isSameIBAN, maskIBAN } from '../../utils/iban';

import {
  StyledClaimAdditionalPaymentsCard,
  StyledPayoutDetailsContainer,
  StyledPayoutDate,
} from './styled/ClaimDetailsAdditionalPayments.styled';

const ClaimDetailsAdditionalPayments = ({ otherPayouts, customerIBAN }) => {
  return (
    <StyledClaimAdditionalPaymentsCard>
      <Typography variant="h3">
        <FormattedMessage id="claim.status.details.other.payouts" />
      </Typography>

      {Array.isArray(otherPayouts) &&
        otherPayouts.map((payout) => {
          return (
            <StyledPayoutDetailsContainer key={payout?.key}>
              <StyledPayoutDate>
                <Typography variant="p">
                  <FormattedMessage
                    id="claim.status.details.invoice.from"
                    values={{ createdAt: epoch(payout?.createdAt) }}
                  />
                </Typography>
              </StyledPayoutDate>

              <Typography variant="p">
                <FormattedMessage
                  id={
                    payout?.confirmedAt
                      ? 'claim_details.additional_payments.payout_confirmed'
                      : 'claim_details.additional_payments.payout_not_confirmed_yet'
                  }
                  values={{
                    sameIban: isSameIBAN(customerIBAN, payout?.recipientIban),
                    payoutAmount: (payout?.amountCents ?? 0) / 100,
                    confirmedAt: epoch(payout?.confirmedAt),
                    recepientName: payout?.recipientName,
                    recepientIban: maskIBAN(payout?.recipientIban),
                  }}
                />
              </Typography>
            </StyledPayoutDetailsContainer>
          );
        })}
    </StyledClaimAdditionalPaymentsCard>
  );
};

export default injectIntl(ClaimDetailsAdditionalPayments);
