import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import { CSSTransition } from 'react-transition-group';
import dayjs from 'dayjs';
import { Container } from '@mui/material';

import { CANCEL_AKUTSCHUTZ, GET_CUSTOMER, useReady } from '../../shared';
import { BackButton } from '../../components/common';
import AkutschutzCancellationContent from './AkutschutzCancellationContent';
import {
  AKUTSCHUTZ_CANCELLATION_AFTER_MINIMUM_DURATION,
  AKUTSCHUTZ_CANCELLATION_WITHIN_MINIMUM_DURATION,
  AKUTSCHUTZ_CANCELLATION_WITHIN_WITHDRAW_PERIOD,
} from './akutschutzCancellationConstants';

const getAkustchutzCancellationPageKey = (withdrawalPeriod, contractWithinMinimumDuration) => {
  if (withdrawalPeriod) {
    return AKUTSCHUTZ_CANCELLATION_WITHIN_WITHDRAW_PERIOD;
  }
  if (contractWithinMinimumDuration) {
    return AKUTSCHUTZ_CANCELLATION_WITHIN_MINIMUM_DURATION;
  }
  return AKUTSCHUTZ_CANCELLATION_AFTER_MINIMUM_DURATION;
};

export default function AkutschutzCancellation() {
  const ready = useReady();
  const history = useHistory();

  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const addonImCoverage = customerData?.customer?.contract?.addonImCoverage;
  const akutschutzNotCancelled = !addonImCoverage?.endingAt;

  const akutschutzStartingDate = dayjs(addonImCoverage?.startingAt);
  const akutschutzMinimumDuration = Number.isInteger(addonImCoverage?.minimumContractDuration)
    ? addonImCoverage?.minimumContractDuration
    : 0;
  const akutschutzMinimumDurationEndDate = akutschutzStartingDate.add(
    akutschutzMinimumDuration,
    'month'
  );
  const today = dayjs();

  const contractWithinMinimumDuration = akutschutzMinimumDurationEndDate.isAfter(today);

  const earliestAvailableDate = addonImCoverage?.calculatedEndingAt;

  const akutschutzCancellationPageKey = getAkustchutzCancellationPageKey(
    addonImCoverage?.withdrawalPeriod,
    contractWithinMinimumDuration
  );

  const [cancelAddonsImCoverage] = useMutation(CANCEL_AKUTSCHUTZ, {
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  const [disableButton, setDisableButton] = useState(false);

  const handleCancelAkutschutz = async () => {
    setDisableButton(true);
    const response = await cancelAddonsImCoverage();

    if (response?.data?.cancelAddonsImCoverage?.status === 'success') {
      history.push(
        `/akutschutz-cancellation/success?withdraw=${akutschutzCancellationPageKey === AKUTSCHUTZ_CANCELLATION_WITHIN_WITHDRAW_PERIOD}`
      );
    } else {
      history.push('/akutschutz-cancellation/error');
    }
    setDisableButton(false);
  };

  useEffect(() => {
    if (!customerLoading && !akutschutzNotCancelled) {
      history.replace('/');
    }
  }, [history, customerLoading, akutschutzNotCancelled]);

  if (!customerLoading && akutschutzNotCancelled) {
    return (
      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Container>
          <BackButton onClick={() => history.goBack()}>
            <FormattedMessage id="common.button.back" />
          </BackButton>

          <AkutschutzCancellationContent
            akutschutzCancellationPageKey={akutschutzCancellationPageKey}
            handleCancelAkutschutz={handleCancelAkutschutz}
            disableButton={disableButton}
            earliestAvailableDate={earliestAvailableDate}
          />
        </Container>
      </CSSTransition>
    );
  }

  return null;
}
