import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Typography } from '@mui/material';

import { imgLinks } from '../../../shared';

import {
  StyledLinkToClaimsPageContents,
  StyledLinkToClaimsPageIcon,
  StyledLinkToClaimsPagePaper,
} from './LinkToClaimsPage.styled';

const renderLink = (chunk, href) => (
  <Link variant="p" href={href}>
    {chunk}
  </Link>
);

export default function LinkToClaimsPage() {
  return (
    <StyledLinkToClaimsPagePaper>
      <StyledLinkToClaimsPageIcon src={imgLinks['icon.user.claim.onboard.euro']} alt="money" />

      <StyledLinkToClaimsPageContents>
        <Typography variant="h2">
          <FormattedMessage id="link_to_claims.title" />
        </Typography>

        <Typography variant="p">
          <FormattedMessage
            id="link_to_claims.description"
            values={{
              a: (chunk) => renderLink(chunk, '/user-claims-info'),
            }}
          />
        </Typography>
      </StyledLinkToClaimsPageContents>
    </StyledLinkToClaimsPagePaper>
  );
}
