import { styled, css } from '@mui/material';

export const StyledVerticalContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledCustomerDetailsCardHeader = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const StyledCustomerDetailsContactUsContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.background.info};
    padding: ${theme.spacing('LARGE')};
  `
);

export const StyledCustomerDetailsItem = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    column-gap: ${theme.spacing('2XL')};

    ${theme.breakpoints.up('desktop')} {
      flex-direction: row;

      > div[data-label] {
        flex: 0 1 11rem;
      }
    }
  `
);

export const StyledPaymentErrorContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.gray.ultra_light_gray};
    padding: ${theme.spacing('LARGE')};
  `
);
