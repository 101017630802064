import { css, styled } from '@mui/material';

import { Icon, Tag } from '../common';

export const StyledVerticalContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledPaymentStatusBlock = styled('div')`
  display: flex;
  align-items: center;
`;

export const StyledVerticalInternalContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: ${theme.spacing('SMALL')};
  `
);

export const StyledPaymentStatusBlockTag = styled(Tag)`
  display: flex;
  width: fit-content;
`;

export const StyledPaymentStatusBlockIcon = styled(Icon)(
  ({ theme }) => css`
    width: 1.5rem;

    ${theme.breakpoints.up('desktop')} {
      width: 2rem;
      height: 2rem;
    }
  `
);
