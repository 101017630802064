import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, Typography } from '@mui/material';

import {
  BackButton,
  ButtonContainer,
  PageTitle,
  Paper,
  Radio,
  RadioLabel,
} from '../../../../components/common';
import { StyledRadioContainer, RadioOptionContainer } from './SelectVideoConsultation.styled';
import useSelectableVideoConsultationsList from './useSelectableVideoConsultationsList';
import {
  useNewPetoloFormDataValue,
  PETOLO_VIDEO_CONSULTATION_ID,
  PETOLO_NO_SUITABLE_VIDEO_CONSULTATION,
  PETOLO_CUSTOMER_NOTE,
  PET_HEALTH_TREATMENT_CATEGORIES,
} from '../../claimFormData/newClaimFormData';
import {
  useSelectVideoConsultationBackButtonLink,
  useSelectVideoConsultationNextButtonLink,
} from './selectVideoConsultationHooks';

export default function SelectVideoConsultation() {
  const history = useHistory();

  const selectableVideoConsultationsList = useSelectableVideoConsultationsList();

  const [treatmentCategories] = useNewPetoloFormDataValue(PET_HEALTH_TREATMENT_CATEGORIES);

  const [selectedVideoConsultationId, setSelectedVideoConsultationId] = useNewPetoloFormDataValue(
    PETOLO_VIDEO_CONSULTATION_ID
  );
  const videoConsultationId =
    selectedVideoConsultationId === PETOLO_NO_SUITABLE_VIDEO_CONSULTATION
      ? ''
      : selectedVideoConsultationId;

  const [, /* customerNote */ setCustomerNote] = useNewPetoloFormDataValue(PETOLO_CUSTOMER_NOTE);

  const handleSelectedVideoConsultationIdChange = (id) => {
    if (id !== PETOLO_NO_SUITABLE_VIDEO_CONSULTATION) {
      setCustomerNote('');
    }
    setSelectedVideoConsultationId(id);
  };

  const backButtonLink = useSelectVideoConsultationBackButtonLink();
  const nextButtonLink = useSelectVideoConsultationNextButtonLink(
    videoConsultationId,
    treatmentCategories
  );

  return (
    <>
      <BackButton to={backButtonLink}>
        <Typography variant="h3">
          <FormattedMessage id="common.button.back" />
        </Typography>
      </BackButton>

      <PageTitle>
        <Typography variant="h1">
          <FormattedMessage id="petolo.new_claim.select_video_consultation.page.title" />
        </Typography>
      </PageTitle>

      {Array.isArray(selectableVideoConsultationsList) &&
        selectableVideoConsultationsList.map((consultationOption) => {
          const { id, label } = consultationOption || {};

          return (
            <StyledRadioContainer key={id}>
              <Paper>
                <RadioOptionContainer
                  onClick={() => {
                    handleSelectedVideoConsultationIdChange(id);
                  }}
                >
                  <Radio
                    name="video_consultation"
                    value={id}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleSelectedVideoConsultationIdChange(id);
                    }}
                    checked={id === selectedVideoConsultationId}
                  />

                  <RadioLabel style={{ marginLeft: '1rem' }}>
                    <Typography variant="p" color="professional_blue">
                      {label}
                    </Typography>
                  </RadioLabel>
                </RadioOptionContainer>
              </Paper>
            </StyledRadioContainer>
          );
        })}

      <ButtonContainer>
        <Button
          disabled={!selectedVideoConsultationId}
          onClick={() => history.push(nextButtonLink)}
        >
          <Typography variant="buttons">
            <FormattedMessage id="common.button.next" />
          </Typography>
        </Button>
      </ButtonContainer>
    </>
  );
}
