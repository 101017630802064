import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { IconButton, Link, Typography } from '@mui/material';

import { useTrackDtEvent } from '../../../DtlTracker';
import {
  GET_FAVORITE_DENTISTS,
  ADD_FAVORITE_DENTIST,
  REMOVE_FAVORITE_DENTIST,
} from '../../../shared';
import { BarLoader, Icon } from '../../../components/common';
import CloseIcon from '../../../icons/CloseIcon';
import FavoriteIcon from '../../../icons/FavoriteIcon';

import {
  StyledDentistDetailsMobilePaper,
  StyledDentistDetailsAvatarContainer,
  StyledDentistDetailsCardHeader,
  StyledDentistDetailsCardItem,
  StyledDentistDetailsCardWrapper,
  StyledDentistDetailsContent,
  StyledToggleDentistAsFavorite,
  StyledBarLoaderWrapper,
} from './DentistsDetails.styled';

export default function OtherDentistDetailsMobile({ customer, dentist, setDentist }) {
  const [showLoader, setShowLoader] = useState(false);
  const [favourites, setFavourites] = useState([]);
  const [sendUserData] = useTrackDtEvent();

  const {
    loading: favouriteDentistsLoading,
    error: favouriteDentistsError,
    data: favouriteDentists,
  } = useQuery(GET_FAVORITE_DENTISTS);

  const [removeFavouriteDentist] = useMutation(REMOVE_FAVORITE_DENTIST, {
    variables: {
      dentistKey: dentist?.referenceKey,
    },
    refetchQueries: [{ query: GET_FAVORITE_DENTISTS }],
  });

  const [setFavouriteDentist] = useMutation(ADD_FAVORITE_DENTIST, {
    variables: {
      dentistKey: dentist?.referenceKey,
    },
    refetchQueries: [{ query: GET_FAVORITE_DENTISTS }],
  });

  const isFavourite = useCallback(
    (fav) => {
      return fav?.some((favourite) => favourite?.dentistKey === dentist?.referenceKey);
    },
    [dentist]
  );

  const trackDtEvent = (eventType = '') => {
    const trackData = {
      userData: {
        eventType: eventType || 'click',
        time: new Date().toISOString(),
        url: window?.location?.href,
        uuid: customer?.uuid,
        dentist: dentist?.referenceKey,
        website: dentist?.websiteUrl,
      },
    };
    sendUserData(trackData);
  };

  const toggleFavourite = () => {
    setShowLoader(true);

    if (isFavourite(favourites)) {
      trackDtEvent('click remove dentist from favourites');
      removeFavouriteDentist();
    } else {
      trackDtEvent('click add dentist to favourites');
      setFavouriteDentist();
    }
  };

  const handleLinkCLick = (event) => {
    event.stopPropagation();
    trackDtEvent('click dentist website');
  };

  useEffect(() => {
    if (!favouriteDentistsError) {
      setFavourites(favouriteDentists?.customer?.favoriteDentists);
    }

    setShowLoader(favouriteDentistsLoading);
  }, [dentist, favouriteDentists, favouriteDentistsError, favouriteDentistsLoading]);

  const address = `${dentist?.streetName} ${dentist?.houseNumber}, ${dentist?.postcode} ${dentist?.city}`;
  const isFavoriteDentist = isFavourite(favouriteDentists?.customer?.favoriteDentists);

  if (!dentist || favouriteDentistsError) return null;

  return (
    <StyledDentistDetailsMobilePaper>
      <StyledDentistDetailsCardWrapper blurred={showLoader}>
        <StyledDentistDetailsCardHeader>
          <Typography variant="h3" color="professional_blue">
            {dentist?.practiceName}
          </Typography>

          <IconButton onClick={() => setDentist(null)} aria-label="close dentist details">
            <CloseIcon />
          </IconButton>
        </StyledDentistDetailsCardHeader>

        <StyledDentistDetailsAvatarContainer>
          <Typography variant="h3" color="professional_blue">
            {dentist?.title} {dentist?.name}
          </Typography>
        </StyledDentistDetailsAvatarContainer>

        <StyledDentistDetailsContent>
          <StyledDentistDetailsCardItem>
            <Icon icon="icon.pin" alt="address" />
            <Typography variant="p">{address}</Typography>
          </StyledDentistDetailsCardItem>

          <StyledDentistDetailsCardItem>
            <Icon icon="icon.phone" alt="phone" />
            <Typography variant="p">
              <FormattedMessage tagName="label" id="dentist.details.phone_number.label" />
            </Typography>
            <Typography variant="h3">{dentist?.phoneNumber}</Typography>
          </StyledDentistDetailsCardItem>

          {dentist?.websiteUrl && (
            <StyledDentistDetailsCardItem>
              <Icon icon="icon.globe" alt="website" />
              <Typography variant="p">
                <FormattedMessage tagName="label" id="dentist.details.website.label" />
              </Typography>
              <Link
                variant="h3"
                href={`${dentist?.websiteUrl}`}
                rel="noopener noreferrer"
                target="_blank"
                onClick={handleLinkCLick}
              >
                {dentist?.websiteUrl}
              </Link>
            </StyledDentistDetailsCardItem>
          )}
        </StyledDentistDetailsContent>

        <StyledToggleDentistAsFavorite>
          <IconButton aria-label="Favourite Dentist" onClick={toggleFavourite} size="large">
            <FavoriteIcon filled={isFavoriteDentist} />
          </IconButton>

          <Typography variant="h3">
            <FormattedMessage tagName="label" id="dentist.details.common.save.favourites" />
          </Typography>
        </StyledToggleDentistAsFavorite>
      </StyledDentistDetailsCardWrapper>

      {showLoader && (
        <StyledBarLoaderWrapper>
          <BarLoader />
        </StyledBarLoaderWrapper>
      )}
    </StyledDentistDetailsMobilePaper>
  );
}

OtherDentistDetailsMobile.propTypes = {
  setDentist: PropTypes.func,
  customer: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  dentist: PropTypes.shape({
    avatar: PropTypes.string,
    city: PropTypes.string,
    dentoloPartner: PropTypes.bool,
    houseNumber: PropTypes.string,
    name: PropTypes.string,
    postcode: PropTypes.string,
    practiceName: PropTypes.string,
    referenceKey: PropTypes.string,
    streetName: PropTypes.string,
    title: PropTypes.string,
    websiteUrl: PropTypes.string,
  }),
};
