// eslint-disable-next-line import/prefer-default-export
export const muiTextFieldComponent = {
  defaultProps: {
    slotProps: {
      input: {
        notched: false,
      },
      inputLabel: {
        shrink: true,
        disableAnimation: true,
      },
    },
    variant: 'outlined',
  },
};
