import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO, INSURANCE_TYPE_VITOLO } from '../shared';

export const showRimburseMap = {
  [INSURANCE_TYPE_DENTOLO]: true,
  [INSURANCE_TYPE_PETOLO]: true,
  [INSURANCE_TYPE_VITOLO]: false,
};

export const showSelfCancellationMap = {
  [INSURANCE_TYPE_DENTOLO]: true,
  [INSURANCE_TYPE_PETOLO]: true,
  [INSURANCE_TYPE_VITOLO]: true,
};
