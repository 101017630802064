import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Container, Paper, Typography } from '@mui/material';

import { useReady } from '../../shared';
import { PageTitle } from '../common';
import InfoWindow from '../InfoWindow';

function SecondAuthStatus() {
  const intl = useIntl();
  const ready = useReady();

  const infoSettings = {
    mode: 'failure',
    titleHtml: intl.formatMessage({ id: 'second.auth.status.failure.title' }),
    text: intl.formatMessage({ id: 'second.auth.status.failure.text' }),
    button: {
      text: intl.formatMessage({ id: 'second.auth.status.failure.button.text' }),
      href: '/',
    },
  };

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <PageTitle>
          <Typography variant="h1">
            <FormattedMessage id="second.auth.status.title" />
          </Typography>
        </PageTitle>

        <Paper>
          <InfoWindow
            mode={infoSettings.mode}
            titleHtml={infoSettings.titleHtml}
            textHtml={infoSettings.text}
            button={infoSettings.button}
          />
        </Paper>
      </Container>
    </CSSTransition>
  );
}

export default SecondAuthStatus;
