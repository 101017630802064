import React from 'react';
import { useQuery } from '@apollo/client';
import { CSSTransition } from 'react-transition-group';
import { Container } from '@mui/material';

import { useReady, GET_CUSTOMER } from '../../shared';

import CancellationReasons from './CancellationReasons';

export default function ContractCancellationReasons() {
  const ready = useReady();

  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const contractCancelled = customerData?.customer?.contract?.cancelationDate;

  if (!customerLoading && contractCancelled) {
    const cancellationType = customerData?.customer?.contract?.cancelationType;

    return (
      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Container>
          <CancellationReasons cancellationType={cancellationType} />
        </Container>
      </CSSTransition>
    );
  }

  return null;
}
