import { css, Paper, styled } from '@mui/material';

export const StyledDentistsMapTitlePaper = styled(Paper)`
  padding: 0;
`;

export const StyledDentistsMapTabPanel = styled('div')`
  overflow-y: scroll;
`;

export const StyledDentistsMapToggleHelpButton = styled('div')(
  ({ theme }) => css`
    align-items: center;
    column-gap: ${theme.spacing('SMALL')};
    display: flex;
    padding: ${theme.spacing('LARGE')};

    > img {
      width: ${theme.spacing('1XL')};
      height: ${theme.spacing('1XL')};
    }
  `
);
