import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { imgLinks, VITOLO_BETTER_DOC_URL } from '../../../shared';

import {
  StyledVitoloBetterDocPaper,
  StyledVitoloBetterDocCardImageMobile,
  StyledVitoloBetterDocCardImageDesktop,
  StyledVitoloBetterDocCardButton,
  StyledVitoloBetterDocCardTitle,
  StyledVitoloBetterDocCardButtonContainer,
  StyledVitoloBetterDocCardContent,
} from './VitoloBetterDoc.styled';

function VitoloBetterDoc() {
  return (
    <StyledVitoloBetterDocPaper>
      <StyledVitoloBetterDocCardImageDesktop
        src={imgLinks['betterdoc.desktop'] || ''}
        alt="Vitolo Better Doc large"
      />
      <StyledVitoloBetterDocCardImageMobile
        src={imgLinks['betterdoc.mobile'] || ''}
        alt="Vitolo Better Doc small"
      />

      <StyledVitoloBetterDocCardContent>
        <StyledVitoloBetterDocCardTitle>
          <Typography variant="h2">
            <FormattedMessage id="promotion.carousel.vitolo.better_doc.vitolo.title" />
          </Typography>
        </StyledVitoloBetterDocCardTitle>

        <Typography variant="p">
          <FormattedMessage id="promotion.carousel.vitolo.better_doc.vitolo.description" />
        </Typography>

        <StyledVitoloBetterDocCardButtonContainer>
          <StyledVitoloBetterDocCardButton
            component="a"
            variant="cross_sell_vitolo"
            href={VITOLO_BETTER_DOC_URL}
            target="_blank"
          >
            <Typography variant="buttons">
              <FormattedMessage id="promotion.carousel.vitolo.better_doc.vitolo.button.label" />
            </Typography>
          </StyledVitoloBetterDocCardButton>
        </StyledVitoloBetterDocCardButtonContainer>
      </StyledVitoloBetterDocCardContent>
    </StyledVitoloBetterDocPaper>
  );
}

export default VitoloBetterDoc;
