import { css, styled } from '@mui/material';

export const StyledCheckboxContainer = styled('div')`
  display: flex;
  margin-top: 1em;
`;

export const StyledHintContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.gray.light_gray};
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 4px;
  `
);
