import React from 'react';

import Footer from '../../features/footer/Footer';
import HeaderWithoutActions from '../Header/HeaderWithoutActions';
import StyledPageContent from './Page.styled';

const PageWithMinimalHeader = ({ children }) => {
  return (
    <>
      <StyledPageContent>
        <HeaderWithoutActions />
        <main>{children}</main>
      </StyledPageContent>

      <Footer />
    </>
  );
};

export default PageWithMinimalHeader;
