import { css, styled } from '@mui/material';
import ButtonContainer from '../../components/ButtonContainer';

export const StyledVerticalContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledButtonContainer = styled(ButtonContainer)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing('1XL')};
  `
);
