import { createTheme } from '@mui/material';

import { PALETTE } from './palette';

import { muiButtonComponent } from './overrides/button';
import { muiPaperComponent } from './overrides/paper';
import { muiTypographyComponent } from './overrides/typography';
import { muiLinkComponent } from './overrides/link';
import { SPACING } from './spacing';
import { muiContainerComponent } from './overrides/container';
import { muiInputBaseComponent } from './overrides/inputBase';
import { muiInputLabelComponent } from './overrides/inputLabel';
import { muiOutlinedInputComponent } from './overrides/outlinedInput';
import { muiTextFieldComponent } from './overrides/textField';
import { muiFormHelperTextComponent } from './overrides/formHelperText';
import { muiIconButtonComponent } from './overrides/iconButton';
import { muiDialogComponent } from './overrides/dialog';
import { muiDialogActionsComponent } from './overrides/dialogActions';
import { muiDialogContentComponent } from './overrides/dialogContent';
import { muiTabsComponent } from './overrides/tabs';
import { muiTabComponent } from './overrides/tab';
import { muiAlertComponent } from './overrides/alert';

// eslint-disable-next-line import/prefer-default-export
export const defaultTheme = createTheme({
  palette: {
    main: {
      professional_blue: PALETTE.PROFESSIONAL_BLUE,
      professional_light_blue: PALETTE.PROFESSIONAL_LIGHT_BLUE,
      product_blue: PALETTE.PRODUCT_BLUE,
      accent_blue: PALETTE.ACCENT_BLUE,
    },
    gray: {
      gray: PALETTE.GRAY,
      light_gray: PALETTE.LIGHT_GRAY,
      ultra_light_gray: PALETTE.ULTRA_LIGHT_GRAY,
    },
    error: {
      main: PALETTE.ERROR_RED,
    },
    success: {
      main: PALETTE.SUCCESS_GREEN,
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      largeMobile: 375,
      tablet: 481,
      desktop: 972,
      largeDesktop: 1441,
    },
  },
  shadows: [
    'none',
    '0px 0px 10px rgba(0, 0, 0, 0.1)',
    '0px 2px 6px rgba(0, 0, 0, 0.1)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  shape: {
    container: '43.75rem',
    headerDesktop: '4rem',
    headerMobile: '4rem',
    navMenuDesktop: '3.1rem',
  },
  spacing: (value) => SPACING[`${value}`] || '0rem',
  typography: {
    fontSize: 16,
    fontFamily:
      process.env.NODE_ENV === 'production'
        ? '"Circular TT", "Arial", sans-serif'
        : 'Impact, cursive, sans-serif', // TODO: Remove this after we complete accessibility testing
  },
  components: {
    MuiAlert: muiAlertComponent,
    MuiButton: muiButtonComponent,
    MuiContainer: muiContainerComponent,
    MuiDialog: muiDialogComponent,
    MuiDialogActions: muiDialogActionsComponent,
    MuiDialogContent: muiDialogContentComponent,
    MuiFormHelperText: muiFormHelperTextComponent,
    MuiIconButton: muiIconButtonComponent,
    MuiInputBase: muiInputBaseComponent,
    MuiInputLabel: muiInputLabelComponent,
    MuiLink: muiLinkComponent,
    MuiOutlinedInput: muiOutlinedInputComponent,
    MuiPaper: muiPaperComponent,
    MuiTab: muiTabComponent,
    MuiTabs: muiTabsComponent,
    MuiTextField: muiTextFieldComponent,
    MuiTypography: muiTypographyComponent,
  },
  daDirekt: {
    fontFamily: 'CentraNo2-Medium, Circular TT ,sans-serif',
  },
});
