import { styled, css, Container, Paper } from '@mui/material';

import { PALETTE } from '../../theme/palette';
import { IconButton } from '../common';

export const StyledContainer = styled(Container)`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;
export const StyledFirstVetFranceHeader = styled('div')(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    ${theme.breakpoints.up('desktop')} {
      position: relative;

      top: 0;
      left: 0;
      height: 35rem;
      overflow: hidden;
      width: auto;
    }
  `
);
export const StyledOnlineVetCoverImage = styled('img')(
  ({ theme }) => css`
    width: 100%;
    max-width: 100%;
    height: auto;
    ${theme.breakpoints.up('desktop')} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.65);
      width: auto;
      max-width: unset;

      height: 54rem;
      object-fit: cover;
    }
  `
);
export const StyledHeaderText = styled('div')(
  ({ theme }) => css`
    z-index: 1;
    position: absolute;
    width: 44%;
    padding-top: ${theme.spacing('2XL')};
    top: 0;
    h1 {
      margin-bottom: ${theme.spacing('SMALL')};
    }
    ${theme.breakpoints.up('tablet')} {
      width: 19rem;
      max-width: 50%;
      padding-top: ${theme.spacing('2XL')};

      h1 {
        margin-bottom: ${theme.spacing('LARGE')};
      }
    }
    ${theme.breakpoints.up('desktop')} {
      padding-top: ${theme.spacing('6XL')};
      max-width: 22rem;
    }
    ${theme.breakpoints.up('largeDesktop')} {
      padding-top: ${theme.spacing('6XL')};
      max-width: 22rem;
    }
  `
);

export const StyledContentContainer = styled('div')(
  ({ theme }) => css`
    z-index: 1;
    position: relative;
    margin-top: -9.5rem;
    ${theme.breakpoints.up('largeMobile')} {
      margin-top: -10.5rem;
    }
    ${theme.breakpoints.up('tablet')} {
      margin-top: -8.5rem;
    }
  `
);

export const StyledItemImg = styled('img')(
  ({ theme }) => css`
    width: 100%;
    display: block;
    margin: 0 auto;
    ${theme.breakpoints.up('tablet')} {
      width: auto;
    }
  `
);
export const StyledArrowImg = styled('img')`
  width: auto;
  display: block;
  margin: 0 auto;
`;

export const StyledNumberImg = styled('img')`
  width: auto;
`;

export const StyledItemContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing('LARGE')};
  `
);

export const StyledContentTextHeader = styled('div')(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing('LARGE')};

    h3 {
      margin-bottom: ${theme.spacing('LARGE')};
    }
  `
);

export const StyledInsuranceNumberContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${PALETTE.CLEAR_LIGHT_ORANGE};
    align-items: center;
    padding: ${theme.spacing('SMALL')};
    margin: auto;
    margin-top: ${theme.spacing('LARGE')};
    margin-bottom: ${theme.spacing('LARGE')};
    border: 1px dashed;
    border-radius: ${theme.spacing('XSMALL')};
  `
);

export const StyledInsuranceNumberCopyContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    column-gap: ${theme.spacing('SMALL')};
    align-items: center;

    &:focus {
      box-shadow: ${theme.shadows[3]};
    }
  `
);

export const StyledIconButton = styled(IconButton)`
  img {
    width: auto;
    height: auto;
  }
`;

export const StyledPaper = styled(Paper)(
  ({ theme }) => css`
    border-radius: ${theme.spacing('LARGE')};
    box-shadow: none;
  `
);

export const StyledFranceFirstVetFlexContainer = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing('MEDIUM')};
    display: flex;
    flex-direction: column;
    align-items: center;
    > button {
      min-width: auto !important;
    }
    ${theme.breakpoints.up('desktop')} {
      margin-top: ${theme.spacing('1XL')};
    }
  `
);
