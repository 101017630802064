import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, Link, Typography } from '@mui/material';

import {
  BackButton,
  ButtonContainer,
  Checkbox,
  IconButton,
  PageTitle,
  Paper,
} from '../../../../components/common';
import FileList from '../UploadDocument/FileList';
import {
  StyledCardContentLine,
  StyledCardHeader,
  StyledCheckBoxContainer,
} from './SubmitClaimForm.styled';

const renderAnchorTag = (chunk, href) => {
  return (
    <Link variant="p" href={href} target="_blank" rel="noopener noreferrer">
      {chunk}
    </Link>
  );
};

const SubmitClaimForm = ({
  selectedDocuments,
  backButtonLink,
  editDetailsButtonLink,
  editUploadedFilesLink,
  pageTitleId,
  editIcon,
  formDataCardHeaderId,
  cardContent,
  uploadedFilesCardHeaderId,
  uploadClaimForm,
  submitButtonId,
  dataProtectionLink,
}) => {
  const history = useHistory();

  const [disabled, setDisabled] = useState(false);

  const [confirmDataProcessing, setConfirmDataProcessing] = useState(false);

  const validSelectedDocuments = Array.isArray(selectedDocuments)
    ? selectedDocuments.filter((d) => d?.valid)
    : [];

  const cardContentToDisplay = Array.isArray(cardContent)
    ? cardContent.filter((contentItem) => !!contentItem?.value)
    : [];

  const handleButtonClick = (event) => {
    event.preventDefault();

    if (typeof uploadClaimForm === 'function' && confirmDataProcessing) {
      setDisabled(true);
      uploadClaimForm();
    }
  };

  return (
    <>
      <BackButton to={backButtonLink}>
        <Typography variant="h3">
          <FormattedMessage id="common.button.back" />
        </Typography>
      </BackButton>

      <PageTitle>
        <Typography variant="h1">
          <FormattedMessage id={pageTitleId} />
        </Typography>
      </PageTitle>

      <Paper style={{ marginTop: '1.5em' }}>
        <StyledCardHeader>
          <PageTitle>
            <Typography variant="h1">
              <FormattedMessage id={formDataCardHeaderId} />
            </Typography>
          </PageTitle>

          {editDetailsButtonLink && (
            <IconButton
              icon={editIcon}
              alt="edit"
              size="sm"
              onClick={() => history.push(editDetailsButtonLink)}
            />
          )}
        </StyledCardHeader>

        {cardContentToDisplay.map((contentItem) => {
          const { label, value } = contentItem;
          return (
            <StyledCardContentLine key={label}>
              <Typography variant="p" color="professional_blue">
                <FormattedMessage id={label} />
              </Typography>
              <Typography variant="h3">&nbsp;{value}</Typography>
            </StyledCardContentLine>
          );
        })}
      </Paper>

      <Paper style={{ marginTop: '1.5em' }}>
        <StyledCardHeader>
          <PageTitle>
            <Typography variant="h1">
              <FormattedMessage id={uploadedFilesCardHeaderId} />
            </Typography>
          </PageTitle>

          <IconButton
            icon={editIcon}
            alt="edit"
            size="sm"
            onClick={() => history.push(editUploadedFilesLink)}
          />
        </StyledCardHeader>

        <div>
          <FileList files={validSelectedDocuments} />
        </div>
      </Paper>

      <form onSubmit={handleButtonClick}>
        <StyledCheckBoxContainer>
          <Checkbox
            id="data-protection-checkbox"
            label={
              <FormattedMessage
                id="new_claim.submit_claim_form.data_protection_checkbox"
                values={{
                  a: (chunk) => renderAnchorTag(chunk, dataProtectionLink),
                }}
              />
            }
            checked={confirmDataProcessing}
            onChange={() => setConfirmDataProcessing(!confirmDataProcessing)}
            required
          />
        </StyledCheckBoxContainer>

        <ButtonContainer>
          <Button
            type="submit"
            data-testid="btn_submit_claim"
            disabled={disabled || !validSelectedDocuments.length}
          >
            <Typography variant="buttons">
              <FormattedMessage id={submitButtonId} />
            </Typography>
          </Button>
        </ButtonContainer>
      </form>
    </>
  );
};

export default SubmitClaimForm;
