import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';

import useVitoloClaims from '../../useVitoloClaims';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { MIN_WIDTH_MD } from '../../../../theme/breakpoints';
import {
  usePrefillSomeFormFieldsForExistingVitoloClaim,
  useUploadTreatmentDocumentVitoloBackButtonLink,
  useUploadTreatmentDocumentVitoloNextButtonLink,
} from './uploadTreatmentDocumentVitoloHooks';
import {
  VITOLO_DOCUMENT_TYPE,
  VITOLO_IS_EXISTING_CLAIM_ANSWER,
  useNewVitoloFormDataValue,
} from '../../claimFormData/newClaimFormData';

import { BackButton, Paper, Paragraph } from '../../../../components/common';
import FileUploader from './FileUploader';
import FileList from './FileList';
import {
  StyledButtonContainer,
  StyledFileList,
  StyledReminderParagraph,
} from './FileUploader.styled';

export default function UploadTreatmentDocumentVitolo(props) {
  const { selectedDocuments, setSelectedDocuments, setClaimSubmitted, claimsCount } = props;

  const isDesktop = useMediaQuery(`(min-width: ${MIN_WIDTH_MD})`);

  const validSelectedDocuments = Array.isArray(selectedDocuments)
    ? selectedDocuments.filter((d) => d?.valid)
    : [];

  const history = useHistory();
  const { claimId } = useParams();

  const [submitVitoloClaim, { loading: submitting }] = useVitoloClaims(claimId);

  const nextButtonLink = useUploadTreatmentDocumentVitoloNextButtonLink(claimId);

  const addFileHandler = (currFiles) => {
    setSelectedDocuments((state) => [...state, ...currFiles]);
  };

  const removeFileHandler = (ev, id) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    const updatedFileList = selectedDocuments?.filter((item) => item?.fileId !== id);
    setSelectedDocuments(updatedFileList);
  };

  const nextStepHandler = () => {
    history.push(nextButtonLink);
  };

  const handleSubmitClaim = () => {
    const payload = {
      files: validSelectedDocuments.map((doc) => doc.file),
    };

    submitVitoloClaim({
      payload,
      setSelectedDocuments,
      setClaimSubmitted,
      clearFormData: false,
    });
  };

  const hasFiles = selectedDocuments?.length > 0;

  const [documentType, setDocumentType] = useNewVitoloFormDataValue(VITOLO_DOCUMENT_TYPE);
  const [isExistingClaim] = useNewVitoloFormDataValue(VITOLO_IS_EXISTING_CLAIM_ANSWER);

  usePrefillSomeFormFieldsForExistingVitoloClaim(claimId, setDocumentType);

  const backButtonLink = useUploadTreatmentDocumentVitoloBackButtonLink(
    claimId,
    documentType,
    claimsCount,
    isExistingClaim
  );

  const shouldSubmitForm = !nextButtonLink;

  return (
    <>
      <BackButton
        label={
          <Typography variant="h3">
            <FormattedMessage id="common.button.back" />
          </Typography>
        }
        to={backButtonLink}
      />

      <Typography variant="h1">
        <FormattedMessage
          id={
            hasFiles
              ? 'new_claim.upload.page.title.with.files'
              : 'new_claim.upload.page.title.without.files'
          }
        />
      </Typography>

      {!hasFiles && (
        <>
          <Paragraph>
            <Typography variant="p">
              <FormattedMessage id="new_claim.upload.page.description" />
            </Typography>
          </Paragraph>

          <StyledReminderParagraph>
            <Typography variant="p">
              <FormattedMessage id="new_claim.upload.page.reminder" />
            </Typography>
          </StyledReminderParagraph>
        </>
      )}
      {hasFiles && (
        <StyledFileList>
          <FileList files={selectedDocuments} onRemove={removeFileHandler} />
        </StyledFileList>
      )}
      <Paper>
        <FileUploader selectedFiles={selectedDocuments} setSelectedFiles={addFileHandler} />

        {isDesktop && (
          <StyledButtonContainer hasFiles={hasFiles}>
            {shouldSubmitForm ? (
              <Button
                onClick={handleSubmitClaim}
                disabled={submitting || !validSelectedDocuments.length}
              >
                <Typography variant="buttons">
                  <FormattedMessage id="vitolo.new_claim.submit_claim_form.send_button" />
                </Typography>
              </Button>
            ) : (
              <Button
                onClick={nextStepHandler}
                disabled={!validSelectedDocuments.length}
                data-testid="next_button"
              >
                <Typography variant="buttons">
                  <FormattedMessage id="common.button.next" />
                </Typography>
              </Button>
            )}
          </StyledButtonContainer>
        )}
      </Paper>
      {!isDesktop && (
        <StyledButtonContainer hasFiles={hasFiles}>
          {shouldSubmitForm ? (
            <Button
              onClick={handleSubmitClaim}
              disabled={submitting || !validSelectedDocuments.length}
            >
              <Typography variant="buttons">
                <FormattedMessage id="vitolo.new_claim.submit_claim_form.send_button" />
              </Typography>
            </Button>
          ) : (
            <Button
              onClick={nextStepHandler}
              disabled={!validSelectedDocuments.length}
              data-testid="next_button"
            >
              <Typography variant="buttons">
                <FormattedMessage id="common.button.next" />
              </Typography>
            </Button>
          )}
        </StyledButtonContainer>
      )}
    </>
  );
}

UploadTreatmentDocumentVitolo.propTypes = {
  selectedDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      fileId: PropTypes.string,
      valid: PropTypes.bool,
    })
  ),
  setSelectedDocuments: PropTypes.func,
};
