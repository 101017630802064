import { Button, css, Paper, styled } from '@mui/material';

import { promotionCarouselSliderHeight } from '../PromotionCarousel/PromotionCarousel.styled';
import { PALETTE } from '../../../theme/palette';

export const StyledVitoloBetterDocPaper = styled(Paper)(
  ({ theme }) => css`
    display: flex;
    background: ${PALETTE.PERIWINKLE};
    height: ${promotionCarouselSliderHeight.mobile};
    padding: ${theme.spacing('NONE')};

    ${theme.breakpoints.up('desktop')} {
      height: ${promotionCarouselSliderHeight.desktop};
    }
  `
);

export const StyledVitoloBetterDocCardImageMobile = styled('img')(
  ({ theme }) => css`
    display: block !important;
    height: ${promotionCarouselSliderHeight.mobile};
    position: relative;
    right: 0.625rem;

    ${theme.breakpoints.up('desktop')} {
      display: none !important;
    }
  `
);

export const StyledVitoloBetterDocCardImageDesktop = styled('img')(
  ({ theme }) => css`
    display: none !important;
    height: 25.75rem;
    position: relative;
    bottom: 5.25rem;

    ${theme.breakpoints.up('desktop')} {
      display: block !important;
    }
  `
);

export const StyledVitoloBetterDocCardContent = styled('div')(
  ({ theme }) => css`
    align-self: center;
    margin: 0 auto;
    padding: ${theme.spacing('LARGE')};

    ${theme.breakpoints.up('desktop')} {
      padding: 0;
      width: 23.125rem;
    }
  `
);

export const StyledVitoloBetterDocCardTitle = styled('div')(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing('SMALL')};
  `
);

export const StyledVitoloBetterDocCardButtonContainer = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing('LARGE')};

    ${theme.breakpoints.up('desktop')} {
      margin-top: ${theme.spacing('1XL')};
    }
  `
);

export const StyledVitoloBetterDocCardButton = styled(Button)(
  ({ theme }) => css`
    min-width: fit-content !important;

    ${theme.breakpoints.up('desktop')} {
      min-width: fit-content !important;
      padding-left: ${theme.spacing('2XL')};
      padding-right: ${theme.spacing('2XL')};
    }
  `
);
