import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, IconButton, Link, Typography } from '@mui/material';

import { useTrackDtEvent } from '../../../DtlTracker';
import {
  ADD_FAVORITE_DENTIST,
  GET_FAVORITE_DENTISTS,
  GET_CUSTOMER,
  REMOVE_FAVORITE_DENTIST,
} from '../../../shared';

import { BarLoader, Icon } from '../../../components/common';
import ButtonContainer from '../../../components/ButtonContainer';
import CloseIcon from '../../../icons/CloseIcon';
import FavoriteIcon from '../../../icons/FavoriteIcon';

import DentistOpeningHours from './DentistOpeningHours';

import {
  StyledDentistDetailsAvatar,
  StyledDentistDetailsAvatarContainer,
  StyledDentistDetailsCardContainer,
  StyledToggleDentistAsFavorite,
  StyledDentistDetailsCardHeader,
  StyledDentistDetailsCardItem,
  StyledDentistDetailsCardWrapper,
  StyledDentistDetailsContent,
  StyledBarLoaderWrapper,
} from './DentistsDetails.styled';

export default function DentistDetails(props) {
  const { dentist, setDentist, hideDismissButton, hidden } = props;

  const intl = useIntl();

  const referenceKey = dentist?.referenceKey;

  const getFavoriteDentistList = useQuery(GET_FAVORITE_DENTISTS);
  const getCustomer = useQuery(GET_CUSTOMER);
  const favouriteDentistListLoading = getFavoriteDentistList.loading;
  const favouriteDentistList = getFavoriteDentistList.data;

  const [heartIcon, setHeartIcon] = useState('icon.blank.heart');
  const [showLoader, setShowLoader] = useState(false);
  const [sendUserData] = useTrackDtEvent();

  const [removeFavoriteDentistMutation] = useMutation(REMOVE_FAVORITE_DENTIST, {
    refetchQueries: [{ query: GET_FAVORITE_DENTISTS }],
  });

  const [setFavoriteDentistMutation] = useMutation(ADD_FAVORITE_DENTIST, {
    variables: {
      dentistKey: dentist?.referenceKey,
    },
    refetchQueries: [{ query: GET_FAVORITE_DENTISTS }],
  });

  const trackDtEvent = (eventType = '') => {
    const trackData = {
      userData: {
        eventType: eventType || '',
        time: new Date().toISOString(),
        url: window?.location?.href,
        uuid: getCustomer?.data?.customer?.uuid,
        dentist: dentist?.referenceKey,
        website: dentist?.websiteUrl,
      },
    };
    sendUserData(trackData);
  };

  const setFavoriteDentist = () => {
    setFavoriteDentistMutation().then((res) => {
      if (res?.data?.addFavoriteDentist && !res?.data?.addFavoriteDentist?.errors?.length) {
        setHeartIcon('icon.fill.heart');
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    });
  };

  const removeFavoriteDentist = (key) => {
    removeFavoriteDentistMutation({ variables: { dentistKey: key } }).then((res) => {
      if (
        res &&
        res.data &&
        res.data.removeFavoriteDentist &&
        !res.data.removeFavoriteDentist.errors.length
      ) {
        setShowLoader(false);
        setHeartIcon('icon.blank.heart');
      } else {
        setShowLoader(false);
      }
    });
  };

  const toggleFavoriteDentist = () => {
    setShowLoader(true);
    if (heartIcon === 'icon.blank.heart') {
      trackDtEvent('click add dentist to favourites');
      setFavoriteDentist();
    } else {
      trackDtEvent('click remove dentist from favourites');
      removeFavoriteDentist(dentist?.referenceKey);
    }
  };

  useEffect(() => {
    if (favouriteDentistList && dentist) {
      const favoriteDentist =
        favouriteDentistList.customer &&
        favouriteDentistList.customer.favoriteDentists.find(
          (favDentist) => dentist.referenceKey === favDentist.dentistKey
        );

      if (favoriteDentist) {
        setHeartIcon('icon.fill.heart');
      } else {
        setHeartIcon('icon.blank.heart');
      }
    }
  }, [dentist, referenceKey, favouriteDentistList]);

  const handleLinkClick = () => {
    window.open(dentist?.websiteUrl, '_blank');
    trackDtEvent('click dentist website');
  };

  if (!dentist || favouriteDentistListLoading || hidden) return null;

  const avatarSrc = dentist?.avatar;
  const isFavourite = heartIcon === 'icon.fill.heart';
  const address = `${dentist?.streetName} ${dentist?.houseNumber}, ${dentist?.postcode} ${dentist?.city}`;

  return (
    <StyledDentistDetailsCardContainer>
      <StyledDentistDetailsCardWrapper blurred={showLoader}>
        <StyledDentistDetailsCardHeader>
          <Typography variant="p">{dentist?.practiceName}</Typography>

          {!hideDismissButton && (
            <IconButton onClick={() => setDentist(null)} aria-label="close dentist details">
              <CloseIcon />
            </IconButton>
          )}
        </StyledDentistDetailsCardHeader>

        <StyledDentistDetailsAvatarContainer>
          {avatarSrc && <StyledDentistDetailsAvatar src={avatarSrc} alt="dentist avatar" />}

          <div>
            <Typography variant="h3">
              {dentist?.title} {dentist?.name}
            </Typography>

            {dentist?.dentoloPartner && (
              <Typography variant="p">
                {intl.formatMessage({ id: 'dentist.details.dentolo.dentist' })}
              </Typography>
            )}
          </div>
        </StyledDentistDetailsAvatarContainer>

        <StyledDentistDetailsContent>
          <StyledDentistDetailsCardItem>
            <Icon icon="icon.pin" alt="address" />
            <Typography variant="p">{address}</Typography>
          </StyledDentistDetailsCardItem>

          {dentist?.phoneNumber && (
            <StyledDentistDetailsCardItem>
              <Icon icon="icon.phone" alt="telephone" />
              <Typography variant={!dentist?.dentoloPartner ? 'h3' : 'p'}>
                {dentist?.phoneNumber}
              </Typography>
            </StyledDentistDetailsCardItem>
          )}

          {dentist?.websiteUrl && (
            <StyledDentistDetailsCardItem>
              <Icon icon="icon.globe" alt="website" />
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                variant={!dentist?.dentoloPartner ? 'h3' : 'p'}
                component="button"
                onClick={handleLinkClick}
              >
                {dentist?.websiteUrl}
              </Link>
            </StyledDentistDetailsCardItem>
          )}

          <DentistOpeningHours dentist={dentist} />
        </StyledDentistDetailsContent>

        {dentist?.dentoloPartner && dentist?.websiteUrl && !hideDismissButton && (
          <ButtonContainer>
            <Button onClick={() => window.open(`${dentist?.websiteUrl}`, '_blank')}>
              <Typography variant="buttons">
                <FormattedMessage id="dentist.details.book.appointment" />
              </Typography>
            </Button>
          </ButtonContainer>
        )}

        {dentist?.phoneNumber && hideDismissButton && (
          <ButtonContainer>
            <Button component="a" href={`tel:${dentist?.phoneNumber}`}>
              <Typography variant="buttons">
                <FormattedMessage id="dentist.details.book.appointment" />
              </Typography>
            </Button>
          </ButtonContainer>
        )}

        <StyledToggleDentistAsFavorite favourite={isFavourite} onClick={toggleFavoriteDentist}>
          <IconButton aria-label="Favourite Dentist" onClick={toggleFavoriteDentist} size="large">
            <FavoriteIcon filled={isFavourite} />
          </IconButton>

          <Typography variant="h3">
            <FormattedMessage tagName="span" id="dentist.details.common.save.favourites" />
          </Typography>
        </StyledToggleDentistAsFavorite>
      </StyledDentistDetailsCardWrapper>

      {showLoader && (
        <StyledBarLoaderWrapper>
          <BarLoader />
        </StyledBarLoaderWrapper>
      )}
    </StyledDentistDetailsCardContainer>
  );
}

DentistDetails.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  setDentist: PropTypes.func,
  hideDismissButton: PropTypes.bool,
  dentist: PropTypes.shape({
    avatar: PropTypes.string,
    city: PropTypes.string,
    dentoloPartner: PropTypes.bool,
    houseNumber: PropTypes.string,
    name: PropTypes.string,
    openingHours: PropTypes.shape({}),
    phoneNumber: PropTypes.string,
    postcode: PropTypes.string,
    practiceName: PropTypes.string,
    referenceKey: PropTypes.string,
    streetName: PropTypes.string,
    title: PropTypes.string,
    websiteUrl: PropTypes.string,
  }),
};
