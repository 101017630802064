import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { EmptyCardIcon } from './DigitalPaymentsIcon';

import { StyledDigitalPaymentStatus, StyledDigitalPaymentDetails } from './DigitalPayment.styled';

const DigitalPaymentLoading = () => {
  return (
    <>
      <StyledDigitalPaymentStatus>
        <Typography variant="p">
          <FormattedMessage id="policy.details.my.digital.payment.loading.status" tagName="span" />
        </Typography>
      </StyledDigitalPaymentStatus>

      <StyledDigitalPaymentDetails>
        <EmptyCardIcon />

        <div>
          <Typography variant="p" color="gray">
            <FormattedMessage id="policy.details.account.digital.payment.card.numper.placeholder" />
          </Typography>

          <Typography variant="p" color="gray">
            <FormattedMessage id="policy.details.account.digital.payment.expire" />
            <FormattedMessage id="policy.details.account.digital.payment.card.numper.expire.placeholder" />
          </Typography>
        </div>
      </StyledDigitalPaymentDetails>
    </>
  );
};

export default DigitalPaymentLoading;
