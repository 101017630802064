export const baseUrl = process.env.REACT_APP_BASE_URL;
export const graphQLEndpointDentolo = process.env.REACT_APP_GRAPHQL_ENDPOINT_DENTOLO;
export const graphQLEndpointPetolo = process.env.REACT_APP_GRAPHQL_ENDPOINT_PETOLO;
export const graphQLEndpointVitolo = process.env.REACT_APP_GRAPHQL_ENDPOINT_VITOLO;
export const graphQLEndpointPatolo = process.env.REACT_APP_GRAPHQL_ENDPOINT_PATOLO;

export const googleMapsKey = `AIzaSyCX-qPHe8YYr7O0tNfrqNTIawhQ_2eW6TA`;

export const helpCenterLinkCancellation =
  '//support.dentolo.de/hc/de/articles/360020463380-Wie-kann-ich-meinen-Zahnschutz-widerrufen-k%C3%BCndigen-?utm_source=account_area&utm_medium=contact&utm_campaign=cancellation_withdrawal';

export const PETOLO_IMPRINT_LINK = 'https://www.petolo.de/impressum';
export const PETOLO_DATA_PROTECT_LINK = 'https://www.petolo.de/datenschutz';
export const PETOLO_HELP_LINK = '//support.petolo.de/hc/de';

export const DENTOLO_IMPRINT_LINK = 'https://dentolo.de/impressum';
export const DENTOLO_DATA_PROTECT_LINK = 'https://dentolo.de/datenschutz';
export const DENTOLO_HELP_LINK = '//support.dentolo.de/hc/de';
export const DENTOLO_DENTOLO_DENTISTS_HELPCENTER_LINK =
  'https://support.dentolo.de/hc/de/articles/360020227733-Wer-sind-die-dentolo-Zahn%C3%A4rzte';
export const DENTOLO_AKUTSCHUTZ_HELPCENTER_LINK =
  'https://support.dentolo.de/hc/de/articles/360012859859-Der-dentolo-Zahnschutz-AKUT';

export const VITOLO_IMPRINT_LINK = 'https://www.vitolo.de/impressum';
export const VITOLO_DATA_PROTECT_LINK = 'https://www.vitolo.de/datenschutz';
export const VITOLO_HELP_LINK = 'https://support.vitolo.de/hc/de';

export const VITOLO_CROSS_SELLING_URL =
  '//www.vitolo.de/?utm_source=account_area&utm_medium=carousel&utm_campaign=dentolo_xsell_vitolo';

export const VITOLO_BETTER_DOC_URL = 'https://partner.betterdoc.org/vitolo';

// # Referral links
export const DENTOLO_REFERRAL_URL = process.env.REACT_APP_DENTOLO_REFERRAL_URL;
export const DENTOLO_ADDITIONAL_INSURANCE_URL =
  process.env.REACT_APP_DENTOLO_ADDITIONAL_INSURANCE_URL;
export const DENTOLO_REFERRAL_TERMS_LINK = '//www.dentolo.de/freunde-werben-teilnahmebedingungen';

export const PETOLO_REFERRAL_URL = process.env.REACT_APP_PETOLO_REFERRAL_URL;
export const PETOLO_INSURE_ANOTHER_PET_URL = process.env.REACT_APP_PETOLO_INSURE_ANOTHER_PET_URL;
export const PETOLO_REFERRAL_TERMS_LINK =
  'https://www.petolo.de/freunde-werben-teilnahmebedingungen';
export const PETOLO_REFERRAL_INFO_LINK = 'https://www.petolo.de/freunde-werben';

export const VITOLO_V1_REFERRAL_URL = process.env.REACT_APP_VITOLO_V1_REFERRAL_URL;
export const VITOLO_V2_REFERRAL_URL = process.env.REACT_APP_VITOLO_V2_REFERRAL_URL;
export const VITOLO_REFERRAL_TERMS_LINK = '//www.vitolo.de/freunde-werben-teilnahmebedingungen';

export const GETOLO_UTM_PARAMS = {
  source: 'account_area',
  campaignReferral: 'account_area_referral',
  campaignReferralDentolo: 'account_area_referral_cf',
  campaignAdditionalDentoloInsurance: 'upsell_additional_contract',
  campaignBannerInsureAnotherPet: 'account_area_referral_bottombanner',
};

//  # Da Direkt
export const DA_DIREKT_REFERRAL_PAGE_LINK_DENTAL =
  '//www.da-direkt.de/zahnzusatzversicherung/empfehlen';
export const DA_DIREKT_REFERRAL_PAGE_LINK_CAROUSEL_DENTAL = `${DA_DIREKT_REFERRAL_PAGE_LINK_DENTAL}?WT.mc_id=owned.taf.dentolo.zzv.kupo.banner.uebersicht`;
export const DA_DIREKT_REFERRAL_PAGE_LINK_NAV_DENTAL = `${DA_DIREKT_REFERRAL_PAGE_LINK_DENTAL}?WT.mc_id=owned.taf.dentolo.zzv.kupo.link.navi`;

export const DA_DIREKT_REFERRAL_PAGE_LINK_PET = '//www.da-direkt.de/tierversicherung/empfehlen';
export const DA_DIREKT_REFERRAL_PAGE_LINK_CAROUSEL_PET = `${DA_DIREKT_REFERRAL_PAGE_LINK_PET}?WT.mc_id=owned.taf.petolo.tkv.kupo.banner.uebersicht`;
export const DA_DIREKT_REFERRAL_PAGE_LINK_NAV_PET = `${DA_DIREKT_REFERRAL_PAGE_LINK_PET}?WT.mc_id=owned.taf.petolo.tkv.kupo.link.navi`;

export const DA_DIREKT_CROSS_SELLING_DENTAL_URL = 'https://www.da-direkt.de/zahnzusatzversicherung';
export const DA_DIREKT_CROSS_SELLING_PET_URL = 'https://www.da-direkt.de/tierversicherung';
export const DA_DIREKT_CROSS_SELLING_HEALTH_URL =
  'https://www.da-direkt.de/krankenzusatzversicherung';

// # Fressnapf
export const FRESSNAPF_FRIENDS_PAGE_LINK = '//www.fressnapf.de/friends/';
export const FRESSNAPF_FRIENDS_PAGE_LINK_PROMOTION_CAROUSEL = `${FRESSNAPF_FRIENDS_PAGE_LINK}?utm_medium=referral&utm_source=petolo&utm_campaign=petolo-kundenkonto`;

export const DENTOLO_HELPCENTER_TAX_RETURN_LINK =
  'https://www.dentolo.de/ratgeber/zahnzusatzversicherung-steuererklaerung/';

export const DENTOLO_DATA_PROTECTION_CHECKBOX_LINK = 'https://www.dentolo.de/datenschutz';
export const PETOLO_DATA_PROTECTION_CHECKBOX_LINK = 'https://www.petolo.de/datenschutz';
export const VITOLO_DATA_PROTECTION_CHECKBOX_LINK = 'https://www.vitolo.de/datenschutz';

export const PATOLO_IMPRINT_LINK = 'https://www.patolo.fr/mentions-legales';
export const PATOLO_DATA_PROTECT_LINK = 'https://www.patolo.fr/politique-de-confidentialite';
