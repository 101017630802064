import { Button, css, styled } from '@mui/material';

import { imgLinks } from '../../../shared';
import { promotionCarouselSliderHeight } from '../PromotionCarousel/PromotionCarousel.styled';

import { PALETTE } from '../../../theme/palette';

export const StyledVitoloCrossSellingCard = styled('div')(
  ({ theme, imgDesktop, imgMobile }) => css`
    background: linear-gradient(
      178deg,
      ${PALETTE.VIBRANT_LIGHT_VIOLET} 27.17%,
      #ded1ff 63.7%,
      ${PALETTE.CLEAR_LIGHT_VIOLET} 86.06%,
      ${theme.palette.common.white} 94.67%
    );
    display: flex;
    flex-direction: column;
    height: ${promotionCarouselSliderHeight.mobile};
    padding: ${theme.spacing('LARGE')};
    position: relative;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 8.5625rem;
      height: 8.9375rem;
      background: url(${imgMobile}) no-repeat;
      background-size: 8.5625rem 8.9375rem;
      background-position: right bottom;
    }

    ${theme.breakpoints.up('desktop')} {
      row-gap: ${theme.spacing('LARGE')};
      padding: ${theme.spacing('2XL')};
      height: ${promotionCarouselSliderHeight.desktop};

      &::before {
        width: 18.5rem;
        height: 18.75rem;
        background-image: url(${imgDesktop});
        background-size: 18.5rem 18.75rem;
      }
    }
  `
);

export const StyledVitoloCrossSellingCardTitle = styled('div')(
  ({ theme }) => css`
    width: 100%;

    ${theme.breakpoints.up('desktop')} {
      width: 20.375rem;
    }
  `
);

export const StyledVitoloCrossSellingCardText = styled('div')(
  ({ theme }) => css`
    position: relative;
    width: 13.5rem;

    u {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
      padding: 0;
      text-decoration: none;

      &::after {
        content: '';
        width: 2.6875rem;
        height: 0.25rem;
        background: url(${imgLinks['crossselling.underline']}) no-repeat;
        background-size: 2.6875rem 0.25rem;
        position: absolute;
        top: 1.375rem;
      }
    }

    ${theme.breakpoints.up('desktop')} {
      width: 15.1875rem;

      u {
        &::after {
          background-size: 3.75rem 0.3125rem;
          width: 3.75rem;
          height: 0.3125rem;
        }
      }
    }
  `
);

export const StyledSpacer = styled('div')`
  flex-grow: 1;
`;

export const StyledVitoloCrossSellingCardButton = styled(Button)(
  ({ theme }) => css`
    position: relative;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    min-width: fit-content !important;
    max-width: fit-content;

    &::after {
      content: '';
    }

    ${theme.breakpoints.up('desktop')} {
      &::after {
        content: '';
        background: url(${imgLinks['crossselling.arrow']}) no-repeat;
        background-size: 2.375rem 3.0625rem;
        position: absolute;
        right: -3rem;
        top: 1.25rem;
        width: 2.375rem;
        height: 3.0625rem;
        -webkit-filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
      }
    }
  `
);
