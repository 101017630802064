import React from 'react';
import { SvgIcon, useTheme } from '@mui/material';

export default function FavoriteIcon(props) {
  const theme = useTheme();

  const { filled, ...otherProps } = props;

  return (
    <SvgIcon inheritViewBox {...otherProps}>
      <svg
        width="16"
        height="13"
        viewBox="0 0 16 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.90863 2.4368C8.77005 2.64838 8.53416 2.77586 8.28123 2.77586C8.0283 2.77586 7.79241 2.64838 7.65383 2.4368C6.90239 1.28953 5.95549 0.75 4.80186 0.75C4.1166 0.75 3.2867 1.08805 2.62021 1.70283C1.96173 2.31022 1.53205 3.1229 1.53135 4.00649C1.52989 5.84275 2.15884 7.07825 3.28434 8.25671C4.14267 9.15543 5.24725 9.98359 6.59063 10.9908C7.10151 11.3738 7.64692 11.7828 8.22646 12.2313C8.25867 12.2562 8.30379 12.2562 8.336 12.2313C8.91554 11.7828 9.46095 11.3738 9.97183 10.9908C11.3152 9.98359 12.4198 9.15543 13.2781 8.25671C14.4036 7.07825 15.0326 5.84275 15.0311 4.00649C15.0304 3.1229 14.6007 2.31022 13.9422 1.70283C13.2758 1.08805 12.4459 0.75 11.7606 0.75C10.607 0.75 9.66007 1.28953 8.90863 2.4368Z"
          stroke={theme?.palette?.main?.professional_blue}
          fill={filled ? theme?.palette?.main?.professional_blue : 'none'}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
