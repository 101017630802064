import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, IconButton, Typography } from '@mui/material';

import ButtonContainer from '../../../components/ButtonContainer';
import { Icon } from '../../../components/common';
import CloseIcon from '../../../icons/CloseIcon';

import {
  StyledDentistDetailsMobilePaper,
  StyledDentistDetailsAvatar,
  StyledDentistDetailsAvatarContainer,
  StyledDentistDetailsCardHeader,
  StyledDentistDetailsCardItem,
  StyledDentistDetailsCardWrapper,
  StyledDentistDetailsContent,
} from './DentistsDetails.styled';

export default function PartnerDentistDetailsMobile({ dentist, setDentist, setShowDetails }) {
  const { avatar, city, houseNumber, name, postcode, practiceName, streetName, title } =
    dentist || {};

  const avatarSrc = avatar;
  const address = `${streetName} ${houseNumber}, ${postcode} ${city}`;

  if (!dentist) return null;

  return (
    <StyledDentistDetailsMobilePaper>
      <StyledDentistDetailsCardWrapper>
        <StyledDentistDetailsCardHeader>
          <Typography variant="p" color="professional_blue">
            {practiceName}
          </Typography>

          <IconButton onClick={() => setDentist(null)} aria-label="close dentist details">
            <CloseIcon />
          </IconButton>
        </StyledDentistDetailsCardHeader>

        <StyledDentistDetailsAvatarContainer>
          {avatarSrc && <StyledDentistDetailsAvatar src={avatarSrc} alt="avatar" />}

          <div>
            <Typography variant="h3" color="professional_blue">
              {title} {name}
            </Typography>

            <Typography variant="p" color="professional_blue">
              <FormattedMessage id="dentist.details.dentolo.dentist" />
            </Typography>
          </div>
        </StyledDentistDetailsAvatarContainer>

        <StyledDentistDetailsContent>
          <StyledDentistDetailsCardItem>
            <Icon icon="icon.pin" alt="address" />

            <Typography variant="p" color="professional_blue">
              {address}
            </Typography>
          </StyledDentistDetailsCardItem>
        </StyledDentistDetailsContent>

        <ButtonContainer>
          <Button onClick={() => setShowDetails(true)}>
            <Typography variant="buttons">
              <FormattedMessage id="dentist.map.float_card.details" />
            </Typography>
          </Button>
        </ButtonContainer>
      </StyledDentistDetailsCardWrapper>
    </StyledDentistDetailsMobilePaper>
  );
}

PartnerDentistDetailsMobile.propTypes = {
  setDentist: PropTypes.func,
  setShowDetails: PropTypes.func,
  dentist: PropTypes.shape({
    avatar: PropTypes.string,
    city: PropTypes.string,
    dentoloPartner: PropTypes.bool,
    houseNumber: PropTypes.string,
    name: PropTypes.string,
    postcode: PropTypes.string,
    practiceName: PropTypes.string,
    streetName: PropTypes.string,
    title: PropTypes.string,
  }),
};
