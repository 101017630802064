import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import Budget from './Budget';
import CheckIcon from './CheckIcon';

import { StyledBoxAlignStart, StyledVerticalContent } from '../PolicyInfo.styled';

const Conditions = ({ conditions, budgetInfo, budgetAmountInfo, hasAddon }) => {
  if (!Array.isArray(conditions)) return null;

  return (
    <StyledVerticalContent>
      <Typography variant="h3">
        <FormattedMessage id="policy.all_details.conditions.title" />:
      </Typography>

      {conditions.map((item) => (
        <StyledBoxAlignStart key={item?.title || item?.text}>
          <CheckIcon />

          <div>
            <Typography variant="h3">{item?.title || ''}</Typography>

            <Typography variant="p" color="subtext">
              {item?.text || ''}
            </Typography>
          </div>
        </StyledBoxAlignStart>
      ))}

      <Budget budgetInfo={budgetInfo} budgetAmountInfo={budgetAmountInfo} hasAddon={hasAddon} />
    </StyledVerticalContent>
  );
};

export default Conditions;
