import React from 'react';
import { SvgIcon, useTheme } from '@mui/material';

export default function DownloadIcon(props) {
  const theme = useTheme();

  return (
    <SvgIcon inheritViewBox {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M15 11.1391V13.2007C15 14.1944 14.2165 15 13.25 15H2.75C1.7835 15 1 14.1944 1 13.2007L1 11.1391M4.5 6.89749L8 10.5455M8 10.5455L11.5 6.89749M8 10.5455V1"
          stroke={theme?.palette?.text?.button}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
