import { css, styled } from '@mui/material';
import ButtonContainer from '../ButtonContainer';

export const StyledVerticalContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledLoginActionStatusHeader = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};
    text-align: center;

    img {
      margin: 0 auto;
    }

    ${theme.breakpoints.up('desktop')} {
      flex-direction: row;
      column-gap: ${theme.spacing('LARGE')};
      align-items: center;
      text-align: left;

      img {
        margin: 0;
      }
    }
  `
);

export const StyledTipContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.background.info};
    column-gap: ${theme.spacing('SMALL')};
    display: flex;
    padding: ${theme.spacing('LARGE')};
  `
);

export const StyledTipIconContainer = styled('div')(
  () => css`
    flex-shrink: 0;
  `
);

export const StyledTipItemsList = styled('ul')(
  ({ theme }) => css`
    margin: 0;
    padding-left: ${theme.spacing('1XL')};
  `
);

export const StyledDateInputContainer = styled('div')(
  ({ theme }) => css`
    column-gap: ${theme.spacing('LARGE')};
    display: flex;

    & > div {
      flex-grow: 1;
    }
  `
);

export const StyledSecondAuthButtonContainer = styled(ButtonContainer)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing('1XL')};
  `
);
