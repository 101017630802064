import React from 'react';
import { SvgIcon, useTheme } from '@mui/material';

export default function EditIcon(props) {
  const theme = useTheme();

  return (
    <SvgIcon inheritViewBox {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.7 3.8L11.5 1L15 4.5L12.2 7.3M8.7 3.8L1 11.5V15H4.5L12.2 7.3M8.7 3.8L12.2 7.3"
          stroke={theme?.palette?.text?.button}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 15H15"
          stroke={theme?.palette?.text?.button}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
