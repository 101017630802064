import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

import {
  combineFullDateFromInputs,
  getInputFieldValuesFromDate,
  validateMonthRange,
} from './dateUtils';
import { dateDiff } from '../../../../shared/utils';
import { VALID_DATE, NOT_VALID_DATE, IN_PAST_DATE } from '../../../../shared/constants';
import {
  BackButton,
  DatePicker,
  FormSectionContainer,
  ButtonContainer,
  ErrorMessage,
  PageTitle,
} from '../../../../components/common';

const SelectDocumentCreationDate = ({
  backButtonLink,
  nextButtonLink,
  pageTitleId,
  formDescription,
  setDocumentCreationDate,
  documentCreationDate,
}) => {
  const history = useHistory();

  const documentCreationDateInputFieldValues = getInputFieldValuesFromDate(documentCreationDate);
  const [date, setDate] = useState(documentCreationDateInputFieldValues);

  const { day, month, year } = date;
  const setDay = (newDay) => setDate({ day: newDay, month, year });
  const setMonth = (newMonth) => setDate({ day, month: newMonth, year });
  const setYear = (newYear) => setDate({ day, month, year: newYear });

  const [dateStatus, setDateStatus] = useState(VALID_DATE);

  useEffect(() => {
    const newDocumentCreationDate = combineFullDateFromInputs(day, month, year);
    const newDocumentCreationDateObject = new Date(newDocumentCreationDate);
    const currentDate = new Date();
    const isValidDate = !Number.isNaN(Number(newDocumentCreationDateObject));
    if (isValidDate) {
      setDateStatus(VALID_DATE);
    }

    const notInTheFuture = isValidDate ? newDocumentCreationDateObject < currentDate : false;
    if (notInTheFuture && validateMonthRange(newDocumentCreationDate)) {
      setDocumentCreationDate(newDocumentCreationDate);
    } else {
      setDocumentCreationDate('');
    }
  }, [day, month, year, setDocumentCreationDate]);

  const handleButtonClick = () => {
    if (!documentCreationDate || !validateMonthRange(documentCreationDate)) {
      setDateStatus(NOT_VALID_DATE);
    } else if (
      documentCreationDate &&
      validateMonthRange(documentCreationDate) &&
      dateDiff('year', new Date(documentCreationDate)) >= 5
    ) {
      setDateStatus(IN_PAST_DATE);
    } else {
      history.push(nextButtonLink);
    }
  };

  const disabledFormChecker = () => {
    return dateStatus !== VALID_DATE || !day || !month || !year || year.length !== 4;
  };

  return (
    <>
      <BackButton to={backButtonLink}>
        <Typography variant="h3">
          <FormattedMessage id="common.button.back" />
        </Typography>
      </BackButton>

      <PageTitle>
        <Typography variant="h1">
          <FormattedMessage id={pageTitleId} />
        </Typography>
      </PageTitle>

      <FormSectionContainer>
        <Typography variant="p" color="professional_blue" style={{ marginBottom: '.5em' }}>
          <FormattedMessage id={formDescription} />
        </Typography>

        <DatePicker
          day={day}
          month={month}
          year={year}
          setDay={setDay}
          setMonth={setMonth}
          setYear={setYear}
        />

        {dateStatus === NOT_VALID_DATE && (
          <ErrorMessage data-testid="error-date">
            <Typography variant="p">
              <FormattedMessage id="new_claim.invalid.date" />
            </Typography>
          </ErrorMessage>
        )}

        {dateStatus === IN_PAST_DATE && (
          <ErrorMessage data-testid="error-date">
            <Typography variant="p">
              <FormattedMessage id="new_claim.invalid.date_past" />
            </Typography>
          </ErrorMessage>
        )}
      </FormSectionContainer>

      <ButtonContainer>
        <Button
          data-testid="next_button"
          onClick={handleButtonClick}
          disabled={disabledFormChecker()}
        >
          <Typography variant="buttons">
            <FormattedMessage id="common.button.next" />
          </Typography>
        </Button>
      </ButtonContainer>
    </>
  );
};

export default SelectDocumentCreationDate;
