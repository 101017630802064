import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, Typography } from '@mui/material';

import { imgLinks } from '../../../shared';

import {
  StyledReferralLinkCardVitolo,
  StyledReferralLinkCardVitoloContent,
  StyledReferralLinkCardVitoloText,
} from './ReferralLinkCardVitolo.styled';

const renderSpanTag = (chunk) => <span>{chunk}</span>;

const ReferralLinkCardDentolo = () => {
  const history = useHistory();

  const handleGotoReferralPage = () => {
    history.push('/referral');
  };

  return (
    <StyledReferralLinkCardVitolo
      imgDesktop={imgLinks['carousel.referral.vitolo.image.desktop']}
      imgMobile={imgLinks['carousel.referral.vitolo.image.mobile']}
    >
      <StyledReferralLinkCardVitoloContent>
        <StyledReferralLinkCardVitoloText variant="h1">
          <FormattedMessage
            id="promotion.carousel.vitolo.referral_link.title"
            values={{
              span: renderSpanTag,
              br: <br />,
            }}
          />
        </StyledReferralLinkCardVitoloText>

        <Button onClick={handleGotoReferralPage} variant="cross_sell_vitolo">
          <Typography variant="buttons">
            <FormattedMessage id="promotion.carousel.vitolo.referral_link.button.label" />
          </Typography>
        </Button>
      </StyledReferralLinkCardVitoloContent>
    </StyledReferralLinkCardVitolo>
  );
};

export default ReferralLinkCardDentolo;
