import { css, styled } from '@mui/material';

export const StyledInfoWindowContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    row-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledInfoWindowIcon = styled('div')(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing('SMALL')};

    img {
      display: block;
      height: ${theme.spacing('3XL')};
      margin: 0 auto;
      width: ${theme.spacing('3XL')};
    }

    ${theme.breakpoints.up('desktop')} {
      img {
        height: ${theme.spacing('4XL')};
        width: ${theme.spacing('4XL')};
      }
    }
  `
);
