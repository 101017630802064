import { createTheme } from '@mui/material';

import { PALETTE } from './palette';
import { defaultTheme } from './default.theme';

// eslint-disable-next-line import/prefer-default-export
export const vitoloTheme = createTheme(defaultTheme, {
  palette: {
    background: {
      button: PALETTE.VIBRANT_LIGHT_VIOLET,
      cancellationDate: 'transparent',
      footer: PALETTE.PROFESSIONAL_BLUE,
      info: PALETTE.CLEAR_LIGHT_VIOLET,
      radio: PALETTE.VIBRANT_LIGHT_VIOLET,
      tag: PALETTE.LIGHT_PEACH,
      viewport: PALETTE.ULTRA_CLEAR_LIGHT_VIOLET,
      modalContainer: PALETTE.DUSTY_BLUE,
      referralBulletPoint: PALETTE.VIBRANT_LIGHT_VIOLET,
    },
    text: {
      button: PALETTE.PROFESSIONAL_BLUE,
      copyright: PALETTE.WHITE,
      disabled: PALETTE.DUSTY_BLUE,
      footerLink: PALETTE.VIBRANT_LIGHT_VIOLET,
      subtext: PALETTE.DUSTY_BLUE,
    },
  },
  shadows: [
    defaultTheme.shadows[0],
    defaultTheme.shadows[1],
    defaultTheme.shadows[2],
    `0px 0px 0px 3px ${PALETTE.VIBRANT_LIGHT_VIOLET}`,
    `0 0 4px 1px ${PALETTE.LAVENDER_INDIGO}`,
    `0px 0px 15px 0px ${PALETTE.LAVENDER_INDIGO}`,
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
});
