import { styled, css } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';
import Paper from '../common/Paper';
import { Icon } from '../common';

const borderColorStyles = (theme, type) =>
  ({
    info: css`
      border-color: ${theme.palette.main.professional_blue};
    `,
    success: css`
      border-color: ${theme.palette.success.main};
    `,
    processing: css`
      border-color: ${theme.palette.main.product_blue};
    `,
    warning: css`
      border-color: ${theme.palette.error.main};
    `,
    danger: css`
      border-color: ${theme.palette.error.main};
    `,
  })[`${type}`];

export const StyledNotificationContainer = styled(Paper)(
  ({ theme, type }) => css`
    position: relative;
    border-left: 5px solid;
    border-color: transparent;

    ${borderColorStyles(theme, type)};
  `
);

export const StyledNotificationBody = styled('div')`
  display: flex;
  align-items: center;

  ${({ dismissable }) =>
    dismissable &&
    css`
      margin-right: 1.75rem;
    `}
`;

export const StyledNotificationContent = styled('div')`
  flex: 1;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;

export const StyledNotificationText = styled('div')(
  ({ theme, $title, noSpacing }) => css`
    color: ${theme.palette.main.professional_light_blue};
    text-align: left;

    ${$title &&
    css`
      color: ${theme.palette.main.professional_blue};
      margin-bottom: 0.5rem;
      opacity: 1;
    `}

    ${noSpacing &&
    css`
      margin-bottom: 0;
    `}

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.25rem;
    }

    a {
      position: relative;
      color: ${theme.palette.main.professional_light_blue};
      text-decoration: none;
      margin-top: -2px;

      &::after {
        content: '';
        position: absolute;
        height: 2px;
        bottom: -1px;
        left: 0;
        right: 0;
        width: 100%;
        background-color: ${theme.palette.main.professional_light_blue};
        transform-origin: bottom left;
        transition:
          transform 0.2s ease-out,
          color 0.2s ease-out;
      }

      &:hover {
        &:after {
          transform: scaleX(0);
          transform-origin: bottom right;
        }
      }

      &:focus,
      &:active {
        &::after {
          background-color: ${theme.palette.main.professional_light_blue};
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  `
);

export const StyledNotificationIcon = styled(Icon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 1rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 1.75rem;
    height: 1.75rem;
  }
`;

export const StyledDissmissButton = styled('button')`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;

  > img {
    width: 0.625rem;
    height: 0.625rem;
  }
`;
