import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, Typography } from '@mui/material';

import { Icon } from '../../../components/common';
import {
  StyledPromotionCarouselCard,
  StyledPromotionCarouselCardContentImageWrapper,
  StyledPromotionCarouselCardContentTitle,
  StyledPromotionCarouselButtonContainer,
  StyledPromotionCarouselCardContent,
} from './ReferralLinkCardPetolo.styled';

const ReferralLinkCardPetolo = () => {
  const history = useHistory();

  const handleGotoReferralPage = () => {
    history.push('/referral');
  };

  return (
    <StyledPromotionCarouselCard>
      <StyledPromotionCarouselCardContent>
        <StyledPromotionCarouselCardContentImageWrapper>
          <Icon icon="icon.user.referral.pet.present" alt="referral link image" />
        </StyledPromotionCarouselCardContentImageWrapper>

        <StyledPromotionCarouselCardContentTitle>
          <Typography variant="h2" color="professional_blue">
            <FormattedMessage id="promotion.carousel.petolo.referral_link.title" />
          </Typography>
        </StyledPromotionCarouselCardContentTitle>
      </StyledPromotionCarouselCardContent>

      <StyledPromotionCarouselButtonContainer>
        <Button onClick={handleGotoReferralPage}>
          <Typography variant="buttons">
            <FormattedMessage id="promotion.carousel.petolo.referral_link.button.label" />
          </Typography>
        </Button>
      </StyledPromotionCarouselButtonContainer>
    </StyledPromotionCarouselCard>
  );
};

export default ReferralLinkCardPetolo;
