import { styled, css } from '@mui/material';
import PropTypes from 'prop-types';

const getBackgroundColor = (theme, filled, transparent) => {
  if (transparent) return 'transparent';
  if (filled) return theme.palette.main.professional_blue;

  return theme.palette.common.white;
};

const StyledPaper = styled('div')(
  ({ theme, filled, transparent, hasShadow }) => css`
    padding: 1rem;
    border-radius: 5px;
    color: ${filled ? theme.palette.common.white : 'inherit'};
    background-color: ${getBackgroundColor(theme, filled, transparent)};

    ${hasShadow &&
    css`
      box-shadow: ${theme.shadows[1]};
    `};
  `
);

export default StyledPaper;

StyledPaper.defaultProps = {
  hasShadow: true,
  filled: false,
};

StyledPaper.propTypes = {
  hasShadow: PropTypes.bool,
  filled: PropTypes.bool,
};
