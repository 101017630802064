export const NEW = 'new'; // Neu
export const WAITING_FOR_INFORMATION = 'waiting_for_information'; // Wartend
export const REIMBURSEMENT_ACKNOWLEDGED = 'reimbursement_acknowledged'; // Deckungszusage
export const WAITING_FOR_PAYOUT = 'waiting_for_payout'; // Auszahlung
export const CLOSED = 'closed'; // Ausgezahlt
export const BLOCKED = 'blocked'; // Blockiert
export const DECLINED = 'declined'; // Abgelehnt
export const CANCELLED = 'cancelled'; // Storniert
export const UPDATED = 'updated'; // Aktualisiert
export const PARTIALLY_CLOSED = 'partially_closed'; // > obsolete status

export const CLAIM_STATUS_DETAILS = {
  // NEW
  CLAIM_OPENED: 'claim_opened',

  // WAITING_FOR_INFORMATION
  TREATMENT_OR_COSTPLAN_MISSING: 'treatment_or_costplan_missing',
  INFORMATION_REQUEST_MISSING: 'information_request_missing',
  PATIENT_FILE_MISSING: 'patient_file_missing',
  INVOICE_MISSING: 'invoice_missing',
  DOCUMENTS_MISSING: 'documents_missing',
  PET_MEDICAL_CARD_IS_MISSING: 'pet_medical_card_is_missing',

  // REIMBURSEMENT_ACKNOWLEDGED
  REIMBURSEMENT_ACKNOWLEDGED: 'reimbursement_acknowledged',
  PARTIAL_REIMBURSEMENT_ACKNOWLEDGED: 'partial_reimbursement_acknowledged',

  // WAITING_FOR_PAYOUT
  REIMBURSEMENT_WILL_BE_PAID: 'reimbursement_will_be_paid',
  PARTIAL_REIMBURSEMENT_WILL_BE_PAID: 'partial_reimbursement_will_be_paid',

  // CLOSED
  REIMBURSEMENT_PAID: 'reimbursement_paid',
  PARTIAL_REIMBURSEMENT_PAID: 'partial_reimbursement_paid',

  // BLOCKED
  CONTRACT_SIGNATURE_MISSING: 'contract_signature_missing',
  WITHDRAWAL_PERIOD_STILL_RUNNING: 'withdrawal_period_still_running',
  OUTSTANDING_PAYMENTS: 'outstanding_payments',
  TRIAL_MONTH: 'trial_month',

  // DECLINED
  TREATMENT_BEFORE_CONTRACT_START: 'treatment_before_contract_start',
  NO_COVERAGE: 'no_coverage',
  KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_PLAN:
    'known_of_treatment_before_contract_start_by_plan',
  KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_DENTIST_REQUEST:
    'known_of_treatment_before_contract_start_by_dentist_request',
  KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_PRETREATMENT_PROVIDE:
    'known_of_treatment_before_contract_start_by_pretreatment_provide',
  TREATMENT_BEFORE_CONTRACT_START_BY_INVOICE: 'treatment_before_contract_start_by_invoice',
  NO_BUDGET: 'no_budget',
  NO_COVERAGE_NO_MEDICAL_NEED: 'no_coverage_no_medical_need',
  NO_COVERAGE_ORTHODONTICS: 'no_coverage_orthodontics',
  COVERAGE_FOR_CHILDREN_ONLY_FOR_ACCIDENT: 'coverage_for_children_only_for_accident',
  MAX_AMOUNT_OF_TOOTH_CLEANINGS_REACHED: 'max_amount_of_tooth_cleanings_reached',
  NO_COVERAGE_COSMETIC_MEASURES: 'no_coverage_cosmetic_measures',
  NOT_THE_INSURED_ANIMAL: 'not_the_insured_animal',
  PET_HEALTH_BUDGET_EXHAUSTED: 'pet_health_budget_exhausted',
  PET_SOS_BUDGET_EXHAUSTED: 'pet_sos_budget_exhausted',
  KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_VETERINARIAN_REQUEST:
    'known_of_treatment_before_contract_start_by_veterinarian_request',
  KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_VATERINARIAN_PRETREATMENT_PROVIDE:
    'known_of_treatment_before_contract_start_by_vaterinarian_pretreatment_provide',
  FIRSTVET_TREATMENT_NEEDED: 'firstvet_treatment_needed',
  TREATMENT_PERSON_IS_NOT_INSURED: 'treatment_person_is_not_insured',
  REIMBURSEMENT_IS_ALREADY_COVERED: 'reimbursement_is_already_covered',
  COPAYMENT_IS_ALREADY_COVERED: 'copayment_is_already_covered',
  TREATMENT_WAS_KNOWN_BEFORE_INSURANCE: 'treatment_was_known_before_insurance',
  TREATMENT_BEFORE_CONTRACT_SIGN: 'treatment_before_contract_sign',
  TREATMENT_NEEDS_BEFORE_CONTRACT_SIGN: 'treatment_needs_before_contract_sign',
  TREATMENT_WAS_KNOWN_BEFORE_INSURANCE_BY_DENTIST:
    'treatment_was_known_before_insurance_by_dentist',
  KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_DOCTOR_REQUEST:
    'known_of_treatment_before_contract_start_by_doctor_request',
  COVERAGE_OF_COSTS_THROUGH_THIRD_PARTY_DAMAGE_CLAIMS:
    'coverage_of_costs_through_third_party_damage_claims',

  // CANCELLED
  CANCELED_BY_DENTOLO: 'canceled_by_dentolo',
  CANCELED_BY_CUSTOMER: 'canceled_by_customer',

  // UPDATED
  CLAIM_UPDATED: 'claim_updated',
};
