import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import { formatDate } from '../../shared';

import {
  StyledClaimDetailsStatusHistoryItem,
  StyledProgressTimeline,
  StyledProgressTimelineVertex,
  StyledProgressTimelineEdge,
  StyledClaimDetailsStatusHistoryItemDetails,
  StyledStatusDate,
} from './styled/ClaimStatusCard.styled';

const ClaimStatusCard = ({ date, status, first, last }) => {
  return (
    <StyledClaimDetailsStatusHistoryItem>
      <StyledProgressTimeline>
        <StyledProgressTimelineVertex />
        <StyledProgressTimelineEdge
          first={first && !last}
          last={last && !first}
          one={last && first}
        />
      </StyledProgressTimeline>

      <StyledClaimDetailsStatusHistoryItemDetails>
        {date && (
          <StyledStatusDate>
            <Typography variant="p">{formatDate(date)}</Typography>
          </StyledStatusDate>
        )}
        <Typography variant="h3">
          <FormattedMessage id={`claim.history.status.${status}`} />
        </Typography>
      </StyledClaimDetailsStatusHistoryItemDetails>
    </StyledClaimDetailsStatusHistoryItem>
  );
};

ClaimStatusCard.propTypes = {
  date: PropTypes.string,
  status: PropTypes.string,
  last: PropTypes.bool,
};

export default injectIntl(ClaimStatusCard);
