import { css, styled } from '@mui/material';

import { MAX_WIDTH_SM } from '../../../theme/breakpoints';

export const StyledNavUser = styled('div')`
  position: relative;
  display: none;

  @media (min-width: ${MAX_WIDTH_SM}) {
    display: initial;
  }
`;

export const StyledNavUserPopup = styled('div')(
  ({ theme }) => css`
    position: absolute;
    right: 0;
    top: 2.5rem;
    display: flex;
    flex-direction: column;
    min-width: 20.25rem;
    border-radius: 5px;
    background-color: ${theme.palette.common.white};
    box-shadow: ${theme.shadows[1]};

    ul {
      margin: 0;
      padding-left: 0;
      max-height: 360px;
      overflow-y: auto;

      > li {
        list-style: none;
      }
    }
  `
);

export const StyledNavUserMenuItem = styled('li')(
  ({ theme, inActive }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    min-height: 4rem;
    background-color: ${theme.palette.common.white};
    cursor: pointer;
    transition: background 0.3s ease-in-out;

    &:hover {
      background-color: ${theme.palette.background.viewport};
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    ${inActive &&
    css`
      opacity: 0.4;
    `}

    >img {
      width: 2rem;
      height: auto;
    }
  `
);

export const StyledNavUserMenuItemDetails = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;

  > img {
    margin-left: 0.5rem;
    width: 2rem;
    height: auto;
  }
`;
