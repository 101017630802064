import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import { StyledNavMenuMobileUserInfo } from './styled/NavMenuMobile.styled';

function NavMenuMobileUserInfo({ name, policyName }) {
  return (
    <StyledNavMenuMobileUserInfo>
      <Typography data-hj-suppress="true" variant="h3">
        {name}
      </Typography>
      <Typography variant="p">{policyName}</Typography>
    </StyledNavMenuMobileUserInfo>
  );
}

export default NavMenuMobileUserInfo;

NavMenuMobileUserInfo.defaultProps = {
  name: '',
  policyName: '',
};

NavMenuMobileUserInfo.propTypes = {
  name: PropTypes.string,
  policyName: PropTypes.string,
};
