// eslint-disable-next-line import/prefer-default-export
export const muiDialogActionsComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.common.white,
      bottom: 0,
      boxShadow: theme.shadows[1],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: theme.spacing('LARGE'),
      padding: theme.spacing('LARGE'),
      position: 'sticky',
      rowGap: theme.spacing('LARGE'),
      zIndex: 2,

      [`${theme.breakpoints.up('desktop')}`]: {
        columnGap: theme.spacing('LARGE'),
        flexDirection: 'row',
      },
    }),
  },
};
