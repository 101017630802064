import {
  DA_DIREKT_REFERRAL_PAGE_LINK_DENTAL,
  DA_DIREKT_REFERRAL_PAGE_LINK_PET,
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_HEALTH,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
  SUBJECTS_ENUMS,
} from '../../shared';

export const SUBJECTS_DEFAULT = [
  SUBJECTS_ENUMS.CONTRACT_QUESTIONS,
  SUBJECTS_ENUMS.MONTHLY_PAYMENT,
  SUBJECTS_ENUMS.REIMBURSEMENT_QUESTIONS,
  SUBJECTS_ENUMS.COVERAGE_BY_PLANS,
  SUBJECTS_ENUMS.CONTACT_CONTRACT_CHANGES,
  SUBJECTS_ENUMS.OTHER,
];

export const SUBJECTS_DENTAL = [
  SUBJECTS_ENUMS.CONTRACT_QUESTIONS,
  SUBJECTS_ENUMS.MONTHLY_PAYMENT,
  SUBJECTS_ENUMS.REIMBURSEMENT_QUESTIONS,
  SUBJECTS_ENUMS.DENTOLO_DENTISTS,
  SUBJECTS_ENUMS.COVERAGE_BY_PLANS,
  SUBJECTS_ENUMS.CONTACT_CONTRACT_CHANGES,
  SUBJECTS_ENUMS.SUBJECT_OPTION_REFERRAL,
  SUBJECTS_ENUMS.OTHER,
];

export const SUBJECTS_PET_HEALTH = [
  SUBJECTS_ENUMS.CONTRACT_QUESTIONS,
  SUBJECTS_ENUMS.MONTHLY_PAYMENT,
  SUBJECTS_ENUMS.REIMBURSEMENT_QUESTIONS,
  SUBJECTS_ENUMS.COVERAGE_BY_PLANS,
  SUBJECTS_ENUMS.CONTACT_CONTRACT_CHANGES,
  SUBJECTS_ENUMS.SUBJECT_OPTION_REFERRAL,
  SUBJECTS_ENUMS.OTHER,
];

export const SUBJECTS_PET_LIABILITY = [...SUBJECTS_DEFAULT];

export const SUBJECTS_HEALTH = [...SUBJECTS_DEFAULT];

export const formSubjectsMap = {
  [INSURANCE_CATEGORY_DENTAL]: SUBJECTS_DENTAL,
  [INSURANCE_CATEGORY_PET_HEALTH]: SUBJECTS_PET_HEALTH,
  [INSURANCE_CATEGORY_PET_LIABILITY]: SUBJECTS_PET_LIABILITY,
  [INSURANCE_CATEGORY_HEALTH]: SUBJECTS_HEALTH,
};

export const DEFAULT_PHONE_ICON = 'icon.telephone.blue.filled';
export const phoneIconMap = {
  [INSURANCE_TYPE_DENTOLO]: 'icon.telephone.blue.filled',
  [INSURANCE_TYPE_PETOLO]: 'icon.telephone.orange.filled',
  [INSURANCE_TYPE_VITOLO]: 'icon.telephone.filled.violet',
};

export const getForwardToDaDirektValue = (subject) => {
  if (subject === SUBJECTS_ENUMS.SUBJECT_OPTION_REFERRAL) return 'referral';

  return null;
};

export const daDirektReferralPageLinkMap = {
  [INSURANCE_CATEGORY_DENTAL]: DA_DIREKT_REFERRAL_PAGE_LINK_DENTAL,
  [INSURANCE_CATEGORY_PET_HEALTH]: DA_DIREKT_REFERRAL_PAGE_LINK_PET,
};

export const SUBJECTS_LINKS_DEFAULT = {
  [SUBJECTS_ENUMS.MONTHLY_PAYMENT]: {
    textId: 'contact.tip.fee.text',
    linkTextId: 'contact.tip.fee.link',
    link: '/payment-details',
  },
  [SUBJECTS_ENUMS.REIMBURSEMENT_QUESTIONS]: {
    textId: 'contact.tip.reimbursement.text',
    linkTextId: 'contact.tip.reimbursement.link',
    link: '/user-claims-info',
  },
  [SUBJECTS_ENUMS.COVERAGE_BY_PLANS]: {
    textId: 'contact.tip.tarif.text',
    linkTextId: 'contact.tip.tarif.link',
    link: '/contract-docs',
  },
  [SUBJECTS_ENUMS.CONTACT_CONTRACT_CHANGES]: {
    textId: 'contact.tip.change.contacts.text',
    linkTextId: 'contact.tip.change.contacts.link',
    link: '/user-info',
  },
};

export const SUBJECTS_LINKS_DENTOLO = {
  ...SUBJECTS_LINKS_DEFAULT,
  [SUBJECTS_ENUMS.CONTRACT_QUESTIONS]: {
    textId: 'contact.tip.contract.text',
    linkTextId: 'contact.tip.contract.link',
    link: 'https://support.dentolo.de/hc/de',
  },
  [SUBJECTS_ENUMS.DENTOLO_DENTISTS]: {
    textId: 'contact.tip.dentists.text',
    linkTextId: 'contact.tip.dentists.link',
    link: '/dentists-map',
  },
  [SUBJECTS_ENUMS.SUBJECT_OPTION_REFERRAL]: {
    textId: 'contact.tip.referral.text',
    linkTextId: 'contact.tip.referral.link',
    link: '/referral',
  },
  [SUBJECTS_ENUMS.OTHER]: {
    textId: 'contact.tip.other.text',
    linkTextId: 'contact.tip.other.link',
    link: 'https://support.dentolo.de/hc/de',
  },
};
export const SUBJECTS_LINKS_DA_DIRECT_DENTOLO = {
  ...SUBJECTS_LINKS_DEFAULT,
  [SUBJECTS_ENUMS.CONTRACT_QUESTIONS]: {
    textId: 'contact.tip.contract.text',
    linkTextId: 'contact.tip.contract.link',
    link: 'https://support.dentolo.de/hc/de',
  },
  [SUBJECTS_ENUMS.DENTOLO_DENTISTS]: {
    textId: 'contact.tip.dentists.text',
    linkTextId: 'contact.tip.dentists.link',
    link: '/dentists-map',
  },
  [SUBJECTS_ENUMS.SUBJECT_OPTION_REFERRAL]: {
    textId: 'contact.tip.referral.text',
    linkTextId: 'contact.tip.referral.link',
    link: DA_DIREKT_REFERRAL_PAGE_LINK_DENTAL,
  },
  [SUBJECTS_ENUMS.OTHER]: {
    textId: 'contact.tip.other.text',
    linkTextId: 'contact.tip.other.link',
    link: 'https://support.dentolo.de/hc/de',
  },
};

export const SUBJECTS_LINKS_PETOLO = {
  ...SUBJECTS_LINKS_DEFAULT,
  [SUBJECTS_ENUMS.CONTRACT_QUESTIONS]: {
    textId: 'contact.tip.contract.text',
    linkTextId: 'contact.tip.contract.link',
    link: 'https://support.petolo.de/hc/de',
  },
  [SUBJECTS_ENUMS.SUBJECT_OPTION_REFERRAL]: {
    textId: 'contact.tip.referral.text',
    linkTextId: 'contact.tip.referral.link',
    link: '/referral',
  },
  [SUBJECTS_ENUMS.OTHER]: {
    textId: 'contact.tip.other.text',
    linkTextId: 'contact.tip.other.link',
    link: 'https://support.petolo.de/hc/de',
  },
};
export const SUBJECTS_LINKS_DA_DIRECT_PETOLO = {
  ...SUBJECTS_LINKS_DEFAULT,
  [SUBJECTS_ENUMS.CONTRACT_QUESTIONS]: {
    textId: 'contact.tip.contract.text',
    linkTextId: 'contact.tip.contract.link',
    link: 'https://support.petolo.de/hc/de',
  },
  [SUBJECTS_ENUMS.SUBJECT_OPTION_REFERRAL]: {
    textId: 'contact.tip.referral.text',
    linkTextId: 'contact.tip.referral.link',
    link: DA_DIREKT_REFERRAL_PAGE_LINK_PET,
  },
  [SUBJECTS_ENUMS.OTHER]: {
    textId: 'contact.tip.other.text',
    linkTextId: 'contact.tip.other.link',
    link: 'https://support.petolo.de/hc/de',
  },
};

export const SUBJECTS_LINKS_VITOLO = {
  ...SUBJECTS_LINKS_DEFAULT,
  [SUBJECTS_ENUMS.CONTRACT_QUESTIONS]: {
    textId: 'contact.tip.contract.text',
    linkTextId: 'contact.tip.contract.link',
    link: 'https://support.vitolo.de/hc/de',
  },
  [SUBJECTS_ENUMS.OTHER]: {
    textId: 'contact.tip.other.text',
    linkTextId: 'contact.tip.other.link',
    link: 'https://support.vitolo.de/hc/de',
  },
};
export const SUBJECTS_LINKS_DA_DIRECT_VITOLO = {
  ...SUBJECTS_LINKS_DEFAULT,
  [SUBJECTS_ENUMS.CONTRACT_QUESTIONS]: {
    textId: 'contact.tip.contract.text',
    linkTextId: 'contact.tip.contract.link',
    link: 'https://support.vitolo.de/hc/de',
  },
  [SUBJECTS_ENUMS.OTHER]: {
    textId: 'contact.tip.other.text',
    linkTextId: 'contact.tip.other.link',
    link: 'https://support.vitolo.de/hc/de',
  },
};
export const SUBJECTS_LINKS = {
  [INSURANCE_CATEGORY_DENTAL]: SUBJECTS_LINKS_DENTOLO,
  [INSURANCE_CATEGORY_PET_HEALTH]: SUBJECTS_LINKS_PETOLO,
  [INSURANCE_CATEGORY_PET_LIABILITY]: SUBJECTS_LINKS_PETOLO,
  [INSURANCE_CATEGORY_HEALTH]: SUBJECTS_LINKS_VITOLO,
};
export const DA_DIREKT_SUBJECTS_LINKS = {
  [INSURANCE_CATEGORY_DENTAL]: SUBJECTS_LINKS_DA_DIRECT_DENTOLO,
  [INSURANCE_CATEGORY_PET_HEALTH]: SUBJECTS_LINKS_DA_DIRECT_PETOLO,
  [INSURANCE_CATEGORY_PET_LIABILITY]: SUBJECTS_LINKS_DA_DIRECT_PETOLO,
  [INSURANCE_CATEGORY_HEALTH]: SUBJECTS_LINKS_DA_DIRECT_VITOLO,
};
