import { styled, css } from '@mui/material';

export const StyledChooseCancellationTitle = styled('div')(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing('1XL')};
  `
);

export const StyledVerticalContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledChooseCancellationRadioInputAndLabel = styled('div')`
  display: flex;
`;

export const StyledChooseCancellationRadioLabel = styled('label')(
  ({ theme, disabled }) => css`
    cursor: pointer;
    margin-left: ${theme.spacing('SMALL')};

    & > h3,
    & > p {
      color: ${disabled ? theme.palette.main.product_blue : theme.palette.main.professional_blue};
    }
  `
);
