import { css, styled } from '@mui/material';

export const StyledDentistsMap = styled('div')`
  position: relative;
  width: 100vw;
  height: 100%;
`;

export const StyledDentistsMapOverlay = styled('div')(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: ${theme.palette.background.viewport};
    overflow: hidden;
    z-index: 30;
  `
);

export const StyledDentistsMapWrapper = styled('div')(
  ({ theme }) => css`
    position: relative;
    background: transparent;
    overflow: hidden;
    appearance: none;
    height: calc(100vh - ${theme.shape.headerMobile});

    ${theme.breakpoints.up('desktop')} {
      height: calc(100vh - ${theme.shape.headerDesktop} - ${theme.shape.navMenuDesktop});
    }
  `
);

export const StyledDentistsMapUI = styled('div')`
  width: 100%;
  height: 100%;
`;

export const StyledDentistsMapTitleContainer = styled('div')(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing('LARGE')};
    left: 50%;
    padding: 0 ${theme.spacing('LARGE')};
    width: 100%;
    max-width: 30rem;
    transform: translateX(-50%);
    z-index: 10;

    ${theme.breakpoints.up('desktop')} {
      left: 0;
      transform: translateX(0);
    }
  `
);
