import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';

import useOptimizely from './hooks/useOptimizely';
import PreloadAssets from './components/ui/PreloadAssets';
import {
  GET_CUSTOMER,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
} from './shared';

import Routes from './Routes';
import { dentoloTheme, petoloTheme, vitoloTheme, defaultTheme } from './theme';
import globalStyles from './theme/globalStyles';
import useInsuranceInfo from './hooks/useInsuranceInfo';

import 'slick-carousel/slick/slick.css';
import useApplicationTheme from './hooks/useApplicationTheme';

const getTheme = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return dentoloTheme;
    case INSURANCE_TYPE_PETOLO:
      return petoloTheme;
    case INSURANCE_TYPE_VITOLO:
      return vitoloTheme;
    default:
      return defaultTheme;
  }
};
function App() {
  const { type } = useInsuranceInfo();
  const theme = getTheme(type);

  const { data: customerData } = useQuery(GET_CUSTOMER);
  const { customer } = customerData || {};
  const { experimentTargetId } = customer || {};

  useOptimizely(experimentTargetId);
  useApplicationTheme();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />

      <Router>
        <Routes />
      </Router>

      <PreloadAssets />
    </ThemeProvider>
  );
}

export default App;
