import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Typography } from '@mui/material';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { getDateProtectionLink, getImprintLink, getSupportLink } from './footerUtils';

import BetaBranchName from './BetaBranchName';
import {
  StyledFooterContainer,
  StyledFooterCopyright,
  StyledFooterLinkSeparator,
  StyledFooterLinksWrapper,
  StyledFooterWrapper,
} from './Footer.styled';

const Footer = () => {
  const { type: insuranceType, isPatolo } = useInsuranceInfo();
  const imprintLink = getImprintLink(insuranceType, isPatolo);
  const dateProtectionLink = getDateProtectionLink(insuranceType, isPatolo);
  const supportLink = getSupportLink(insuranceType);

  const handleCookieSettingsClick = (ev) => {
    if (ev) ev.preventDefault();

    if (!window.UC_UI) return;

    window.UC_UI.showSecondLayer();
  };

  return (
    <StyledFooterContainer>
      <StyledFooterWrapper>
        <StyledFooterLinksWrapper>
          <Link variant="p" href={imprintLink} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="common.title.imprint" />
          </Link>

          <StyledFooterLinkSeparator />

          <Link variant="p" href={dateProtectionLink} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="common.title.data.protection" />
          </Link>

          <StyledFooterLinkSeparator />

          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link variant="p" component="button" onClick={handleCookieSettingsClick}>
            <FormattedMessage id="common.title.cookie.settings" />
          </Link>

          {!isPatolo && (
            <>
              <StyledFooterLinkSeparator />

              <Link variant="p" href={supportLink} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="common.title.help" />
              </Link>
            </>
          )}
        </StyledFooterLinksWrapper>

        <StyledFooterCopyright>
          <Typography variant="h3" component="span">
            <FormattedMessage id={`footer.copyright.${insuranceType}`} />
          </Typography>
        </StyledFooterCopyright>
      </StyledFooterWrapper>

      <BetaBranchName />
    </StyledFooterContainer>
  );
};

export default Footer;
