import 'dayjs/locale/fr';
import {
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
  SUBJECTS_ENUMS,
} from '../shared';
import { CLAIM_STATUS_DETAILS } from '../shared/claimStatus';

const fr = {
  // Common
  'common.button.back.home': "Retour à l'accueil",
  'common.button.back': 'Retour',
  'common.button.next': 'Suivant',
  'common.button.yes': 'Oui',
  'common.button.no': 'Non',
  'common.button.update': 'Modifier',
  'common.button.upload.invoice': 'Importer des factures',
  'common.button.close': 'Fermer',
  'common.button.upload.document': 'Importer des documents',
  'common.title.history': 'Historique',
  'common.title.status': 'Statut',
  'common.first.name': 'Prénom',
  'common.last.name': 'Nom de famille',
  'common.title.imprint': 'Mentions légales',
  'common.title.data.protection': 'Protection des données',
  'common.title.help': 'Aide',
  'common.title.cookie.settings': 'Paramètres des cookies',

  'common.form.error.invalid_amount': 'Veuillez entrer un montant valide.',
  'common.form.error.invalid_time': 'Veuillez entrer une heure valide.',
  'common.form.error.invalid_email': 'Veuillez entrer une adresse e-mail valide.',

  // Menu
  'menu.header.item.my_profile': 'Mon profil',
  'menu.header.item.reimbursement': 'Remboursements',
  'menu.header.item.claim_report': 'Déclarations de sinistre',
  'menu.header.item.find_dentist': 'Trouver des dentistes',
  'menu.header.item.find_vet': 'Vétérinaires en ligne',
  'menu.header.item.referrals': 'Parrainage',
  'menu.header.item.visit_dentolo': 'Visitez dentolo.fr',
  'menu.header.item.help': 'Aide',

  'menu.header.item.dashboard': 'Tableau de bord',
  'menu.header.item.tarif': 'Détails de la formule',
  'menu.header.item.contact_details': 'Gestion du contrat',
  'menu.header.item.docs': 'Documents contractuels',
  'menu.header.item.premiums': "Cotisations d'assurance",
  'menu.header.item.contribution_statement': 'Attestation de paiement',
  'menu.header.item.help_center': "Centre d'aide",
  'menu.header.item.contacts': 'Contact',

  // Contact
  'contact.page.main.title': 'Contact',
  'contact.page.main.description':
    "Si vous avez des questions ou des problèmes, vous pouvez contacter notre équipe d'assistance à tout moment.",
  'contact.page.main.phone':
    "En cas d'urgence ou pour des problèmes complexes, vous pouvez nous joindre par téléphone.",
  'contact.page.main.working.open_hours': 'Lundi - Vendredi : 09:00 - 18:00',
  'contact.page.main.form.compose': 'Rédiger un message',
  'contact.page.main.form.sender': 'Expéditeur :',
  'contact.page.main.form.reference': 'Objet : ',
  'contact.page.main.form.message': 'Votre message',
  'contact.page.main.form.send': 'Envoyer',
  'contact.page.main.sent': 'Votre message a été envoyé avec succès.',
  'contact.page.main.register.email':
    'Nous vous contacterons par email dans les plus brefs délais.',
  'contact.page.main.back': "Retour à l'accueil",
  'contact.page.main.fail': "Erreur ! <br />Un problème est survenu lors de l'envoi du message.",
  'contact.page.main.cancel': 'Annuler',
  'contact.page.main.try.again': 'Réessayer',
  'contact.page.main.cancellation.text':
    'Si vous souhaitez résilier ou annuler votre contrat, vous pouvez le faire vous-même <a>ici.</a>',
  'contact.page.form.success.text.referral.dadirekt':
    'Votre demande a été transmise au service client de DA Direkt.',
  'contact.page.form.banner.subject.referral.dadirekt.info':
    'Veuillez noter que toutes les demandes relatives à votre participation au programme de parrainage DA Direkt sont traitées par le service client de DA Direkt. {br} <a>Ici</a> vous pouvez en savoir plus sur le fonctionnement du programme de parrainage DA Direkt.',
  'contact.tip.title': 'ASTUCE :',
  'contact.tip.contract.text': "Pour plus d'informations, consultez notre",
  'contact.tip.contract.link': "centre d'aide.",
  'contact.tip.fee.text': 'Pour un aperçu de vos paiements, consultez',
  'contact.tip.fee.link': 'ici',
  'contact.tip.reimbursement.text':
    "Pour demander un nouveau remboursement ou consulter les détails d'un remboursement existant, consultez ici un",
  'contact.tip.reimbursement.link': 'aperçu.',
  'contact.tip.dentists.text': 'Trouvez les dentistes dentolo les plus proches dans notre',
  'contact.tip.dentists.link': 'réseau de dentistes',
  'contact.tip.tarif.text': 'Tous les détails de votre contrat se trouvent dans',
  'contact.tip.tarif.link': "vos documents d'assurance.",
  'contact.tip.change.contacts.text': 'Pour mettre à jour vos données personnelles, cliquez',
  'contact.tip.change.contacts.link': 'ici.',
  'contact.tip.referral.text':
    'Tout sur notre programme de parrainage et le statut de vos recommandations actuelles se trouve',
  'contact.tip.referral.link': 'ici.',
  'contact.tip.other.text': "Pour plus d'informations, consultez notre",
  'contact.tip.other.link': "centre d'aide.",
  [`contact.page.menu.${SUBJECTS_ENUMS.CONTRACT_QUESTIONS}`]: 'Questions sur le contrat',
  [`contact.page.menu.${SUBJECTS_ENUMS.MONTHLY_PAYMENT}`]: 'Cotisation mensuelle',
  [`contact.page.menu.${SUBJECTS_ENUMS.REIMBURSEMENT_QUESTIONS}`]:
    'Questions sur le remboursement ou le versement',
  [`contact.page.menu.${SUBJECTS_ENUMS.COVERAGE_BY_PLANS}`]: 'Couverture par les tarifs',
  [`contact.page.menu.${SUBJECTS_ENUMS.CONTACT_CONTRACT_CHANGES}`]:
    'Modification des données de contact ou du contrat',
  [`contact.page.menu.${SUBJECTS_ENUMS.DENTOLO_DENTISTS}`]: 'Dentistes dentolo',
  [`contact.page.menu.${SUBJECTS_ENUMS.SUBJECT_OPTION_REFERRAL}`]:
    'Questions sur le programme de parrainage',
  [`contact.page.menu.${SUBJECTS_ENUMS.OTHER}`]: 'Autres',

  // Dashboard
  'dashbaord.title': 'Tableau de bord',
  'policy.info.your.policy': "Votre {policy} en un coup d'œil",
  'policy.info.policy.details': 'Détails du tarif',
  'policy.info.policy.status.title': 'Statut du contrat',
  'policy.info.policy.status.accepted': 'Actif',
  'policy.info.policy.status.active': 'Actif',
  'policy.info.policy.status.pending': 'En attente',
  'policy.info.policy.status.ended': 'Clôturé',
  'policy.info.policy.active.now-or-past': 'Couverture active depuis',
  'policy.info.policy.active.future': 'Couverture active à partir du',
  'policy.info.policy.active.canceled': "Couverture active jusqu'au",
  'policy.info.policy.montly.price': 'Prime mensuelle',
  'policy.info.my.claims': 'Mes demandes de remboursement',
  'policy.info.new.claim.description':
    'Souhaitez-vous soumettre une nouvelle demande de remboursement ou ajouter des documents à une demande existante ?',
  'policy.info.document.notification.submitted': 'Documents reçus',
  'policy.info.document.notification.description':
    'Vos documents sont actuellement en cours de traitement. Nous vous avertirons par e-mail dès que vous pourrez accéder à plus de détails.',
  'policy.info.see.contracts': 'Voir les documents contractuels',
  'policy.info.your_contact_details': 'Vos coordonnées',
  'policy.info.your_contract_documents': 'Vos documents contractuels',
  'policy.info.your_claims': 'Vos demandes de remboursement',
  'policy.info.your_payments': "Vos cotisations d'assurance",
  'policy.cancellation.text': 'Cliquez <a>ici</a> pour résilier votre contrat.',
  'policy.info.missing.iban.title': 'Pour des remboursements plus rapides',
  'policy.info.missing.iban.message':
    'Indiquez votre IBAN <a>ici</a> pour un traitement plus rapide',
  'bank.details.add.missed.iban.modal.title':
    'Veuillez fournir un IBAN valide pour vos remboursements',
  'bank.details.add.missed.iban.modal.desc':
    "Veuillez noter que tous les remboursements seront désormais versés sur le nouveau compte ajouté. Veuillez vérifier que vos informations bancaires sont correctes afin d'assurer un paiement sans encombre.",

  // Policy Card
  'policy.card.label.name': 'Nom',
  'policy.card.label.policy': 'Formule',
  'policy.card.label.pet.name': 'Votre animal',
  'policy.card.label.addon': 'Aide immédiate',
  'policy.card.label.contract_number': 'Numéro de contrat',
  'policy.card.label.monthly_premium': 'Prime mensuelle',
  'policy.card.label.account_number': 'Numéro client·e',
  'policy.card.contact.and.billing.info': 'Coordonnées et informations de facturation',
  'policy.card.label.addon.trial_month': "Mois d'essai",
  'policy.card.addon.trial_month.from': 'À partir du',

  // Policy Coverage Info
  'policy.covergae_info.title': 'Mes garanties',
  'policy.covergae_info.additional_advantages.title': 'Autres avantages',
  'policy.covergae_info.all_details.button': 'Détail des garanties',

  // Policy All Details
  'policy.all_details.title': 'Votre protection en détail',
  'policy.all_details.conditions.title': 'Conditions générales',
  'policy.all_details.premium.title': 'Échelonnement des primes',
  'policy.all_details.budget.prefix': 'Dans la',
  'policy.all_details.budget.last_prefix': 'À partir de',
  'policy.all_details.budget.only_prefix': 'À partir de',
  'policy.all_details.budget.suffix': 'année',
  'policy.all_details.budget.unlimited': 'Illimité',
  'policy.all_details.premium.years': 'années',

  // Policy Personal Details
  'policy.details.policy_management.title': 'Gestion du contrat',
  'policy.details.policy_management.description':
    'Cliquez sur le crayon pour apporter une modification.',
  'policy.details.my.contact.info': 'Mes coordonnées',
  'policy.details.contact.info.name': 'Nom',
  'policy.details.contact.info.email': 'E-mail',
  'policy.details.contact.info.phone': 'Téléphone',
  'policy.details.contact.info.address': 'Adresse',
  'policy.details.contact.info.dob': 'Date de naissance',
  'policy.details.contact.info.address_details': "Complément d'adresse",
  'policy.details.my.billing.info': 'Mes coordonnées bancaires',
  'policy.details.account.billing.desc':
    'Les primes mensuelles sont débitées et les remboursements sont versés sur ce compte.',
  'policy.details.billing.info.account_holder': 'Titulaire du compte',
  'policy.details.billing.info.iban': 'IBAN',
  'policy.details.billing.info.billing_day': 'Date du prélèvement',
  'policy.details.contact_us.text.person':
    "Vous souhaitez changer la personne assurée ou souscrire un autre contrat ? Vous avez des questions ou souhaitez modifier d'autres informations ?",
  'policy.details.contact_us.text.pet':
    "Souhaitez-vous modifier l'animal assuré ou souscrire un autre contrat ? Avez-vous encore des questions ou souhaitez-vous modifier d'autres informations ?",
  'policy.details.contact.us': 'Il vous suffit de nous contacter.',
  'policy.details.to.contact': 'Service clientèle',
  'policy.details.billing_day_1': 'Le 1ᵉ du mois',
  'policy.details.billing_day_15': 'Le 15 du mois',
  'policy.details.insured.person.info.title': 'Personne assurée',
  'policy.details.insured.person.info.name': 'Nom',
  'policy.details.insured.person.info.address': 'Adresse',
  'policy.details.pet.info.title': 'Animal assuré',
  'policy.details.pet.info.name': 'Nom',
  'policy.details.pet.info.transponder_code': 'Code du transpondeur',
  'policy.details.pet.info.transponder_code.missing': 'Aucun code de transpondeur disponible',

  // Digital payment
  'policy.details.my.refund_account.info': 'Votre compte pour les remboursements',
  'policy.details.my.refund_account.desc': 'Les remboursements seront versés sur ce compte.',
  'bank.details.edit_refund_account.modal.title': 'Changement de compte pour les remboursements',
  'bank.details.edit_refund_account.modal.desc':
    "Veuillez noter que tous les remboursements seront désormais versés sur le nouveau compte ajouté. Veuillez vérifier que vos informations bancaires sont correctes afin d'assurer un paiement sans encombre.",
  'policy.details.my.digital.payment.info': 'Primes mensuelles',
  'policy.details.account.digital.payment.desc':
    'Vos primes mensuelles seront débitées via le mode de paiement suivant.',
  'policy.details.account.digital.payment.expire': 'Expiration : ',
  'policy.details.account.digital.payment.paypal': 'PayPal',
  'policy.details.account.digital.payment.googlePay': 'Google Pay',
  'policy.details.account.digital.payment.applePay': 'Apple Pay',
  'policy.details.account.digital.payment.error':
    "Votre moyen de paiement n'a pas pu être modifié comme souhaité. Veuillez réessayer.",
  'policy.details.account.digital.payment.card.numper.placeholder': '**** **** **** XXXX',
  'policy.details.account.digital.payment.card.numper.expire.placeholder': 'MM/AAAA',
  'policy.details.my.digital.payment.loading.status': 'Mise à jour',

  // Personal Details Edit Form
  'personal.details.form.title': 'Modifier mes coordonnées',
  'personal.details.form.gender.label': 'Civilité',
  'personal.details.form.gender.male.label': 'Monsieur',
  'personal.details.form.gender.female.label': 'Madame',
  'personal.details.form.email.label': 'E-mail',
  'personal.details.form.email.error.message': 'Veuillez renseigner une adresse e-mail complète.',
  'personal.details.form.phone.label': 'Téléphone',
  'personal.details.form.phone.error.message':
    'Veuillez renseigner un numéro de téléphone complet.',
  'personal.details.form.street.house.number.label': 'Adresse',
  'personal.details.form.postcode.city.label': 'Code postal, ville',
  'personal.details.form.address.error.message': 'Veuillez renseigner une adresse complète.',
  'personal.details.form.address.details.label': "Complément d'adresse",
  [`personal.details.form.additional.details.${INSURANCE_TYPE_DENTOLO}`]:
    'Pour toute modification du nom, veuillez nous écrire à service@dentolo.de ou utiliser le formulaire de contact.',
  [`personal.details.form.additional.details.${INSURANCE_TYPE_PETOLO}`]:
    'Pour toute modification du nom, veuillez nous écrire à service@patolo.fr ou utiliser le formulaire de contact.',
  [`personal.details.form.additional.details.${INSURANCE_TYPE_VITOLO}`]:
    'Pour toute modification du nom, veuillez nous écrire à service@vitolo.de ou utiliser le formulaire de contact.',
  'personal.details.form.submit.button': 'Modifier les informations',

  // Insured Person Details Edit Form
  'insured.person.details.form.title': 'Modifier la personne assurée',
  'insured.person.details.form.gender.label': 'Civilité',
  'insured.person.details.form.gender.male.label': 'Monsieur',
  'insured.person.details.form.gender.female.label': 'Madame',
  'insured.person.details.form.street.house.number.label': 'Adresse',
  'insured.person.details.form.postcode.city.label': 'Code postal, ville',
  'insured.person.details.form.address.error.message': 'Veuillez renseigner une adresse complète.',
  [`insured.person.details.form.additional.details.${INSURANCE_TYPE_DENTOLO}`]:
    'Pour toute modification du nom, veuillez nous écrire à service@dentolo.de ou utiliser le formulaire de contact.',
  [`insured.person.details.form.additional.details.${INSURANCE_TYPE_PETOLO}`]:
    'Pour toute modification du nom, veuillez nous écrire à service@patolo.fr ou utiliser le formulaire de contact.',
  [`insured.person.details.form.additional.details.${INSURANCE_TYPE_VITOLO}`]:
    'Pour toute modification du nom, veuillez nous écrire à service@vitolo.de ou utiliser le formulaire de contact.',
  'insured.person.details.form.submit.button': 'Modifier les informations',

  // Pet Details Edit Form
  'pet.details.form.title': 'Animal assuré',
  'pet.details.form.name.label': 'Nom',
  'pet.details.form.transponder_code.label': 'Code de transpondeur',
  'pet.details.form.transponder_code.placeholder': "Il s'agit d'un nombre à 15 chiffres",
  'pet.details.form.transponder_code.validation.message':
    'Le code de transpondeur doit contenir 15 chiffres.',
  'pet.details.form.additional.details.line.1':
    'Pour effectuer un remboursement, nous avons besoin du numéro de puce à 15 chiffres, qui est enregistré sur le transpondeur (puce électronique) de votre animal.',
  'pet.details.form.additional.details.line.2':
    'Pour toute modification du nom, veuillez nous écrire à service@patolo.fr ou utiliser le formulaire de contact.',
  'pet.details.form.submit.button': 'Modifier les informations',

  // Digital payment edit form
  'customer.details.digital.payment.edit.title': 'Ajuster les primes mensuelles',
  'customer.details.digital.payment.billing.date.title': 'Modifier la date de prélèvement',
  'customer.details.digital.payment.billing.date.start.label': 'Le 1ᵉ du mois',
  'customer.details.digital.payment.billing.date.middle.label': 'Le 15 du mois',
  'customer.details.digital.payment.details.title': 'Méthode de paiement actuelle',
  'customer.details.digital.payment.novalnet.form.title': 'Choisir un autre mode de paiement',
  'customer.details.digital.payment.success.title':
    'Les informations ont été modifiées avec succès.',
  'customer.details.digital.payment.failure.title':
    "La modification du mode de paiement n'est pas possible actuellement.",
  'customer.details.digital.payment.failure.desc':
    "Malheureusement, ce service n'est pas disponible pour le moment. Nous nous excusons pour le désagrément et vous prions de réessayer plus tard.",
  'customer.details.digital.payment.success.btn': 'Retour à vos coordonnées',

  // Bank Details Edit Form
  'bank.details.form.title': 'Modifier mes coordonnées bancaires',
  'bank.details.form.error.firstname': 'Le prénom doit être renseigné',
  'bank.details.form.error.lastname': 'Le nom doit être renseigné',
  'bank.details.form.error.iban_invalid':
    'Format IBAN invalide. Veuillez vérifier les informations.',
  'bank.details.form.error.iban_empty': "L'IBAN doit être renseigné.",
  'bank.details.form.error.iban_not_sepa':
    "Cet IBAN n'est pas valide. Veuillez fournir un IBAN d'un pays SEPA officiel pour continuer.",
  'bank.details.form.error.billingDay': 'Veuillez renseigner les informations de prélèvement.',
  'bank.details.form.note_1':
    'Cette modification générera un nouveau mandat SEPA pour vous, permettant le prélèvement direct de vos cotisations futures.',
  'bank.details.form.note_2':
    "Veuillez noter que les modifications ne prendront effet qu'à partir de la prochaine période de prélèvement et que les anciennes coordonnées bancaires seront encore utilisées pour les paiements en cours.",
  'bank.details.form.submit_button': 'Modifier les informations',
  'bank.details.update.success.title': 'Les informations ont été modifiées avec succès.',
  'bank.details.update.success.message':
    "Si vous avez d'autres questions, veuillez contacter notre service clientèle.",
  'bank.details.update.failure.title':
    'Erreur ! Un problème est survenu lors de la modification de vos informations.',
  'bank.details.update.failure.message':
    'Si vous rencontrez des problèmes, veuillez contacter notre service clientèle.',

  // Policies
  'policies.vorsorgeschutz': 'Protection préventive',
  'policies.akutschutz': 'Protection immédiate',
  'policies.vorsorgeschutz-kompakt': 'Protection préventive compacte',
  'policies.akutschutz-kompakt': 'Protection immédiate compacte',
  'policies.rundumschutz': 'Protection globale',
  'policies.zahnschutz-prophylaxe': 'Protection dentaire prophylactique',
  'policies.premium': 'Premium',
  'policies.premium_plus': 'Dentolo Premium Plus',
  'policies.zahnschutz-basis': 'Dentolo Basic',
  'policies.zahnschutz-komfort': 'Dentolo Confort',
  'policies.zahnschutz-premium': 'Dentolo Premium',
  'policies.zahnschutz-premium-plus': 'Dentolo Premium Plus',
  'policies.addons.im_coverage': 'Assistance immédiate',
  'policies.addons.trial_month': "Mois d'essai",
  'policies.hundekrankenversicherung-komfort': 'Assurance santé pour chien Confort',
  'policies.hundekrankenversicherung-premium': 'Assurance santé pour chien Premium',
  'policies.hundekrankenversicherung-premium-plus': 'Assurance santé pour chien Premium Plus',
  'policies.katzenkrankenversicherung-komfort': 'Assurance santé pour chat Confort',
  'policies.katzenkrankenversicherung-premium': 'Assurance santé pour chat Premium',
  'policies.katzenkrankenversicherung-premium-plus': 'Assurance santé pour chat Premium Plus',
  'policies.tierkrankenversicherung-komfort': 'Assurance santé pour animaux Confort',
  'policies.tierkrankenversicherung-premium': 'Assurance santé pour animaux Premium',
  'policies.tierkrankenversicherung-premium-plus': 'Assurance santé pour animaux Premium Plus',
  'policies.krankenzusatzversicherung-komfort': 'Assurance complémentaire maladie Confort',
  'policies.krankenzusatzversicherung-premium': 'Assurance complémentaire maladie Premium',
  'policies.krankenzusatzversicherung-premium-plus':
    'Assurance complémentaire maladie Premium Plus',

  // File Upload Status
  'file.upload.title': 'Envoi de fichiers',
  'file.upload.success.title.html': 'Envoi réussi !',
  'file.upload.success.text.html.plural':
    '{filesNum} fichiers ont été envoyés. \nNous vérifierons vos documents et vous informerons par e-mail des prochaines étapes.',
  'file.upload.success.text.html.singular':
    "{filesNum} fichier a été envoyé. \nNous allons l'examiner et vous contacter par e-mail pour les prochaines étapes.",
  'file.upload.success.text.html.plural.delay':
    "{filesNum} fichiers ont été envoyés. \nEn raison d'un nombre élevé de demandes, le traitement de votre dossier peut prendre plus de temps que prévu. Actuellement, le délai de traitement peut aller jusqu'à 14 jours. Nous regrettons cette attente et examinerons vos documents dans les plus brefs délais. Nous vous informerons des prochaines étapes par e-mail.",
  'file.upload.success.text.html.singular.delay':
    "{filesNum} fichier a été envoyé. \nEn raison d'un nombre élevé de demandes, le traitement de votre dossier peut prendre plus de temps que prévu. Actuellement, le délai de traitement peut aller jusqu'à 14 jours. Nous regrettons cette attente et examinerons votre document dans les plus brefs délais. Nous vous informerons des prochaines étapes par e-mail.",
  'file.upload.success.back.home.btn': "Retour à l'accueil",
  'file.upload.failure.title.html':
    'Erreur ! <br />Un problème est survenu lors du téléchargement du fichier.',
  'file.upload.failure.text.html':
    'Si vous rencontrez des problèmes, veuillez contacter notre service clientèle.',
  'file.upload.failure.cancel.btn': 'Annuler',
  'file.upload.failure.try.again.btn': 'Réessayer',
  'file.upload.pet_liability.success.title.html':
    'Merci, votre déclaration de sinistre a été reçue.',
  'file.upload.pet_liability.success.text.html':
    "Pour le traitement du sinistre, une équipe d'experts de notre partenaire d'assurance DA Direkt vous assistera. \nVous serez contacté(e) rapidement pour connaître les prochaines étapes.",

  // # User claims
  'user_claims.page.title': 'Mes remboursements',
  'user_claims.page.description':
    "Ici, vous pouvez soumettre une nouvelle demande de remboursement et consulter les détails des remboursements en cours ou passés. Si vous avez déjà soumis une demande, vous pouvez ajouter les documents manquants en cliquant sur 'Demande en cours'.",
  'user_claims.button.new_claim_document': 'Nouvelle demande',
  'user_claims.button.existing_claim_document': 'Demande en cours',
  'user_claims.pet_liability.page.title': 'Mes déclarations de sinistre',
  'user_claims.pet_liability.page.description':
    'Ici, vous pouvez consulter les détails de toutes vos déclarations de sinistre, passées ou en cours, ou soumettre une nouvelle déclaration.',
  'user_claims.pet_liability.button.new_claim_report': 'Déclarer un sinistre',

  // # Treatment categories - DENTAL
  'treatment.category.null': 'Soins dentaires',
  'treatment.category.dummy_dental_category_for_account_area': 'Soins dentaires',
  'treatment.category.implant_basic': 'Implantation basique',
  'treatment.category.implant_complex': 'Implantation complexe',
  'treatment.category.total': 'Prothèses dentaires',
  'treatment.category.combined': 'Traitement prothétique',
  'treatment.category.crown': 'Pose de couronnes',
  'treatment.category.bridge': 'Pose de bridges',
  'treatment.category.fixed': 'Traitement prothétique',
  'treatment.category.telescope': 'Traitement prothétique',
  'treatment.category.conservative': 'Traitement conservateur',
  'treatment.category.endodontic': 'Traitement de canal',
  'treatment.category.veneer': 'Pose de facettes',
  'treatment.category.misc': 'Soins dentaires',
  'treatment.category.pzr': 'Détartrage',
  'treatment.category.periodontic': 'Traitement parodontal',
  'treatment.category.interim_care': 'Soins temporaires',
  'treatment.category.broken_jaw': 'Fracture de la mâchoire',
  'treatment.category.request_for_info': "Demande d'information",
  'treatment.category.x_ray': 'Radiographie',
  'treatment.category.orthodontics': 'Orthodontie',
  'treatment.category.general_anesthetic': 'Anesthésie générale',

  // # Treatment categories - PET
  'treatment.category.pet_null': 'Consultation vétérinaire',
  'treatment.category.dummy_pet_category_for_account_area': 'Consultation vétérinaire',
  'treatment.category.pet_diagnosis': 'Diagnostic',
  'treatment.category.pet_worming': 'Vermifuge',
  'treatment.category.pet_health_check': 'Bilan de santé',
  'treatment.category.pet_vaccination': 'Vaccination',
  'treatment.category.pet_fleas_and_ticks': 'Prévention contre les puces et les tiques',
  'treatment.category.pet_gastrointestinal_disease': 'Maladie gastro-intestinale',
  'treatment.category.pet_dental_prophylaxis': 'Prévention dentaire',
  'treatment.category.pet_castration_or_sterilization': 'Castration ou stérilisation',
  'treatment.category.pet_euthanasia': 'Euthanasie',
  'treatment.category.pet_rescue': "Frais de recherche, de sauvetage et d'enterrement",
  'treatment.category.pet_desensitization': 'Désensibilisation',
  'treatment.category.pet_entering_medication': 'Administration de médicaments',
  'treatment.category.pet_implant': "Pose d'un implant médicamenteux",
  'treatment.category.pet_injection': 'Injection, instillation, perfusion',
  'treatment.category.pet_eyes_treatment': 'Traitements des yeux',
  'treatment.category.pet_respiratory_treatment': "Traitements de l'appareil respiratoire",
  'treatment.category.pet_muscles_treatment': "Traitements de l'appareil locomoteur",
  'treatment.category.pet_fracture': 'Traitement des fractures',
  'treatment.category.pet_digestive_system': "Traitements de l'appareil digestif",
  'treatment.category.pet_dental': 'Traitement dentaire',
  'treatment.category.pet_spine_and_nervous_system_treatment':
    'Traitements de la colonne vertébrale et du système nerveux',
  'treatment.category.pet_eyes_operation': 'Opération des yeux',
  'treatment.category.pet_respiratory_operation': "Opérations de l'appareil respiratoire",
  'treatment.category.pet_muscles_operation': "Opérations de l'appareil locomoteur",
  'treatment.category.pet_amputation': 'Amputation',
  'treatment.category.pet_heart_operation': 'Opération du cœur',
  'treatment.category.pet_digestive_system_operation': "Opérations sur l'appareil digestif",
  'treatment.category.pet_spine_and_nervous_system_operation':
    'Opération de la colonne vertébrale et du système nerveux',
  'treatment.category.pet_prosthesis': 'Prothèse',
  'treatment.category.pet_telemedicine': 'Téléconsulation',
  'treatment.category.pet_misdevelopment': 'Pathologie génétique ou développementale',

  // # Treatment categories - PET LIABILITY
  'treatment.category.pet_liability_damage_to_small_object': 'Dégât matériel mineur',
  'treatment.category.pet_liability_damage_to_large_object': 'Dégât matériel majeur',
  'treatment.category.pet_liability_damage_to_motor_vehicle': 'Véhicule endommagé',
  'treatment.category.pet_liability_damage_to_bicycle_or_similar':
    'Véhicule (vélo, etc.) endommagé',
  'treatment.category.pet_liability_damage_to_an_apartment': 'Dégât dans un appartement',
  'treatment.category.pet_liability_damage_to_a_building': 'Dégât dans un bâtiment',
  'treatment.category.pet_liability_damage_to_a_property': 'Dégât sur une propriété',
  'treatment.category.pet_liability_injured_animal': 'Animal blessé',
  'treatment.category.pet_liability_injured_person': 'Personne blessée',
  'treatment.category.pet_liability_other': 'Autre dommage',

  // Treatment category Vitolo
  'treatment.category.dummy_health_category_for_account_area': 'Soins de santé',
  'treatment.category.health_preventive_examination': 'Examen de prévention',
  'treatment.category.health_ophthalmology': 'Ophtalmologie',
  'treatment.category.health_alternative_medicine': 'Médecine alternative',
  'treatment.category.health_statutory_additional_payment': 'Participation légale supplémentaire',
  'treatment.category.health_medicines_and_dressings': 'Médicaments et pansements',
  'treatment.category.health_remedies_and_aids': 'Remèdes et aides',
  'treatment.category.health_additional_outpatient_services':
    'Services ambulatoires complémentaires',
  'treatment.category.health_inpatient_additional_services':
    'Services hospitaliers complémentaires',
  'treatment.category.health_rooming_in': 'Rooming-In',
  'treatment.category.health_daily_hospital_allowance': "Indemnité journalière d'hospitalisation",
  'treatment.category.health_diagnosis': 'Diagnostic',
  'treatment.category.health_sos_budget': 'Budget SOS',

  // Claim Statuses
  'claim.status.last.update': 'Dernière mise à jour :',
  'claim.status.label.new': 'En cours de traitement',
  'claim.status.label.waiting_for_information': 'En attente - Action nécessaire',
  'claim.status.label.reimbursement_acknowledged': 'Confirmation de prise en charge',
  'claim.status.label.waiting_for_payout': 'En cours de traitement',
  'claim.status.label.closed': 'Payé',
  'claim.status.label.declined': 'Refusé',
  'claim.status.label.blocked': 'Bloqué',
  'claim.status.label.cancelled': 'Annulé',
  'claim.status.label.updated': 'En cours de traitement',

  // History item claim statuses
  'claim.history.status.new': 'En cours de traitement',
  'claim.history.status.processing': 'En cours de traitement',
  'claim.history.status.waiting_for_information': 'En attente - Action nécessaire',
  'claim.history.status.processing_completed': 'Traitement terminé',
  'claim.history.status.declined': 'Refusé',
  'claim.history.status.reimbursement_acknowledged': 'Confirmation de prise en charge',
  'claim.history.status.checking_invoice': 'Facture en cours de vérification',
  'claim.history.status.waiting_for_payout': 'En cours de traitement',
  'claim.history.status.closed': 'Payé',
  'claim.history.status.blocked': 'Bloqué',
  'claim.history.status.cancelled': 'Annulé',
  'claim.history.status.updated': 'En cours de traitement',

  // Claim Status details
  'claim.status.details.updated_at':
    '{updatedAt, date, ::dd.MM.yyyy} {updatedAt, time, ::HH:mm} heures',
  // New
  'claim.status.details.claim_opened': 'Demande de remboursement ouverte',
  // Updated
  'claim.status.details.claim_updated': 'Demande de remboursement mise à jour',
  // Waiting
  'claim.status.details.treatment_or_costplan_missing': 'Devis manquant',
  'claim.status.details.information_request_missing': "Demande d'information manquante",
  'claim.status.details.patient_file_missing': "Dossier de l'animal manquant",
  'claim.status.details.invoice_missing': 'Facture manquante',
  'claim.status.details.documents_missing': 'Documents manquants',
  'claim.status.details.pet_medical_card_is_missing': 'Historique médical manquant',
  // Reimbursement acknowledged
  'claim.status.details.reimbursement_acknowledged': 'Prise en charge accordée',
  'claim.status.details.partial_reimbursement_acknowledged': 'Prise en charge partielle accordée',
  // Payout
  'claim.status.details.reimbursement_will_be_paid':
    'Demande de remboursement en cours de traitement',
  'claim.status.details.partial_reimbursement_will_be_paid':
    'Remboursement partiel en cours de paiement',
  'claim.status.details.reimbursement_paid': 'Remboursement effectué',
  'claim.status.details.partial_reimbursement_paid': 'Remboursement partiel effectué',
  'claim.status.details.help.center.text1':
    "Vous trouverez plus d'informations sur le montant du remboursement de la facture.",
  'claim.status.details.help.center.link': 'ici.',
  // Blocked
  'claim.status.details.contract_signature_missing': 'Confirmation du contrat manquant',
  'claim.status.details.withdrawal_period_still_running': 'Délai de rétractation toujours en cours',
  'claim.status.details.outstanding_payments': 'Primes mensuelles en attente',
  'claim.status.details.trial_month': "Assurance/formule pas encore active (mois d'essai)",
  // Refused - TODO: update details
  'claim.status.details.treatment_before_contract_start':
    "La consultation a eu lieu avant le début de l'assurance.",
  'claim.status.details.known_of_treatment_before_contract_start_by_plan':
    "Selon le devis, la nécessité du traitement était connue avant le début de l'assurance.",
  'claim.status.details.known_of_treatment_before_contract_start_by_dentist_request':
    "Besoins de traitement connus d'après la demande du dentiste, avant le début de l'assurance",
  'claim.status.details.known_of_treatment_before_contract_start_by_pretreatment_provide':
    "Besoins de traitement connus d'après la demande de prétraitement, avant le début de l'assurance",
  'claim.status.details.treatment_before_contract_start_by_invoice':
    "Selon la facture, le traitement a eu lieu avant le début de l'assurance.",
  'claim.status.details.no_budget': 'Plafond de prise en charge atteint',
  'claim.status.details.no_coverage': "Non couvert par la formule d'assurance",
  'claim.status.details.no_coverage_no_medical_need':
    "Non couvert par la formule d'assurance, faute de nécessité médicale",
  'claim.status.details.no_coverage_orthodontics':
    'Traitement orthodontique non couvert par le contrat',
  'claim.status.details.coverage_for_children_only_for_accident':
    "Couverture pour enfants uniquement en cas d'accident",
  'claim.status.details.max_amount_of_tooth_cleanings_reached':
    'Nombre maximal de détartrages professionnels atteint',
  'claim.status.details.no_coverage_cosmetic_measures':
    'Traitement purement esthétique non couvert',
  'claim.status.details.not_the_insured_animal': "L'animal soigné n'est pas l'animal assuré.",
  'claim.status.details.pet_health_budget_exhausted': 'Budget Bien-Être épuisé',
  'claim.status.details.pet_sos_budget_exhausted': 'Budget Coups Durs épuisé',
  'claim.status.details.known_of_treatment_before_contract_start_by_veterinarian_request':
    "Selon la demande d'information du·de la vétérinaire, la nécessité du traitement était connue avant le début de l'assurance.",
  'claim.status.details.known_of_treatment_before_contract_start_by_vaterinarian_pretreatment_provid':
    "Selon la demande d'information du·de la vétérinaire précédent·e, la nécessité du traitement était connue avant le début de l'assurance.",
  'claim.status.details.firstvet_treatment_needed':
    "Selon la téléconsultation, la nécessité du traitement était connue avant le début de l'assurance.",
  'claim.status.details.treatment_person_is_not_insured': "La personne traitée n'est pas assurée",
  'claim.status.details.reimbursement_is_already_covered':
    "L'assurance maladie couvre déjà le montant remboursable selon le contrat",
  'claim.status.details.copayment_is_already_covered':
    "La part complémentaire est déjà prise en charge par l'assurance maladie",
  'claim.status.details.treatment_was_known_before_insurance':
    "Selon le dossier de l'animal, la nécessité du traitement était connue avant le début de l'assurance.",
  'claim.status.details.treatment_before_contract_sign':
    'Le traitement a eu lieu avant la souscription du contrat.',
  'claim.status.details.treatment_needs_before_contract_sign':
    'La nécessité du traitement était connue avant la souscription du contrat.',
  'claim.status.details.treatment_was_known_before_insurance_by_dentist':
    "Besoins de traitement indiqués par le dentiste, déjà connus avant l'assurance",
  'claim.status.details.known_of_treatment_before_contract_start_by_doctor_request':
    "Besoins de traitement connus d'après la demande du médecin, avant le début de l'assurance",
  'claim.status.details.coverage_of_costs_through_third_party_damage_claims':
    'Couverture des frais par des recours contre des tiers',
  // Cancelled
  'claim.status.details.canceled_by_dentolo': 'À la demande de patolo',
  'claim.status.details.canceled_by_customer': 'À la demande de la personne titulaire du contrat',
  'claim.status.details.other.payouts': 'Autres paiements',
  'claim.status.details.invoice.from': 'Facture du {createdAt, date, ::dd.MM.yyyy}',

  'claim.details.general.amount': 'Montant',
  'claim.details.general.hour': 'heure',
  'claim.details.general.load.invoice':
    'Pour le remboursement, veuillez importer les factures relatives au traitement.',

  'claim.user.claims.make.appointment.title': 'Prendre rendez-vous',
  'claim.user.claims.no.reimbursement': 'Aucun remboursement pour le moment',
  'claim.user.claims.make.appointment.description':
    "Prenez rendez-vous dès maintenant chez un dentiste dentolo pour bénéficier d'un remboursement plus élevé.",

  // Claim contact person details
  'claim.contact.person.title': 'Interlocuteur·ice',
  'claim.contact.person.text':
    'En cas de questions, veuillez toujours mentionner le numéro de référence {external_reference_number}.',

  // Claim payout helpcenter article
  'claim.payout.helpcenter_article.text':
    "Vous avez des questions sur le montant remboursé ? <a>Ici</a> vous trouverez plus d'informations.",

  // Dentists Map
  'dentist.details.open.hours.day.monday.short': 'Lun',
  'dentist.details.open.hours.day.tuesday.short': 'Mar',
  'dentist.details.open.hours.day.wednesday.short': 'Mer',
  'dentist.details.open.hours.day.thursday.short': 'Jeu',
  'dentist.details.open.hours.day.friday.short': 'Ven',
  'dentist.details.open.hours.day.saturday.short': 'Sam',
  'dentist.details.open.hours.day.sunday.short': 'Dim',
  'dentist.details.open.hours.now.open': 'Ouvert maintenant',
  'dentist.details.open.hours.closing.soon': 'Ferme bientôt',
  'dentist.details.open.hours.closed': 'Fermé',
  'dentist.details.open.hours.day.closed': 'Fermé',
  'dentist.details.dentolo.dentist': 'Dentiste dentolo',
  'dentist.details.common.save.favourites': 'Enregistrer pour plus tard',
  'dentist.details.book.appointment': 'Prendre rendez-vous',
  'dentist.details.back.to.map': 'Retour à la carte des dentistes',
  'dentist.map.loading': 'Chargement de la carte des dentistes',
  'dentist.map.float_card.details': 'Détails',
  'dentist.details.phone_number.label': 'Réserver un rendez-vous : ',
  'dentist.details.website.label': 'En ligne sur : ',
  // Map tabs
  'map.dentists.tabs.dentist.favourite': 'Mes dentistes favoris',
  'map.dentists.tabs.dentist.favourite.message': 'Nous prenons soin de votre sourire éclatant',
  'map.dentists.tabs.dentist.favourite.message.no_dentist':
    "Vous n'avez pas encore ajouté de dentiste dentolo à vos favoris.",
  'map.dentists.tabs.dentist.favourite.expand_details': 'Détails',
  'map.dentists.tabs.dentist.find': 'Trouver des dentistes dentolo',
  'map.dentists.network.about': 'À propos du réseau dentolo',
  // Map modal
  'dentist.map.modal.title': 'À propos du réseau dentolo',
  'dentist.map.modal.content_1':
    "Vous trouverez ici une carte de tous les dentistes dentolo où, en tant que client dentolo, vous bénéficiez d'un remboursement plus élevé.",
  'dentist.map.modal.content_2':
    "Il vous suffit de prendre rendez-vous chez un dentiste proche de chez vous. Pour toute question concernant les dentistes dentolo, des informations détaillées sont disponibles dans notre <button>Centre d'aide</button>.",

  // Sign out
  'sign.out': 'Déconnexion',
  'sign.out.change.user': 'Changer de personne assurée',

  // Select customer
  'select.customer.insured.title': 'Veuillez choisir un assuré :',
  'select.customer.insured.note':
    "Vous pouvez sélectionner une autre personne assurée à tout moment via le menu ou l'icône utilisateur·ice.",

  // Second Auth
  'second.auth.title': 'Authentification à deux facteurs pour votre espace personnel',
  'second.auth.description':
    'Pour renforcer la sécurité de votre espace personnel, nous avons besoin de votre date de naissance pour vous connecter.',
  [`second.auth.description.${INSURANCE_TYPE_DENTOLO}`]:
    'Pour renforcer la sécurité de votre espace personnel, nous avons besoin de votre date de naissance pour vous connecter.',
  [`second.auth.description.${INSURANCE_TYPE_PETOLO}`]:
    "Pour la sécurité de votre compte client, nous avons besoin de votre date de naissance lorsque vous vous connectez - mais pas de celle de votre animal, s'il vous plaît.",
  [`second.auth.description.${INSURANCE_TYPE_VITOLO}`]:
    'Pour renforcer la sécurité de votre espace personnel, nous avons besoin de votre date de naissance pour vous connecter.',
  'second.auth.input.label.day': 'Jour',
  'second.auth.input.label.month': 'Mois',
  'second.auth.input.label.year': 'Année',
  'second.auth.wrong.details': 'Veuillez vérifier les informations.',
  'second.auth.login.btn': 'Connexion',
  'second.auth.fields.required': 'Champs obligatoires',
  'second.auth.status.title': 'Authentification à deux facteurs',
  'second.auth.status.failure.title': 'Les trois dernières tentatives de connexion ont échoué.',
  'second.auth.status.failure.text':
    'Pour des raisons de sécurité, le processus de connexion doit être relancé.',
  'second.auth.status.failure.button.text': "Retour à l'accueil",

  // Login
  'login.common.title': 'Mon espace personnel',
  'login.common.send.email':
    'Nous vous envoyons immédiatement un e-mail contenant un lien qui vous connectera directement et en toute sécurité à votre espace personnel.',
  'login.common.send.link': 'Envoyer le lien de connexion',
  'login.common.tip.title': 'CONSEIL :',
  'login.common.tip.description':
    "Veuillez vérifier l'adresse e-mail saisie afin d'éviter toute faute de frappe.",

  // Login Status
  'login.status.success.text_1':
    'Si un contrat est associé à cette adresse e-mail, vous recevrez bientôt un lien de connexion pour accéder à votre espace personnel.',
  'login.status.success.text_2':
    "Si vous ne recevez pas d'e-mail, veuillez vérifier votre dossier spam.",
  'login.status.email.sent': 'Nous avons envoyé un e-mail à {email}.',
  'login.status.success.try_again': 'Réessayer',
  'login.info.message.logout_success': 'Déconnexion réussie !',
  'login.info.title.invalid_link': 'Lien de connexion invalide ou expiré.',
  'login.info.message.invalid_link':
    'Veuillez saisir à nouveau votre adresse e-mail pour que nous puissions vous envoyer un lien de connexion valide.',
  [`login.link_to_homepage.${INSURANCE_TYPE_DENTOLO}`]: "Retour à la page d'accueil dentolo",
  [`login.link_to_homepage.${INSURANCE_TYPE_PETOLO}`]: "Retour à la page d'accueil patolo",
  [`login.link_to_homepage.${INSURANCE_TYPE_VITOLO}`]: "Retour à la page d'accueil vitolo",
  'login.form.label': 'Adresse e-mail',
  'login.form.check': 'Se souvenir des identifiants',

  'login.status.success.tip.question': "Vous n'avez pas reçu d'e-mail ?",
  'login.status.success.tip.description': 'Veuillez vérifier votre dossier spam.',
  'login.status.success.tip.list.title': "Si vous ne recevez toujours pas d'e-mail :",
  'login.status.success.tip.list.item_1': "Essayez d'utiliser un autre navigateur",
  'login.status.success.tip.list.item_2':
    'Videz le cache du navigateur et supprimez les cookies de votre navigateur.',

  // Contract status title
  'contract.status.title.start': "Début de l'assurance",
  'contract.status.title.end': "Fin de l'assurance",
  // Contract status label
  'contract.status.start': 'Actif',
  'contract.status.will.start': 'Activation en cours',
  'contract.status.revoked': 'Révoqué',
  'contract.status.will.end': 'En cours de résiliation',
  'contract.status.end': 'Résilié',
  'contract.status.active.trial_month': "Mois d'essai actif",

  // Contract docs
  'contract.docs.title': 'Mes documents contractuels',
  'contract.docs.description':
    'Ici, vous pouvez consulter et télécharger tous les documents contractuels pertinents au format PDF.',
  'contract.docs.download.all': 'Télécharger tous les documents',
  'contract.docs.download.all.subtext': 'Téléchargez tous les documents dans une archive ZIP.',
  // Contract status/placeholder
  'contract.placeholder.inactive': "Ce contrat n'est pas actif",

  // Sign docs
  'sign.docs.title': 'Vos documents contractuels',
  'sign.docs.description':
    "Vous trouverez ici tous les documents relatifs à votre contrat d'assurance. Vous pouvez les consulter à tout moment dans la section « Ma couverture ».",
  'sign.docs.accordion.toggle.text': 'Autres documents',
  'sign.docs.accordion.show.text': 'Montrer plus',
  'sign.docs.accordion.hide.text': 'Fermer',
  'sign.docs.go.to.account': "Retour vers l'espace personnel",
  'sign.docs.input.label.text':
    "Je confirme avoir reçu et enregistré la police d'assurance ainsi que les documents mentionnés ci-dessus, et en accepter le contenu.",

  // Documents
  'document.Allgemeine_Versicherungsbedingungen': "Conditions générales d'assurance",
  'document.Produktinformationsblatt': "Fiche d'information sur le produit",
  'document.Kundeninformationsblatt': "Fiche d'information clientèle",
  'document.Kundeninformation': 'Informations clientèle',
  'document.Einwilligungserklärung': 'Déclaration de consentement',
  'document.Datenschutzerklärung': 'Protection des données',
  'document.protocol': 'Rapport de consultation',
  'document.contract': "Police d'assurance",
  'document.sepa': 'Mandat SEPA',
  'document.opt-in_document': 'Déclaration de consentement',
  'document.Versicherungsbedingungen': "Conditions générales d'assurance",
  'document.Nutzungsbedingungen Kundenkonto': "Conditions d'utilisation de l'espace personnel",
  'document.Extra Vorteile': 'Avantages supplémentaires',
  'document.dentolo Zahnarztnetzwerk': 'Réseau de dentistes dentolo',
  'document.Informations complémentaires pour la personne assurée':
    'Informations complémentaires pour la personne assurée',
  'document.Documents contractuels': 'Documents contractuels',
  'document.Sondervereinbarung Probemonat': "Accord spécial - Mois d'essai",
  'document.Tarifblatt Akutschutz Modul.pdf': 'Fiche tarifaire du module Protection immédiate',

  // rfi text
  'document.rfi.text.head': "Votre demande d'information",
  'document.rfi.text.title': 'Téléchargez le document PDF ici.',
  'document.rfi.dentolo.text.desc':
    "Après avoir rempli les documents, vous ou votre cabinet dentaire pouvez les télécharger ici dans votre espace client. Utilisez la fonction 'Télécharger les documents'.",
  'document.rfi.petolo.text.desc':
    'Une fois les documents remplis par vous ou votre clinique vétérinaire, veuillez les importer à nouveau dans votre espace personnel. Pour cela, utilisez la fonction "Importer des documents".',
  // Payment status block
  'payment.status.total_premium': 'Prime mensuelle',
  'payment.status.policy_premium': 'Formule',

  // Payment general
  'payment.general.to.document': 'Vers les documents',
  'payment.general.access.documents': 'Télécharger les attestations de paiement',

  // Payment overview
  'payment.overview.download.certificate':
    'Vous trouverez ici les attestations de paiement de toutes vos primes mensuelles. Elles peuvent être téléchargées chaque année à partir du 1ᵉʳ février pour l’année précédente.',
  'payment.overview.heading': 'Mes attestations de paiement',
  'payment.overview.contribution.document': 'Attestation de paiement {year}',
  'payment.overview.about.taxes': "Besoin d'en savoir plus sur les impôts et les assurances ?",
  'payment.overview.helpcenter': "Vers le centre d'aide",
  'payment.overview.notification.title': 'Votre attestation de paiement',
  'payment.overview.notification.text': 'Vous pouvez télécharger le document ici.',
  'payment.overview.message.no_contribution':
    'Aucune attestation de paiement disponible pour le moment.',

  // Payment error messages
  'payment.failed.title': "Mes cotisations d'assurance",
  'payment.failed.desc': "Gérez vos cotisations d'assurance",
  'payment.failed.subText': 'Ici, vous pouvez consulter le statut de vos primes mensuelles.',
  'payment.failed.payment.link': "Afficher les cotisations d'assurance",
  'payment.failed.notification.text.one':
    "Vous avez une prime d'assurance impayée. Cliquez ici pour réactiver votre couverture.",
  'payment.failed.notification.text.two':
    "Vous avez des primes d'assurance impayées. Cliquez ici pour réactiver votre couverture.",
  'payment.failed.re.payment.heading': "La prime n'a pas pu être débitée.",
  'payment.failed.re.payment.heading.continue': "N'a pas pu être débitée.",
  'payment.failed.re.payment.link': 'Payer maintenant',
  'payment.paid.message': 'Payée',
  'payment.page.main.heading': "Mes cotisations d'assurance",
  'payment.page.main.sub.heading':
    "Ici, vous pouvez consulter les détails de vos primes d'assurance et régler les primes impayées.",
  'payment.page.no.payments': "Aucune prime d'assurance enregistrée pour le moment",
  'payment.page.network.error': 'Une erreur est survenue.',
  'payment.exported.message': 'Votre compte sera débité le {date}',
  'payment.exportable.message': 'Le débit est en cours.',
  'payment.exportable.refunded': 'Remboursée',
  'payment.exportable.refundable': 'Remboursement en cours',
  'payment.cancelled.message': 'Annulée',
  'payment.blocked.message': "La prime n'a pas pu être débitée.",

  // Payment notification
  'payment.notification.pay_now': 'Payer maintenant',
  'payment.notification.not_active': "Votre protection n'est pas active",
  'payment.notification.click_here_to_pay.singular':
    'Cliquez ici pour payer votre cotisation en souffrance',
  'payment.notification.click_here_to_pay.plural':
    'Cliquez ici pour payer vos cotisations en souffrance',

  // ErrorMessage common
  'auth.failure.page.title': 'Oups ! Une erreur est survenue !',
  [`auth.failure.page.email.support.${INSURANCE_TYPE_DENTOLO}`]:
    'Pour toute demande urgente, veuillez nous écrire à : <a>service@dentolo.de</a>',
  [`auth.failure.page.email.support.${INSURANCE_TYPE_PETOLO}`]:
    'Pour toute demande urgente, veuillez nous écrire à : <a>service@patolo.fr</a>',
  [`auth.failure.page.email.support.${INSURANCE_TYPE_VITOLO}`]:
    'Pour toute demande urgente, veuillez nous écrire à : <a>service@vitolo.de</a>',
  'auth.failure.page.email.thanks': 'Merci de votre compréhension.',
  'auth.failure.page.email.your_team': 'Votre équipe {insuranceType}',
  [`auth.failure.page.email.your_team.${INSURANCE_TYPE_DENTOLO}`]: 'Votre équipe dentolo',
  [`auth.failure.page.email.your_team.${INSURANCE_TYPE_PETOLO}`]: 'Votre équipe patolo',
  [`auth.failure.page.email.your_team.${INSURANCE_TYPE_VITOLO}`]: 'Votre équipe vitolo',

  // # Promotion carousel
  'promotion.carousel.dentolo.referral_car.text1': 'dentolo recommande : ',
  'promotion.carousel.dentolo.referral_car.text2': 'Assurance auto de ',
  'promotion.carousel.dentolo.referral_car.text3': "DA Direkt. Jusqu'au 30/11. ",
  'promotion.carousel.dentolo.referral_car.text4': "changez et économisez jusqu'à 60% !",
  'promotion.carousel.dentolo.car_link.button.label': 'En savoir plus',
  'promotion.carousel.dentolo.referral_link.title': 'Parrainez <span>des amis</span>',
  'promotion.carousel.dentolo.referral_link.button.label': 'Obtenez un bon de 50€',
  'promotion.carousel.petolo.referral_link.title': 'Recommandez patolo et recevez une prime de 50€',
  'promotion.carousel.petolo.referral_link.button.label': 'Vers ma prime',
  'promotion.carousel.petolo.confirmation.title': 'Parrainez des amis et bénéficiez doublement',
  'promotion.carousel.petolo.confirmation.accept_joining_text_1': "J'accepte les ",
  'promotion.carousel.petolo.confirmation.accept_joining_text_link': 'conditions de participation',
  'promotion.carousel.petolo.confirmation.accept_joining_text_2':
    ' et je souhaite participer au programme de parrainage patolo.',
  'promotion.carousel.petolo.confirmation.accept': 'Recommander patolo',
  'promotion.carousel.petolo.confirmation.reject': 'Peut-être plus tard',
  'promotion.carousel.vitolo.referral_link.title':
    'Parrainez <span>{br} des amis</span> <span>{br} ça rapporte !</span>',
  'promotion.carousel.vitolo.referral_link.button.label': 'Obtenez un bon de 50€',
  'promotion.carousel.vitolo.cross_selling.dentolo.title': 'Ganzheitlich vorsorgen',
  'promotion.carousel.vitolo.cross_selling.dentolo.description':
    'Der <u>vitolo</u> Zusatzschutz für Ihre Gesundheit.',
  'promotion.carousel.vitolo.cross_selling.dentolo.button.label': 'Mehr erfahren',
  'promotion.carousel.vitolo.cross_selling.petolo.title': 'Gesundbleiben für Ihr Tier',
  'promotion.carousel.vitolo.cross_selling.petolo.description':
    'Der <u>vitolo</u> Zusatzschutz bietet starke Vorsorge.',
  'promotion.carousel.vitolo.cross_selling.petolo.button.label': 'Mehr erfahren',
  'promotion.carousel.vitolo.better_doc.vitolo.title': 'Facharztsuche leicht gemacht',
  'promotion.carousel.vitolo.better_doc.vitolo.description':
    'Jetzt mit BetterDoc Fachärzt:innen finden.',
  'promotion.carousel.vitolo.better_doc.vitolo.button.label': 'Mehr erfahren',
  'promotion.carousel.fressnapf.fn_friends.text':
    '<b>Exklusive Angebote und Rabatte mit Fressnapf Friends</b> bei jedem Einkauf im Markt und Online.',
  'promotion.carousel.fressnapf.fn_friends.button.label': 'Jetzt Rabatt sichern',

  // # Pomotion carousel - DA Direkt
  'promotion.carousel.da_direkt.dental.referral_link.title':
    'Recommandez une assurance dentaire et recevez 90€ de prime',
  'promotion.carousel.da_direkt.dental.referral_link.button.label': 'Vers ma prime',
  'promotion.carousel.da_direkt.pet.referral_link.title':
    'Recommandez une assurance pour animaux et recevez 70€ de prime',
  'promotion.carousel.da_direkt.pet.referral_link.button.label': 'Vers ma prime',
  'promotion.carousel.da_direkt.cross_selling.button.label': 'En savoir plus',
  'promotion.carousel.da_direkt.dental.cross_selling.title.desktop':
    "Notre protection d'élite pour des dents saines : l'assurance dentaire DA Direkt",
  'promotion.carousel.da_direkt.dental.cross_selling.text.desktop':
    "Profitez d'une protection complète et d'un remboursement élevé pour prothèses dentaires, détartrages professionnels et plus encore, sans délai d'attente et résiliable mensuellement.",
  'promotion.carousel.da_direkt.dental.cross_selling.title.mobile': 'Protection dentaire optimale',
  'promotion.carousel.da_direkt.dental.cross_selling.list.title.mobile': 'Assurance dentaire :',
  'promotion.carousel.da_direkt.dental.cross_selling.list.item_1.mobile':
    "Jusqu'à 100 % de remboursement",
  'promotion.carousel.da_direkt.dental.cross_selling.list.item_2.mobile':
    'Prothèses, détartrages & plus',
  'promotion.carousel.da_direkt.dental.cross_selling.list.item_3.mobile':
    'Résiliable mensuellement',
  'promotion.carousel.da_direkt.pet.cross_selling.title.desktop':
    "Pour la santé de vos compagnons : l'assurance animale DA Direkt",
  'promotion.carousel.da_direkt.pet.cross_selling.text.desktop':
    "Assurance d'OP & santé pour votre chien ou chat avec protection immédiate sans délai d'attente, remboursement jusqu'à 4 fois le tarif GOT et consultation vidéo gratuite.",
  'promotion.carousel.da_direkt.pet.cross_selling.title.mobile': 'Pour la santé de vos compagnons',
  'promotion.carousel.da_direkt.pet.cross_selling.list.title.mobile': 'Assurance animale :',
  'promotion.carousel.da_direkt.pet.cross_selling.list.item_1.mobile': 'OP & santé',
  'promotion.carousel.da_direkt.pet.cross_selling.list.item_2.mobile':
    "Remboursement jusqu'à 4 fois le tarif GOT",
  'promotion.carousel.da_direkt.pet.cross_selling.list.item_3.mobile':
    'Consultation vidéo gratuite',
  'promotion.carousel.da_direkt.health.cross_selling.title.desktop':
    "En complément de la sécurité sociale : l'assurance complémentaire DA Direkt",
  'promotion.carousel.da_direkt.health.cross_selling.text.desktop':
    "Obtenez une protection complète et comblez le déficit. Nous prenons en charge des prestations telles que le dépistage du cancer de la peau, la naturopathie et l'aide visuelle.",
  'promotion.carousel.da_direkt.health.cross_selling.title.mobile':
    'En complément de la sécurité sociale',
  'promotion.carousel.da_direkt.health.cross_selling.list.title.mobile':
    'Assurance complémentaire :',
  'promotion.carousel.da_direkt.health.cross_selling.list.item_1.mobile':
    'Dépistage du cancer de la peau',
  'promotion.carousel.da_direkt.health.cross_selling.list.item_2.mobile': 'Naturopathie',
  'promotion.carousel.da_direkt.health.cross_selling.list.item_3.mobile': 'Aide visuelle',

  // Referral banner
  'referral.banner.link': "Plus d'informations",
  'referral.banner.content': 'Recommandez dentolo dès maintenant et recevez une prime de 50€ !',

  // # Join referral page
  'referral_permission.dental.page.title': 'Parrainez des amis et bénéficiez doublement',
  'referral_permission.dental.form.checkbox.label_1': "J'accepte les",
  'referral_permission.dental.form.checkbox.label.terms_link': 'conditions de participation',
  'referral_permission.dental.form.checkbox.label_2':
    'et je souhaite participer au programme de parrainage dentolo.',
  'referral_permission.dental.form.button.agree': 'Recommander dentolo',
  'referral_permission.dental.form.button.later': 'Peut-être plus tard',

  // Referrals - Common
  'referral.page.title': 'Parrainage',
  'referral.page.content': 'Recommandez {contract} dès maintenant et recevez une prime de 50€',
  'referral.page.copy.button': 'Copier le lien de parrainage',
  'referral.page.link.copied': 'Lien copié',
  'referral.page.share_in_socials':
    'ou partagez le lien directement via Facebook, WhatsApp ou e-mail',
  'referral.page.referral_id.description':
    'Vos amis souhaiteraient-ils souscrire {product} {insurance} après un entretien ? Pour que nos experts associent correctement votre recommandation dans ce cas, vos amis auront besoin du code de parrainage suivant :',
  'referral.page.referral_id.title': 'Votre code de parrainage',
  'referral.page.privacy.text':
    'Pour des raisons de confidentialité, nous déconseillons de transmettre en plus votre numéro de téléphone ou adresse e-mail.',
  'referral.page.terms_and_conditions.text':
    "Pour plus d'informations sur votre participation au programme de parrainage et nos conditions générales, cliquez <a>ici</a>.",
  'referral.page.tab.label.one': 'Parrainer des amis',
  'referral.page.tab.label.two': 'Vos recommandations',
  'referral.page.information.header': 'Voici comment faire',
  'referral.page.status.title': 'Vérifiez le statut de vos recommandations',
  'referral.page.status.empty.title': 'Aucune recommandation enregistrée pour le moment',
  'referral.page.status.empty.description':
    'Partagez votre expérience et recommandez {product} à vos proches.',
  'referral.page.list.title.email': 'E-mail de vos amis',
  'referral.page.list.title.status': 'Statut',
  'referral.reward.title': 'Votre code de bon {rewardbrand} :',
  'referral.status.label.pending': 'En attente',
  'referral.status.label.cancelled': 'Annulé',
  'referral.status.label.sent': 'Terminé',
  'referral.status.description.title': 'Signification du statut',
  'referral.status.description.pending':
    "<b>En attente :</b> Votre recommandation est en cours de traitement. <b>Ce processus peut durer jusqu'à 31 jours, à compter de la date de début de l'assurance de vos amis.</b> Une fois le processus terminé avec succès, nous vous informerons.",
  'referral.status.description.cancelled':
    "<b>Annulé :</b> Votre recommandation n'a malheureusement pas abouti en raison du non-respect des critères requis. N'hésitez pas à réessayer et à recommander {product} à d'autres amis.",
  'referral.status.description.completed':
    "<b>Terminé :</b> Votre recommandation a été traitée avec succès et votre bon de {amount}€ {giftbrand} vous attend dans votre boîte e-mail. Vous pouvez utiliser le code du bon depuis l'e-mail ou le copier directement depuis cette page.",

  // Referrals - Dentolo
  'referral.page.dentolo.title': 'Recommandez dentolo et recevez {amount}€ de prime',
  'referral.page.dentolo.step.one.title': 'Partagez le lien avec vos amis',
  'referral.page.dentolo.step.one.description':
    'Cliquez sur le bouton ci-dessous « Copier le lien de parrainage » et partagez-le avec vos amis.',
  'referral.page.dentolo.step.two.title': 'Votre ami(e) souscrit un contrat avec dentolo',
  'referral.page.dentolo.step.two.description':
    "Une fois que votre ami(e) a souscrit une assurance dentaire dentolo via le lien de parrainage, il/elle bénéficie d'un mois de cotisation offert.",
  'referral.page.dentolo.step.three.title': 'Recevez votre bon',
  'referral.page.dentolo.step.three.description':
    'Vous recevrez votre {giftbrand} après que votre ami(e) :',
  'referral.page.dentolo.step.three.additional_info.one':
    "a testé avec succès le mois d'essai gratuit,",
  'referral.page.dentolo.step.three.additional_info.two':
    'a confirmé les documents contractuels et',
  'referral.page.dentolo.step.three.additional_info.three': 'a payé le premier mois de cotisation.',
  'referral.page.dentolo.whatsapp.message':
    "Vous cherchez une assurance dentaire ? Je vous recommande dentolo. Choisissez le tarif qui vous convient et bénéficiez d'un mois d'essai gratuit via ce lien : {referralUrl}",
  'referral.page.dentolo.email.subject': 'Ma recommandation : assurance dentaire dentolo',
  'referral.page.dentolo.email.message':
    "Bonjour,%0D%0DVous cherchez une assurance dentaire ? Je vous recommande dentolo. Via ce lien, vous pouvez choisir le tarif qui vous convient et bénéficier d'un mois d'essai gratuit : {referralUrl}%0D%0DCordialement",

  // Referrals - Petolo → Patolo
  'referral.page.petolo.title': 'Recommandez patolo et recevez {amount}€ de prime',
  'referral.page.petolo.more_info.title': 'Vous avez des questions ?',
  'referral.page.petolo.more_info.text':
    'Toutes les informations sur le programme de parrainage sont disponibles <a>ici</a>.',
  'referral.page.petolo.step.one.title': 'Partagez le lien avec vos amis',
  'referral.page.petolo.step.one.description':
    'Cliquez sur le bouton ci-dessous « Copier le lien de parrainage » et partagez-le avec vos amis.',
  'referral.page.petolo.step.two.title': 'Votre ami(e) souscrit un contrat avec patolo',
  'referral.page.petolo.step.two.description':
    "Une fois que votre ami(e) a souscrit une assurance pour animaux patolo via le lien de parrainage, il/elle bénéficie d'un mois de cotisation offert.",
  'referral.page.petolo.step.three.title': 'Recevez votre bon',
  'referral.page.petolo.step.three.description':
    'Vous recevrez votre {giftbrand} après que votre ami(e) :',
  'referral.page.petolo.step.three.additional_info.one':
    "a testé avec succès le mois d'essai gratuit,",
  'referral.page.petolo.step.three.additional_info.two': 'a confirmé les documents contractuels et',
  'referral.page.petolo.step.three.additional_info.three': 'a payé le premier mois de cotisation.',
  'referral.page.petolo.whatsapp.message':
    "Vous cherchez une assurance pour animaux ? Je vous recommande patolo. Choisissez le tarif qui vous convient et bénéficiez d'un mois d'essai gratuit via ce lien : {referralUrl}",
  'referral.page.petolo.email.subject': 'Ma recommandation : assurance pour animaux patolo',
  'referral.page.petolo.email.message':
    "Bonjour,%0D%0DVous cherchez une assurance pour animaux ? Je vous recommande patolo. Via ce lien, vous pouvez choisir le tarif qui vous convient et bénéficier d'un mois d'essai gratuit : {referralUrl}%0D%0DCordialement",

  // Referrals - Vitolo
  'referral.page.vitolo.title': 'Recommandez vitolo et recevez {amount}€ de prime',
  'referral.page.vitolo.step.one.title': 'Partagez le lien avec vos amis',
  'referral.page.vitolo.step.one.description':
    'Cliquez sur le bouton ci-dessous « Copier le lien de parrainage » et partagez-le avec vos amis.',
  'referral.page.vitolo.step.two.title': 'Votre ami(e) souscrit un contrat avec vitolo',
  'referral.page.vitolo.step.two.description':
    'Votre ami(e) souscrit une assurance complémentaire vitolo via le lien de parrainage.',
  'referral.page.vitolo.step.three.title': 'Recevez votre bon',
  'referral.page.vitolo.step.three.description':
    'Vous recevrez votre {giftbrand} après que votre ami(e) :',
  'referral.page.vitolo.step.three.additional_info.one': 'a confirmé les documents contractuels et',
  'referral.page.vitolo.step.three.additional_info.two': 'a payé le premier mois de cotisation.',
  'referral.page.vitolo.whatsapp.message':
    'Vous cherchez une assurance complémentaire robuste ? Avec vitolo, ne faites aucun compromis sur votre santé. Trouvez le tarif qui vous convient via ce lien : {referralUrl}',
  'referral.page.vitolo.email.subject': 'Ma recommandation : assurance complémentaire vitolo',
  'referral.page.vitolo.email.message':
    'Bonjour,%0D%0DVous cherchez une assurance complémentaire robuste pour compléter votre couverture ? Avec vitolo, ne faites aucun compromis sur votre santé. Trouvez le tarif qui vous convient via ce lien : {referralUrl}%0D%0DCordialement',

  // First Vet
  'first.vet.go.to.link': 'Aller sur FirstVet',
  'first.vet.online.vets': 'Vétérinaires en ligne',
  'first.vet.app.find': "Trouvez l'application FirstVet ici :",
  'first.vet.slider.create.account': 'Créer un compte gratuit',
  'first.vet.slider.appointment': 'Prendre rendez-vous',
  'first.vet.slider.secure': 'Assuré en toute sérénité',
  'first.vet.slider.create.account.description':
    "Créez un compte sur le site FirstVet ou téléchargez l'application FirstVet. Inscrivez votre animal et bénéficiez d'un remboursement plus élevé après une consultation en ligne.",
  'first.vet.slider.appointment.description':
    "Vous pouvez bénéficier d'une consultation vidéo avec un vétérinaire 24h/24. Les frais de la consultation initiale seront pris en charge par nos soins.",
  'first.vet.slider.secure.description':
    'Votre animal est entre de bonnes mains : grâce à une consultation professionnelle ou une orientation vers une clinique vétérinaire locale, si nécessaire.',

  // Fressnapf
  'fressnapf.header': 'Vétérinaires en ligne',
  'fressnapf.button.text': 'Aller chez Dr. Fressnapf',
  'fressnapf.slide1.title': 'Se connecter',
  'fressnapf.slide2.title': 'Prendre rendez-vous',
  'fressnapf.slide3.title': 'Se rétablir',
  'fressnapf.slide1.description':
    'Créez un compte client avec votre adresse e-mail chez Dr. Fressnapf ou connectez-vous à votre compte Fressnapf.',
  'fressnapf.slide2.description':
    "Prenez rendez-vous pour une consultation vidéo avec un vétérinaire et bénéficiez d'une consultation initiale.",
  'fressnapf.slide3.description':
    'Votre animal est entre de bonnes mains : grâce à une consultation professionnelle ou une orientation vers une clinique vétérinaire locale.',

  // First Vet France
  'first.vet.france.header.title': `Vétérinaires en ligne`,
  'first.vet.france.header.description': `Service de consultation vidéo avec un vétérinaire directement depuis votre téléphone.`,
  'first.vet.france.content.header.title': `Comment utiliser le service`,
  'first.vet.france.content.header.description': `En tant que client Patolo, vous pouvez utiliser le service Liv’vet gratuitement en quelques étapes:`,
  'first.vet.france.content.section1.title': `Créer un compte`,
  'first.vet.france.content.section1.description': `Accédez à Liv’vet en ligne ou téléchargez l’application Liv’vet et créez un compte.`,
  'first.vet.france.content.section1.link.button': `Vers la page Liv’vet`,
  'first.vet.france.content.section2.title': `Sélectionnez Patolo comme assurance pour votre animal`,
  'first.vet.france.content.section2.description': `Lorsque vous devez sélectionner votre assurance pour animaux, choisissez Patolo.`,
  'first.vet.france.content.section3.title': `Indiquez votre adresse e-mail et votre numéro d'assurance.`,
  'first.vet.france.content.section3.description': `Copiez votre numéro d'assurance ci-dessous et collez-le dans le champ requis “Informations d'assurance”, accompagné de votre adresse e-mail.`,
  'first.vet.france.content.section3.insurance_number': `Votre numéro de contract Patolo`,

  'first.vet.france.content.section4.title': `Obtenez votre consultation gratuitement`,
  'first.vet.france.content.section4.description': `Après avoir fourni les informations requises, vous serez facturé·e {dummyPrice} pour rejoindre la salle d'attente et accéder à un vétérinaire Liv’vet en quelques minutes.`,
  'first.vet.france.content.section4.dumy.price': `0,00 €`,
  'first.vet.france.link.copied': 'Lien copié',

  // Patolo cross selling banner
  'petolo.cross-selling.banner.title': 'Nous assurons désormais vos compagnons à quatre pattes !',
  'petolo.cross-selling.banner.content':
    "L'assurance pour chiens et interventions chirurgicales patolo vous protège désormais contre les frais vétérinaires élevés.",
  'petolo.cross-selling.banner.button': 'En savoir plus',

  // Datepicker placeholder
  'dentolo.day_placeholder': 'Jour',
  'dentolo.month_placeholder': 'Mois',
  'dentolo.year_placeholder': 'Année',

  // New Claim Creation Flow common
  'new_claim.prompt.message':
    'Voulez-vous vraiment quitter la page ? Les fichiers déjà sélectionnés devront être sélectionnés à nouveau.',
  'new_claim.file.too.big': 'Fichier trop volumineux (max. 20 Mo)',
  'new_claim.file.wrong.type': 'Type de fichier invalide',
  'new_claim.file.ready.to.upload': "Prêt à l'envoi",
  'new_claim.invalid.date': 'Veuillez entrer une date valide.',
  'new_claim.invalid.date_past': 'La date ne peut pas remonter à plus de cinq ans',
  'new_claim.invalid.postcode': 'Code postal invalide',
  'new_claim.upload.page.title.with.files': 'Vos documents sélectionnés',
  'new_claim.upload.page.title.without.files': 'Importer des documents',
  'new_claim.upload.page.description':
    "Veuillez importer les documents uniquement pour un remboursement à la fois. Si vous avez des documents pour d'autres demandes de remboursement, veuillez recommencer après avoir terminé l'envoi en cours.",
  'new_claim.upload.page.reminder':
    "Veuillez également importer les courriers d'orientation vers un·e spécialiste délivrés par vos vétérinaires.",
  'new_claim.upload.page.received_refund':
    'Veuillez importer les documents attestant de la réception des remboursements mentionnés.',
  'new_claim.upload.page.hint.description':
    "Pour garantir un traitement plus rapide de votre demande de remboursement, veuillez vous assurer d'importer une facture comprenant :",
  'new_claim.upload.page.hint.good_stuff_pet_1': "L'animal traité",
  'new_claim.upload.page.hint.good_stuff_dental_1': 'La personne traitée',
  'new_claim.upload.page.hint.good_stuff_2': 'Date',
  'new_claim.upload.page.hint.good_stuff_3': 'Les soins et traitements',
  'new_claim.upload.page.hint.good_stuff_4': 'Le montant de la facture',
  'new_claim.upload.page.hint.bad_stuff_title': 'ne sont pas valides',
  'new_claim.upload.page.hint.bad_stuff_1': "L'ordre de paiement",
  'new_claim.upload.page.hint.bad_stuff_2': 'Le détail des frais',
  'new_claim.upload.page.hint.bad_stuff_3': 'Le bordereau de versement',
  'new_claim.file.uploader.title.with_files': "Sélectionner d'autres fichiers",
  'new_claim.file.uploader.title.without_files': 'Sélectionner des fichiers',
  'new_claim.file.uploader.description.with_files': `Veuillez vérifier que vous avez sélectionné les fichiers souhaités. Tous les fichiers avec le statut "Prêt à l'envoi" seront envoyés.`,
  'new_claim.file.uploader.description.without_files':
    '**Cliquez ici** pour importer des fichiers. Vous pouvez également utiliser le glisser-déposer directement dans ce champ.',
  'new_claim.date.info_text_max_error_message': 'Maximum 50 mots',
  'new_claim.select_treatment_creation_date.helper_text':
    'Veuillez saisir la première date de traitement figurant sur la facture.',
  'new_claim.select_treatment_creation_date.warning_text':
    'Attention : le traitement a eu lieu avant le {contractAcceptedAt} et pourrait ne pas être éligible au remboursement. Nous examinerons attentivement votre dossier et vous contacterons.',
  'new_claim.treatment_amount.helper_text':
    'Pour accélérer le traitement, veuillez saisir le montant exact indiqué sur la facture, sans arrondi.',
  'new_claim.submit_claim_form.data_protection_checkbox':
    "J'accepte le traitement de mes données conformément à la <a>protection des données</a> pour la gestion de mon dossier.",

  // New Claim Creation Flow Dentolo
  'dentolo.new_claim.select_treatment_category.page_title': 'Sélectionnez le type de traitement',
  'dentolo.new_claim.select_treatment_category.page_description':
    'Ici, vous pouvez choisir le type de traitement pour soumettre une déclaration de sinistre.',
  'dentolo.new_claim.select_treatment_category.teeth_cleaning': 'Détartrage',
  'dentolo.new_claim.select_treatment_category.tooth_filling': 'Obturation',
  'dentolo.new_claim.select_treatment_category.other_treatments': 'Autres traitements',
  'dentolo.new_claim.select_document_type.page_title':
    'Quel type de document souhaitez-vous télécharger ?',
  'dentolo.new_claim.select_document_type.invoice': 'Facture',
  'dentolo.new_claim.select_document_type.treatment_plan': 'Plan de traitement',
  'dentolo.new_claim.select_document_creation_date.page_title':
    'Quand le document a-t-il été créé ?',
  'dentolo.new_claim.select_document_creation_date.form_description': 'Date du document',
  'dentolo.new_claim.select_treatment_creation_date.page_title':
    'Quand a eu lieu le premier traitement ?',
  'dentolo.new_claim.select_treatment_creation_date.form_description': 'Date du traitement',
  'dentolo.new_claim.select_treatment_creation_date.check_label_text': 'Utiliser la même date',
  'dentolo.new_claim.date.error_message': 'Veuillez saisir une date valide.',
  'dentolo.new_claim.treatment_amount.page.title': 'Quel est le montant indiqué sur la facture ?',
  'dentolo.new_claim.treatment_amount.form.label.amount': 'Montant',
  'dentolo.new_claim.treatment_amount.form.error.invalid_treatment_amount':
    'Veuillez saisir un montant valide',
  'dentolo.new_claim.dentist_details.page_title': 'Quel est le nom de votre dentiste ?',
  'dentolo.new_claim.dentist_details.input_label': 'Nom du dentiste',
  'dentolo.new_claim.filling_count.page.title': "Combien d'obturations avez-vous reçues ?",
  'dentolo.new_claim.filling_count.form.label.filling_count': "Nombre d'obturations",
  'dentolo.new_claim.filling_count.form.error.invalid_filling_count':
    'Veuillez saisir un nombre valide',
  'dentolo.new_claim.submit_claim_form.page_title': 'Dernière étape : Confirmez vos informations',
  'dentolo.new_claim.submit_claim_form.treatment_category.pzr': 'Détartrage',
  'dentolo.new_claim.submit_claim_form.treatment_category.filling': 'Obturation',
  'dentolo.new_claim.submit_claim_form.treatment_category.other': 'Soins dentaires',
  'dentolo.new_claim.submit_claim_form.card_label.document_type': 'Type de document :',
  'dentolo.new_claim.submit_claim_form.card_label.document_date': 'Date du document :',
  'dentolo.new_claim.submit_claim_form.card_label.treatment_date': 'Date du premier traitement :',
  'dentolo.new_claim.submit_claim_form.card_label.amount': 'Montant :',
  'dentolo.new_claim.submit_claim_form.card_label.fillings': "Nombre d'obturations :",
  'dentolo.new_claim.submit_claim_form.card_label.dentist': 'Dentiste :',
  'dentolo.new_claim.submit_claim_form.uploaded_files.title': 'Documents',
  'dentolo.new_claim.submit_claim_form.send_button': 'Envoyer',

  // New Claim Creation Flow Patolo
  'pet_health.new_claim.select_treatment_category.page_title': 'Quel traitement a été effectué ?',
  'pet_health.new_claim.select_treatment_category.other': 'Traitement préventif',
  'petolo.new_claim.reason_for_treatment.emergency': 'Maladie/Accident',
  'petolo.new_claim.select_document_type.page_title':
    'Quel type de document souhaitez-vous envoyer ?',
  'petolo.new_claim.select_document_type.invoice': 'Facture',
  'petolo.new_claim.select_document_type.treatment_plan': 'Devis',
  'petolo.new_claim.select_document_creation_date.page_title':
    'Quelle était la raison de la consultation ?',
  'petolo.new_claim.select_document_creation_date.form_description': 'Date de la visite',
  'petolo.new_claim.select_treatment_creation_date.page_title':
    'Quand a eu lieu la première consultation ?',
  'petolo.new_claim.select_treatment_creation_date.form_description': 'Date du traitement',
  'petolo.new_claim.select_treatment_creation_date.check_label_text':
    'Reprendre la date précédente',
  'petolo.new_claim.treatment_amount.page.title': 'Quel est le montant indiqué sur la facture ?',
  'petolo.new_claim.treatment_amount.form.label.amount': 'Montant',
  'petolo.new_claim.treatment_amount.form.error.invalid_treatment_amount':
    'Veuillez saisir un montant valide',
  'petolo.new_claim.treatment_amount.form.hint_text':
    'Les factures couvrant des prestations pour plusieurs animaux assurés doivent être importées individuellement pour chaque contrat. La même facture peut être utilisée plusieurs fois.',
  'petolo.new_claim.consultation_question.page.title':
    "Avez-vous bénéficié d'une téléconsultation avant la visite vétérinaire ?",
  'petolo.new_claim.consultation_question.option.yes': 'Oui',
  'petolo.new_claim.consultation_question.option.no': 'Non',
  'petolo.new_claim.treatment_info_text.page_title':
    'Quand les symptômes sont-ils apparus et quelle était la raison de la consultation ?',
  'petolo.new_claim.treatment_info_text.page_description':
    'Veuillez indiquer la date des premiers symptômes et la raison pour laquelle la consultation était nécessaire.',
  'petolo.new_claim.submit_claim_form.treatment_category.other': 'Aperçu',
  'petolo.new_claim.submit_claim_form.page_title': 'Dernière étape : confirmation des informations',
  'petolo.new_claim.submit_claim_form.uploaded_files.title': 'Documents',
  'petolo.new_claim.submit_claim_form.send_button': 'Envoyer',
  'petolo.new_claim.submit_claim_form.card_label.document_type': 'Type de document :',
  'petolo.new_claim.submit_claim_form.card_label.document_date': "Date d'émission du document :",
  'petolo.new_claim.submit_claim_form.card_label.treatment_date':
    'Date de la première consultation :',
  'petolo.new_claim.submit_claim_form.card_label.amount': 'Montant :',
  'petolo.new_claim.submit_claim_form.card_label.treatment_necessary_answer':
    'Raison de la consultation :',
  'petolo.new_claim.submit_claim_form.card_label.videoConsultation': 'Téléconsultation :',
  'petolo.new_claim.select_video_consultation.page.title':
    'Quand a eu lieu la première téléconsultation ?',
  'petolo.new_claim.select_video_consultation.option.default.label':
    'Aucune téléconsultation correspondante',
  'petolo.new_claim.select_video_consultation.label':
    '{timestamp, date, ::dd.MM.yyyy} - {timestamp, time, ::HH:mm} heures',
  'petolo.new_claim.treatment_info_text.placeholder': '80 mots maximum',
  'petolo.new_claim.treatment_info_text.error': '80 mots maximum',
  'petolo.new_claim.existing_claim_question.page.title':
    'Cette facture concerne-t-elle une consultation de suivi liée à une demande de remboursement existante ?',
  'petolo.new_claim.existing_claim_question.option.yes': 'Oui',
  'petolo.new_claim.existing_claim_question.option.no': 'Non',
  'petolo.new_claim.existing_claims.page.title': "De quelle demande de remboursement s'agit-il ?",
  'petolo.new_claim.existing_claims.form.label.not_selected':
    'Aucune de ces demandes de remboursement',

  // New Claim Creation Flow Pet Liability
  'pet_liability.new_claim.liability_reason.page_title': "Que s'est-il exactement passé ?",
  'pet_liability.new_claim.liability_reason.item': 'Objet endommagé',
  'pet_liability.new_claim.liability_reason.another_animal': 'Autre animal blessé',
  'pet_liability.new_claim.liability_reason.vehicle': 'Véhicule/Transport endommagé',
  'pet_liability.new_claim.liability_reason.property': 'Bâtiment/Propriété endommagé(e)',
  'pet_liability.new_claim.liability_reason.person': 'Personne blessée',
  'pet_liability.new_claim.liability_reason.other': 'Autre',
  'pet_liability.new_claim.liability_damage_caused_by.page_title.damage':
    "Qui est à l'origine du dommage ?",
  'pet_liability.new_claim.liability_damage_caused_by.page_title.injury':
    "Qui est à l'origine de la blessure ?",
  'pet_liability.new_claim.liability_damage_caused_by.other': "Quelqu'un d'autre",
  'pet_liability.new_claim.pet_accompanied_by.page_title':
    "Qui était avec {petName} au moment de l'incident ?",
  'pet_liability.new_claim.pet_accompanied_by.myself': 'Moi-même',
  'pet_liability.new_claim.pet_accompanied_by.partner': 'Partenaire',
  'pet_liability.new_claim.pet_accompanied_by.family': 'Membre de la famille / proche',
  'pet_liability.new_claim.pet_accompanied_by.flatmate': 'Colocataire',
  'pet_liability.new_claim.pet_accompanied_by.work_colleague': 'Collègue de travail',
  'pet_liability.new_claim.pet_accompanied_by.no_relation': 'Aucune relation',
  'pet_liability.new_claim.damage_estimate.page_title':
    'Quel est le montant estimé du dommage total ?',
  'pet_liability.new_claim.damage_estimate.upto_500': '0 à 500 €',
  'pet_liability.new_claim.damage_estimate.501_1000': '501 à 1 000 €',
  'pet_liability.new_claim.damage_estimate.1001_5000': '1 001 à 5 000 €',
  'pet_liability.new_claim.damage_estimate.5001_10000': '5 001 à 10 000 €',
  'pet_liability.new_claim.damage_estimate.unknown': 'Inconnu',
  'pet_liability.new_claim.affected_party.page_title.injured_animal':
    "Qui est le propriétaire de l'animal blessé ?",
  'pet_liability.new_claim.affected_party.page_title.injured_person': 'Qui a été blessé ?',
  'pet_liability.new_claim.affected_party.page_title.who_is_affected':
    'Qui est affecté par le dommage ?',
  'pet_liability.new_claim.affected_party.option.myself': 'Moi-même',
  'pet_liability.new_claim.affected_party.option.other': "Quelqu'un d'autre",
  'pet_liability.new_claim.relation_with_affected_party.page_title.injured_animal':
    "Quelle est votre relation avec le propriétaire de l'animal blessé ?",
  'pet_liability.new_claim.relation_with_affected_party.page_title.damage':
    'Quelle est votre relation avec la personne endommagée ?',
  'pet_liability.new_claim.relation_with_affected_party.page_title.injured_person':
    'Quelle est votre relation avec la personne blessée ?',
  'pet_liability.new_claim.relation_with_affected_party.partner': 'Partenaire',
  'pet_liability.new_claim.relation_with_affected_party.family': 'Membre de la famille / proche',
  'pet_liability.new_claim.relation_with_affected_party.flatmate': 'Colocataire',
  'pet_liability.new_claim.relation_with_affected_party.work_colleague': 'Collègue de travail',
  'pet_liability.new_claim.relation_with_affected_party.no_relation': 'Aucune relation',
  'pet_liability.new_claim.medical_exam.page_title_person': 'Avez-vous fait un examen médical ?',
  'pet_liability.new_claim.medical_exam.page_title_animal':
    'Votre animal a-t-il été examiné par un vétérinaire ?',
  'pet_liability.new_claim.medical_exam.yes': 'Oui',
  'pet_liability.new_claim.medical_exam.no': 'Non',
  'pet_liability.new_claim.incident_location.page_title':
    "Où l'incident s'est-il produit exactement ?",
  'pet_liability.new_claim.details_of_affected_party.page_title.injured_animal':
    'Détails sur le propriétaire',
  'pet_liability.new_claim.details_of_affected_party.page_title.injured_person':
    'Détails sur la personne blessée',
  'pet_liability.new_claim.details_of_affected_party.page_title.damage':
    'Détails sur la personne endommagée.',
  'pet_liability.new_claim.incident_note.page_title': "Comment cela s'est-il produit ?",
  'pet_liability.new_claim.incident_note.page_description':
    "Veuillez décrire brièvement ce qui s'est passé.",
  'pet_liability.new_claim.incident_note.placeholder': 'Maximum 150 mots',
  'pet_liability.new_claim.upload_document.page.description':
    'Veuillez télécharger les documents pertinents tels que factures, photos des dommages, etc.',
  'pet_liability.new_claim.upload_document.file_uploader.description_with_files':
    "Veuillez vérifier que tous les fichiers souhaités sont correctement sélectionnés avant l'envoi. Tous les fichiers marqués 'Prêt à être envoyé' seront transférés.",

  'pet_liability.new_claim.incident_date_time.page_title.injury':
    "Quand la blessure s'est-elle produite ?",
  'pet_liability.new_claim.incident_date_time.page_title.damage':
    "Quand le dommage s'est-il produit ?",
  'pet_liability.new_claim.incident_date_time.checkbox.label.dont_know_time':
    "Je ne connais pas l'heure exacte",
  'pet_liability.new_claim.damaged_item.page_title': 'Quel objet a été endommagé ?',
  'pet_liability.new_claim.damaged_item.form.label.damaged_item': 'Objet endommagé',
  'pet_liability.new_claim.damaged_item.form.label.damaged_item_age': "Âge de l'objet endommagé",
  'pet_liability.new_claim.damaged_item.form.label.damaged_item_price': "Prix d'achat",
  'pet_liability.new_claim.damaged_item.form.error.invalid_price': 'Le montant est invalide',
  'pet_liability.new_claim.submit_claim_form.page_title':
    'Dernière étape : Confirmez vos informations',
  'pet_liability.new_claim.submit_claim_form.data_card.title': 'Récapitulatif',
  'pet_liability.new_claim.submit_claim_form.card_label.tariff': 'Tarif :',
  'pet_liability.new_claim.submit_claim_form.card_label.incident_date': 'Date :',
  'pet_liability.new_claim.submit_claim_form.card_label.city': 'Ville :',
  'pet_liability.new_claim.submit_claim_form.uploaded_files_card.title': 'Documents',
  'pet_liability.new_claim.submit_claim_form.button.send': 'Envoyer',

  'new_claim.form.label.date': 'Date',
  'new_claim.form.label.time': 'Heure',
  'new_claim.form.label.name': 'Nom',
  'new_claim.form.label.street_number': 'Nom et numéro de rue',
  'new_claim.form.label.postcode_city': 'Code postal, ville',
  'new_claim.form.label.email.optional': 'E-mail (facultatif)',
  'new_claim.form.label.phone.optional': 'Téléphone (facultatif)',

  // New Claim Creation Flow Vitolo
  'vitolo.new_claim.select_document_type.invoice': 'Facture',
  'vitolo.new_claim.select_document_type.treatment_plan': 'Plan de traitement',
  'vitolo.new_claim.select_document_type.page_title':
    'Quel type de document avez-vous téléchargé ?',
  'vitolo.new_claim.submit_claim_form.send_button': 'Envoyer',
  'vitolo.new_claim.select_document_creation_date.page_title':
    'Quand le premier document téléchargé a-t-il été créé ?',
  'vitolo.new_claim.select_document_creation_date.form_description': 'Date du document',
  'vitolo.new_claim.treatment_amount.page.title': 'Quel est le montant indiqué sur la facture ?',
  'vitolo.new_claim.treatment_amount.form.label.amount': 'Montant',
  'vitolo.new_claim.treatment_amount.form.error.invalid_treatment_amount':
    'Veuillez saisir un montant valide',
  'vitolo.new_claim.treatment_info_text.page_title':
    "Avez-vous déjà reçu un remboursement d'autres assurances pour votre sinistre ?",
  'vitolo.new_claim.treatment_info_text.page_description':
    'Si oui, veuillez nous indiquer quelles prestations vous avez reçues ou recevrez, par exemple de votre sécurité sociale, assurance accident, assurance dentaire ou autres.',
  'vitolo.new_claim.treatment_info_text.placeholder': 'Maximum 80 mots',
  'vitolo.new_claim.treatment_info_text.error': 'Maximum 80 mots',
  'vitolo.new_claim.treatment_info_text.page_skip_text':
    "Si vous n'avez pas reçu de remboursement d'autres assurances, passez à l'étape suivante.",
  'vitolo.new_claim.submit_claim_form.card_label.document_type': 'Type de document :',
  'vitolo.new_claim.submit_claim_form.card_label.document_date': 'Date du document :',
  'vitolo.new_claim.submit_claim_form.card_label.amount': 'Montant :',
  'vitolo.new_claim.submit_claim_form.card_label.treatment_answer':
    "Remboursements d'autres assurances :",
  'vitolo.new_claim.submit_claim_form.treatment_category.other': 'Aperçu',
  'vitolo.new_claim.existing_claims.page.title': 'À quel dossier existant cela se rapporte-t-il ?',
  'vitolo.new_claim.existing_claims.form.label.not_selected': 'Aucun de ces dossiers',
  'vitolo.new_claim.submit_claim_form.page_title': 'Dernière étape : Confirmez vos informations',
  'vitolo.new_claim.submit_claim_form.uploaded_files.title': 'Documents',

  // 404 page
  'petolo.404.page.message': "Oh non, cette page n'existe pas.",

  // cancellation contract
  'cancellation.contract.cancel.exit': 'Annuler',
  'cancellation.contract.confirm.continue': "Confirmer l'annulation",
  'cancellation.contract.confirm.cancel': 'Confirmer la résiliation',
  'cancellation.contract.title': 'Dommage que vous souhaitiez partir !',
  'cancellation.contract.withdrawl.success.title':
    'Votre contrat a été résilié avec succès. Vous recevrez une confirmation par e-mail.',
  'cancellation.contract.withdrawl.success.description':
    'Veuillez indiquer la raison de votre rétractation afin que nous puissions améliorer nos services.',
  'cancellation.contract.regular.success.title':
    'Votre contrat a été résilié avec succès. Vous recevrez une confirmation par e-mail.',
  'cancellation.contract.regular.success.description':
    'Veuillez indiquer la raison de votre rétractation afin que nous puissions améliorer nos services.',
  'cancellation.contract.button.send': 'Envoyer',
  'cancellation.contract.button.backToHome': "Retour à l'accueil",
  'contract.cancellation.success.title': 'Merci de votre retour !',
  'contract.cancellation.success.button': "Retour à l'accueil",
  'contract.cancellation.failure.title':
    'Erreur ! Un problème est survenu lors de la résiliation de votre contrat.',
  'contract.cancellation.failure.text':
    'Si le problème persiste, veuillez contacter notre service clientèle.',
  'contract.cancellation.failure.button': 'Service clientèle',
  'cancellation.contract.landing.page.question': 'Voulez-vous vraiment révoquer votre contrat ?',
  'cancellation.contract.landing.page.warning1':
    'Vous êtes encore dans le délai de rétractation. Si vous poursuivez, vous perdrez votre couverture.',
  'cancellation.contract.landing.page.warning2':
    'Les primes mensuelles payées en trop vous seront automatiquement remboursées sur votre compte bancaire dans les prochains jours.',
  'cancellation.contract.landing.page.warning3':
    'Vos demandes de remboursement en attente seront automatiquement clôturées. Un remboursement ne sera plus possible.',
  'cancellation.contract.landing.page.warning4':
    'Vos demandes de remboursement en attente seront automatiquement clôturées, et un remboursement ne sera plus possible.',
  'cancellation.contract.landing.page.regular.next_possible_date':
    'La prochaine date de résiliation possible est le :',
  'cancellation.contract.landing.page.regular.text1':
    "Étant donné que vous avez déjà reçu un remboursement de notre part, la durée minimale d'engagement de votre contrat s'applique conformément à l'article 5 des conditions générales d'assurance.",
  'cancellation.contract.landing.page.regular.text2':
    'Nous résilierons votre contrat à la prochaine échéance possible en fin de mois.',
  'cancellation.contract.landing.page.regular.text3':
    'Les primes mensuelles payées en trop vous seront automatiquement remboursées sur votre compte bancaire dans les prochains jours.',
  'cancellation.contract.landing.page.regular.text4':
    'Vos demandes de remboursement en attente seront automatiquement clôturées. Un remboursement ne sera plus possible.',
  'cancellation.contract.landing.page.regular.text5':
    "En raison du module de couverture d'urgence, la résiliation n'est possible qu'après l'expiration de la durée minimale du contrat.",

  // Block screen when contract is not approved
  'contract_not_approved.title.dentolo': "Merci d'avoir choisi dentolo !",
  'contract_not_approved.title.petolo': "Merci d'avoir choisi patolo !",
  'contract_not_approved.title.vitolo': "Merci d'avoir choisi vitolo !",
  'contract_not_approved.text_1':
    'Votre contrat est en cours de vérification, mais cela ne devrait pas prendre longtemps.',
  'contract_not_approved.text_2': 'Veuillez attendre quelques minutes, puis actualiser la page.',
  'contract_not_approved.button': 'Actualiser la page',

  // Insure Another Pet Banner
  'insure_another_pet.banner.title': "Protégez d'autres boules poils maintenant !",
  'insure_another_pet.banner.description':
    'Vous avez plusieurs animaux à assurer ? Cliquez sur "Assurer un autre animal" pour protéger vos autres compagnons.',
  'insure_another_pet.banner.button.label': 'Assurer un autre animal',

  // Maintenance Banner
  'maintenance_banner.text':
    "Maintenance le 07/02 de 6h30 à environ 7h30. L'accès à votre espace personnel ne sera pas possible durant cette période.",

  // Link to claims page
  'link_to_claims.title': 'Remboursement',
  'link_to_claims.description':
    "Pour demander un remboursement, il vous suffit <a>d'importer</a> votre facture et la feuille de soin",

  // Already uploaded documents
  'user_claims_info.already_uploaded_documents.title': 'Documents envoyés',
  'user_claims_info.already_uploaded_documents.document_date_label': 'Envoyé le',
  'user_claims_info.already_uploaded_documents.document_open_link': 'Afficher',

  // Decline cat ATB upgrade
  'decline_cat_atb_upgrade.title': 'Refuser la mise à jour',
  'decline_cat_atb_upgrade.description.paragraph_1':
    "Conformément à notre e-mail, nous vous offrons la possibilité de refuser la mise à jour de vos conditions d'assurance, y compris la subvention des défauts de développement.",
  'decline_cat_atb_upgrade.description.paragraph_2':
    "Vous pouvez consulter les conditions d'assurance précédentes ainsi que les mises à jour ici :",
  'decline_cat_atb_upgrade.description.previous_document': "Conditions d'assurance précédentes",
  'decline_cat_atb_upgrade.description.current_document': "Conditions d'assurance mises à jour",
  'decline_cat_atb_upgrade.description.paragraph_3':
    "En cliquant sur « Refuser la mise à jour », votre assurance pour chats ne sera pas mise à jour et les conditions d'assurance antérieures continueront de s'appliquer.",
  'decline_cat_atb_upgrade.confirm': 'Refuser la mise à jour',
  'decline_cat_atb_upgrade.confirm.success.title':
    "Vos conditions d'assurance ne seront pas mises à jour",
  'decline_cat_atb_upgrade.confirm.success.description':
    "Votre assurance pour chats restera inchangée et les conditions initiales continueront de s'appliquer.",
  'decline_cat_atb_upgrade.banner.title': "Mise à jour des conditions d'assurance",
  'decline_cat_atb_upgrade.banner.contents':
    "Pour plus d'informations et pour refuser la mise à jour, cliquez [ici](/decline-cat-atb-upgrade).",

  // Akutschutz cancellation
  'choose_cancellation.title': "Résilier l'assurance dentaire ou l'ensemble de votre protection",
  'choose_cancellation.cancel_only_akutschutz.title': "Résilier uniquement l'assurance immédiate",
  'choose_cancellation.cancel_only_akutschutz.description':
    "Cliquez ici pour retirer l'assistance immédiate de votre protection d'assurance.",
  'choose_cancellation.radio_cancel_contract.title': "Résilier l'ensemble de votre protection",
  'choose_cancellation.radio_cancel_contract.description':
    "Cliquez ici si vous souhaitez résilier l'ensemble de votre assurance dentolo.",
  'choose_cancellation.next_button': 'Suivant',

  'akutschutz_cancellation.contract_within_withdraw_period.title':
    "Rétracter l'assurance immédiate",
  'akutschutz_cancellation.contract_within_withdraw_period.content_1':
    "Vous rétractez la souscription de l'assistance immédiate. Votre cotisation mensuelle diminuera, mais votre protection dentolo et la couverture associée resteront en vigueur.",
  'akutschutz_cancellation.contract_within_withdraw_period.content_2':
    "Pour consulter les détails et avantages de l'assistance immédiate avant de procéder à la rétractation, veuillez visiter notre <a>article dans le centre d'aide</a>.",
  'akutschutz_cancellation.contract_within_withdraw_period.confirm_cancel_button':
    "Rétracter l'assurance immédiate",
  'akutschutz_cancellation.contract_within_withdraw_period.abort_cancel_button': 'Annuler',
  'akutschutz_cancellation.contract_within_minimum_duration.title':
    "Résilier l'assurance immédiate",
  'akutschutz_cancellation.contract_within_minimum_duration.content_1':
    "En résiliant l'assistance immédiate, votre cotisation mensuelle sera réduite, mais votre protection dentolo et la couverture associée resteront en vigueur.",
  'akutschutz_cancellation.contract_within_minimum_duration.content_2':
    "Veuillez noter que l'assistance immédiate comporte une durée minimale de 24 mois.",
  'akutschutz_cancellation.contract_within_minimum_duration.next_possible_cancellation_date':
    'La prochaine date possible de résiliation est le',
  'akutschutz_cancellation.contract_within_minimum_duration.confirm_cancel_button':
    "Résilier l'assurance immédiate",
  'akutschutz_cancellation.contract_within_minimum_duration.abort_cancel_button': 'Annuler',
  'akutschutz_cancellation.contract_outside_minimum_duration.title':
    "Résilier l'assurance immédiate",
  'akutschutz_cancellation.contract_outside_minimum_duration.content_1':
    "En résiliant l'assistance immédiate, votre cotisation mensuelle sera réduite, mais votre protection dentolo et la couverture associée resteront en vigueur.",
  'akutschutz_cancellation.contract_outside_minimum_duration.content_2':
    "La résiliation de l'assistance immédiate prendra effet à la fin du mois.",
  'akutschutz_cancellation.contract_outside_minimum_duration.next_possible_cancellation_date':
    'Votre date de résiliation est le',
  'akutschutz_cancellation.contract_outside_minimum_duration.confirm_cancel_button':
    "Résilier l'assurance immédiate",
  'akutschutz_cancellation.contract_outside_minimum_duration.abort_cancel_button': 'Annuler',
  'akutschutz_cancellation.success.withdraw.title':
    'Votre assurance immédiate a été rétractée avec succès.',
  'akutschutz_cancellation.success.withdraw.content': 'Votre contrat a été ajusté en conséquence.',
  'akutschutz_cancellation.success.cancel.title':
    'Votre assurance immédiate a été résiliée avec succès.',
  'akutschutz_cancellation.success.cancel.content': 'Votre contrat a été ajusté en conséquence.',
  'akutschutz_cancellation.success.back_to_start_page': "Retour à l'accueil",

  'akutschutz_cancellation.abort.withdraw.content':
    'Votre assurance immédiate reste en vigueur. Vous pouvez la rétracter dans votre espace client pendant la période de rétractation.',
  'akutschutz_cancellation.abort.cancel.content':
    'Votre assurance immédiate reste en vigueur. Vous pouvez la résilier à tout moment dans votre espace client.',
  'akutschutz_cancellation.abort.back_to_page': 'Retour',
  'akutschutz_cancellation.error.title': 'Une erreur est survenue.',
  'akutschutz_cancellation.error.content':
    'Veuillez réessayer. Si le problème persiste, contactez notre <a>service client</a>.',
  'akutschutz_cancellation.error.try_again': 'Réessayer',

  // Footer
  [`footer.copyright.${INSURANCE_TYPE_DENTOLO}`]: 'dentolo est une marque de © getolo GmbH',
  [`footer.copyright.${INSURANCE_TYPE_PETOLO}`]: 'patolo est une marque de © getolo GmbH',
  [`footer.copyright.${INSURANCE_TYPE_VITOLO}`]: 'vitolo est une marque de © getolo GmbH',

  // Claim Status Details
  [`claim_status_details.${CLAIM_STATUS_DETAILS.CLAIM_OPENED}`]: `
    {insuranceType, select,
      ${INSURANCE_TYPE_PETOLO} {Votre dossier de sinistre a été créé. Le traitement peut prendre jusqu'à 14 jours. Nous nous excusons pour le délai, mais nous vérifierons vos documents dès que possible et vous informerons par e-mail des prochaines étapes.}
      ${INSURANCE_TYPE_DENTOLO} {Votre dossier de sinistre a été créé. Le traitement peut prendre jusqu'à 14 jours. Nous nous excusons pour le délai, mais nous vérifierons vos documents dès que possible et vous informerons par e-mail des prochaines étapes.}
      other {Votre dossier de sinistre a été créé. Le traitement prend généralement jusqu'à cinq jours ouvrables. Nous vous contacterons dès qu'il y aura des nouveautés.}
    }`,
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_OR_COSTPLAN_MISSING}`]:
    "Pour traiter votre dossier, nous avons besoin du plan de traitement approuvé par votre caisse d'assurance maladie.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.INFORMATION_REQUEST_MISSING}`]:
    'Nous avons besoin des documents complétés pour traiter votre demande de remboursement.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.PATIENT_FILE_MISSING}`]:
    "Pour traiter votre dossier, nous avons besoin d'une copie de votre dossier médical. Veuillez la demander auprès du cabinet effectuant le traitement.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.INVOICE_MISSING}`]:
    'Pour finaliser le traitement de votre dossier, nous avons besoin de la facture de votre traitement.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.DOCUMENTS_MISSING}`]:
    'Pour traiter votre dossier, nous avons besoin de documents supplémentaires.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.PET_MEDICAL_CARD_IS_MISSING}`]: `Pour traiter votre dossier, nous avons besoin d'une copie de votre dossier médical. Veuillez la demander auprès de votre {insuranceType, select, ${INSURANCE_TYPE_DENTOLO} {dentiste} ${INSURANCE_TYPE_PETOLO} {vétérinaire} other {médecin}}.`,
  [`claim_status_details.${CLAIM_STATUS_DETAILS.REIMBURSEMENT_ACKNOWLEDGED}`]: `
    {reimbursableAmountAvailable, select,
      true {Conformément à nos conditions d'assurance, nous remboursons <b>{reimbursableAmount, number, ::sign-auto currency/EUR}</b> pour votre traitement prévu. Notre accord est basé sur les documents que vous avez soumis.}
      other {Nous sommes heureux de pouvoir vous accorder une prise en charge. Toutefois, nous avons encore besoin de la facture de votre traitement.}
    }`,
  [`claim_status_details.${CLAIM_STATUS_DETAILS.PARTIAL_REIMBURSEMENT_ACKNOWLEDGED}`]: `
    {reimbursableAmountAvailable, select,
      true {Conformément à nos conditions d'assurance, nous remboursons <b>{reimbursableAmount, number, ::sign-auto currency/EUR}</b> pour votre traitement prévu. Notre accord est basé sur les documents que vous avez soumis.}
      other {Nous sommes heureux de pouvoir vous accorder une prise en charge partielle, mais nous avons encore besoin de la facture de votre traitement.}
    }`,
  [`claim_status_details.${CLAIM_STATUS_DETAILS.REIMBURSEMENT_WILL_BE_PAID}`]:
    'Votre dossier de sinistre a été créé. Nous traiterons vos documents dès que possible et vous informerons par e-mail des prochaines étapes.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.PARTIAL_REIMBURSEMENT_WILL_BE_PAID}`]: `
    {sameIban, select,
      true {Le remboursement de {payoutAmount, number, ::sign-auto currency/EUR} sera viré sur le compte suivant : {recepientIban}.}
      other {Le remboursement de {payoutAmount, number, ::sign-auto currency/EUR} sera viré sur le compte de {recepientName} avec l'IBAN {recepientIban}.}
    }`,
  [`claim_status_details.${CLAIM_STATUS_DETAILS.REIMBURSEMENT_PAID}`]: `
    {sameIban, select,
      true {Le remboursement de {payoutAmount, number, ::sign-auto currency/EUR} a été viré sur le compte suivant : {recepientIban}.}
      other {Le remboursement de {payoutAmount, number, ::sign-auto currency/EUR} a été viré sur le compte de {recepientName} avec l'IBAN {recepientIban}.}
    }`,
  [`claim_status_details.${CLAIM_STATUS_DETAILS.PARTIAL_REIMBURSEMENT_PAID}`]: `
    {sameIban, select,
      true {Le remboursement de {payoutAmount, number, ::sign-auto currency/EUR} a été viré sur le compte suivant : {recepientIban}.}
      other {Le remboursement de {payoutAmount, number, ::sign-auto currency/EUR} a été viré sur le compte de {recepientName} avec l'IBAN {recepientIban}.}
    }`,
  [`claim_status_details.${CLAIM_STATUS_DETAILS.CONTRACT_SIGNATURE_MISSING}`]:
    'Nous sommes heureux de pouvoir vous accorder une prise en charge.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.WITHDRAWAL_PERIOD_STILL_RUNNING}`]:
    'Nous sommes heureux de pouvoir vous accorder une prise en charge. Le remboursement sera effectué après la période de rétractation de 14 jours.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.OUTSTANDING_PAYMENTS}`]:
    "Nous sommes heureux de pouvoir vous accorder une prise en charge. Pour procéder au remboursement, veuillez d'abord régler les paiements en souffrance.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TRIAL_MONTH}`]:
    "Nous sommes heureux de pouvoir vous accorder une prise en charge. Le remboursement sera effectué après la fin de votre mois d'essai gratuit.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_BEFORE_CONTRACT_START}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car le traitement a eu lieu avant le début de l'assurance.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.NO_COVERAGE}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car ce traitement n'est pas couvert par votre contrat.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_PLAN}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car le traitement indiqué dans le plan était déjà prévu avant le début de l'assurance.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_DENTIST_REQUEST}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car le besoin de traitement, selon la demande de votre dentiste, était déjà connu avant le début de l'assurance.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_PRETREATMENT_PROVIDE}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car le besoin de traitement, selon la demande de prétraitement, était déjà connu avant le début de l'assurance.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_BEFORE_CONTRACT_START_BY_INVOICE}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car le traitement, selon la facture, a eu lieu avant le début de l'assurance.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.NO_BUDGET}`]:
    'Malheureusement, nous ne pouvons garantir de remboursement, car votre budget de traitement est épuisé.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.NO_COVERAGE_NO_MEDICAL_NEED}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car il n'y a pas de nécessité médicale pour ce traitement.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.NO_COVERAGE_ORTHODONTICS}`]:
    'Malheureusement, nous ne pouvons garantir de remboursement, car les traitements orthodontiques ne sont pas couverts par votre assurance dentaire.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.COVERAGE_FOR_CHILDREN_ONLY_FOR_ACCIDENT}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car les enfants assurés ne sont couverts qu'en cas d'accident.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.MAX_AMOUNT_OF_TOOTH_CLEANINGS_REACHED}`]:
    "Vous avez atteint le nombre maximal de détartrages professionnels remboursables pour cette année d'assurance.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.NO_COVERAGE_COSMETIC_MEASURES}`]:
    'Malheureusement, nous ne pouvons garantir de remboursement, car le traitement est purement esthétique.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.NOT_THE_INSURED_ANIMAL}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car l'animal traité n'est pas celui assuré.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.PET_HEALTH_BUDGET_EXHAUSTED}`]:
    'Malheureusement, nous ne pouvons garantir de remboursement, car le budget santé pour cet animal est épuisé.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.PET_SOS_BUDGET_EXHAUSTED}`]:
    'Malheureusement, nous ne pouvons garantir de remboursement, car le budget SOS pour cet animal est épuisé.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_VETERINARIAN_REQUEST}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car selon la demande de votre vétérinaire, le besoin de traitement était déjà connu avant le début de l'assurance.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_VATERINARIAN_PRETREATMENT_PROVIDE}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car selon la demande du vétérinaire prétraité, le besoin de traitement était déjà connu avant le début de l'assurance.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.FIRSTVET_TREATMENT_NEEDED}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car selon la vidéoconsultation, le besoin de traitement était déjà connu avant le début de l'assurance.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_PERSON_IS_NOT_INSURED}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car la personne traitée n'est pas assurée.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.REIMBURSEMENT_IS_ALREADY_COVERED}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car l'assurance maladie couvre déjà le montant remboursable selon votre contrat.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.COPAYMENT_IS_ALREADY_COVERED}`]:
    'Malheureusement, nous ne pouvons garantir de remboursement, car la part complémentaire sera prise en charge par votre assurance maladie après traitement.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_WAS_KNOWN_BEFORE_INSURANCE}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car le besoin de traitement était déjà connu d'après le dossier médical avant le début de l'assurance.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_BEFORE_CONTRACT_SIGN}`]:
    'Malheureusement, nous ne pouvons garantir de remboursement, car le traitement a eu lieu avant la signature du contrat.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_NEEDS_BEFORE_CONTRACT_SIGN}`]:
    'Malheureusement, nous ne pouvons garantir de remboursement, car le besoin de traitement était déjà connu avant la signature du contrat.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.TREATMENT_WAS_KNOWN_BEFORE_INSURANCE_BY_DENTIST}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car selon le dentiste, le besoin de traitement était déjà connu avant le début de l'assurance.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.KNOWN_OF_TREATMENT_BEFORE_CONTRACT_START_BY_DOCTOR_REQUEST}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car selon le médecin, le besoin de traitement était déjà connu avant le début de l'assurance.",
  [`claim_status_details.${CLAIM_STATUS_DETAILS.COVERAGE_OF_COSTS_THROUGH_THIRD_PARTY_DAMAGE_CLAIMS}`]:
    "Malheureusement, nous ne pouvons garantir de remboursement, car vous bénéficiez déjà d'une prise en charge via une réclamation de tiers.",
  // Cancelled
  [`claim_status_details.${CLAIM_STATUS_DETAILS.CANCELED_BY_DENTOLO}`]:
    'Dossier annulé par dentolo.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.CANCELED_BY_CUSTOMER}`]:
    'Dossier annulé à la demande du client.',
  [`claim_status_details.${CLAIM_STATUS_DETAILS.CLAIM_UPDATED}`]:
    "Votre document a été créé. Le traitement peut prendre jusqu'à 14 jours. Nous nous excusons pour le délai et traiterons vos documents dès que possible pour vous informer par e-mail des prochaines étapes.",

  // Claim Details - Additional Payments
  'claim_details.additional_payments.payout_confirmed': `
    {sameIban, select,
      true {Le remboursement de {payoutAmount, number, ::sign-auto currency/EUR} a été effectué le {confirmedAt, date, ::dd.MM.yyyy} sur votre compte.}
      other {Le remboursement de {payoutAmount, number, ::sign-auto currency/EUR} a été effectué le {confirmedAt, date, ::dd.MM.yyyy} sur le compte de {recepientName} avec l'IBAN {recepientIban}.}
    }`,
  'claim_details.additional_payments.payout_not_confirmed_yet': `
    {sameIban, select,
      true {Le remboursement de {payoutAmount, number, ::sign-auto currency/EUR} sera effectué sur votre compte.}
      other {Le remboursement de {payoutAmount, number, ::sign-auto currency/EUR} sera effectué sur le compte de {recepientName} avec l'IBAN {recepientIban}.}
    }`,

  // Pet onboard
  'pet_onboard.card_1.title': 'Bienvenue dans <br /> le compte client patolo !',
  'pet_onboard.card_1.body':
    "A partir de là, vous avez accès à toutes les informations et fonctions importantes de votre couverture d'assurance patolo.",
  'pet_onboard.card_1.button': 'Sauter',

  'pet_onboard.card_2.title': 'Aperçu de vos <br /> prestations patolo',
  'pet_onboard.card_2.body':
    'Dans votre compte client, vous trouverez toutes les informations relatives à vos prestations tarifaires, vous pourrez télécharger vos factures en quelques étapes et consulter, par exemple, le statut de vos remboursements.',
  'pet_onboard.card_2.button': 'Sauter',

  'pet_onboard.card_3.title': 'Nouvelles fonctions & <br /> avantages exclusifs',
  'pet_onboard.card_3.body':
    'Vos services & avantages patolo sont constamment étendus et améliorés afin que vous puissiez profiter de nos prestations de manière simple, rapide et sans complications. Nous vous informerons en temps utile des modifications apportées à votre compte client.',
  'pet_onboard.card_3.button': 'Vers le compte client',
  'contact.page.backlog.banner': `En raison d'une forte demande, des retards sont à prévoir. Merci de votre patience.`,

  // placeholder
  'placeholder.login.email': 'jean.dupont@gmail.fr',
  'placeholder.contact.email': 'alex.dupont@email.fr',
  'placeholder.contact.phone': '06 12 34 56 78',
  'placeholder.contact.street': 'Rue du Soleil',
  'placeholder.contact.house.number': '5',
  'placeholder.contact.address': 'Bâtiment A, 2e étage',
  'placeholder.account.first_name': 'Jean',
  'placeholder.account.last_name': 'Dupont',
  'placeholder.account.iban': 'FR76 3000 6000 0112 3456 7890 189',
  'placeholder.account.city': 'Paris',

  //  customer service numbers

  [`customer.service.${INSURANCE_TYPE_DENTOLO}`]: '030 959 99 33 00',
  [`customer.service.${INSURANCE_TYPE_PETOLO}`]: '045 821 20 02',
  [`customer.service.${INSURANCE_TYPE_VITOLO}`]: '030 417 35010',
};

export default fr;
