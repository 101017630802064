import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

import { imgLinks } from '../../shared';
import ButtonContainer from '../ButtonContainer';

import { StyledInfoWindowContent, StyledInfoWindowIcon } from './InfoWindow.styled';

const getIcon = (mode) => {
  switch (mode) {
    case 'success':
      return 'notification_success';
    case 'failure':
      return 'notification_error';
    default:
      return 'notification_info';
  }
};

const InfoWindow = (props) => {
  const { mode, titleHtml, textHtml, button, buttons } = props;

  const iconToUse = getIcon(mode);

  return (
    <>
      {mode && iconToUse && (
        <StyledInfoWindowIcon>
          <img src={imgLinks[`${iconToUse}`]} alt={iconToUse} />
        </StyledInfoWindowIcon>
      )}

      <StyledInfoWindowContent>
        {titleHtml && (
          <Typography variant="h2" dangerouslySetInnerHTML={{ __html: titleHtml || '' }} />
        )}

        {textHtml && (
          <Typography variant="p">
            <ReactMarkdown components={{ p: Fragment }}>{textHtml}</ReactMarkdown>
          </Typography>
        )}
      </StyledInfoWindowContent>

      {button && (
        <ButtonContainer>
          <Button
            variant={button.style || 'outlined'}
            onClick={button.func}
            href={button.href}
            target={button.target}
          >
            <Typography variant="buttons">{button.text}</Typography>
          </Button>
        </ButtonContainer>
      )}

      {buttons && (
        <ButtonContainer>
          {buttons.map((btn) => (
            <Button
              key={btn.text}
              variant={btn.style || 'outlined'}
              onClick={btn.func}
              href={btn.href}
              target={btn.target}
            >
              <Typography variant="buttons">{btn.text}</Typography>
            </Button>
          ))}
        </ButtonContainer>
      )}
    </>
  );
};

const buttonPropTypes = {
  text: PropTypes.string,
  func: PropTypes.func,
  href: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  style: PropTypes.string,
};

InfoWindow.propTypes = {
  mode: PropTypes.string,
  titleHtml: PropTypes.string,
  textHtml: PropTypes.string,
  button: PropTypes.shape(buttonPropTypes),
  buttons: PropTypes.arrayOf(PropTypes.shape(buttonPropTypes)),
};

export default InfoWindow;
