import React, { useState } from 'react';
import Slider from 'react-slick';
import { CSSTransition } from 'react-transition-group';

import { imgLinks } from '../../../shared';

import FressnapfSlide, { slidesConfig } from './FressnapfSlide';
import { StyledMobileSlidesContainer } from './Fressnapf.styled';
import {
  StyledOnlineVetSliderCounter,
  StyledOnlineVetSliderCustomDots,
} from '../OnlineVets.styled';

const renderCustomDots = (dots) => (
  <StyledOnlineVetSliderCustomDots>{dots}</StyledOnlineVetSliderCustomDots>
);

const FressnapfMobileSlides = () => {
  const [currentSlide, setCurrentSlide] = useState(null);
  const [nextSlide, setNextSlide] = useState(0);
  const sliderSettings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    appendDots: (dots) => renderCustomDots(dots), // custom dots
    beforeChange: () => {
      setNextSlide(nextSlide);
      setCurrentSlide(null);
    },
    afterChange: (idx) => {
      setCurrentSlide(slidesConfig[`${idx}`]);
    },
    onInit: () => {
      setCurrentSlide(slidesConfig[0]);
    },
  };

  return (
    <StyledMobileSlidesContainer>
      <CSSTransition in={!!currentSlide} timeout={400} classNames="fade" unmountOnExit>
        <StyledOnlineVetSliderCounter
          src={imgLinks[currentSlide?.img]}
          alt={currentSlide?.alt || ''}
        />
      </CSSTransition>

      <Slider {...sliderSettings}>
        {slidesConfig.map((card) => (
          <FressnapfSlide key={card.alt} {...card} />
        ))}
      </Slider>
    </StyledMobileSlidesContainer>
  );
};

export default FressnapfMobileSlides;
