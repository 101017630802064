import { styled, css, Paper } from '@mui/material';

export const StyledVerticalContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('LARGE')};
  `
);

export const StyledDentoloPaymentOverviewHelpContentPaper = styled(Paper)(
  ({ theme }) => css`
    background-color: ${theme.palette.main.professional_blue};
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing('SMALL')};
  `
);

export const StyledDentoloPaymentOverviewImage = styled('img')`
  max-width: 100%;
`;
