import { css, styled } from '@mui/material';

import { BackButton } from '../../components/common';
import ButtonContainer from '../../components/ButtonContainer';

export const StyledBackButton = styled(BackButton)(
  ({ theme }) => css`
    display: flex;

    ${theme.breakpoints.up('desktop')} {
      display: none;
    }
  `
);

export const StyledImg = styled('img')(
  ({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.spacing('LARGE')};
  `
);

export const StyledButtonContainer = styled(ButtonContainer)(
  ({ theme }) => css`
    display: none;

    ${theme.breakpoints.up('desktop')} {
      display: flex;
    }
  `
);
