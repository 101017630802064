import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { formatDate, INSURANCE_TYPE_PETOLO } from '../../shared';

import { RightCaretIcon } from '../common/Icon';
import {
  StyledClaimStatusCard,
  StyledClaimStatusCardButton,
  StyledArrowIcon,
  StyledTreatmentDetailsContainer,
  StyledTreatmentDetails,
  StyledTreatmentOtherInfo,
  StyledClaimStatus,
} from './styled/ClaimStatus.styled';

const ClaimStatus = ({ claim, setSelectedClaim }) => {
  const {
    id,
    key,
    status,
    treatmentCategory,
    statusDetails,
    notificationDate,
    lossDate,
    statusUpdated,
  } = claim;

  const history = useHistory();

  const { type: insuranceType } = useInsuranceInfo();
  const isPetolo = insuranceType === INSURANCE_TYPE_PETOLO;

  const handleClaimStatusClick = () => {
    setSelectedClaim({
      statusUpdated,
      notificationDate,
      treatmentCategory,
      statusDetails,
      lossDate,
      status,
      id,
      key,
    });

    history.push(`/user-claims-info/${id}`);
  };

  return (
    <StyledClaimStatusCard>
      <StyledClaimStatusCardButton
        statusUpdated={statusUpdated}
        onClick={handleClaimStatusClick}
        variant="nostyle"
        ariaLabel="Toggle Claim"
      >
        <>
          <StyledArrowIcon>
            <RightCaretIcon />
          </StyledArrowIcon>

          <StyledTreatmentDetailsContainer>
            <StyledTreatmentDetails>
              {!!treatmentCategory && (
                <Typography variant="h3">
                  <FormattedMessage id={`treatment.category.${treatmentCategory}`} />
                </Typography>
              )}
              {isPetolo && !treatmentCategory && (
                <Typography variant="h3">
                  <FormattedMessage id="treatment.category.pet_null" />
                </Typography>
              )}
            </StyledTreatmentDetails>
            <StyledTreatmentOtherInfo data-hj-suppress="true">
              <Typography variant="p">
                {formatDate(lossDate || notificationDate)} ({key})
              </Typography>
            </StyledTreatmentOtherInfo>
          </StyledTreatmentDetailsContainer>

          <StyledClaimStatus claimStatus={status}>
            <Typography variant="p">
              <FormattedMessage id={`claim.status.label.${status}`} />
            </Typography>
          </StyledClaimStatus>
        </>
      </StyledClaimStatusCardButton>
    </StyledClaimStatusCard>
  );
};

ClaimStatus.propTypes = {
  setSelectedClaim: PropTypes.func,
  claim: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    status: PropTypes.string,
    treatmentCategory: PropTypes.string,
    statusDetails: PropTypes.string,
    notificationDate: PropTypes.string,
    lossDate: PropTypes.string,
    statusUpdated: PropTypes.bool,
  }).isRequired,
};

export default ClaimStatus;
