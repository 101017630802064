import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { claimStatus, GET_CLAIMS, INSURANCE_TYPE_VITOLO } from '../../shared';

export default function useVitoloNPSSurvey() {
  const { type: insuranceType } = useInsuranceInfo();

  const claims = useQuery(GET_CLAIMS);

  const hasClosedClaim = claims?.data?.claims?.some((claim) => claim.status === claimStatus.CLOSED);

  useEffect(() => {
    if (insuranceType === INSURANCE_TYPE_VITOLO && hasClosedClaim) {
      window.hj('event', 'NPS_VITOLO_SURVEY');
    }
  }, [hasClosedClaim, insuranceType]);
}
